import { useHistory } from 'react-router';
import NimblLogo from '../../assets/Nimbl-logos/Nimbl_Primary-Horizontal-White.png';

const ExternalHeader = () => {
	const history = useHistory();
	return (
		<>
			<div className="w-100 p-2 d-flex">
				<img
					onClick={() => history.push('/')}
					style={{
						height: 50,
						cursor: 'pointer',
					}}
					alt="nimbl logo"
					src={NimblLogo}
				/>
				<button
					className="btn btn-outline-white border border-1 btn-lg py-1 ms-auto"
					onClick={() => history.push('/signin')}
				>
					Sign In
				</button>
			</div>
		</>
	);
};

export default ExternalHeader;
