import { MutableRefObject, useRef, useState } from 'react';
import ProfilePanel from '../../components/general-ui/profile-panel';
import LeftNav from '../../components/layout/left-nav-layout/left-nav-layout';
import { useAuth } from '../../providers/auth/auth-provider';
import EntitlementSettings, {
	EntitlementEditModal,
} from './entitlement-settings';
import GiftSettings, {
	GiftEditModal,
} from './gift-settings';

import {
	SettingsProvider,
	useSettings,
} from './settings-provider';
import UserModal from './user-modal';
import UserSettings from './user-settings';

const Settings = () => {
	const {
		settingsPath,
		permissionLevel,
		openUserModal,
		setOpenUserModal,
		openGiftModal,
		setOpenGiftModal,
		editingUser,
		openEntitlementModal,
		setOpenEntitlementModal,
	} = useSettings();

	const { user } = useAuth();

	const modalContainer =
		useRef() as MutableRefObject<HTMLDivElement>;

	return (
		<>
			<LeftNav>
				<div className="d-flex flex-row flex-grow-1 w-100 h-100">
					<div className="d-flex flex-column w-100 px-4 pb-4 h-100">
						<header className="d-flex justify-content-between  align-items-center py-3">
							<h1 className="text-white">
								{settingsPath === 'user' &&
									'User Management'}
								{settingsPath === 'gift' &&
									'Gift Management'}
								{settingsPath ===
									'entitlement' &&
									'Entitlement Management'}
							</h1>
							<ProfilePanel user={user} />
						</header>
						<div
							className="d-flex flex-column flex-grow-1"
							ref={modalContainer}
						>
							{settingsPath === 'user' && (
								<UserSettings />
							)}

							{settingsPath === 'gift' && (
								<GiftSettings />
							)}
							{settingsPath ===
								'entitlement' && (
								<EntitlementSettings />
							)}
						</div>
					</div>
				</div>
				<UserModal
					edit={!!editingUser}
					parentContainer={modalContainer}
					open={openUserModal}
					setOpen={setOpenUserModal}
				/>

				<GiftEditModal
					open={openGiftModal}
					setOpen={setOpenGiftModal}
					parentContainer={modalContainer}
				/>
				<EntitlementEditModal
					open={openEntitlementModal}
					setOpen={setOpenEntitlementModal}
					parentContainer={modalContainer}
				/>
			</LeftNav>
		</>
	);
};

const SettingsPage = () => (
	<SettingsProvider>
		<Settings />
	</SettingsProvider>
);

export default SettingsPage;
