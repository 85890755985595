import {
	ApolloError,
	ApolloQueryResult,
} from '@apollo/client';
import { onAuthStateChanged } from '@firebase/auth';

import { GetHermesUserQuery } from 'gql/queries/generated/graphql';

import {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

import { getHermesUser } from '../../gql/manual-fetch-calls/get-hermes-user';
import { auth } from '../../firebaseConfig/config';
import { apolloClient } from '../../gql/apollo-client';
import { signin } from '../../firebaseConfig/auth-functions';
import { toastToFailure } from '../../components/trigger-toasts/toast-to-failure';

// This defines the shape of the auth context which is exactly what gets returned
// When you type in `const context = useAuth()` or destructured `const {user, error, loading} = useAuth()`
export interface IAuthContext {
	user: GetHermesUserQuery['LoggedInUser'] | null;
	error: ApolloError | undefined;
	loading: boolean;
}

// Set the default values for auth context
export const AuthContext = createContext<IAuthContext>({
	user: null,
	loading: true,
	error: undefined,
});

// Any element wrapped in <AuthProvider></AuthProvider> needs to have a way to pass in children
export interface IAuthProviderProps {
	children: JSX.Element[] | JSX.Element;
}

// this wraps the complex logic for the useAuth hook
export function useProvideAuth() {
	// The loading state depends on if the hermes user is acquired
	// and if we are logged in via firebase so both states must be tracked
	// const [loggedIn, setLoggedIn] =
	// 	useState<boolean>(false);

	const [hermesUserResponse, setHermesUserResponse] =
		useState<ApolloQueryResult<GetHermesUserQuery>>();

	const [loading, setLoading] = useState<boolean>(true);
	const [signinLoading, setSigninLoading] =
		useState<boolean>(true);

	const fbUserFound = () =>
		Promise.resolve()
			.then(() => apolloClient.resetStore())
			.then(() => getHermesUser())
			.then(response => {
				setHermesUserResponse(response);
			})
			.then(() => {
				setLoading(false);
				// setLoggedIn(true);
			});

	// attempt to signin
	useEffect(() => {
		// signin on load => this just registers the auth user in the local storage on the front
		signin()
			.catch(error => {
				// eslint-disable-next-line no-console
				console.error(error);
				// not a problem, but errors are thrown in the signin method, just for debugging purposes
			})
			.finally(() => {
				setTimeout(() => {
					setSigninLoading(false);
				}, 1000); // doubled the wait time which will hopefully fix sign-in bug
			});

		return () => {};
	}, []);
	// set a timeout for finding firebase user

	useEffect(() => {
		// firebase listens for the state of the user's auth state
		// To get an idea of what exactly this translates to look in your local storage on the browser
		// That's where this function is configured to look through

		const authListener = onAuthStateChanged(
			auth,
			fbUser => {
				if (fbUser) {
					fbUserFound().catch(error => {
						setLoading(false);
						// setLoggedIn(false);
						setHermesUserResponse(undefined);
						toastToFailure(error?.message);
					});
				} else {
					setLoading(false);
					// setLoggedIn(false);
					setHermesUserResponse(undefined);
				}
			},
		);
		return () => {
			authListener();
		};
	}, []);

	const user = hermesUserResponse?.data.LoggedInUser;
	const error = hermesUserResponse?.error;

	return {
		user,
		// loading: loading && signinLoading,
		loading: !!(loading || signinLoading),
		error,
	};
}

export const AuthProvider = ({
	children,
}: IAuthProviderProps) => {
	const { user, loading, error } = useProvideAuth();

	return (
		<AuthContext.Provider
			value={{ user: user || null, loading, error }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error(
			'useAuth can only be used within the scope of an Auth Provider',
		);
	}
	return context;
};
