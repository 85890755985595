import { ApolloError } from '@apollo/client';
import {
	faCheckCircle,
	faExclamationCircle,
	faPlus,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Colors, {
	HermesColor,
} from '../../../../../colors.enum';
import TodoButton from '../../../../../components/todo-button';
import { toastToNotify } from '../../../../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../../../../components/trigger-toasts/toast-to-success';
import {
	GetExperienceDocument,
	useCreateMultiVersionEmailMutation,
	useRemoveMultiVersionEmailMutation,
	VersionType,
} from '../../../../../gql/queries/generated/graphql';
import promptModal from '../../../../../providers/alert-provider/prompt-modal';
import { useCurrentExperience } from '../../../current-experience.provider';
import { useEmailConfigurationStep } from './providers/email-configuration-step.provider';

interface TabProps {
	color: HermesColor;
	text: string;
	onClick: () => void;
	version:
		| 'settings'
		| VersionType.Versiona
		| VersionType.Versionb;
	onDeleteClick?: () => void;
	id: string;
}

const Tab = ({
	color,
	text,
	onClick,
	version,
	onDeleteClick,
	id,
}: TabProps) => (
	<div
		id={id}
		className={`bg-${color.toString()} tab d-flex rounded-top h-100 me-1 text-center`}
		style={{
			cursor: 'pointer',
		}}
		onClick={() => onClick()}
	>
		<p className="text-center m-0 fw-bold align-self-center px-3">
			{text}
		</p>
		{version === VersionType.Versionb && (
			<div className="tab-x-button">
				<FontAwesomeIcon
					icon={faTimes}
					color={Colors.white}
					className="pointer"
					style={{
						cursor: 'pointer',
						position: 'relative',
						top: 0,
						right: 4,
					}}
					onClick={e => {
						e.stopPropagation();
						if (onDeleteClick) {
							onDeleteClick();
						}
					}}
				/>
			</div>
		)}
	</div>
);

const PlusTab = ({ onClick }: { onClick: () => void }) => (
	<div
		data-cy="plus-tab"
		id="plusTab"
		className="tab plus-tab d-flex justify-content-center align-items-center px-2"
		onClick={() => onClick()}
	>
		<FontAwesomeIcon
			icon={faPlus}
			color="white"
			className="pointer"
			style={{ cursor: 'pointer' }}
		/>
	</div>
);

interface ConfigureEmailTabBarProps {
	showVersions: boolean;
	showSettings: boolean;
	showTodo: boolean;
	onTodo: () => void;
}

const ConfigureEmailTabBar = ({
	showVersions,
	showSettings,
	showTodo,
	onTodo,
}: ConfigureEmailTabBarProps) => {
	const {
		setShowSettings,
		setSelectedVersion,
		selectedVersion,
	} = useEmailConfigurationStep();

	const { data, refetch: refetchExperience } =
		useCurrentExperience();
	const experience = data?.GetHybridExperienceById;
	const experienceId = experience?.id || '';

	const [removeB] = useRemoveMultiVersionEmailMutation({
		variables: {
			hybridExperienceId: experienceId,
		},
		refetchQueries: [GetExperienceDocument],
	});

	const [createVersionB] =
		useCreateMultiVersionEmailMutation({
			variables: { experienceId },
			refetchQueries: [GetExperienceDocument],
		});

	const [creatingVersionB, setCreatingVersionB] =
		useState<boolean>(false);

	const color = {
		[VersionType.Versiona]: 'indigo',
		[VersionType.Versionb]: 'dark-turquoise',
		settings: 'gray-dark',
	}[selectedVersion];

	const showTab2 = showVersions;
	const showPlus = !showVersions;
	const deleteVersionB = () => {
		Promise.resolve()
			.then(
				promptModal(
					'Are you sure that you want to delete your Version B?',
					'stop',
				),
			)
			.then(async () => {
				setSelectedVersion(VersionType.Versiona);
				return removeB();
			})
			.then(async () => refetchExperience())
			.then(() => {
				toastToSuccess('Version B deleted');
			})
			.catch(() => {
				toastToNotify('Version B not deleted');
			});
	};

	return (
		<div
			className={`bg-${color} text-white d-flex rounded-top p-0 justify-content-between`}
		>
			{/* Tabs Container */}
			<div
				className={`bg-${color} text-white d-flex rounded-top p-0`}
			>
				<Tab
					color={'purple'}
					text="Version A"
					onClick={() => {
						setSelectedVersion(
							VersionType.Versiona,
						);
						setShowSettings(false);
					}}
					version={VersionType.Versiona}
					id={'tab-1'}
				/>
				{showTab2 && (
					<Tab
						color={'turquoise'}
						text="Version B"
						onClick={() => {
							setSelectedVersion(
								VersionType.Versionb,
							);
							setShowSettings(false);
						}}
						onDeleteClick={deleteVersionB}
						version={VersionType.Versionb}
						id={'tab-2'}
					/>
				)}
				{false && (
					<Tab
						color={'gray'}
						text="Settings"
						onClick={() => {
							setShowSettings(true);
						}}
						version={'settings'}
						id="tab-settings"
					/>
				)}
				{showPlus && (
					<PlusTab
						onClick={() => {
							if (
								!showVersions &&
								!creatingVersionB
							) {
								Promise.resolve()
									.then(() =>
										setCreatingVersionB(
											true,
										),
									)
									.then(() =>
										toastToNotify(
											'Creating version B...',
										),
									)
									.then(async () =>
										createVersionB(),
									)
									.then(async () =>
										refetchExperience(),
									)
									.then(() =>
										toastToSuccess(
											'Version B created',
										),
									)
									.catch(err => {
										console.error(err);
										throw new ApolloError(
											err,
										);
									})
									.finally(() =>
										setCreatingVersionB(
											false,
										),
									);
							}
						}}
					/>
				)}
			</div>
			{/* End Tabs Container */}
			{/* notification button */}
			<TodoButton
				showTodo={showTodo}
				onTodo={onTodo}
			/>
		</div>
	);
};

export default ConfigureEmailTabBar;
