import React from 'react';

export interface OrgField {
	displayName: string;
	field: string;
	value?: string | null;
}

interface props {
	index: number;
	orgUserFields: OrgField[];
	setOrgUserFields: React.Dispatch<
		React.SetStateAction<OrgField[] | undefined>
	>;
}

const OrgUserFieldInputComponent = ({
	index,
	orgUserFields,
	setOrgUserFields,
}: props) => {
	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const changingObject = { ...orgUserFields[index] };
		changingObject.value = e.target.value;
		setOrgUserFields([
			...orgUserFields.slice(0, index),
			changingObject,
			...orgUserFields.slice(index + 1),
		]);
	};

	return (
		<div className="my-3">
			<label
				htmlFor={orgUserFields[index].field}
				className="form-label required"
			>
				{orgUserFields[index].displayName}
			</label>
			<input
				data-cy="userFormOrgUserField"
				id={orgUserFields[index].field}
				required={true}
				value={orgUserFields[index].value || ''}
				onChange={e => handleChange(e)}
				type="text"
				className="form-control bg-white text-black"
			/>
		</div>
	);
};

export default OrgUserFieldInputComponent;
