import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';
import SignInPage from 'routes/signin-page/signin-page';
import { useAuth } from 'providers/auth/auth-provider';
import HomePage from 'routes/home-page/home-page';
import LoadingScreen from 'routes/loading-screen/loading-screen';
import ProtectedRoute from '../../components/protected-route/protected-route';
import { assignAuthState } from './assign-auth-state';
import ExperienceAnalyticsPage from '../experience-analytics/experience-analytics-page';
import CreateExperiencePage from '../create-experience-page/create-experience-page';
import SettingsPage from '../settings-page/settings-page';
import KnowledgeBasePage from '../knowledge-base-page/knowledge-base-page';
import FourOhFour from '../404';
import SplashPage from '../../components/splash-page/splash';
import ContactUs from '../../components/contact-us/contact';
import ThankYou from '../../components/thank-you/thankyou';

export default function AppRouter(): JSX.Element {
	const { user, loading } = useAuth();

	// assignAuthState returns either "authenticated" | "unauthenticated" | "loading"
	// , and feeds the protected routes to tell them whether or not to load
	const authState = assignAuthState(!!user, loading);

	// Check to see if user is trying to login with link
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const hasToken = urlParams.get('token');

	const defaultRouter = () => {
		if (
			authState === 'loading' ||
			(hasToken && authState !== 'authenticated')
		) {
			return <LoadingScreen />;
		}
		if (authState === 'authenticated') {
			return <Redirect to="/home" />;
		}
		return <SplashPage />;
	};

	return (
		<Router>
			<Switch>
				<Route exact path="/">
					{defaultRouter()}
				</Route>
				<ProtectedRoute
					authState={authState}
					redirectTo="/"
					path="/signin"
					loadingComponent={<LoadingScreen />}
					inverted
				>
					<SignInPage />
				</ProtectedRoute>
				<ProtectedRoute
					exact
					authState={authState}
					path="/home"
					redirectTo="/signin"
					loadingComponent={<LoadingScreen />}
				>
					<HomePage />
				</ProtectedRoute>

				<ProtectedRoute
					exact
					authState={authState}
					path="/create-experience/:step"
					redirectTo="/signin"
					loadingComponent={<LoadingScreen />}
					component={CreateExperiencePage}
				/>

				<ProtectedRoute
					exact
					authState={authState}
					path="/create-experience/:experienceId/:step"
					redirectTo="/signin"
					loadingComponent={<LoadingScreen />}
					component={CreateExperiencePage}
				/>

				<ProtectedRoute
					exact
					authState={authState}
					path="/experience-analytics"
					redirectTo="/signin"
					loadingComponent={<LoadingScreen />}
				>
					<ExperienceAnalyticsPage />
				</ProtectedRoute>

				<ProtectedRoute
					exact
					authState={authState}
					path="/experience-analytics/review-experience/:experienceId/"
					redirectTo="/signin"
					loadingComponent={<LoadingScreen />}
				>
					<ExperienceAnalyticsPage />
				</ProtectedRoute>

				<ProtectedRoute
					exact
					authState={authState}
					path="/settings/:settingsPath"
					redirectTo="/signin"
					loadingComponent={<LoadingScreen />}
				>
					<SettingsPage />
				</ProtectedRoute>

				<ProtectedRoute
					exact
					authState={authState}
					path="/knowledge-base"
					redirectTo="/signin"
					loadingComponent={<LoadingScreen />}
				>
					<KnowledgeBasePage />
				</ProtectedRoute>
				<Route exact path="/404">
					<FourOhFour />
				</Route>
				<Route exact path="/contact-us">
					<ContactUs />
				</Route>
				<Route exact path="/thank-you">
					<ThankYou />
				</Route>
				<Redirect to="/404" />
			</Switch>
		</Router>
	);
}
