import { HybridExperience } from '../../../gql/queries/generated/graphql';
import { EmailStepProvider } from '../../../providers/email-step/email-step.provider';
import CreateExperienceLayout from '../create-experience-layout';
import { CreateExperienceSubroute1 } from '../create-experience-types';
import { EmailConfigurationStepProvider } from './components/configure-email/providers/email-configuration-step.provider';
import AddEmailRouter from './router/add-email-router';

const AddEmailSubroute = ({
	experience,
	currentStep,
}: {
	experience: HybridExperience;
	currentStep: CreateExperienceSubroute1;
}) => (
	<EmailConfigurationStepProvider experience={experience}>
		<EmailStepProvider experience={experience}>
			<CreateExperienceLayout
				experience={experience}
				currentStep={currentStep}
			>
				<AddEmailRouter />
			</CreateExperienceLayout>
		</EmailStepProvider>
	</EmailConfigurationStepProvider>
);
export default AddEmailSubroute;
