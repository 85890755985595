import { useEffect, useState } from 'react';
import Colors from '../../../../colors.enum';
import CustomSlider from '../../../../components/slider/custom-slider';
import { toastToFailure } from '../../../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../../../components/trigger-toasts/toast-to-success';
import { useUpdateHybridExperienceSettingsMutation } from '../../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../../current-experience.provider';
import { useConfigurationStep } from '../review-step/review-step-provider';
import SliderSplitCard from './SliderSplitCard';

const TestSizePanel = () => {
	const { showVersionB, displayOnly } =
		useConfigurationStep();

	const [updatingSplit, setUpdatingSplit] =
		useState<boolean>(false);

	const {
		data: experienceQuery,
		refetch: refetchExperience,
	} = useCurrentExperience();
	const currentExperience =
		experienceQuery?.GetHybridExperienceById;

	const [percent, setPercent] = useState(
		currentExperience?.splitPercentage || 50,
	);

	const [updateHybridExperienceSettings] =
		useUpdateHybridExperienceSettingsMutation();

	const audienceCount =
		currentExperience?.audienceCount || 0;

	const aCount = () => {
		// Case: Odd audienceCount and 100% split
		if (audienceCount % 2 && percent === 100) {
			return (
				Math.floor(
					((percent / 100) * audienceCount) / 2,
				) + 1
			);
		}
		// Case: Simple split unless audienceCount is 1
		return (
			Math.floor(
				((percent / 100) * audienceCount) / 2,
			) || 1
		);
	};
	// Case: Simple split unless audience count is 1 and bCount should to be zero.
	const bCount = () =>
		Math.floor(((percent / 100) * audienceCount) / 2) ||
		(audienceCount > 1 ? 1 : 0);

	const winCount = audienceCount - aCount() - bCount();

	useEffect(() => {
		if (
			currentExperience &&
			showVersionB &&
			!currentExperience.splitPercentage
		) {
			updateHybridExperienceSettings({
				variables: {
					hybridExperienceId:
						currentExperience?.id || '',
					splitPercentage: percent,
				},
			});
		}
	}, [showVersionB]);

	const onUpdate = () => {
		if (!updatingSplit) {
			Promise.resolve()
				.then(() => setUpdatingSplit(true))
				.then(() =>
					toastToNotify(
						'Updating split percentage...',
					),
				)
				.then(() =>
					updateHybridExperienceSettings({
						variables: {
							hybridExperienceId:
								currentExperience?.id || '',
							// later on we can make testEvaluationLength a function of state
							testEvaluationLengthDays: 3,
							splitPercentage: percent,
						},
					})
						.then(() => refetchExperience())
						.then(() =>
							toastToSuccess(
								'Updated successfully',
							),
						)
						.catch(() => {
							toastToFailure(
								'There was a problem saving your updates, please try again',
							);
						}),
				)
				.catch(err => {
					toastToFailure(err.message);
				})
				.finally(() => setUpdatingSplit(false));
		}
	};

	if (showVersionB) {
		return (
			<div className=" text-white">
				<div
					className="bg-black p-3 h-100 d-flex flex-column"
					style={{ borderRadius: 10 }}
				>
					<div className="d-flex">
						<div>
							<h4 className="fw-bold">
								Test Size
							</h4>
							<p className="fw-light">
								This defines the portion of
								your total list that will be
								used for the test
							</p>
						</div>
						{!displayOnly && (
							<button
								onClick={() => onUpdate()}
								className="btn btn-sm ms-auto btn-turquoise h-100 text-white"
							>
								Update
							</button>
						)}
					</div>
					<CustomSlider
						onSlideChange={slideVal => {
							setPercent(slideVal);
						}}
						{...(displayOnly && {
							disabled: true,
						})}
					/>
					<div className="row mt-auto">
						<SliderSplitCard
							count={aCount()}
							title="Version A"
							color={Colors.purple}
						/>
						<SliderSplitCard
							count={bCount()}
							title="Version B"
							color={Colors.turquoise}
						/>
						<SliderSplitCard
							count={winCount}
							title="Winner"
							color={Colors.green}
						/>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className=" h-100 w-100">
			<div
				className="bg-black p-3 h-100 d-flex flex-column"
				style={{ borderRadius: 10 }}
			>
				<h5 className="fw-bold text-gray my-auto mx-auto w-75 text-center">
					Test evaluation settings not displayed
					if no experience components have been
					versioned.
				</h5>
			</div>
		</div>
	);
};
export default TestSizePanel;
