/* eslint-disable react/jsx-no-target-blank */

const NimblFooter = ({
	backgroundColor,
	textColor,
	footerType,
	footerLogo,
}: {
	backgroundColor: string;
	textColor: string;
	footerType: string;
	footerLogo: string;
}) => (
	<>
		{' '}
		<footer id="footer-main">
			<div
				className={`footer footer-${footerType} bg-${backgroundColor}`}
			>
				<div className="container text-dark fs-5">
					<div className="row pt-md">
						<div className="col-lg-4 mb-5 mb-lg-0">
							<a href="./">
								<img
									src={footerLogo}
									alt="Footer logo"
									style={{
										height: 70,
									}}
								/>
							</a>
							<p>
								Nimbl is a new service that
								allows you to construct
								immersive hybrid
								experiences. Whether your
								recipients are in the office
								or fully remote, Nimbl can
								help build your brand and
								engage your audience.
							</p>
						</div>
						<div className="ms-lg-auto col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
							<h6 className="heading mb-3">
								About
							</h6>
							<ul className="list-unstyled text-small">
								<li>
									<a
										target="_blank"
										href="https://www.pfl.com/about-us/"
									>
										About PFL
									</a>
								</li>
								<li>
									<a
										target="_blank"
										href="https://www.pfl.com/discover-how-to-start/"
									>
										Contact
									</a>
								</li>
								<li>
									<a
										target="_blank"
										href="https://www.pfl.com/partners/"
									>
										Partner With Us
									</a>
								</li>
							</ul>
						</div>
						<div className="col-lg-2 col-sm-4 mb-5 mb-lg-0">
							<h6 className="heading mb-3">
								Company
							</h6>
							<ul className="list-unstyled">
								<li>
									<a
										target="_blank"
										href="https://www.pfl.com/terms/#PrivacyPolicy"
									>
										Privacy
									</a>
								</li>
								<li>
									<a
										target="_blank"
										href="https://www.pfl.com/terms/"
									>
										Terms
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="row align-items-center justify-content-md-between py-4 delimiter-top">
						<div className="col-md-6">
							<div
								className={`copyright text-sm font-weight-bold text-center text-md-left text-${textColor}`}
							>
								&copy; 2022{' '}
								<a
									href="https://pfl.com"
									className="font-weight-bold"
									target="_blank"
								>
									PFL
								</a>
								. All rights reserved.
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</>
);

export default NimblFooter;
