import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/*
 * Simple editor component that takes placeholder text as a prop
 */

function Editor({
	placeholder,
	onChange,
	value,
}: {
	placeholder?: string;
	onChange: (arg0: string) => void;
	value: string;
}) {
	return (
		<ReactQuill
			onChange={html => {
				onChange(html);
			}}
			placeholder={
				placeholder || 'Write Something...'
			}
			value={value}
		/>
	);
}

export default Editor;
