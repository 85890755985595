import { HermesColor } from '../../../colors.enum';
import { VersionType } from '../../../gql/queries/generated/graphql';
import { useConfigurationStep } from './review-step/review-step-provider';
import TodoButton from '../../../components/todo-button';

interface TabProps {
	color: HermesColor;
	text: string;
	onClick: () => void;
	showTab: boolean;
	version:
		| 'settings'
		| VersionType.Versiona
		| VersionType.Versionb;
	onDeleteClick?: () => void;
}

const Tab = ({ color, text, onClick }: TabProps) => (
	<div
		id="tab-1"
		className={`bg-${color.toString()} tab d-flex rounded-top h-100 me-1 text-center`}
		style={{
			cursor: 'pointer',
		}}
		onClick={() => onClick()}
	>
		<p className="text-center m-0 fw-bold align-self-center px-3">
			{text}
		</p>
	</div>
);

const ReviewTabBar = ({
	showTodo,
	onTodo,
}: {
	showTodo: boolean;
	onTodo: () => void;
}) => {
	const {
		showVersionB,
		selectedVersion,
		setSelectedVersion,
	} = useConfigurationStep();

	const color = {
		[VersionType.Winner]: 'purple',
		[VersionType.Versiona]: 'indigo',
		[VersionType.Versionb]: 'dark-turquoise',
	}[selectedVersion as VersionType];

	return (
		<div
			className={`bg-${color} text-white d-flex rounded-top p-0 justify-content-between`}
		>
			<div
				className={`bg-${color} text-white d-flex rounded-top p-0`}
			>
				<Tab
					color={'purple'}
					text="Version A"
					showTab={true}
					onClick={() => {
						setSelectedVersion(
							VersionType.Versiona,
						);
					}}
					version={VersionType.Versiona}
				/>
				{/* show tab 2 */}
				{showVersionB && (
					<Tab
						showTab={showVersionB}
						color={'turquoise'}
						text="Version B"
						onClick={() => {
							setSelectedVersion(
								VersionType.Versionb,
							);
						}}
						version={VersionType.Versionb}
					/>
				)}
			</div>
			<TodoButton
				showTodo={showTodo}
				onTodo={onTodo}
			/>
		</div>
	);
};

export default ReviewTabBar;
