import NavCircle from './nav-circle';
import {
	assignBorderColor,
	assignIcon,
} from './navbar-helper-functions';

export type IRouteMap = {
	label: string;
	valid: boolean;
}[];

export type OnClickStepHandler = (
	step: number,
	reachable: boolean,
) => void;

interface CreateExperienceNavbarProps {
	// controls the state of the navbar
	routeMap: IRouteMap;

	// The maximum step that a user can reach if they want to.
	maxStep: number;

	// This is just for styling the label
	labelClassName: string;

	// an icon, or any other JSX element, if that step is valid( Try a checkmark )
	validIcon: JSX.Element;

	// an icon, or any other JSX element, if that step is invalid( Try a red X, or something )
	invalidIcon: JSX.Element;

	// an icon, or any other JSX element, if that step is unreachable( Try a lock icon)
	unreachableIcon: JSX.Element;

	// an icon, or any other JSX element, if that step is unreachable( Try a rocketship )
	currentIcon: JSX.Element;

	// The current step that you are on
	selectedStep: number;

	// If a step is reachable and before or equal to the current step, then it will be accented with this
	reachableHighlightColor: string;

	// If a step is reachable but after the current step, then it will be accented with this
	reachableInactiveHighlightColor: string;

	// If a step is not reachable it will be accented with this color
	unreachableHighlightColor: string;

	// Allow to click at an index, and do something
	onClickStep?: OnClickStepHandler;
}

const CreateExperienceNavbar = ({
	routeMap,
	maxStep,
	labelClassName,
	validIcon,
	invalidIcon,
	unreachableIcon,
	currentIcon,
	selectedStep,
	reachableHighlightColor,
	reachableInactiveHighlightColor,
	unreachableHighlightColor,
	onClickStep,
}: CreateExperienceNavbarProps) => (
	<div>
		<div className="navbar-label-container-bar nodrag">
			{routeMap.map((route, index) => (
				<div
					className="navbar-label-container"
					key={index + route.label}
				>
					<p
						className={`navbar-label ${labelClassName}`}
					>
						{route.label}
					</p>
				</div>
			))}
		</div>
		<div id="create-experience-navbar-container">
			<nav id="create-experience-navbar">
				{routeMap.map((route, index) => {
					const icon = assignIcon({
						maxStep,
						icons: {
							valid: validIcon,
							invalid: invalidIcon,
							current: currentIcon,
							unreachable: unreachableIcon,
						},
						index,
						isValid: route.valid,
						selectedStep,
					});

					const isReachable = maxStep >= index;
					const isActive = selectedStep >= index;

					const borderColor = assignBorderColor({
						isReachable,
						isActive,
						reachableHighlight:
							reachableHighlightColor,
						reachableInactiveHighlight:
							reachableInactiveHighlightColor,
						unreachableHighlight:
							unreachableHighlightColor,
					});

					if (index === 0) {
						// first circle
						return (
							<NavCircle
								key={`create-experience-option-${index}`}
								position="first"
								icon={icon}
								borderColor={borderColor}
								onClick={() => {
									if (onClickStep) {
										onClickStep(
											index,
											isReachable,
										);
									}
								}}
							/>
						);
					}

					if (index === routeMap.length - 1) {
						// last circle
						return (
							<NavCircle
								key={`create-experience-option-${index}`}
								position="last"
								icon={icon}
								onClick={() => {
									if (onClickStep) {
										onClickStep(
											index,
											isReachable,
										);
									}
								}}
								borderColor={borderColor}
							/>
						);
					}

					return (
						// middle circles
						<NavCircle
							key={`create-experience-option-${index}`}
							position="middle"
							icon={icon}
							reachable={isReachable}
							borderColor={borderColor}
							onClick={() => {
								if (onClickStep) {
									onClickStep(
										index,
										isReachable,
									);
								}
							}}
						/>
					);
				})}
			</nav>

			<div id="create-experience-line-container">
				{routeMap.map((route, index) => {
					if (index < routeMap.length) {
						if (
							index >= maxStep &&
							// may have to change later
							maxStep !== 5
						) {
							return (
								<div
									className="create-experience-line"
									style={{
										backgroundColor:
											unreachableHighlightColor, // unreachable
									}}
									key={
										route.label + index
									}
								></div>
							);
						}

						if (
							index >= selectedStep &&
							selectedStep !== 5
						) {
							return (
								<div
									key={index}
									className="create-experience-line"
									style={{
										backgroundColor:
											reachableInactiveHighlightColor, // reachable-inactive
									}}
								></div>
							);
						}

						return (
							<div
								key={index}
								className="create-experience-line"
								style={{
									backgroundColor:
										reachableHighlightColor, // reachable
								}}
							></div>
						);
					}
					return <></>;
				})}
			</div>
		</div>
	</div>
);

export default CreateExperienceNavbar;
