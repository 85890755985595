import ConfigureEmailMappingForm from './configure-email-mapping-form';

export const ConfigureEmailMapping = () => (
	<div className={`col h-100 d-flex flex-column`}>
		<div
			className="d-flex flex-column flex-grow-1 mx-auto w-100"
			style={{
				marginBottom: '70px',
			}}
		>
			<div className="shrink-content scrollbar">
				<ConfigureEmailMappingForm />
			</div>
		</div>
	</div>
);
