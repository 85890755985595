import React, { useEffect, useState } from 'react';
import UserRow from './userRow';

import { User } from '../../../../gql/queries/generated/graphql';
import SearchInput from '../../../../components/inputs/search-input';

const UsersTable = ({
	editableUsers,
	handleDelete,
	setOpenModal,
	setEditingUser,
}: {
	editableUsers: [User];
	handleDelete: (user: User) => void;
	setOpenModal: (modal: boolean) => void;
	setEditingUser: (arg0: any) => void;
}) => {
	const [users, setUsers] = useState<[User]>(
		editableUsers || [],
	);

	const [filteredUsers, setFilteredUsers] =
		useState<[User]>();

	useEffect(() => {
		setFilteredUsers(editableUsers);
	}, [editableUsers]);

	console.log(filteredUsers);

	return (
		<div className="container d-flex flex-column flex-grow-1 h-100">
			<div className="border-1 pt-6">
				<div className="card-toolbar">
					<div className="d-flex justify-content-between px-5">
						<div className=" my-4 d-flex">
							<div className="w-auto">
								<h4 className="mb-0">
									Current Users
								</h4>
								<p>
									<span className="text-purple">
										{`${editableUsers.length} `}
									</span>
									/ Unlimited
								</p>
							</div>
							<SearchInput
								searchArray={editableUsers}
								fieldsToSearch={[
									'firstName',
									'lastName',
									'emailAddress',
								]}
								setSearchedObject={
									setFilteredUsers
								}
								placeholder={'Search Users'}
								keyField={'id'}
							/>
						</div>
						<div className="d-flex justify-content-end">
							<button
								id="user-add-btn"
								data-cy="user-add-btn"
								type="button"
								className="btn text-white btn-purple"
								onClick={() => {
									setEditingUser(
										undefined,
									);
									setOpenModal(true);
								}}
							>
								Add User
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				className="mx-3 px-4 table-responsive shrink-content"
				style={{
					overflow: 'auto',
				}}
			>
				<table className="table align-middle fs-6 gy-5">
					<thead
						className="sticky-top"
						style={{
							backgroundColor: '#1e1f23',
							zIndex: 1,
						}}
					>
						<tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
							<th className="min-w-125px">
								User
							</th>
							<th className="min-w-125px">
								Permission
							</th>
							<th className="min-w-125px">
								Entitlement
							</th>
							<th className="min-w-125px">
								Last login
							</th>

							<th className="text-center min-w-100px">
								Actions
							</th>
						</tr>
					</thead>
					<tbody className="text-gray-600 fw-bold">
						{filteredUsers &&
							filteredUsers.map(
								(
									user: User,
									userIndex: number,
								) => (
									<UserRow
										user={user}
										key={userIndex}
										handleDelete={
											handleDelete
										}
										setOpenModal={
											setOpenModal
										}
										setEditingUser={
											setEditingUser
										}
									/>
								),
							)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default UsersTable;
