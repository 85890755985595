import { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { Gift } from '../../../gql/queries/generated/graphql';
import AccordionItem from './accordion-item';
import CartCard from './cart-card';
import CheckboxContainer, {
	ICheckbox,
} from './checkbox-container';
import { GiftFilters } from './gift-types';
import { priceCheckboxData } from './price-brackets';
import { useConfigurationStep } from './select-gift-step/select-gift-step-provider';

// Cart cards show up in the left hand "filter column, when someone has added a product to their experience for that version"

interface FilterColumnProps {
	setFilters: Dispatch<SetStateAction<GiftFilters>>;
	filters: GiftFilters;
}

const FilterColumn = ({
	setFilters,
	filters,
}: FilterColumnProps) => {
	const { selectedGiftsAtVersion } =
		useConfigurationStep();

	const [searchInput, setSearchInput] =
		useState<string>('');

	const [maxValue, setMaxValue] =
		useState<number>(100000000000);
	const [minValue, setMinValue] = useState<number>(0);

	const onChangePriceRanges = (
		checkboxStates: ICheckbox<{
			minValue: number;
			maxValue: number;
		}>[],
	) => {
		const priceRanges = checkboxStates
			.filter(cbstate => cbstate.checked)
			.map(cbstate => ({
				maxValue: cbstate.value.maxValue,
				minValue: cbstate.value.minValue,
			}));

		const newFilter = (gift: Gift) =>
			priceRanges.length > 0
				? priceRanges.some(
						priceRange =>
							gift?.price &&
							gift?.price >=
								priceRange.minValue &&
							gift?.price &&
							gift?.price <=
								priceRange.maxValue,
				  )
				: true;
		setFilters({ ...filters, priceFilter: newFilter });
	};

	useEffect(() => {
		setFilters({
			...filters,
			searchFilter:
				searchInput.length === 0
					? gift => true
					: (gift: Gift) =>
							gift.name
								.toLowerCase()
								.includes(
									searchInput.toLowerCase(),
								),
		});
	}, [searchInput]);

	// Sets Filters For The Filter Column
	useEffect(() => {
		setFilters({
			...filters,
			priceFilter: gift => {
				if (!gift.price) {
					return true;
				}
				if (gift.price > maxValue) {
					return false;
				}
				if (gift.price < minValue) {
					return false;
				}
				return true;
			},
		});
	}, [minValue, maxValue]);

	return (
		<div className="h-100 w-100">
			<form>
				<div className="p-3">
					{/** TODO: Implement searching */}
					<input
						type="text"
						className="form-control bg-white text-black"
						aria-describedby="emailHelp"
						placeholder="&#xF002; Search"
						onChange={e =>
							setSearchInput(
								e.currentTarget.value,
							)
						}
						value={searchInput}
						style={{
							fontFamily:
								'FontAwesome, Arial',
							fontStyle: 'normal',
						}}
					/>
					<div className="accordion my-3">
						<AccordionItem header={'Price'}>
							<CheckboxContainer
								initialChecked={
									priceCheckboxData
								}
								onChange={
									onChangePriceRanges
								}
							/>
						</AccordionItem>
					</div>
					<div className="my-5">
						<p className="border-bottom border-white pb-3 ps-4">
							My Gifts (
							{(selectedGiftsAtVersion &&
								selectedGiftsAtVersion.length) ||
								0}
							)
						</p>
						{selectedGiftsAtVersion &&
						selectedGiftsAtVersion.length !==
							0 ? (
							selectedGiftsAtVersion.map(
								(gift, giftIndex) => (
									<CartCard
										gift={gift}
										key={giftIndex}
									/>
								),
							)
						) : (
							<p>
								Your experience does not
								currently have any gifts.
							</p>
						)}
					</div>
				</div>
			</form>
		</div>
	);
};

export default FilterColumn;
