import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs, { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import Select from 'react-select';
import Colors from '../../../../colors.enum';
import HighlightedBox from '../highlighted-box';

export default function LaunchDateCard({
	date,
	availableTimes,
	selectedTime,
	setSelectedTime,
}: {
	date: Dayjs | undefined;
	availableTimes: Dayjs[];
	selectedTime: Dayjs | undefined;
	setSelectedTime: (time: Dayjs) => void;
}) {
	const options = availableTimes.map(availableTime => ({
		value: availableTime.toString(),
		label: availableTime.format('hh:mm:A'),
	}));

	return (
		<HighlightedBox
			color={Colors.green}
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
			}}
		>
			<div className="w-100 d-flex text-white">
				<FontAwesomeIcon
					icon={faCalendar}
					color={'white'}
					size="2x"
				/>
				<h5 className="ms-2 my-auto">
					Launch Date
				</h5>
			</div>
			<p className="text-white">
				Date:{' '}
				{(date && date.format('MMMM DD, YYYY')) ||
					'Not Set'}
			</p>
			<Select
				className="my-2 flex-grow-1"
				defaultValue={{
					value:
						(selectedTime &&
							selectedTime.format(
								'hh:mm:A',
							)) ||
						availableTimes[0].toString(),
					label:
						(selectedTime &&
							selectedTime.format(
								'hh:mm:A',
							)) ||
						'Not Set',
				}}
				value={{
					value:
						selectedTime &&
						selectedTime.toString(),
					label:
						selectedTime &&
						selectedTime.format('hh:mm:A'),
				}}
				options={options}
				styles={{
					container: prev => ({
						...prev,
						color: 'black',
					}),
				}}
				onChange={input => {
					const correctdayjs = dayjs(
						input?.value,
					).isValid()
						? dayjs(input?.value)
						: dayjs();
					setSelectedTime(correctdayjs);
				}}
			/>
		</HighlightedBox>
	);
}
