import ReactDOM from 'react-dom';
import { IStepLeft } from '../../routes/create-experience-page/validations/types.validation';
import Modal from './modal';
import TodoModal from './todo-modal';

type HandleButton = (arg0: () => string) => void;
interface IButton {
	handler: () => string;
	name: string;
	className?: string;
}

const defaultTwoButtonAlertProps = {
	title: 'MessageBox',
	content: (<></>) as JSX.Element | JSX.Element[],
	container: '#left-nav-main-container',
	buttons: [] as IButton[],
};

const TwoButtonAlertModal = ({
	title,
	content,
	buttons,
	handleButton,
}: {
	title: string;
	content: JSX.Element | JSX.Element[];
	buttons: IButton[];
	handleButton: HandleButton;
}) => (
	<Modal title={title}>
		<>
			{content}
			<div className=" modal-footer w-100 d-flex justify-content-between p-5">
				{buttons.map(btn => (
					<button
						onClick={e => {
							(
								handleButton(
									btn.handler,
								) as any
							)(e);
						}}
						key={btn.name}
						className={btn.className || ''}
						data-cy={`${btn.name}-button`}
					>
						{btn.name}
					</button>
				))}
			</div>
		</>
	</Modal>
);

export default {
	openTodoList: ({
		title,
		tasks,
		subtitle,
		headerA,
		headerB,
	}: {
		title: string;
		tasks: IStepLeft[];
		subtitle: string;
		headerA: string;
		headerB: string;
	}) => {
		const container = '#left-nav-main-container';
		/* const */
		// const
		return new Promise(resolve => {
			const containerElement =
				document.querySelector(container);

			if (!containerElement)
				throw Error(
					`can't find container ${container}`,
				);

			const tempDiv = document.createElement('div');
			containerElement.appendChild(tempDiv);
			// render in child
			// unmount
			const handleClose = (value: string) => {
				let result = null;
				if (value) {
					result = value;
				}
				ReactDOM.unmountComponentAtNode(tempDiv);
				return resolve(result);
			};

			ReactDOM.render(
				<TodoModal
					tasks={tasks}
					title={title}
					subtitle={subtitle}
					closeFunction={() => {
						handleClose('null');
					}}
					headerA={headerA}
					headerB={headerB}
				/>,
				tempDiv,
			);
		});
	},

	openTwoButtonAlert: (
		props: typeof defaultTwoButtonAlertProps,
	) =>
		new Promise(resolve => {
			const { title, content, container, buttons } = {
				...defaultTwoButtonAlertProps,
				...props,
			};
			const containerElement =
				document.querySelector(container);

			if (!containerElement)
				throw Error(
					`can't find container ${container}`,
				);

			const tempDiv = document.createElement('div');
			containerElement.appendChild(tempDiv);
			// render in child
			// unmount

			const handleClose = (value: string) => {
				let result = null;
				if (value) {
					result = value;
				}
				ReactDOM.unmountComponentAtNode(tempDiv);
				return resolve(result);
			};

			const handleButton: HandleButton =
				(handler: () => string) => () => {
					handleClose(handler());
				};

			ReactDOM.render(
				<TwoButtonAlertModal
					buttons={buttons}
					content={content}
					handleButton={handleButton}
					title={title}
				/>,
				tempDiv,
			);
		}),
};
