import { useState } from 'react';
import { useHistory } from 'react-router';
import {
	Gift,
	useGetGiftsByIdsQuery,
} from '../../../../gql/queries/generated/graphql';
import LearnMoreModal from './learn-more-modal';
import { useConfigurationStep } from '../review-step/review-step-provider';

interface GiftDisplayProps {
	image: string;
	name: string;
	price: number;
	gift: Gift;
}

const GiftDisplay = ({
	image,
	name,
	price,
	gift,
}: GiftDisplayProps) => {
	const [openModal, setOpenModal] =
		useState<boolean>(false);
	const { color } = useConfigurationStep();

	return (
		<>
			<div className="col mx-auto">
				<div className="card bg-white ">
					<div className="card-body pb-0 pt-3 border-0 text-dark">
						<div className="card-px pt-5 pb-5 d-flex justify-content-between">
							<h2
								className={`fw-bold text-${color} ms-0 my-0 fw-normal d-flex`}
							>
								{name}
							</h2>
							<h2
								className={`fw-bold text-${color} ms-auto my-0 fw-normal d-flex`}
							>
								${price}
							</h2>
						</div>
					</div>
					<div className="card-body ratio ratio-4x3 p-0">
						<img
							className="img-fluid"
							src={image}
						/>
					</div>
					<div className="card-footer bg-white border-0 text-center">
						<button
							style={{ borderRadius: '50px' }}
							type="button"
							className={`w-75 my-1 btn btn-outline btn-outline-${color} fw-bold`}
							onClick={() =>
								setOpenModal(true)
							}
						>
							Learn More
						</button>
					</div>
				</div>
				<LearnMoreModal
					gift={gift}
					open={openModal}
					setOpen={setOpenModal}
				/>
			</div>
		</>
	);
};

const GiftReview = () => {
	const {
		currentExperience,
		selectedVersion,
		displayOnly,
	} = useConfigurationStep();
	const history = useHistory();
	const versionGifts =
		currentExperience?.selectedGifts.filter(
			selected =>
				selected.versionType === selectedVersion,
		);
	const selectedGifts =
		versionGifts && versionGifts.length < 1
			? currentExperience?.selectedGifts
			: versionGifts;

	const giftIds: string[] = [];

	selectedGifts?.forEach(gift => {
		giftIds.push(gift.gift.id);
	});

	const giftDetails = useGetGiftsByIdsQuery({
		variables: {
			giftIds,
		},
	});

	return (
		<div className="d-flex flex-column h-100">
			<div className="row h-100 w-100 mx-auto">
				<div className="d-flex p-3">
					<h3
						className="text-white"
						style={{
							fontWeight: 900,
						}}
					>
						Gift Overview
					</h3>
					{!displayOnly && (
						<button
							onClick={() =>
								history.push(
									`/create-experience/${
										currentExperience?.id ||
										''
									}/choose-gift`,
								)
							}
							className="ms-auto btn btn-light"
						>
							Edit Gifts
						</button>
					)}
				</div>
				<div className="col">
					<div className="row row-cols-1 row-cols-lg-2 row-cols-xxxl-3 align-items-stretch m-4 p-3">
						{giftDetails.data?.GetGiftsByIds?.map(
							(gift, giftIndex) => (
								<GiftDisplay
									name={gift?.name || ''}
									price={gift?.price || 0}
									gift={gift as Gift}
									image={
										gift?.previewImage
											?.url || ''
									}
									key={giftIndex}
								/>
							),
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default GiftReview;
