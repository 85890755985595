import { Email } from '../../../gql/queries/generated/graphql';
import {
	HybridExperience,
	VersionType,
} from '../../../gql/queries/generated/graphql';
import { isTruthey } from '../../../util/is-truthey';
import { IStepLeft } from './types.validation';

const isExperienceConfigured = ({
	audienceConfigured,
	emailConfigured,
	giftConfigured,
	pageConfigured,
	printConfigured,
	readyToLaunch,
}: HybridExperience): boolean =>
	[
		audienceConfigured,
		emailConfigured,
		giftConfigured,
		pageConfigured,
		printConfigured,
		readyToLaunch,
	].every(isTruthey);

export const getExperienceSteps = (
	experience: any,
): IStepLeft[] => {
	const steps: IStepLeft[] = [
		{
			message: 'Audience Uploaded',
			complete:
				experience?.audienceConfigured || false,
			version: VersionType.Versiona,
		},
		{
			message: 'Email Steps Complete',
			complete:
				experience?.audienceConfigured || false,
			version: VersionType.Versiona,
		},
		{
			message: 'Gift Steps Complete',
			complete: experience?.giftConfigured || false,
			version: VersionType.Versiona,
		},
		{
			message: 'Page Steps Complete',
			complete: experience?.pageConfigured || false,
			version: VersionType.Versiona,
		},
		{
			message: 'Print Steps Complete',
			complete: experience?.printConfigured || false,
			version: VersionType.Versiona,
		},
		{
			message: 'Launch Date Scheduled',
			complete: experience?.readyToLaunch || false,
			version: VersionType.Versiona,
		},
	];
	return steps;
};
