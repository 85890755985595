import { useState } from 'react';
import { useHistory } from 'react-router';
import { UserPermissionLevel } from '../../gql/queries/generated/graphql';

interface NavItemProps {
	icon: string;
	title: string;
	onClick: () => void;
	onHover: (path: string) => void;
	isActive: boolean;
	path: string;
	dataCy: string;
}

const NavItem = ({
	icon,
	title,
	onClick,
	onHover,
	path,
	dataCy,
	isActive,
}: NavItemProps) => (
	<li
		onMouseEnter={() => onHover(path)}
		onClick={() => onClick()}
		className={`${
			isActive && 'active'
		} btn btn-active-color-primary btn-left btn-active-light w-100 nav-item`}
		style={{ cursor: 'pointer' }}
		data-cy={`${dataCy}`}
	>
		<div className={`d-flex text-left`}>
			<a
				className={`nav-link text-white text-left`}
				aria-current="page"
				data-cy={`${dataCy}-link`}
			>
				<i
					className={` text-white me-2 ${icon}`}
				></i>
				{title}
			</a>
		</div>
	</li>
);
const SettingsNav = ({
	permLevel,
	settingsPath,
}: {
	permLevel: string;
	settingsPath: string;
}) => {
	const history = useHistory();
	const [highlightItem, setHighlightItem] =
		useState<string>(settingsPath);

	return (
		<div className="aside-secondary d-flex flex-row-fluid border border-right-0 border-top-0 border-bottom-0">
			<div
				className="aside-workspace"
				id="kt_aside_workspace"
			>
				<div className="tab-content">
					<div
						className="tab-pane fade show active"
						id="kt_aside_tab_1"
					>
						<div
							className="menu menu-column menu-fit menu-rounded menu-title-gray-900 menu-icon-gray-900 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-5 my-5 mt-lg-2 mb-lg-0"
							id="kt_aside_menu"
							data-kt-menu="true"
						>
							<div
								className="menu-fit hover-scroll-y"
								id="kt_aside_menu_wrapper"
								data-kt-scroll="true"
								data-kt-scroll-activate="{default: false, lg: true}"
								data-kt-scroll-height="auto"
								data-kt-scroll-offset="20px"
								data-kt-scroll-wrappers="#kt_aside_workspace"
							>
								<div className="menu-item pt-5">
									<div className="menu-content text-center">
										<span className="fw-bold fs-3 text-uppercase fs-7 text-white">
											Settings
										</span>
									</div>
								</div>
								<NavItem
									isActive={history.location.pathname.includes(
										'/user',
									)}
									onHover={path => {
										setHighlightItem(
											path,
										);
									}}
									path="user"
									onClick={() =>
										history.push(
											'./user',
										)
									}
									icon="fa-thin fa-users"
									title="Users"
									dataCy="user"
								/>
								{permLevel ===
									UserPermissionLevel.Systemadmin && (
									<NavItem
										isActive={history.location.pathname.includes(
											'/gift',
										)}
										onHover={path => {
											setHighlightItem(
												path,
											);
										}}
										path="gift"
										onClick={() =>
											history.push(
												'./gift',
											)
										}
										icon="fa-thin fa-gifts"
										title="Gifts"
										dataCy="gift"
									/>
								)}
								<NavItem
									isActive={history.location.pathname.includes(
										'/entitlement',
									)}
									onHover={path => {
										setHighlightItem(
											path,
										);
									}}
									path="entitlement"
									onClick={() =>
										history.push(
											'./entitlement',
										)
									}
									icon="fa-thin fa-tools"
									title="Entitlements"
									dataCy="entitlement"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SettingsNav;
