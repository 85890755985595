import { useState } from 'react';

export interface AccordionProps {
	header: string;
	children?: JSX.Element | JSX.Element[];
}

// This houses a set of checkboxes
const AccordionItem = ({
	header,
	children,
}: AccordionProps) => {
	const [expanded, setExpanded] =
		useState<boolean>(false);

	return (
		<div className="accordion">
			<div className="accordion-item border-0 bg-dark text-white">
				<h2
					className="accordion-header"
					id="headingOne"
				>
					<button
						className={`accordion-button btn-dark bg-dark text-white border-bottom border-white border-bottom-1 ${
							expanded ? 'collapsed' : ''
						}`}
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#collapseOne"
						aria-expanded="false"
						style={{ textDecoration: 'none' }}
						aria-controls="collapseOne"
						onClick={() =>
							setExpanded(!expanded)
						}
					>
						{header}
					</button>
				</h2>
				<div
					id="collapseOne"
					className={`accordion-collapse collapse ${
						expanded === true && 'show'
					}`}
					aria-labelledby="headingOne"
					data-bs-parent="#accordionExample"
				>
					<div className="accordion-body">
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccordionItem;
