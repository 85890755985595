// This is to help choose whether or not to let a user through depending on the state variables
export const assignAuthState = (
	isUser: boolean,
	isLoading: boolean,
) => {
	if (isLoading) {
		return 'loading';
	}

	if (!isUser) {
		return 'unauthenticated';
	}

	return 'authenticated';
};

export const invertAuthStateIfInverted = (
	state: 'unauthenticated' | 'loading' | 'authenticated',
	inverted: boolean,
) => {
	if (inverted) {
		if (state === 'authenticated') {
			return 'unauthenticated';
		}
		if (state === 'unauthenticated') {
			return 'authenticated';
		}
		if (state === 'loading') {
			return state;
		}
	}
	return state;
};
