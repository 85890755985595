const defaultProps = {
	reachable: false,
	inactive: false,
};

export interface NavCircleProps {
	position: 'first' | 'middle' | 'last';
	icon: JSX.Element;
	borderColor: string;
	onClick?: () => void;
}

const NavCircle = ({
	position,
	icon,
	borderColor,
	onClick,
}: NavCircleProps & typeof defaultProps) => (
	<div
		className={`nav-circle-container ${`nav-circle-container-${position}`} `}
	>
		<div>
			<div
				className={`nav-circle`}
				onClick={() => {
					if (onClick) {
						onClick();
					}
				}}
				style={{
					borderColor,
					cursor: onClick ? 'pointer' : 'default',
				}}
			>
				{icon}
			</div>
		</div>
	</div>
);

NavCircle.defaultProps = defaultProps;

export default NavCircle;
