import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useEmailStep } from '../../../../../providers/email-step/email-step.provider';
import PrevAndNextContainer from '../../../components/prev-and-next-container';
import { useCurrentExperience } from '../../../current-experience.provider';
import ConfigureVersionBrowser from './configure-email-browser';
import RenderWindow from '../render-window/render-window';
import ConfigureEmailTabBar from './configure-email-tab-bar';
import { useEmailConfigurationStep } from './providers/email-configuration-step.provider';
import {
	Email,
	ExperienceStep,
	HybridExperience,
} from '../../../../../gql/queries/generated/graphql';
import { getEmailSteps } from '../../../validations/email.validations';
import messagebox from '../../../../../providers/alert-provider/messagebox';

const ConfigureEmailLayout = () => {
	// Experience data
	const { data } = useCurrentExperience();
	const history = useHistory();
	const currentExperience = data?.GetHybridExperienceById;
	const maxStep = currentExperience?.maxStep;
	const emails = currentExperience?.emails || [];

	// only matters for the first step
	const { setEmailStep, setValidity } = useEmailStep();

	const { emailHtml, showSettings, selectedVersion } =
		useEmailConfigurationStep();

	const selectedEmail =
		emails.find(
			email => email.version === selectedVersion,
		) || null;

	const nextButtonState =
		maxStep !== ExperienceStep.Email
			? 'enabled'
			: 'disabled';

	const onNext = () => {
		history.push(
			`/create-experience/${
				currentExperience?.id || 'null'
			}/configure-form`,
		);
	};
	const onPrev = () => {
		setEmailStep('select-template');
	};

	const showVersionTabs = emails.length > 1;

	useEffect(() => {
		if (maxStep !== ExperienceStep.Email) {
			setValidity('configure-email', true);
			setValidity('loading', true);
			setValidity('select-template', true);
		}
	}, [maxStep, currentExperience]);

	const emailSteps =
		getEmailSteps(emails as Email[]) || [];
	const showTodos = !emailSteps.every(
		email => email.complete === true,
	);

	return (
		<>
			<div className="d-flex flex-column flex-grow-1">
				<div className="row w-100 h-100 mx-auto px-0 d-flex flex-grow-1 mt-2">
					<div className="col-6 col-xxxl-7 p-2 d-flex flex-column">
						<ConfigureEmailTabBar
							showVersions={showVersionTabs}
							showSettings={showSettings}
							onTodo={() => {
								messagebox.openTodoList({
									tasks: getEmailSteps(
										emails as Email[],
									),
									title: 'Email: ',
									subtitle:
										'Please complete all of the configuration steps below to continue.',
									headerA: 'A',
									headerB: 'B',
								});
							}}
							showTodo={showTodos}
						/>
						<ConfigureVersionBrowser />
					</div>
					<div
						className="col-6 col-xxxl-5 p-2"
						style={{
							flex: '1',
							flexDirection: 'column',
							display: 'flex',
						}}
					>
						<RenderWindow
							emailHtml={emailHtml}
							subject={`Subject: ${
								selectedEmail?.subjectLine ||
								'(fill out on left)'
							}`}
						/>
					</div>
				</div>
			</div>
			<PrevAndNextContainer
				experience={
					currentExperience as HybridExperience
				}
				nextOnClick={onNext}
				prevOnClick={onPrev}
				nextButtonLabel="Landing Page"
				prevButtonLabel="Select Template"
				nextButtonState={`next-${nextButtonState}`}
			/>
		</>
	);
};

export default ConfigureEmailLayout;
