import { createContext, useContext } from 'react';
import {
	GetExperienceQueryHookResult,
	useGetExperienceQuery,
} from '../../gql/queries/generated/graphql';

export const CurrentExperienceContext =
	createContext<GetExperienceQueryHookResult | null>(
		null,
	);

export const CurrentExperienceProvider = ({
	children,
	experienceId,
}: {
	children: JSX.Element | JSX.Element[];
	experienceId: string | undefined;
}) => {
	const hookResult = useGetExperienceQuery({
		variables: {
			hybridExperienceId: experienceId || 'null',
		},
	});

	return (
		<CurrentExperienceContext.Provider
			value={hookResult}
		>
			{children}
		</CurrentExperienceContext.Provider>
	);
};

export const useCurrentExperience = () => {
	const context =
		useContext<GetExperienceQueryHookResult | null>(
			CurrentExperienceContext,
		);

	if (!context) {
		throw new Error(
			'You are probably trying to use the useCurrentExperience hook outside of the Current Experience Provider',
		);
	}
	return context;
};
