import {
	faCheck,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { partition } from 'lodash';
import Colors from '../../colors.enum';
import { VersionType } from '../../gql/queries/generated/graphql';
import { IStepLeft } from '../../routes/create-experience-page/validations/types.validation';

const defaultProps = {
	title: 'Todo List',
	children: (<></>) as JSX.Element | JSX.Element[],
	tasks: [
		{
			complete: true,
			message: 'This is a message',
			version: VersionType.Versiona,
		},
	] as IStepLeft[],
	subtitle:
		'Please complete the configuration steps below.',
	headerA: 'A',
	headerB: 'B',
};

const TodoHeaders = ({
	headerA = 'A',
	headerB = 'B',
	showB,
}: {
	headerA: string;
	headerB: string;
	showB: boolean;
}) => (
	<thead>
		<tr className="fs-3">
			<th scope="col" className="ms-1">
				Configuration Step
			</th>
			<th className="text-center" scope="col">
				{headerA}
			</th>
			{showB && (
				<th className="text-center" scope="col">
					{headerB}
				</th>
			)}
		</tr>
	</thead>
);

const TodoRow = ({
	a,
	b,
}: {
	a: IStepLeft;
	b?: IStepLeft;
}) => {
	const aIcon = a.complete ? faCheck : faTimes;

	const bIcon = (() => {
		if (b?.disabled) {
			return faTimes;
		}
		if (!b?.complete) {
			return faTimes;
		}
		return faCheck;
	})();

	const aColor = a.complete ? Colors.green : Colors.red;
	const bColor = (() => {
		if (b?.disabled) {
			return Colors.gray;
		}
		if (b?.complete) {
			return Colors.green;
		}
		return Colors.red;
	})();

	return (
		<tr>
			<th scope="row">{a.message}</th>
			<td className="text-center">
				<FontAwesomeIcon
					icon={aIcon}
					color={aColor}
					size={'2x'}
				/>
			</td>
			{b && (
				<td className="text-center">
					<FontAwesomeIcon
						icon={bIcon}
						color={bColor}
						size={'2x'}
					/>
				</td>
			)}
		</tr>
	);
};

const TodoModal = (
	props: {
		closeFunction: () => void;
	} & typeof defaultProps,
) => {
	const { title, tasks, subtitle, headerA, headerB } = {
		...defaultProps,
		...props,
	};

	const [aTasks, bTasks] = partition(
		tasks,
		task => task.version === VersionType.Versiona,
	);

	const isATasks = aTasks.length > 0;
	const isBTasks = bTasks.length > 0;

	const bTasksDisabled = aTasks.map(task => ({
		...task,
		disabled: true,
	}));

	return (
		<div
			className="alertOverlay"
			style={{ zIndex: 999 }}
			onClick={() => {
				props.closeFunction();
			}}
		>
			<div className="alertModal w-100 d-flex justify-content-center">
				<div className="d-flex flex-column  bg-dark rounded w-50">
					<div className="container d-flex bg-black rounded-top border-bottom border-purple">
						<div className="my-auto p-1 pt-5">
							<h2 className="">{title}</h2>
							<p className="">{subtitle}</p>
						</div>
					</div>
					<div className="p-3">
						<div className="todo-container container border-rounded-3">
							<table className="table">
								<TodoHeaders
									headerA={headerA}
									headerB={headerB}
									showB={isBTasks}
								/>
								<tbody>
									{isATasks &&
										aTasks.map(
											(
												stepLeft,
												i,
											) => (
												<TodoRow
													key={i}
													a={
														stepLeft
													}
													b={
														isBTasks
															? bTasks[
																	i
															  ]
															: undefined
													}
												/>
											),
										)}
								</tbody>
							</table>
						</div>
					</div>
					<div className="modal__content mt-4">
						{props.children}
					</div>
					<button className="btn btn-red text-white mb-4 fs-5">
						Close
					</button>
				</div>
			</div>
		</div>
	);
};

TodoModal.defaultProps = defaultProps;

export default TodoModal;
