import { useEffect, useState } from 'react';
import { toastToFailure } from '../../../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../../../components/trigger-toasts/toast-to-success';
import {
	GetExperienceDocument,
	LegalTerms,
	GetLandingPagePreviewUrlDocument,
	useGetEntitlementByIdQuery,
	useUpdateHybridExperiencePageCustomizationMutation,
} from '../../../../gql/queries/generated/graphql';
import { isTruthey } from '../../../../util/is-truthey';
import { useCurrentExperience } from '../../current-experience.provider';
import { useConfigurationStep } from '../configuration-form-step/configuration-form-step-provider';

import Editor from './Editor';

const LegalTermsSelect = ({
	footerTerms,
	selectedFooter,
	setFooterTerms,
}: {
	footerTerms: LegalTerms[];
	selectedFooter: string;
	setFooterTerms: React.Dispatch<
		React.SetStateAction<string>
	>;
}) => {
	useEffect(() => {
		const defaultTerm = footerTerms.find(
			term => term.isDefault === true,
		);

		if (defaultTerm && defaultTerm.text) {
			setFooterTerms(defaultTerm.text);
		}
	}, [footerTerms, setFooterTerms]);

	return (
		<>
			{footerTerms.map((x, y) => (
				<option
					data-selected={
						!!x.isDefault ||
						selectedFooter === x?.text
					}
					key={y.toString()}
					value={x.text || ''}
				>
					{x?.displayName}
				</option>
			))}
		</>
	);
};

const FormCustomizationBrowser = () => {
	// Pull out the current entitlement, legal terms
	const { data: currentEntitlementData } =
		useGetEntitlementByIdQuery();

	const currentEntitlement =
		currentEntitlementData?.GetEntitlementById;

	const entitlementFooterTerms =
		currentEntitlement?.landingPageFooterTerms ||
		([] as LegalTerms[]);
	const entitlementButtonTerms =
		currentEntitlement?.landingPageButtonTerms ||
		([] as LegalTerms[]);

	const { data } = useCurrentExperience();

	const {
		selectedVersion,

		setPageUpdating,
	} = useConfigurationStep();
	const experience = data?.GetHybridExperienceById;
	const experienceId = experience?.id || '';
	const pages = experience?.pages;
	const selectedPage =
		pages &&
		pages.find(
			page => page.version === selectedVersion,
		);
	const defaultHtml = selectedPage?.bodyHtml || '';
	const defaultButtonText =
		selectedPage?.buttonText || '';
	const defaultFooterTerms =
		selectedPage?.footerTerms || '';
	const defaultButtonTerms =
		selectedPage?.buttonTerms || '';

	const [bodyHtml, setBodyHtml] =
		useState<string>(defaultHtml);

	const [buttonText, setButtonText] = useState<string>(
		defaultButtonText,
	);

	const [updateHybridExperiencePageCustomization] =
		useUpdateHybridExperiencePageCustomizationMutation();
	const [footerTerms, setFooterTerms] = useState<string>(
		defaultFooterTerms,
	);
	const [buttonTerms, setButtonTerms] = useState<string>(
		defaultButtonTerms,
	);

	useEffect(() => {
		setButtonText(defaultButtonText);
		setBodyHtml(defaultHtml);
	}, [selectedVersion, defaultHtml, defaultButtonText]);

	const onUpdate = () => {
		Promise.resolve()
			.then(() => {
				if (
					![
						buttonTerms,
						bodyHtml,
						footerTerms,
						buttonText,
					].every(isTruthey)
				) {
					throw new Error(
						'Please fill out all required fields',
					);
				}
			})
			.then(() =>
				updateHybridExperiencePageCustomization({
					variables: {
						hybridExperienceId:
							experienceId || '',
						version: selectedVersion,
						bodyHtml,
						footerTerms: footerTerms || '',
						buttonTerms: buttonTerms || '',
						buttonText,
					},
					refetchQueries: [
						GetExperienceDocument,
						GetLandingPagePreviewUrlDocument,
					],
				}).catch(error => {
					console.error(error);
					throw new Error(
						'Failed to update, please try again',
					);
				}),
			)
			.then(() => setPageUpdating(true))
			.then(() => {
				toastToSuccess('Updated successfully');
			})
			.catch(error => {
				toastToFailure(error.message);
			});
	};

	return (
		<>
			<div className="col h-100 d-flex flex-column text-white">
				<div
					className="d-flex flex-column flex-grow-1 mx-auto w-100"
					style={{
						marginBottom: '70px',
					}}
				>
					<div className="shrink-content scrollbar">
						<form
							className="px-3 noValidate"
							onSubmit={e => {
								e.preventDefault();
								onUpdate();
							}}
						>
							{/* HTML Editor that maps the body on the rendered Form */}
							<div className="row w-100 mx-auto my-2">
								<div className="col">
									<h5 className="mb-2 mt-3">
										Body Copy*
									</h5>
									<Editor
										onChange={html => {
											setBodyHtml(
												html,
											);
										}}
										value={bodyHtml}
									/>
								</div>
							</div>

							{/* A grid to organize the inputs */}

							{/* contains the first two inputs    */}
							<div className="row w-100 mx-auto my-2">
								{/* The text that shows up on the rendered form */}
								<div className="col-5">
									<label
										htmlFor="buttontext"
										className="form-label"
									>
										<h5>
											Button Text*
										</h5>
									</label>
									<input
										type="text"
										className="form-control bg-white text-black"
										id="buttontext"
										data-cy="buttonText-input"
										value={buttonText}
										placeholder="Edit Me"
										onChange={e => {
											setButtonText(
												e.target
													.value,
											);
										}}
										aria-describedby="emailHelp"
									/>
								</div>
								{/* The terms associated with the button on the rendered form */}
								<div className="col-7">
									<label className="form-label">
										<h5>
											Button Terms*
										</h5>
									</label>
									<select
										className="form-select form-select-md mb-3 bg-white text-black"
										data-cy="buttonTerms-select"
										onChange={e =>
											setButtonTerms(
												e
													.currentTarget
													.value,
											)
										}
										value={buttonTerms}
										aria-label=".form-select-lg"
									>
										{!entitlementButtonTerms && (
											<option
												value={
													undefined
												}
											>
												Select
												Button Terms
											</option>
										)}
										{entitlementButtonTerms && (
											<LegalTermsSelect
												setFooterTerms={
													setButtonTerms
												}
												selectedFooter={
													selectedPage?.buttonTerms ||
													''
												}
												footerTerms={
													entitlementButtonTerms
												}
											/>
										)}
									</select>
								</div>
							</div>
							<div className="row w-100 mx-auto my-2">
								{/* Maps to The Footer terms on the right */}
								<div className="col">
									<label className="form-label">
										<h5>
											Footer Terms*
										</h5>
									</label>
									<select
										data-cy="footerTerms-select"
										className="form-select form-select-md mb-3 w-auto bg-white text-black"
										onChange={e =>
											setFooterTerms(
												e
													.currentTarget
													.value,
											)
										}
										aria-label=".form-select-lg"
										value={footerTerms}
									>
										{!entitlementFooterTerms && (
											<option
												value={
													undefined
												}
											>
												Select
												Footer Terms
											</option>
										)}
										{entitlementFooterTerms && (
											<LegalTermsSelect
												setFooterTerms={
													setFooterTerms
												}
												selectedFooter={
													selectedPage?.footerTerms ||
													''
												}
												footerTerms={
													entitlementFooterTerms
												}
											/>
										)}
									</select>
								</div>
							</div>
							<div className="row w-100 mx-auto my-2">
								<div className="col">
									<input
										data-cy="formCustomization-update"
										type="submit"
										value="Update"
										className={`btn text-white bold ${
											selectedVersion !==
											'VERSIONA'
												? 'btn-turquoise'
												: 'btn-purple'
										}`}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default FormCustomizationBrowser;
