/* eslint-disable consistent-return */
import { useEffect } from 'react';
import AudienceReview from './browser-components/audience-review';
import EmailReview from './browser-components/email-review';
import FormReview from './browser-components/form-review';
import GiftReview from './browser-components/gift-review';
import RenderReview from './browser-components/render-review';
import ReviewNavbar from './navbar/review-navbar';
import {
	NavMenuStep,
	useConfigurationStep,
} from './review-step/review-step-provider';

const ReviewBrowser = () => {
	const { color, currentNavStep } =
		useConfigurationStep();

	return (
		<div
			className={`col d-flex flex-column flex-grow-1 border-${color} border rounded-bottom rounded-end border-2`}
			style={{ overflowX: 'hidden' }}
		>
			<div
				className="shrink-content scrollbar"
				style={{ overflowX: 'hidden' }}
			>
				{currentNavStep ===
					NavMenuStep.AUDIENCE && (
					<AudienceReview />
				)}
				{currentNavStep === NavMenuStep.EMAIL && (
					<EmailReview />
				)}

				{currentNavStep === NavMenuStep.FORM && (
					<FormReview />
				)}
				{currentNavStep === NavMenuStep.GIFT && (
					<GiftReview />
				)}

				{currentNavStep === NavMenuStep.PRINT && (
					<RenderReview />
				)}
			</div>
			<ReviewNavbar />
		</div>
	);
};

export default ReviewBrowser;
