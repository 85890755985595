import {
	Gift,
	PageType,
} from '../../../gql/queries/generated/graphql';

// This is a curried function
const isGiftCorrectPageType = (
	pageType: PageType,
	gift: Gift,
): boolean => {
	if (
		!gift.pageTypes.some(
			giftPageType => giftPageType === pageType,
		)
	) {
		return false;
	}
	switch (pageType) {
		case PageType.Singleitem:
			if (gift.products.length > 1) {
				return false;
			}
			return true;
			break;
		case PageType.Multiitem:
			return true;
			break;
		case PageType.Singleitemsize:
			return true;
			break;
		default:
			return false;
			break;
	}
	return false;
};

export const filterCorrectGifts = (
	gifts: Gift[],
	pageType: PageType,
): Gift[] => {
	let filteredGifts = gifts.filter(
		// curried filters allows us to use this syntax
		gift => isGiftCorrectPageType(pageType, gift),
	);
	filteredGifts = filteredGifts.filter(
		gift => gift.active,
	);
	return filteredGifts;
};
