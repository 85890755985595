import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import FileSVG from '../../../assets/file-image.svg';
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import {
	AssetType,
	useCreateAssetMutationMutation,
	GetAssetsByEntitlementAndAssetTypesDocument,
} from '../../../gql/queries/generated/graphql';
import { useAuth } from '../../../providers/auth/auth-provider';
import getBase64 from '../../../util/thennables/get-base-64';
import { grabFirstFileThrowIfMoreFiles } from '../../../util/thennables/grab-first-file-throw-if-more';
import { throwIfNotExtension } from '../../../util/thennables/throw-if-not-extension';

const NoImagesTab = () => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const [disabled, setDisabled] = useState(false);

	const [createAsset] = useCreateAssetMutationMutation({
		refetchQueries: [
			GetAssetsByEntitlementAndAssetTypesDocument,
		],
	});
	const { user } = useAuth();

	const handleFiles = (files: File[]) => {
		let file: File;

		return Promise.resolve()
			.then(() => setDisabled(true))
			.then(() => files)
			.then(
				grabFirstFileThrowIfMoreFiles(
					'Please only upload one file at a time.',
				),
			)
			.then(firstFile => {
				file = firstFile;
				return firstFile;
			})
			.then(
				throwIfNotExtension(
					['jpg', 'png', 'jpeg'],
					'Invalid File Type',
				),
			)
			.then(() => files[0])
			.then(getBase64) // 12345
			.then(output => {
				const entitlementId = user?.entitlement.id;

				if (!entitlementId) {
					throw new Error(
						'Entitlement Not Found',
					);
				}

				return createAsset({
					variables: {
						content: output as string,
						entitlementId,
						filename: file.name,
						assetType:
							AssetType.Pageheaderimage,
					},
				});
			})
			.catch(err => {
				toastToFailure(err.message);
			})
			.finally(() => setDisabled(false));
	};

	const { getRootProps, getInputProps, isDragActive } =
		useDropzone({ onDrop: handleFiles, disabled });

	return (
		<div
			className={`col h-100 d-flex flex-column`}
			style={{
				opacity: isDragActive ? 0.7 : 1,
				width: 'auto',
				height: 'auto',
			}}
		>
			<div
				id="upload-image-bar"
				className="d-flex m-3"
			>
				<input
					{...getInputProps()}
					ref={inputRef}
				/>
				<p
					className=" text-white"
					style={{
						fontWeight: 700,
					}}
				>
					Select an Image
				</p>
				<button
					className="btn btn-sm btn-purple text-white ms-auto"
					style={{ fontWeight: 700 }}
					onClick={() => {
						inputRef.current?.click();
					}}
				>
					Upload An Image
				</button>
			</div>
			{/* Input allows for file upload */}
			<input {...getInputProps()} />

			<div
				className="d-flex flex-column flex-grow-1 mx-auto w-100"
				style={{
					marginBottom: '70px',
				}}
			>
				<div className="shrink-content text-center align-items-center d-flex">
					<div className="my-auto mx-auto">
						<img
							{...getRootProps()}
							src={FileSVG}
							className="noselect nodrag img-fluid mb-3"
							style={{ height: '20vh' }}
							alt=""
						/>
						<input {...getInputProps()} />
						<div className="px-5">
							<p
								className="text-white text-center"
								style={{ fontWeight: 300 }}
							>
								Drag and drop onto this
								screen, or click to upload
								from your computer
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NoImagesTab;
