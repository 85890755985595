// import { useState } from 'react';

// interface CheckboxProps {
// 	label: string;
// }

// // This is used for price bracket filtering
// export const Checkbox = ({ label }: CheckboxProps) => {
// 	const [checked, setChecked] = useState<boolean>(false);

// 	return (
// 		<div className="form-check">
// 			<input
// 				className="form-check-input"
// 				type="checkbox"
// 				checked={checked}
// 				value=""
// 				id="flexCheckDefault"
// 				onChange={() => setChecked(!checked)}
// 			/>
// 			<label className="form-check-label">
// 				{label}
// 			</label>
// 		</div>
// 	);
// };

// interface CheckBoxDataPoint<T> {
// 	checked: boolean;
// }

// export const CheckboxContainer = <T>({ checkboxData }) => {
// 	const [checkboxData, setCheckboxData] = useState<
// 		CheckBoxDataPoint<T>[]
// 	>([]);
// 	return <div></div>;
// };

import { useEffect, useState } from 'react';

export interface ICheckbox<T> {
	checked: boolean;
	label: string;
	value: T;
}

const Checkbox = ({
	isChecked,
	label,
	toggle,
}: {
	isChecked: boolean;
	label: string;
	toggle: () => void;
}) => (
	<div
		className="flex justify-content-between form-check form-check-custom form-check-solid form-check-sm"
		style={{ marginBottom: 4, display: 'flex' }}
	>
		<p>{label}</p>
		<input
			onClick={() => {
				toggle();
			}}
			className="form-check-input"
			type="checkbox"
			checked={isChecked}
		/>
	</div>
);

const CheckboxContainer = <T,>({
	initialChecked,
	onChange,
}: {
	initialChecked: ICheckbox<T>[];
	onChange: (checkboxStates: ICheckbox<T>[]) => void;
}) => {
	const [checkedState, setCheckedState] =
		useState<ICheckbox<T>[]>(initialChecked);

	const toggleAtIndex = (index: number) => {
		const newCheckedState = checkedState.map(
			(checkState, i): ICheckbox<T> =>
				i === index
					? {
							...checkState,
							checked: !checkState.checked,
					  }
					: checkState,
		);
		setCheckedState(newCheckedState);
	};

	useEffect(() => {
		onChange(checkedState);
	}, [checkedState]);

	return (
		<div>
			{checkedState.map((checkState, index) => (
				<Checkbox
					key={index}
					isChecked={checkState.checked}
					label={checkState.label}
					toggle={() => {
						toggleAtIndex(index);
					}}
				/>
			))}
		</div>
	);
};

export default CheckboxContainer;
