import { ApolloError } from '@apollo/client';
import {
	faPlus,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Colors, { HermesColor } from '../../../colors.enum';
import TodoButton from '../../../components/todo-button';
import { toastToNotify } from '../../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../../components/trigger-toasts/toast-to-success';
import {
	GetExperienceDocument,
	useCreateMultiVersionPageMutation,
	useRemoveMultiVersionPageMutation,
	VersionType,
} from '../../../gql/queries/generated/graphql';

import promptModal from '../../../providers/alert-provider/prompt-modal';
import { useCurrentExperience } from '../current-experience.provider';
import { useConfigurationStep } from './configuration-form-step/configuration-form-step-provider';

interface TabProps {
	color: HermesColor;
	text: string;
	onClick: () => void;
	version:
		| 'settings'
		| VersionType.Versiona
		| VersionType.Versionb;
	onDeleteClick?: () => void;
}

const Tab = ({
	color,
	text,
	onClick,
	version,
	onDeleteClick,
}: TabProps) => (
	<div
		data-cy={`version-tab`}
		className={`bg-${color.toString()} tab d-flex rounded-top h-100 me-1 text-center`}
		style={{
			cursor: 'pointer',
		}}
		onClick={() => onClick()}
	>
		<p className="text-center m-0 fw-bold align-self-center px-3">
			{text}
		</p>
		{version === VersionType.Versionb && (
			<div className="tab-x-button">
				<FontAwesomeIcon
					icon={faTimes}
					color={Colors.white}
					className="pointer"
					style={{
						cursor: 'pointer',
						position: 'relative',
						top: 0,
						right: 4,
					}}
					onClick={e => {
						e.stopPropagation();
						if (onDeleteClick) {
							onDeleteClick();
						}
					}}
				/>
			</div>
		)}
	</div>
);

const PlusTab = ({ onClick }: { onClick: () => void }) => (
	<div
		id="tab-1"
		className="tab plus-tab d-flex justify-content-center align-items-center px-2"
		onClick={() => onClick()}
	>
		<FontAwesomeIcon
			icon={faPlus}
			color="white"
			className="poiter"
			style={{ cursor: 'pointer' }}
		/>
	</div>
);

interface ConfigureFormTabBarProps {
	showVersions: boolean;
	showSettings: boolean;
	showTodo: boolean;
	onTodo: () => void;
}

const ConfigureFormTabBar = ({
	showVersions,
	showSettings,
	showTodo,
	onTodo,
}: ConfigureFormTabBarProps) => {
	const {
		setShowSettings,
		setSelectedVersion,
		selectedVersion,
	} = useConfigurationStep();
	const { data, refetch: refetchExperience } =
		useCurrentExperience();
	const experience = data?.GetHybridExperienceById;
	const experienceId = experience?.id || '';

	const [removeB] = useRemoveMultiVersionPageMutation({
		variables: {
			hybridExperienceId: experienceId,
		},
	});

	const [createVersionB] =
		useCreateMultiVersionPageMutation({
			variables: { experienceId },
			refetchQueries: [GetExperienceDocument],
		});

	const [creatingVersionB, setCreatingVersionB] =
		useState<boolean>(false);

	const color = {
		[VersionType.Winner]: 'purple',
		[VersionType.Versiona]: 'indigo',
		[VersionType.Versionb]: 'dark-turquoise',
		settings: 'gray-dark',
	}[selectedVersion as VersionType | 'settings'];

	const [deleting, setDeleting] =
		useState<boolean>(false);

	const showTab2 = showVersions;
	const showPlus = !showVersions;
	const deleteVersionB = () => {
		if (!deleting) {
			Promise.resolve()
				.then(() => setDeleting(true))
				.then(
					promptModal(
						'Are you sure that you want to delete your Version B?',
						'stop',
					),
				)
				.then(async () => {
					setSelectedVersion(
						VersionType.Versiona,
					);
					return removeB();
				})
				.then(() => refetchExperience())
				.then(() => {
					toastToSuccess(
						'Deleted Your Version B',
					);
				})
				.catch(() => {
					toastToNotify(
						'Experience B Not Deleted',
					);
				})
				.finally(() => setDeleting(false));
		} else {
			// Aleady deleting, do nothing asd
		}
	};

	return (
		<div
			className={`bg-${color} text-white d-flex rounded-top p-0 justify-content-between`}
		>
			<div
				className={`bg-${color} text-white d-flex rounded-top p-0`}
			>
				<Tab
					color={'purple'}
					text="Version A"
					onClick={() => {
						setSelectedVersion(
							VersionType.Versiona,
						);
						setShowSettings(false);
					}}
					version={VersionType.Versiona}
				/>
				{showTab2 && (
					<Tab
						color={'turquoise'}
						text="Version B"
						onClick={() => {
							setSelectedVersion(
								VersionType.Versionb,
							);
							setShowSettings(false);
						}}
						onDeleteClick={deleteVersionB}
						version={VersionType.Versionb}
					/>
				)}
				{false && (
					<Tab
						color={'gray'}
						text="Settings"
						onClick={() => {
							setShowSettings(true);
						}}
						version={'settings'}
					/>
				)}
				{showPlus && (
					<PlusTab
						onClick={() => {
							if (
								!showVersions &&
								!creatingVersionB
							) {
								Promise.resolve()
									.then(() =>
										setCreatingVersionB(
											true,
										),
									)
									.then(() =>
										toastToNotify(
											'Creating version B...',
										),
									)
									.then(async () =>
										createVersionB(),
									)
									.then(async () =>
										refetchExperience(),
									)
									.then(() =>
										toastToSuccess(
											'Version B created',
										),
									)
									.catch(err => {
										console.error(err);
										throw new ApolloError(
											err,
										);
									})
									.finally(() =>
										setCreatingVersionB(
											false,
										),
									);
							}
						}}
					/>
				)}
			</div>
			<TodoButton
				showTodo={showTodo}
				onTodo={onTodo}
			/>
		</div>
	);
};

export default ConfigureFormTabBar;
