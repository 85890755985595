import { useHistory } from 'react-router';
import Diver from '../assets/Diver404.svg';

const FourOhFour = () => {
	const history = useHistory();

	const goToMainPage = () => {
		history.push('/');
	};

	return (
		<div
			style={{ overflowY: 'hidden' }}
			className="h-100 row flex-row-reverse w-100 mx-auto bg-dark text-light d-flex flex-grow-1 noselect "
		>
			<div className="col-lg-6 d-flex flex-column">
				<div className="d-none d-lg-block text-left my-auto">
					<h1 className="display-2 fw-bold">
						Invalid Link
					</h1>
					<p className="lead mb-4">
						Uh oh, we can&apos;t seem to find
						the page you&apos;re looking for.
					</p>
					<a
						className="text-turquoise"
						href="#"
						onClick={() => {
							goToMainPage();
						}}
					>
						Click here to go back
					</a>
				</div>
				<div className="d-lg-none text-center mt-5">
					<h1 className="display-2 fw-bold">
						Invalid Link
					</h1>
					<p className="lead">
						Uh oh, we can&apos;t seem to find
						the page you&apos;re looking for.
					</p>
					<a
						className="text-turquoise"
						href="#"
						onClick={() => {
							goToMainPage();
						}}
					>
						Click here to go back
					</a>
				</div>
			</div>
			<div className="col-lg-6 d-flex flex-column px-0">
				<div className="my-auto ms-auto">
					<img
						alt="Diver"
						src={Diver}
						width={900}
						height={900}
					/>
				</div>
			</div>
		</div>
	);
};

export default FourOhFour;
