import { useHistory } from 'react-router';
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../../components/trigger-toasts/toast-to-success';
import {
	VersionType,
	HybridExperience,
	PageType,
	SelectedGift,
	useUpdateHybridExperienceGiftsMutation,
	GetExperienceDocument,
	GiftInput,
} from '../../../gql/queries/generated/graphql';
import messagebox from '../../../providers/alert-provider/messagebox';
import promptModal from '../../../providers/alert-provider/prompt-modal';
import PrevAndNextContainer from '../components/prev-and-next-container';
import { useCurrentExperience } from '../current-experience.provider';
import GiftSelector from './gift-selector';
import GiftTabBar from './gift-tab-bar';
import { getGiftSteps } from './gifts.validation';
import { useConfigurationStep } from './select-gift-step/select-gift-step-provider';

// This is the top level component that provides visual structure to the gift selector page
const GiftSelectorWrapper = () => {
	const { data: experienceData } = useCurrentExperience();
	const {
		showB,
		setShowB,
		setSelectedVersion,
		selectedVersion,
		setIsLoading,
		isLoading,
		allSelectedGifts,
		refetchExperience,
	} = useConfigurationStep();

	const experience =
		experienceData?.GetHybridExperienceById;
	const giftConfigured =
		(experience && experience.giftConfigured) || false;
	const history = useHistory();
	const selectedGifts = experience?.selectedGifts;
	const pageType =
		experience?.pages[0]?.pageType ||
		PageType.Singleitem;

	const [updateHybridExperienceGifts] =
		useUpdateHybridExperienceGiftsMutation();

	const onPlus = () => {
		if (experience?.pageType !== PageType.Singleitem) {
			toastToFailure(
				'Versioning is not allowed with this page type',
			);
			return;
		}

		const filtered = allSelectedGifts?.filter(
			gift =>
				gift.versionType === VersionType.Versiona,
		);

		const giftInput = filtered?.map(
			gift =>
				({
					gift: gift.gift.id,
					versionType: gift.versionType,
				} as GiftInput),
		);

		const duplicateGifts = giftInput.map(
			gift =>
				({
					gift: gift.gift,
					versionType: VersionType.Versionb,
				} as GiftInput),
		);

		Promise.resolve()
			.then(() => {
				setIsLoading(true);
			})
			.then(() =>
				toastToNotify('Creating Version B...'),
			)
			.then(async () =>
				updateHybridExperienceGifts({
					variables: {
						hybridExperience:
							experience?.id || '',
						gifts: [
							...giftInput,
							...duplicateGifts,
						],
					},
				}),
			)
			.then(async () => refetchExperience())
			.catch(err => {
				toastToFailure(
					err?.message || "Couldn't update gifts",
				);
			})
			.finally(() => {
				toastToSuccess('Version B created');
				setShowB(true);
				setIsLoading(false);
			});
	};

	const deleteVersionB = () => {
		Promise.resolve()
			.then(
				promptModal(
					'Are you sure that you want to delete your Version B?',
					'stop',
				),
			)
			.then(() => {
				setIsLoading(true);
			})
			.then(() => {
				toastToNotify('Deleting Version B...');
			})
			.then(async () => {
				const filtered = allSelectedGifts?.filter(
					gift =>
						gift.versionType ===
						VersionType.Versiona,
				);

				const giftInput = filtered?.map(
					gift =>
						({
							gift: gift.gift.id,
							versionType: gift.versionType,
						} as GiftInput),
				);
				return updateHybridExperienceGifts({
					variables: {
						hybridExperience:
							experience?.id || '',
						gifts: giftInput || [],
					},
				});
			})
			.then(async () => refetchExperience())
			.then(() => {
				setSelectedVersion(VersionType.Versiona);
			})
			.catch(() => {
				toastToNotify('Version B not deleted');
			})
			.finally(() => {
				setShowB(false);
				setIsLoading(false);
				toastToSuccess('Version B deleted');
			});
	};
	const tasks = getGiftSteps(
		selectedGifts as SelectedGift[],
		pageType,
		showB,
	);

	return (
		<>
			<div className="d-flex flex-column flex-grow-1">
				<div className="row w-100 h-100 mx-auto px-0 d-flex flex-grow-1 mt-2">
					<div className="d-flex h-100 flex-column">
						<GiftTabBar
							showVersions={showB}
							showSettings={false}
							showTodo={
								!tasks.every(
									task => task.complete,
								)
							}
							onTodo={() => {
								messagebox.openTodoList({
									tasks,
									title: 'Gift Selection: ',
									subtitle:
										'Please complete all of the configuration steps below to continue.',
									headerA: 'A',
									headerB: 'B',
								});
							}}
							onPlus={() => {
								onPlus();
							}}
							onRemove={() => {
								deleteVersionB();
							}}
						/>
						<GiftSelector />
					</div>
				</div>
			</div>
			<PrevAndNextContainer
				experience={experience as HybridExperience}
				nextButtonLabel="Configure Print"
				prevButtonLabel="Form"
				nextButtonState={
					giftConfigured && !isLoading
						? 'next-enabled'
						: 'next-disabled'
				}
				nextOnClick={() => {
					history.push(
						`/create-experience/${
							experience?.id || ''
						}/configure-print`,
					);
				}}
				prevOnClick={() => {
					history.push(
						`/create-experience/${
							experience?.id || ''
						}/configure-form`,
					);
				}}
			/>
		</>
	);
};

export default GiftSelectorWrapper;
