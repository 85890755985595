import {
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toastToFailure } from '../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../components/trigger-toasts/toast-to-success';
import {
	CountUserEntitlement,
	Entitlement,
	GetEntitlementsByOrgDocument,
	LegalTerms,
	LegalTermsType,
	useCountUsersByEntitlementQuery,
	useCreateEntitlementMutation,
	useCreateLegalTermMutation,
	useDeleteEntitlementMutation,
	useDeleteLegalTermMutation,
	useGetEntitlementsByOrgQuery,
	useUpdateEntitlementNameMutation,
	useUpdateLegalTermMutation,
} from '../../gql/queries/generated/graphql';
import promptModal from '../../providers/alert-provider/prompt-modal';
import { useSettings } from './settings-provider';

/*
 * Simple editor component that takes placeholder text as a prop
 */

function Editor({
	placeholder,
	onChange,
	value,
}: {
	placeholder?: string;
	onChange: (arg0: string) => void;
	value: string;
}) {
	return (
		<ReactQuill
			onChange={html => {
				onChange(html);
			}}
			placeholder={
				placeholder || 'Write Something...'
			}
			value={value}
		/>
	);
}

interface TermEditProps {
	term: LegalTerms | undefined;
	setTermValue: React.Dispatch<
		React.SetStateAction<string | undefined>
	>;
	termValue: string | undefined;
}

const TermEdit = ({
	term,
	setTermValue,
	termValue,
}: TermEditProps) => {
	useEffect(() => {
		setTermValue(term?.text || '');
	}, [term]);
	return (
		<Editor
			value={termValue || ''}
			onChange={html => {
				setTermValue(html);
			}}
			placeholder={'Write something...'}
		/>
	);
};

interface EntitlementEditModalProps {
	parentContainer: MutableRefObject<HTMLDivElement>;
	open: boolean;
	setOpen: (arg0: boolean) => void;
}

export const EntitlementEditModal = ({
	parentContainer,
	open,
	setOpen,
}: EntitlementEditModalProps) => {
	const { editingEntitlement, setEditingEntitlement } =
		useSettings();

	const [createLegalTerm] = useCreateLegalTermMutation();

	const [entitlementName, setEntitlementName] =
		useState<string>();

	const [isDefaultTerm, setIsDefaultTerm] =
		useState<boolean>();

	const [termValue, setTermValue] = useState<string>();

	const [termName, setTermName] = useState<string>();

	const [selectedTerm, setSelectedTerm] =
		useState<LegalTerms>();
	const [selectedTermType, setSelectedTermType] =
		useState<LegalTermsType>();

	const [deletingTerm, setDeletingTerm] =
		useState<boolean>(false);

	useEffect(() => {
		if (editingEntitlement) {
			setEntitlementName(
				editingEntitlement.name || '',
			);
		}
	}, [editingEntitlement]);

	const onTermSelectChange = (
		e: React.ChangeEvent<HTMLSelectElement>,
	) => {
		setDeletingTerm(false);
		const selectedOptionIndex =
			e.currentTarget.selectedIndex;
		const selectedOption =
			e.currentTarget.options[selectedOptionIndex];

		const selectedOptionId =
			selectedOption.dataset.termid;

		const typeSelected =
			selectedOption.dataset.termtype;

		if (!selectedOptionId) {
			setSelectedTerm(undefined);
			setTermName('');
			setTermValue('');
		}

		if (typeSelected === LegalTermsType.Emailfooter) {
			setSelectedTerm(
				editingEntitlement?.emailFooterTerms?.find(
					term => term.id === selectedOptionId,
				),
			);
			setSelectedTermType(LegalTermsType.Emailfooter);
		} else if (
			typeSelected === LegalTermsType.Pagefooter
		) {
			setSelectedTerm(
				editingEntitlement?.landingPageFooterTerms?.find(
					term => term.id === selectedOptionId,
				),
			);
			setSelectedTermType(LegalTermsType.Pagefooter);
		} else if (
			typeSelected === LegalTermsType.Pagebutton
		) {
			setSelectedTerm(
				editingEntitlement?.landingPageButtonTerms?.find(
					term => term.id === selectedOptionId,
				),
			);
			setSelectedTermType(LegalTermsType.Pagebutton);
		}

		setTermName(selectedOption.dataset.termname);

		const defaultBool =
			selectedOption.dataset.isdefault === 'true';

		setIsDefaultTerm(defaultBool);
	};

	const onCreateTerm = () => {
		createLegalTerm({
			variables: {
				legalTerm: {
					displayName:
						termName || 'No Name Provided',
				},
				entitlementId: editingEntitlement?.id || '',
				type:
					selectedTermType ||
					LegalTermsType.Emailfooter,
			},
			refetchQueries: [GetEntitlementsByOrgDocument],
		})
			.then(() => {
				toastToSuccess('New legal term created');
			})
			.catch(error => {
				console.error(error);
				toastToFailure(
					'Failed to create legal term',
				);
			});
	};

	const [deleteLegalTerm] = useDeleteLegalTermMutation();

	const onDeleteTerm = () => {
		if (selectedTerm) {
			setDeletingTerm(true);

			if (deletingTerm) {
				deleteLegalTerm({
					variables: {
						legalTermId: selectedTerm.id || '',
						type: selectedTermType,
						entitlementId:
							editingEntitlement?.id || '',
					},
					refetchQueries: [
						GetEntitlementsByOrgDocument,
					],
				})
					.then(deleteResponse => {
						toastToSuccess('Term Deleted');
						setTermName('');
						setSelectedTermType(undefined);
						setDeletingTerm(false);

						const { data } = deleteResponse;
						const updatedEntitlement =
							data?.DeleteLegalTerm;
						if (updatedEntitlement) {
							setEditingEntitlement(
								updatedEntitlement as Entitlement,
							);
						}
					})
					.catch(error => {
						console.error(error);
						toastToFailure(
							'Failed to delete term',
						);
					});
			}
		} else {
			toastToFailure(
				'Please select a term to delete',
			);
		}
	};

	const [updateEntitlementName] =
		useUpdateEntitlementNameMutation();
	const [updateLegalTerm] = useUpdateLegalTermMutation();

	const onSaveChanges = () => {
		if (editingEntitlement?.name !== entitlementName) {
			updateEntitlementName({
				variables: {
					entitlementId:
						editingEntitlement?.id || '',
					entitlementName: entitlementName || '',
				},
				refetchQueries: [
					GetEntitlementsByOrgDocument,
				],
			})
				.then(updateResponse => {
					toastToSuccess(
						'Entitlement name updated',
					);
					const { data } = updateResponse;
					const updatedEntitlement =
						data?.UpdateEntitlementName;
					if (updatedEntitlement) {
						setEditingEntitlement(
							updatedEntitlement as Entitlement,
						);
					}
				})
				.catch(error => {
					console.error(error);
					toastToFailure(
						'Unable to update entitlement name',
					);
				});
		}
		if (selectedTerm && selectedTermType) {
			updateLegalTerm({
				variables: {
					type: selectedTermType,
					entitlementId:
						editingEntitlement?.id || '',
					legalTermId: selectedTerm?.id || '',
					legalTerm: {
						isDefault: isDefaultTerm || false,
						displayName: termName || '',
						text: termValue || '',
					},
				},
				refetchQueries: [
					GetEntitlementsByOrgDocument,
				],
			})
				.then(updateResponse => {
					toastToSuccess('Legal term updated');
					const { data } = updateResponse;
					const updatedEntitlement =
						data?.UpdateLegalTerm;
					if (updatedEntitlement) {
						setEditingEntitlement(
							updatedEntitlement as Entitlement,
						);
					}
				})
				.catch(error => {
					console.error(error);
					toastToFailure(
						'Unable to update legal term',
					);
				});
		}
	};

	return (
		<>
			<Modal
				onRequestClose={() => setOpen(false)}
				className="customModal"
				overlayClassName="customOverlay"
				parentSelector={() =>
					parentContainer.current
				}
				shouldCloseOnOverlayClick={false}
				isOpen={open}
			>
				<div className="modal-content w-100 border-0 bg-dark text-white">
					<div className="d-flex justify-content-between mt-3">
						<h4 className="fw-bold my-auto px-3">
							Edit Entitlement
						</h4>
						<button
							onClick={() => setOpen(false)}
							className=" ms-auto my-auto btn btn-close btn-close-white px-3"
						></button>
					</div>
					<hr className="mx-3" />
					<div className="container">
						<div className="modal-body pb-4 px-4">
							<div className="row w-100 mx-auto">
								<div className="mb-3 px-0">
									<h5 className="mb-2 fw-bold">
										Entitlement Name:
									</h5>
									<input
										type="text"
										className="form-control bg-white text-black"
										value={
											entitlementName
										}
										onChange={e =>
											setEntitlementName(
												e
													.currentTarget
													.value,
											)
										}
										placeholder="Example Entitlement"
									/>
								</div>
							</div>
							<div className="row w-100 mx-auto">
								<div className="col-auto ps-0">
									<h5 className="px-0 mb-2 fw-bold">
										Legal Terms:
									</h5>
									<select
										className="form-select bg-white text-black"
										onChange={e =>
											onTermSelectChange(
												e,
											)
										}
										aria-label="Default select example"
									>
										<option selected>
											Select a term to
											edit
										</option>
										<optgroup label="Email Footer Terms">
											{editingEntitlement &&
												editingEntitlement.emailFooterTerms?.map(
													(
														footerTerm,
														fIndex,
													) => (
														<option
															key={
																fIndex
															}
															data-termId={
																footerTerm.id
															}
															data-termType={
																LegalTermsType.Emailfooter
															}
															data-termName={
																footerTerm?.displayName
															}
															data-isDefault={
																footerTerm.isDefault
															}
															value={
																fIndex
															}
														>
															{
																footerTerm?.displayName
															}
														</option>
													),
												)}
										</optgroup>
										<optgroup label="Page Footer Terms">
											{editingEntitlement &&
												editingEntitlement.landingPageFooterTerms?.map(
													(
														footerTerm,
														fIndex,
													) => (
														<option
															key={
																fIndex
															}
															data-termId={
																footerTerm.id
															}
															data-termType={
																LegalTermsType.Pagefooter
															}
															data-termName={
																footerTerm?.displayName
															}
															data-isDefault={
																footerTerm.isDefault
															}
															value={
																fIndex
															}
														>
															{
																footerTerm?.displayName
															}
														</option>
													),
												)}
										</optgroup>
										<optgroup label="Page Button Terms">
											{editingEntitlement &&
												editingEntitlement.landingPageButtonTerms?.map(
													(
														buttonTerm,
														fIndex,
													) => (
														<option
															key={
																fIndex
															}
															data-termId={
																buttonTerm.id
															}
															data-termType={
																LegalTermsType.Pagebutton
															}
															data-termName={
																buttonTerm?.displayName
															}
															data-isDefault={
																buttonTerm.isDefault
															}
															value={
																fIndex
															}
														>
															{
																buttonTerm?.displayName
															}
														</option>
													),
												)}
										</optgroup>
									</select>
								</div>
								<div className="col-auto ps-0">
									<div>
										<h5 className="px-0 mb-2 fw-bold">
											Edit Term Name:
										</h5>
										<input
											value={termName}
											onChange={e =>
												setTermName(
													e
														.currentTarget
														.value,
												)
											}
											type="text"
											className="form-control text-black bg-white"
											id="termNameEdit"
											aria-describedby="emailHelp"
										/>
									</div>
								</div>
								<div className="col-auto ps-0">
									<h5 className="px-0 mb-2 fw-bold">
										Term Type:
									</h5>
									<Select
										id={
											'term-type-select'
										}
										className="flex-grow-1"
										defaultValue={{
											value: '',
											label: 'Select your term type',
										}}
										value={{
											value: selectedTermType,
											label:
												selectedTermType ||
												'Select your term type',
										}}
										options={Object.keys(
											LegalTermsType,
										).map(
											(
												termType,
												tIndex,
											) => ({
												label: termType.toUpperCase(),
												value: termType,
											}),
										)}
										styles={{
											container:
												prev => ({
													...prev,
													color: 'black',
												}),
										}}
										onChange={input => {
											switch (
												input!.value?.toUpperCase() as LegalTermsType
											) {
												case LegalTermsType.Emailfooter:
													setSelectedTermType(
														LegalTermsType.Emailfooter,
													);
													break;
												case LegalTermsType.Pagebutton:
													setSelectedTermType(
														LegalTermsType.Pagebutton,
													);
													break;
												case LegalTermsType.Pagefooter:
													setSelectedTermType(
														LegalTermsType.Pagefooter,
													);
													break;
												default:
													toastToNotify(
														`No term type match found`,
													);
											}
										}}
									/>
								</div>
								<div className="col-auto d-flex ps-0">
									<div className="d-flex mt-auto">
										<p className="px-0 me-1">
											Default:
										</p>
										<div className="form-check form-switch">
											<input
												checked={
													isDefaultTerm
												}
												onChange={e => {
													setIsDefaultTerm(
														e
															.currentTarget
															.checked,
													);
												}}
												className="form-check-input text-black bg-white"
												type="checkbox"
												value=""
												aria-label="Checkbox for following text input"
											/>
										</div>
									</div>
								</div>
								<div className="col d-flex flex-column pe-0">
									<div className="d-flex mt-auto w-100">
										<div className="col px-1">
											<button
												onClick={() =>
													onCreateTerm()
												}
												className=" mt-auto h-100 border-0 ms-auto btn gradient-button fw-bold text-white"
											>
												<div className="d-flex">
													<i className="far fa-plus-square my-auto me-1"></i>{' '}
													New
												</div>
											</button>
										</div>
										<div className="col px-1">
											<button
												onClick={() =>
													onDeleteTerm()
												}
												className="mt-auto ms-auto btn btn-outline  btn-outline-red"
											>
												{deletingTerm
													? 'Confirm'
													: 'Delete'}
											</button>
										</div>
									</div>
								</div>
							</div>
							<div
								className="my-2"
								style={{
									minWidth: '600px',
								}}
							>
								<TermEdit
									setTermValue={
										setTermValue
									}
									termValue={termValue}
									term={selectedTerm}
								/>
							</div>
							<button
								onClick={e => {
									e.preventDefault();
									onSaveChanges();
								}}
								className="btn btn-lg btn-green text-white my-3 w-100"
							>
								Save Changes
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

interface EntitlementCardProps {
	userCount: CountUserEntitlement | undefined | null;
	entitlement: Entitlement;
}

const EntitlementCard = ({
	userCount,
	entitlement,
}: EntitlementCardProps) => {
	const {
		setOpenEntitlementModal,
		setEditingEntitlement,
	} = useSettings();

	const [deleteEntitlement] =
		useDeleteEntitlementMutation();
	const onDelete = () =>
		Promise.resolve()
			.then(
				promptModal(
					`Are you sure that you want to delete ${
						entitlement.name ||
						'this entitlement'
					}?`,
					'stop',
				),
			)
			.then(() => {
				deleteEntitlement({
					variables: {
						entitlementId: entitlement.id || '',
					},
					refetchQueries: [
						GetEntitlementsByOrgDocument,
					],
				});
			})
			.then(() => {
				toastToSuccess(
					`Deleted ${entitlement.name}`,
				);
			})
			.catch(() => {
				toastToNotify('Entitlement Not Deleted');
			});

	const onEdit = () => {
		setEditingEntitlement(entitlement);
		setOpenEntitlementModal(true);
	};

	return (
		<>
			<div className="col d-flex flex-column text-white">
				<div className="container shadow bg-dark p-4 rounded rounded-5 text-white h-100 d-flex flex-column">
					<div className="row w-100 mx-auto">
						<div className="col px-0">
							<h5 className="fw-bold mb-0">
								{entitlement.name}
							</h5>
							<p className="text-muted">
								Users:{' '}
								{userCount?.countUser}
							</p>
						</div>
					</div>
					<div className="row w-100 mx-auto my-3 px-0">
						<p className="px-0 my-1">
							<i className="fal fa-envelope me-2"></i>
							Email Footer Terms:{' '}
							{(entitlement.emailFooterTerms &&
								entitlement.emailFooterTerms
									?.length) ||
								0}
						</p>
						<p className="px-0 my-1">
							<i className="far fa-browser me-2"></i>
							Landing Page Footer Terms:{' '}
							{(entitlement.landingPageFooterTerms &&
								entitlement
									.landingPageFooterTerms
									?.length) ||
								0}
						</p>
						<p className="px-0 my-1">
							<i className="fas fa-mouse me-2"></i>
							Landing Page Button Terms:{' '}
							{(entitlement.landingPageButtonTerms &&
								entitlement
									.landingPageButtonTerms
									?.length) ||
								0}
						</p>
					</div>
					<div className="row mt-auto pt-3">
						<div className="col-4">
							<button
								onClick={() => onDelete()}
								className="btn btn-sm btn-outline btn-outline-red w-100"
							>
								Delete
							</button>
						</div>
						<div className="col-8">
							<button
								onClick={() => {
									onEdit();
								}}
								className="btn btn-sm btn-outline btn-outline-turquoise w-100"
							>
								<i className="fas fa-edit"></i>{' '}
								Edit
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const EntitlementSettings = () => {
	const [entitlementList, setEntitlementList] =
		useState<Entitlement[]>();

	const [createEntitlement] =
		useCreateEntitlementMutation();

	const onAdd = () => {
		createEntitlement({
			refetchQueries: [GetEntitlementsByOrgDocument],
		})
			.then(() => {
				toastToSuccess('Entitlement Created');
			})
			.catch(error => {
				console.error(error);
				toastToFailure(
					'Unable to create entitlement',
				);
			});
	};

	const { data: entitlementsQueryResult } =
		useGetEntitlementsByOrgQuery();

	const { data: userCountQueryResponse } =
		useCountUsersByEntitlementQuery();

	const countUsers =
		userCountQueryResponse?.CountUsersByEntitlement;

	const entitlements =
		entitlementsQueryResult?.GetEntitlementsByOrg;

	useEffect(() => {
		if (entitlements) {
			const entArray: Entitlement[] = [];
			entitlements.forEach(entitlement =>
				entArray.push(entitlement as Entitlement),
			);
			setEntitlementList(entArray);
		}
	}, [entitlements]);

	return (
		<>
			<div className="container-fluid px-0">
				<div className="div mx-auto w-100 justify-content-between d-flex w-100 mx-auto">
					<button
						onClick={() => {
							onAdd();
						}}
						className="btn gradient-button btn-md border-0 text-white me-4 fw-bold"
					>
						<i className="fas fa-plus"></i>{' '}
						Create Entitlement
					</button>
				</div>
				<div className="row w-100 mx-auto mt-3 row-cols-1 row-cols-lg-2 row-cols-xxxl-5 g-4">
					{entitlementList &&
						entitlementList.map(
							(entitlement, eIndex) => (
								<EntitlementCard
									entitlement={
										entitlement
									}
									userCount={countUsers?.find(
										el =>
											el?.entitlementId ===
											entitlement.id,
									)}
									key={eIndex}
								/>
							),
						)}
				</div>
			</div>
		</>
	);
};

export default EntitlementSettings;
