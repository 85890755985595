import { toast } from 'react-hot-toast';

/** Send a toast to notify the user that something has failed */
export const toastToFailure = (message: string) =>
	toast(message, {
		duration: 4000,
		position: 'top-center',
		className: 'bg-red text-white',
		// Emoji
		icon: '⚠️',
		// Aria
		ariaProps: {
			role: 'status',
			'aria-live': 'polite',
		},
	});
