import {
	ExperienceStep,
	HybridExperience,
} from '../../gql/queries/generated/graphql';

import { CreateExperienceSubroute1 } from './create-experience-types';
import { IRouteMap } from './navbar/create-experience-navbar';

export const mapStepToRoute = (
	experienceStep: ExperienceStep | undefined,
): CreateExperienceSubroute1 => {
	const stepMapping: Record<
		ExperienceStep,
		CreateExperienceSubroute1
	> = {
		[ExperienceStep.Createname]: 'create-name',
		[ExperienceStep.Audience]: 'add-audience',
		[ExperienceStep.Email]: 'add-email',
		[ExperienceStep.Page]: 'configure-form',
		[ExperienceStep.Gift]: 'choose-gift',
		[ExperienceStep.Print]: 'configure-print',
		[ExperienceStep.Review]: 'review',
	};
	return experienceStep
		? stepMapping[experienceStep]
		: 'create-name';
};

export const recommendedRedirect = (
	experience: HybridExperience | undefined,
) =>
	experience
		? `/create-experience/${
				experience?.id
		  }/${mapStepToRoute(
				experience?.maxStep || undefined,
		  )}`
		: '/create-experience/create-name';

export const routeOrder: {
	position: number;
	name: CreateExperienceSubroute1;
}[] = [
	{
		position: 0,
		name: 'create-name',
	},
	{ name: 'add-audience', position: 1 },
	{ name: 'add-email', position: 2 },
	{ name: 'configure-form', position: 3 },
	{ name: 'choose-gift', position: 4 },
	{ name: 'configure-print', position: 5 },
	{ name: 'review', position: 6 },
];

export const isValidSubroute = (
	newHybridExperience: HybridExperience | undefined,
	stepFromRouter: CreateExperienceSubroute1,
) => {
	const hybridIndex = newHybridExperience
		? routeOrder.findIndex(
				route =>
					route.name ===
					mapStepToRoute(
						newHybridExperience.maxStep ||
							undefined,
					),
		  )
		: 0;

	const stepIndex = routeOrder.findIndex(
		route => route.name === stepFromRouter,
	);

	if (stepIndex === -1) {
		throw new Error('Configuration Step not valid');
	}

	if (hybridIndex === -1) {
		throw new Error('Configuration Step not valid');
	}

	return stepIndex <= hybridIndex;
};

export const chooseRouteOrRedirect = ({
	loading,
	hybridExperience,
	history,
	subroute,
}: {
	loading: boolean;
	hybridExperience: HybridExperience | undefined;
	history: { push: (arg0: string) => void };
	subroute: CreateExperienceSubroute1;
}): CreateExperienceSubroute1 | 'loading' => {
	if (loading) {
		return 'loading';
	}

	if (!isValidSubroute(hybridExperience, subroute)) {
		history.push(recommendedRedirect(hybridExperience));
		return 'loading';
	}
	return subroute;
};

export const createRouteMap = (
	experience: HybridExperience,
): IRouteMap => {
	const audienceRoute = {
		valid: experience.audienceConfigured,
		label: 'Audience',
	};

	const emailRoute = {
		valid: experience.emailConfigured,
		label: 'Email',
	};

	const giftRoute = {
		// TODO: Update experience kit config property to be gift
		valid: experience.giftConfigured,
		label: 'Gift',
	};

	const formRoute = {
		valid: experience.pageConfigured,
		label: 'Page',
	};

	const printRoute = {
		valid: experience.printConfigured,
		label: 'Print',
	};

	const mostRoutes = [
		audienceRoute,
		emailRoute,
		formRoute,
		giftRoute,
		printRoute,
	];

	return [
		...mostRoutes,
		{
			label: 'Review',
			valid: mostRoutes.every(route => route.valid),
		},
	];
};
