import { useState } from 'react';
import LoadingScreen from '../../../../loading-screen/loading-screen';
import {
	Asset,
	useGetAssetsByEntitlementAndAssetTypesQuery,
	VersionType,
	AssetType,
} from '../../../../../gql/queries/generated/graphql';
import EmailCustomizationNavbar from '../navbar/email-customization-navbar';
import SelectImagesTab from './select-images-tab';
import EmailCustomizationTab from './email-customization-tab';
import { useEmailConfigurationStep } from './providers/email-configuration-step.provider';
import { HermesColor } from '../../../../../colors.enum';

import { ConfigureEmailMapping } from './configure-email-mapping/configure-email-mapping-tab';

const ConfigureEmailBrowser = () => {
	const { data } =
		useGetAssetsByEntitlementAndAssetTypesQuery({
			variables: {
				assetTypes: [AssetType.Emailheaderimage],
			},
		});

	// Todo! Delete this, refactor components
	const [activeMenuItem, setActiveMenuItem] =
		useState<HTMLButtonElement>();

	const assets =
		(data?.GetAssetsByEntitlementAndAssetTypes ||
			[]) as Asset[];

	const {
		emailConfigurationStep,
		selectedVersion,
		showSettings,
	} = useEmailConfigurationStep();

	const assignHighlightColor = (): HermesColor => {
		if (showSettings) {
			return 'gray';
		}
		if (selectedVersion === VersionType.Versionb) {
			return 'turquoise';
		}
		return 'purple';
	};

	return (
		<div
			className={`border 
			border-${assignHighlightColor()} 
			border-5 w-100  d-flex flex-grow-1`}
			style={{
				borderBottomLeftRadius: '10px',
				borderBottomRightRadius: '10px',
				overflowX: 'hidden',
			}}
		>
			{
				<div
					className="w-100 h-100"
					style={{
						position: 'relative',
						overflow: 'hidden',
					}}
				>
					<div
						id="overlay"
						className="w-100 h-100 d-flex flex-column justify-content-end  rounded "
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							// Without this property you couldn't click on anything inside of the pointerEvent
							pointerEvents: 'none',
						}}
					>
						<EmailCustomizationNavbar
							setActive={setActiveMenuItem}
							showSettings={showSettings}
						/>
					</div>
					{!showSettings && (
						<>
							{emailConfigurationStep ===
								'loading' && (
								<LoadingScreen />
							)}

							{emailConfigurationStep ===
								'select-image' && (
								<SelectImagesTab
									assets={assets}
								/>
							)}

							{emailConfigurationStep ===
								'email-customization' && (
								<EmailCustomizationTab />
							)}

							{emailConfigurationStep ===
								'field-mapping' && (
								<ConfigureEmailMapping />
							)}
						</>
					)}
				</div>
			}
		</div>
	);
};

export default ConfigureEmailBrowser;
