import { useConfigurationStep } from '../review-step/review-step-provider';

const RenderWindow = ({
	emailHtml,
}: {
	emailHtml: string;
}) => {
	const { color } = useConfigurationStep();
	function createMarkup() {
		return { __html: emailHtml };
	}

	function Render() {
		return (
			<div
				className={`mx-auto my-auto rounded rounded-3 border border-${color} border-2`}
				dangerouslySetInnerHTML={createMarkup()}
			/>
		);
	}
	return (
		<div
			className=" bg-dark text-white d-flex py-5 pt-5"
			style={{
				background: '#f6f9fc',
			}}
		>
			<Render></Render>
		</div>
	);
};

export default RenderWindow;
