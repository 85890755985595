import { HybridExperience } from '../../../gql/queries/generated/graphql';
import AddEmailButton, {
	TEmailNavigatorButtonState,
} from '../2-add-email/add-email-buttons';
import Namebar from '../namebar';

const defaultProps = {
	nextButtonText: 'Next' as string,
	prevButtonText: 'Previous' as string,
	showNext: true as boolean,
	showPrev: true as boolean,
};

const PrevAndNextContainer = ({
	nextOnClick,
	prevOnClick,
	nextButtonState,
	nextButtonLabel,
	prevButtonLabel,
	prevButtonText,
	nextButtonText,
	experience,
	showNext,
	showPrev,
}: {
	nextOnClick: () => any;
	prevOnClick: () => any;
	nextButtonState: TEmailNavigatorButtonState;
	nextButtonLabel: string;
	prevButtonLabel: string;
	experience: HybridExperience | undefined;
} & typeof defaultProps) => (
	<div className="w-100 d-flex align-items-center justify-content-center px-5 mt-4">
		{showPrev && (
			<AddEmailButton
				type="previous"
				onClick={prevOnClick}
				text={prevButtonText}
				label={prevButtonLabel}
			/>
		)}
		{experience && <Namebar experience={experience} />}
		{showNext && (
			<AddEmailButton
				type={nextButtonState}
				onClick={nextOnClick}
				text={nextButtonText}
				label={nextButtonLabel}
			/>
		)}
	</div>
);

PrevAndNextContainer.defaultProps = defaultProps;

export default PrevAndNextContainer;
