import 'react-calendar/dist/Calendar.css';
import AudienceOverviewPanel from './AudienceOverview';
import SchedulePanel from './SchedulePanel';
import TestSizePanel from './TestSizePanel';
import NotesCard from './NotesCard';

const AudienceReview = () => (
	<div className="h-100 w-100 d-flex flex-column">
		<div className="row w-100 mx-auto g-0">
			<div className="col-4 p-1">
				<AudienceOverviewPanel />
			</div>
			<div className="col-8 p-1">
				<TestSizePanel />
			</div>
		</div>
		<div className="row w-100 mx-auto g-0 h-100">
			<div className="col-4 p-1">
				<NotesCard />
			</div>
			<div className="col-8 p-1">
				<SchedulePanel />
			</div>
		</div>
	</div>
);

export default AudienceReview;
