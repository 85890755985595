import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import { fileURLToPath } from 'url';
import { toastToFailure } from '../../../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../../../components/trigger-toasts/toast-to-success';
import {
	FormField,
	FormFieldInput,
	FormFieldType,
	GetExperienceDocument,
	GetLandingPagePreviewUrlDocument,
	ResetActionType,
	useResetToExperienceStepMutation,
	useUpdateHybridExperiencePageMappingMutation,
	VersionType,
} from '../../../../gql/queries/generated/graphql';
import promptModal from '../../../../providers/alert-provider/prompt-modal';
import { useCurrentExperience } from '../../current-experience.provider';
import { useConfigurationStep } from '../configuration-form-step/configuration-form-step-provider';

interface FormFieldProps {
	formField: FormFieldInput;
	onFieldChange: (field: FormFieldInput) => void;
}

// An individual mapping row within the field mapping
const FormFieldRow = ({
	formField,
	onFieldChange,
}: FormFieldProps) => {
	const { data, refetch: refetchCurrentExperience } =
		useCurrentExperience();

	const { setPageUpdating } = useConfigurationStep();

	const currentExperience = data?.GetHybridExperienceById;

	const formFieldMappedInPrint =
		currentExperience?.printOnDemandFields.find(
			podField =>
				podField.formField?.type === formField.type,
		);

	const [deletePrintFields] =
		useResetToExperienceStepMutation();

	const onRequiredChange = async (req: boolean) => {
		// If the field was mapped in print and is trying to be de-required, we need to nuke the print settings
		if (formFieldMappedInPrint && !req) {
			// Warn the user the change they're about to make will impact their print mapping
			const resetExp = await promptModal(
				'Your Print configurations depend on this field being required. Changing this will delete all of your Print configurations. Are you sure you want to continue?',
				'Not Changed.',
			)()
				.then(() => true)
				.catch(() => false);
			if (resetExp) {
				Promise.resolve()
					.then(() =>
						toastToNotify(
							'Deleting print configurations...',
						),
					)
					.then(() =>
						deletePrintFields({
							variables: {
								hybridExperienceId:
									currentExperience?.id ||
									'',
								resetActionType:
									ResetActionType.ChangeRequiredFieldsOnPage,
							},
						})
							.then(() => {
								setPageUpdating(true);
							})
							.then(() =>
								refetchCurrentExperience(),
							)
							.then(() => {
								toastToSuccess(
									'Successfully deleted all Print configurations.',
								);
							})
							.catch(() => {
								throw new Error(
									"Couldn't delete Print configurations",
								);
							}),
					);
			}
		} else {
			onFieldChange({
				...formField,
				isRequired: req,
				isVisible: req ? true : formField.isVisible,
			});
		}
	};

	return (
		<div className="row mx-auto w-100 my-2 g-1">
			<div className="col">
				<p className="text-white px-0 my-auto fw-bold">
					{formField.displayText}
				</p>
			</div>
			<div className="col">
				<input
					type="text"
					className="form-control w-100 rounded bg-white"
					style={{
						overflow: 'auto',
					}}
					value={formField.placeholder}
					autoComplete={formField.autoComplete}
					onChange={e =>
						onFieldChange({
							...formField,
							placeholder: e.target.value,
							label: e.target.value, // Actually shows up in exp consumption app
						})
					}
				/>
			</div>
			<div className="col-2 text-center">
				{!formField.isDefault && (
					<div className="form-check form-check-custom form-check-solid form-check-sm">
						<input
							checked={formField.isRequired}
							type="checkbox"
							className="form-check-input mx-auto "
							onChange={e =>
								onRequiredChange(
									e.target.checked,
								)
							}
						/>
					</div>
				)}
				{formField.isDefault && (
					<p>
						<i className="text-gray-dark fs-2 fa-solid fa-square-check"></i>
					</p>
				)}
			</div>
			<div className="col-2 text-center">
				{!formField.isDefault && (
					<div className="form-check form-check-custom form-check-solid form-check-sm">
						<input
							type="checkbox"
							disabled={formField.isRequired}
							checked={
								formField.isVisible ||
								formField.isRequired
							}
							className="form-check-input mx-auto "
							onChange={e =>
								onFieldChange({
									...formField,
									isVisible:
										e.target.checked,
								})
							}
						/>
					</div>
				)}
				{formField.isDefault && (
					<p>
						<i className="text-gray-dark fs-2 fa-solid fa-square-check"></i>
					</p>
				)}
			</div>
		</div>
	);
};

const FieldHeaders = () => (
	<div className="row text-purple mx-auto w-100 g-1">
		<p className="fw-bold col">Field</p>
		<p className="fw-bold col">Placeholder</p>
		<p className="fw-bold col-2 text-center">
			Required
		</p>
		<p className="fw-bold col-2 text-center">Visible</p>
	</div>
);

const ConfigureFormMappingForm = () => {
	// Current experience data and refetch query
	const { data } = useCurrentExperience();
	// set some specific consts to use in the component
	const experience = data?.GetHybridExperienceById;
	const experienceId = experience?.id;

	// Figure out which version of the page we are configuring
	const { setPageUpdating, selectedVersion } =
		useConfigurationStep();

	// On map submission, update the hx
	const [updateHybridExperiencePageMapping] =
		useUpdateHybridExperiencePageMappingMutation();

	const pages = experience?.pages;
	const selectedPage =
		pages &&
		pages.find(
			page => page.version === selectedVersion,
		);

	const pageTemplate = selectedPage?.pageTemplate;
	const templateFormFields = pageTemplate?.formFields;
	const pageFormFields = selectedPage?.formFields;

	// Starting with an array from the template allows for updates to run properly to the right sub object
	const [mappedFormFields, setMappedFormFields] =
		useState<FormFieldInput[]>();

	useEffect(() => {
		if (templateFormFields && pageFormFields) {
			const tfMap = templateFormFields.map(tf => {
				const mapMatch = pageFormFields.find(
					pf => pf.type === tf.type,
				);
				return {
					autoComplete: tf.autoComplete,
					displayText:
						mapMatch?.displayText ||
						tf.label ||
						'', // silly input type undefined issue
					isDefault: tf.isDefault,
					isRequired:
						mapMatch?.isRequired ||
						tf.isRequired,
					isVisible:
						mapMatch?.isVisible || tf.isVisible,
					label: mapMatch?.label || tf.label,
					placeholder:
						mapMatch?.placeholder ||
						tf.placeholder,
					type: tf.type,
				};
			});
			setMappedFormFields(tfMap);
		}
	}, [selectedPage]);

	// As individual field properties change we need to update the local state map so that update actions reflect properly
	const onFieldChange = (field: FormFieldInput) => {
		if (mappedFormFields) {
			const stateCopy = [...mappedFormFields];
			const fieldIndex = stateCopy.findIndex(
				sc => sc.type === field.type,
			);
			stateCopy[fieldIndex] = field;
			setMappedFormFields([...stateCopy]);
		}
	};

	const onSubmit = () => {
		Promise.resolve()
			.then(() =>
				updateHybridExperiencePageMapping({
					variables: {
						hybridExperienceId:
							experienceId || '',
						version: selectedVersion,
						formFields: mappedFormFields || [],
					},
					refetchQueries: [
						GetExperienceDocument,
						GetLandingPagePreviewUrlDocument,
					],
				}).catch(() => {
					throw new Error(
						'Failed to update, please try again',
					);
				}),
			)
			.then(() => setPageUpdating(true))
			.then(() => {
				toastToSuccess('Updated successfully');
			})
			.catch(error => {
				console.error(error);
				toastToFailure(error.message);
			});
	};

	return (
		<>
			<form
				onSubmit={e => {
					e.preventDefault();
					onSubmit();
				}}
				className=" d-flex flex-column"
				style={{ flexGrow: 1 }}
			>
				<div
					id="configure-form-text-fields"
					className="w-100 "
				>
					<FieldHeaders />

					{mappedFormFields &&
						mappedFormFields.map(
							(mf, ffIndex) => (
								<FormFieldRow
									key={`${mf.type}-${selectedVersion}`}
									onFieldChange={
										onFieldChange
									}
									formField={mf}
								/>
							),
						)}
				</div>
				<div className="d-flex flex-column justify-content-end">
					<div className="w-100 d-flex justify-content-between align-items-start">
						<input
							data-cy="formFieldMapping-update"
							type="submit"
							value="Update"
							className={`btn text-white bold ${
								selectedVersion !==
								'VERSIONA'
									? 'btn-turquoise'
									: 'btn-purple'
							}`}
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default ConfigureFormMappingForm;
