import { useHistory } from 'react-router';
import LetterMan from './assets/letterman.svg';

const ThankYou = () => {
	const history = useHistory();
	return (
		<div className="purpose-ui">
			<section className="slice slice-lg vh-100 bg-gradient-primary overflow-hidden">
				<div className="container h-100 d-flex align-items-center position-relative zindex-100">
					<div className="col">
						<div className="row justify-content-center">
							<div className="col-lg-10 text-center">
								<div className="row flex-lg-row-reverse align-items-center g-5 py-5">
									<div className="col-10 col-sm-8 col-lg-6">
										<img
											src={LetterMan}
											className="d-block mx-lg-auto img-fluid"
											alt="Thank You"
											loading="lazy"
										/>
									</div>
									<div className="col-lg-6 text-white">
										<h1 className="display-5 fw-bold lh-1 mb-3 text-white">
											Thanks For
											Reaching Out!
										</h1>
										<p className="lead">
											We{`'`}ll follow
											up on your
											request for more
											information as
											soon as we can!
										</p>
									</div>
								</div>
								<div
									onClick={() =>
										history.push('/')
									}
									className="btn btn-white rounded-pill hover-translate-y-n3 mb-4"
								>
									<span className="btn-inner--icon">
										<i className="fas fa-home"></i>
									</span>
									<span className="btn-inner--text">
										Home
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ThankYou;
