import { useHistory } from 'react-router';
import React, { ReactElement, useContext } from 'react';
import {
	faCheck,
	faLock,
	faRocket,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../colors.enum';
import { HybridExperience } from '../../gql/queries/generated/graphql';
import {
	createRouteMap,
	routeOrder,
} from './create-experience-helpers';
import {
	getExperienceStep,
	getRouteName,
	GetRouteNameInput,
	selectStep,
	subrouteToExperienceStep,
} from './navbar/navbar-helper-functions';
import CreateExperienceNavbar, {
	OnClickStepHandler,
} from './navbar/create-experience-navbar';
import { CreateExperienceSubroute1 } from './create-experience-types';
import { toastToNotify } from '../../components/trigger-toasts/toast-to-notify';
import { toastToFailure } from '../../components/trigger-toasts/toast-to-failure';

export const ContainerContext = React.createContext<
	HTMLDivElement | string | undefined
>(undefined);

export const useContainer = () => {
	const context = useContext(ContainerContext);
	if (context === undefined) {
		throw new Error(
			'useAuth can only be used within the scope of an Auth Provider',
		);
	}
	return context;
};

const CreateExperienceLayout = ({
	children,
	experience,
	currentStep,
}: {
	children: ReactElement | ReactElement[];
	experience: HybridExperience;
	currentStep: CreateExperienceSubroute1;
}) => {
	const routeMap = createRouteMap(experience);
	const history = useHistory();

	const maxStep = getExperienceStep(
		experience.maxStep || undefined,
	);

	const selectedStep = selectStep({
		currentStep,
		routeOrder,
	});

	const onClickStepHandler: OnClickStepHandler = (
		step,
		reachable,
	) => {
		const selectedRouteName = getRouteName(
			step as GetRouteNameInput,
		);
		const selectedExperienceStep =
			subrouteToExperienceStep(selectedRouteName);

		const selectedStepNumber = getExperienceStep(
			selectedExperienceStep,
		);

		const stepState = (() => {
			const isValidStep =
				selectedStepNumber <= maxStep;
			const isSelectedStep =
				selectedStepNumber === selectedStep;

			if (isSelectedStep) {
				return 'selected';
			}
			if (isValidStep) {
				return 'valid';
			}
			return 'invalid';
		})();

		if (stepState === 'valid') {
			history.push(
				`/create-experience/${experience.id}/${selectedRouteName}`,
			);
		}

		if (stepState === 'selected') {
			toastToNotify("You're already on that step!");
		}

		if (stepState === 'invalid') {
			toastToFailure(
				'You have not configured all required fields for this step.',
			);
		}
	};

	return (
		<>
			<div
				style={{ overflowX: 'hidden' }}
				className="w-100 bg-dark px-3 d-flex flex-column py-2"
			>
				<CreateExperienceNavbar
					maxStep={maxStep}
					routeMap={routeMap}
					labelClassName="text-white fs-4 noselect"
					selectedStep={selectedStep}
					validIcon={
						<div className="navbar-icon valid-icon">
							<FontAwesomeIcon
								icon={faCheck}
								color={Colors.white}
								size="1x"
							/>
						</div>
					}
					invalidIcon={
						<div className="navbar-icon invalid-icon">
							<FontAwesomeIcon
								icon={faTimes}
								color={Colors.white}
								size="1x"
							/>
						</div>
					}
					unreachableIcon={
						<div className="navbar-icon unreachable-icon">
							<FontAwesomeIcon
								icon={faLock}
								color={Colors['off-white']}
								size="1x"
							/>
						</div>
					}
					currentIcon={
						<div className="navbar-icon current-icon">
							<FontAwesomeIcon
								icon={faRocket}
								color={Colors.white}
								size="1x"
							/>
						</div>
					}
					reachableInactiveHighlightColor={
						Colors.gray
					}
					reachableHighlightColor={Colors.purple}
					unreachableHighlightColor={
						Colors['gray-dark']
					}
					onClickStep={onClickStepHandler}
				/>

				{children}
			</div>
		</>
	);
};

export default CreateExperienceLayout;
