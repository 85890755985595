import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import {
	SmartLady,
	BirdLady,
	SpaceMan,
	SadRobot,
	HatBoat,
	HangGlider,
} from '../../pictures/index';

export default function UnderConstruction({
	feature,
}: {
	feature: string;
}) {
	const history = useHistory();

	const images = [
		SmartLady,
		BirdLady,
		SpaceMan,
		SadRobot,
		HatBoat,
		HangGlider,
	];

	const [image, setImage] = useState<string>();

	const goToMainPage = () => {
		history.push('/');
	};

	useEffect(() => {
		const index = Math.floor(
			Math.random() * images.length,
		);
		setImage(images[index]);
	}, []);

	return (
		<div
			style={{ overflowY: 'hidden' }}
			className="h-100 row flex-row-reverse w-100 mx-auto bg-dark text-light d-flex flex-grow-1 noselect "
		>
			<div className="row d-flex">
				<div className="text-left my-3">
					<h1 className=" fw-bold">
						The {feature} is currently under
						construction.
					</h1>

					<a
						className="text-turquoise"
						href="#"
						onClick={() => {
							goToMainPage();
						}}
						data-cy="link-to-main-page"
					>
						Click here to go back
					</a>
				</div>
			</div>
			<div className="row d-flex flex-column px-0 ">
				<div className="container text-center">
					<img
						className="p-3 img mx-auto"
						alt="Under Construction"
						src={image}
						style={{
							maxHeight: '600px',
						}}
					/>
				</div>
			</div>
		</div>
	);
}
