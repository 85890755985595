import { useState } from 'react';
import Identicon from 'react-identicons';
import swal from 'sweetalert';
import { toastToNotify } from '../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../components/trigger-toasts/toast-to-success';
import {
	GetUsersByOrgDocument,
	useDeleteUserByIdMutation,
	useGetActiveUserStatsQuery,
	useGetUsersByOrgQuery,
	User,
} from '../../gql/queries/generated/graphql';
import { useSettings } from './settings-provider';
import UserAggregateCard from './components/users/userAggregateCard';
import UsersTable from './components/users/users-table';

const UserSettingsV2 = () => {
	// Grab all users in an org
	const { data: userQueryResult } =
		useGetUsersByOrgQuery();

	const editableUsers =
		userQueryResult?.GetUsersByOrg || [];

	// User stats
	const { data: userAggregateStats } =
		useGetActiveUserStatsQuery();
	const twentyFourUsers =
		userAggregateStats?.GetTwentyFourHourUsers;
	const thirtyDayUsers =
		userAggregateStats?.GetThirtyDayUsers;
	const inactiveUsers =
		userAggregateStats?.GetInactiveUsers;

	// Modal controls
	const {
		setOpenUserModal: setOpenModal,
		setEditingUser,
	} = useSettings();

	const [deleteUser] = useDeleteUserByIdMutation();

	const [deleting, setDeleting] = useState(false);

	const handleDelete = (user: User) => {
		if (user && !deleting) {
			setDeleting(true);
			Promise.resolve()
				.then(() =>
					swal({
						title: 'Delete User',
						text: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
						icon: 'error',
						buttons: {
							cancel: {
								text: 'Nevermind',
								value: false,
								visible: true,
								className:
									'btn btn-md btn-outline btn-outline-white text-white bg-hover-dark',
								closeModal: true,
							},
							confirm: {
								text: 'Yes, delete!',
								value: true,
								visible: true,
								className:
									'btn btn-md btn-outline btn-outline-danger bg-hover-danger text-hover-white',
								closeModal: true,
							},
						},
						className: 'bg-dark',
					}).then(value => {
						if (value) {
							deleteUser({
								variables: {
									deleteUserId: user.id,
								},
								refetchQueries: [
									GetUsersByOrgDocument,
								],
							}).then(() => {
								toastToSuccess(
									'The user has been deleted.',
								);
							});
						} else {
							toastToNotify(
								'The user was not deleted.',
							);
						}
					}),
				)

				.catch(() => {
					toastToNotify(
						'The user was not deleted',
					);
				})
				.finally(() => {
					setDeleting(false);
				});
		}
	};

	return (
		<div className="container px-0 d-flex flex-column flex-grow-1">
			<div className="row w-100 row-cols-3 mx-auto">
				<UserAggregateCard
					title="Active Today"
					numberDisplay={twentyFourUsers || 0}
					color="purple"
				/>
				<UserAggregateCard
					title="Total Active Users"
					numberDisplay={thirtyDayUsers || 0}
					color="pink"
				/>
				<UserAggregateCard
					title="Inactive Users"
					numberDisplay={inactiveUsers || 0}
					color="orange"
				/>
			</div>

			<UsersTable
				editableUsers={editableUsers as [User]}
				handleDelete={handleDelete}
				setOpenModal={setOpenModal}
				setEditingUser={setEditingUser}
			/>
		</div>
	);
};

export default UserSettingsV2;
