import { MutableRefObject } from 'react';
import Modal from 'react-modal';
import UserEditCreate from './components/org-user-create-edit';

interface UserModalProps {
	open: boolean;
	parentContainer: MutableRefObject<HTMLDivElement>;
	setOpen: (arg0: boolean) => void;
	edit: boolean;
}
const UserModal = ({
	open,
	setOpen,
	parentContainer,
	edit,
}: UserModalProps) => (
	<Modal
		onRequestClose={() => setOpen(false)}
		className="customModal scrollbar"
		overlayClassName="customOverlay"
		parentSelector={() => parentContainer.current}
		shouldCloseOnOverlayClick={false}
		isOpen={open}
		data-cy="user-modal"
		appElement={document.body}
	>
		<div className="modal-content w-100 border-0 bg-dark text-white">
			<UserEditCreate />
		</div>
	</Modal>
);

export default UserModal;
