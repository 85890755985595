import Select from 'react-select';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router';
import {
	SendType,
	useSendTestExperienceMutation,
	useUpdateHybridExperienceMutationMutation,
} from '../../../../gql/queries/generated/graphql';
import { useConfigurationStep } from '../review-step/review-step-provider';
import { toastToFailure } from '../../../../components/trigger-toasts/toast-to-failure';
import { toastToSuccess } from '../../../../components/trigger-toasts/toast-to-success';
import { toastToNotify } from '../../../../components/trigger-toasts/toast-to-notify';

const AudienceOverviewPanel = () => {
	const { currentExperience } = useConfigurationStep();
	const history = useHistory();
	const currentSendTypeOnExperience =
		currentExperience?.sendType;

	const listSize = currentExperience?.audienceCount;

	const fileName =
		currentExperience?.audienceList?.list?.filename;
	const experienceId = currentExperience?.id || '';
	const sendTypes = Object.values(SendType) as SendType[];
	const [updateHybridExperienceMutation] =
		useUpdateHybridExperienceMutationMutation();
	const [selectedSendType, setSelectedSendType] =
		useState<SendType | null>(
			() => currentSendTypeOnExperience || null,
		);

	const onUpdate = () => {
		updateHybridExperienceMutation({
			variables: {
				hybridExperience: {
					id: experienceId,
					sendType: selectedSendType,
				},
			},
		})
			.then(() => {
				toastToSuccess(
					'Successfully updated send type',
				);
			})
			.catch(err => {
				toastToFailure(err.message);
			});
	};

	const downloadUrl =
		currentExperience?.audienceList?.list?.fileUrl ||
		'';

	const lineHeight = 10;

	const onClickEditList = () => {
		history.push(
			`/create-experience/${experienceId}/add-audience`,
		);
	};

	const [sendTestExperience] =
		useSendTestExperienceMutation();

	const [showInput, setShowInput] = useState(false);

	const [testEmails, setTestEmails] = useState('');
	const [sendTestDisabled, setSendTestDisabled] =
		useState(false);

	const sendTestEmails = (emails: string) => {
		const emailArray = emails.split(',');
		setSendTestDisabled(true);
		Promise.resolve()
			.then(() =>
				toastToNotify('Sending test emails...'),
			)
			.then(() =>
				sendTestExperience({
					variables: {
						hybridExperienceId: experienceId,
						recipients: emailArray,
					},
				}),
			)
			.then(() => toastToSuccess('Test emails sent!'))
			.catch(err => {
				console.error(err);
				toastToFailure(
					'Failed to send, please try again',
				);
			})
			.finally(() => setSendTestDisabled(false));
	};

	return showInput ? (
		<>
			<div
				className="bg-black p-3 h-100 d-flex flex-column text-white w-100 py-5"
				style={{ borderRadius: 10 }}
			>
				<div className="d-flex">
					<h4 className="fw-bold">
						Send Test Experiences
					</h4>
					<button
						className="ms-auto btn btn-sm btn-outline btn-outline-white ms-2 mb-1 backIconButton"
						onClick={() => setShowInput(false)}
					>
						<i className="fa-solid fa-backward-step backIcon"></i>
					</button>
				</div>
				<div className="input-group my-2">
					<input
						onChange={e =>
							setTestEmails(e.target.value)
						}
						type="text"
						value={testEmails}
						className="form-control bg-white text-black"
						placeholder="Recipient Emails (comma separated)"
						aria-label="Recipient Emails (comma separated)"
						aria-describedby="button-addon2"
					/>
					<button
						disabled={sendTestDisabled}
						onClick={() =>
							sendTestEmails(testEmails)
						}
						className="btn btn-green text-white"
						type="button"
						id="button-addon2"
					>
						Send
					</button>
				</div>
			</div>
		</>
	) : (
		<>
			<div
				id="audience-overview"
				className="bg-black p-3 h-100 d-flex flex-column text-white w-100"
				style={{ borderRadius: 10 }}
			>
				<div className="d-flex">
					<h4 className="fw-bold">
						Audience Overview
					</h4>
					<button
						className="ms-auto btn btn-sm btn-outline btn-outline-green ms-2 mb-1 testSendButton"
						onClick={() => setShowInput(true)}
					>
						<i className="fa-solid fa-paper-plane testSendIcon"></i>
					</button>
				</div>
				<div
					style={{
						display: 'flex',
						width: '75%',
					}}
				>
					<p
						className="me-2"
						style={{
							textDecoration: 'underline',
							marginBottom: lineHeight,
							cursor: 'pointer',
						}}
						onClick={() => onClickEditList()}
					>
						Edit List
					</p>
					<a
						className="text-turquoise ms-2"
						href={downloadUrl}
						style={{
							textDecoration: 'underline',
							marginBottom: lineHeight,
							cursor: 'pointer',
						}}
					>
						Download
					</a>
				</div>
				<p style={{ marginBottom: lineHeight }}>
					File Name: {fileName}
				</p>
				<p style={{ marginBottom: lineHeight }}>
					List Size: {listSize}
				</p>
				<div className="d-flex align-items-center">
					<label>Send Type:</label>
					<Select
						className="ps-2 flex-grow-1"
						defaultValue={{
							value: selectedSendType,
							label: capitalize(
								selectedSendType || '',
							),
						}}
						value={{
							value: selectedSendType,
							label: capitalize(
								selectedSendType || '',
							),
						}}
						options={sendTypes.map(
							sendType => ({
								label: capitalize(sendType),
								value: sendType,
							}),
						)}
						styles={{
							container: prev => ({
								...prev,
								color: 'black',
							}),
						}}
						onChange={input => {
							setSelectedSendType(
								input?.value || null,
							);
						}}
					/>
					<button
						className="btn btn-sm ms-2 btn-turquoise h-100 text-white"
						onClick={() => {
							onUpdate();
						}}
					>
						Update
					</button>
				</div>
			</div>
		</>
	);
};

export default AudienceOverviewPanel;
