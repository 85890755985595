import LeftNav from '../../components/layout/left-nav-layout/left-nav-layout';
import CreateExperienceRouter from './create-experience-router';
import { CreateExperienceSubroute1 } from './create-experience-types';
import { CurrentExperienceProvider } from './current-experience.provider';

const defaultProps = {
	match: {
		params: {
			step: '' as CreateExperienceSubroute1,
			experienceId: undefined as string | undefined,
		},
	},
};

const CreateExperiencePage = (
	// eslint-disable-next-line @typescript-eslint/ban-types
	props: {} & typeof defaultProps,
) => {
	const { step, experienceId } = props.match.params;

	return (
		<CurrentExperienceProvider
			experienceId={experienceId}
		>
			<LeftNav>
				<CreateExperienceRouter step={step} />
			</LeftNav>
		</CurrentExperienceProvider>
	);
};

CreateExperiencePage.defaultProps = defaultProps;

export default CreateExperiencePage;
