import { CSSProperties, useEffect, useState } from 'react';
import InputRange from 'react-input-range';
import { useConfigurationStep } from '../../routes/create-experience-page/6-review/review-step/review-step-provider';
import './custom-slider.scss';

const labelStyle: CSSProperties = {
	color: 'white',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontWeight: 600,
	fontSize: 20,
};

const defaultProps = {
	defaultPercent: 50,
	disabled: false,
};

const CustomSlider = ({
	onSlideChange,
	disabled,
}: {
	onSlideChange: (val: number) => void;
} & typeof defaultProps) => {
	const { currentExperience } = useConfigurationStep();

	const [sliderState, setSliderState] = useState(50);

	useEffect(() => {
		if (
			currentExperience &&
			currentExperience.splitPercentage
		) {
			setSliderState(
				Number(currentExperience?.splitPercentage),
			);
		}
	}, []);

	useEffect(() => {
		onSlideChange(sliderState);
	}, [sliderState, onSlideChange]);

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				alignItems: 'center',
				paddingLeft: 20,
				paddingRight: 20,
				justifyContent: 'space-between',
			}}
		>
			<div style={labelStyle}>0%</div>
			<div
				style={{
					display: 'flex',
					width: '100%',
					height: 80,
					flexDirection: 'column',
					justifyContent: 'center',
					paddingLeft: 15,
					paddingRight: 30,
					cursor: disabled
						? 'not-allowed'
						: 'pointer',
					// cursor: 'not-allowed',
				}}
			>
				<div
					style={{
						pointerEvents: disabled
							? 'none'
							: 'auto',
					}}
				>
					<InputRange
						classNames={{
							activeTrack:
								'input-range-active-track',
							disabledInputRange:
								'input-range-disabled',
							inputRange: 'input-range',
							labelContainer:
								'input-range-label-container',
							maxLabel:
								'input-range-max-label',
							minLabel:
								'input-range-min-label',
							slider: 'input-range-slider',
							sliderContainer:
								'input-range-slider-container',
							track: 'input-range-track',
							valueLabel:
								'input-range-value-label',
						}}
						onChange={rangeVal => {
							setSliderState(
								rangeVal as number,
							);
						}}
						value={sliderState}
						minValue={0}
						maxValue={100}
						formatLabel={value => `${value}%`}
					/>
				</div>
			</div>
			<div style={labelStyle}>100%</div>
		</div>
	);
};

CustomSlider.defaultProps = defaultProps;

export default CustomSlider;
