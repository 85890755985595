import { HybridExperience } from '../../../gql/queries/generated/graphql';
import CreateExperienceLayout from '../create-experience-layout';
import { CreateExperienceSubroute1 } from '../create-experience-types';
import GiftSelectorWrapper from './gift-selector-wrapper';
import { ConfigurationStepProvider } from './select-gift-step/select-gift-step-provider';

function ConfigureGiftSubroute({
	experience,
	currentStep,
}: {
	experience: HybridExperience;
	currentStep: CreateExperienceSubroute1;
}) {
	return (
		<CreateExperienceLayout
			currentStep={currentStep}
			experience={experience}
		>
			<ConfigurationStepProvider>
				<GiftSelectorWrapper />
			</ConfigurationStepProvider>
		</CreateExperienceLayout>
	);
}

export default ConfigureGiftSubroute;
