export default function LoadingScreen() {
	return (
		<div
			id="loading-page-container"
			style={{
				width: '100%',
				height: '100%',
			}}
		>
			<div id="loading-animation-container">
				<div className="dot bg-orange dot-1"></div>
				<div className="dot bg-pink dot-2"></div>
				<div className="dot bg-turquoise dot-3"></div>
			</div>

			<svg
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
			>
				<defs>
					<filter id="goo">
						<feGaussianBlur
							in="SourceGraphic"
							stdDeviation="10"
							result="blur"
						/>
						<feColorMatrix
							in="blur"
							mode="matrix"
							values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
						/>
					</filter>
				</defs>
			</svg>
		</div>
	);
}
