import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toastToFailure } from '../../../../../components/trigger-toasts/toast-to-failure';
import {
	Asset,
	AssetType,
	GetAssetsByEntitlementAndAssetTypesDocument,
	GetExperienceDocument,
	useCreateAssetMutationMutation,
} from '../../../../../gql/queries/generated/graphql';
import promptModal from '../../../../../providers/alert-provider/prompt-modal';
import { useAuth } from '../../../../../providers/auth/auth-provider';
import getBase64 from '../../../../../util/thennables/get-base-64';
import { grabFirstFileThrowIfMoreFiles } from '../../../../../util/thennables/grab-first-file-throw-if-more';
import { throwIfNotExtension } from '../../../../../util/thennables/throw-if-not-extension';
import ImageAssetCard from './image-asset-card';
import NoImagesTab from './no-images-tab';

const SelectImagesTab = ({
	assets,
}: {
	assets: Asset[];
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const [disabled, setDisabled] = useState(false);

	const [createAsset] = useCreateAssetMutationMutation({
		refetchQueries: [
			GetAssetsByEntitlementAndAssetTypesDocument,
			GetExperienceDocument,
		],
	});

	// from https://www.tutorialspoint.com/euclidean-algorithm-for-calculating-gcd-in-javascript#:~:text=In%20mathematics%2C%20Euclid%27s%20algorithm%2C%20is,them%20without%20leaving%20a%20remainder.
	const findGCD = (num1: number, num2: number) => {
		let a = Math.abs(num1);
		let b = Math.abs(num2);
		while (a && b && a !== b) {
			if (a > b) {
				// eslint-disable-next-line no-self-assign
				[a, b] = [a - b, b];
			} else {
				// eslint-disable-next-line no-self-assign
				[a, b] = [a, b - a];
			}
		}
		return a || b;
	};

	const { user } = useAuth();

	const handleFiles = (files: File[]) => {
		let file: File;

		return Promise.resolve()
			.then(() => setDisabled(true))
			.then(() => files)
			.then(
				grabFirstFileThrowIfMoreFiles(
					'Please only upload one file at a time.',
				),
			)
			.then(firstFile => {
				file = firstFile;
				return firstFile;
			})
			.then(
				throwIfNotExtension(
					['jpg', 'png', 'jpeg'],
					'Invalid File Type',
				),
			)
			.then(() => files[0])
			.then(async uploadedFile => {
				// We need to wait for the img to load to get dimensions so we wrap the event in a promise that is resolved on load
				const loadedImg = (await new Promise(
					(resolve, reject) => {
						const img = new Image();
						img.src =
							window.URL.createObjectURL(
								uploadedFile,
							);
						img.onload = () => resolve(img);
						img.onerror = reject;
					},
				)) as HTMLImageElement;

				const gcd = findGCD(
					loadedImg.width,
					loadedImg.height,
				);

				// Images should have aspect ratio 12:7
				if (
					loadedImg.width / gcd !== 12 ||
					loadedImg.height / gcd !== 7
				) {
					return Promise.resolve()
						.then(
							promptModal(
								'Image does not have 12:7 aspect ratio, do you wish to continue?',
								'Image upload cancelled due to dimensions',
							),
						)
						.then(() => uploadedFile);
				}
				return uploadedFile;
			})
			.then(getBase64)
			.then(output => {
				const entitlementId = user?.entitlement.id;
				if (!entitlementId) {
					throw new Error(
						'Entitlement Not Found',
					);
				}
				return createAsset({
					variables: {
						content: output as string,
						entitlementId,
						filename: file.name,
						assetType:
							AssetType.Emailheaderimage,
					},
				});
			})
			.catch(err => {
				console.error(err);
				toastToFailure('Image Not Uploaded');
			})
			.finally(() => {
				const form = inputRef.current?.form;

				// resetting the form allows for repeated uploads
				if (form) {
					form.reset();
				}

				setDisabled(false);
			});
	};

	const { getInputProps, isDragActive } = useDropzone({
		onDrop: handleFiles,
		disabled,
	});

	// Filtered to only be EmailHeaderImage right now
	const filteredAssets = assets.filter(
		asset =>
			asset.assetType === AssetType.Emailheaderimage,
	);

	return (
		<>
			{assets.length > 0 && (
				<div
					className={`col h-100 d-flex flex-column`}
					style={{
						opacity: isDragActive ? 0.7 : 1,
						width: 'auto',
						height: 'auto',
					}}
				>
					<div
						id="upload-image-bar"
						className="d-flex m-3"
					>
						<input
							{...getInputProps()}
							ref={inputRef}
						/>
						<p
							className=" text-white"
							style={{
								fontWeight: 700,
							}}
						>
							Select an Image
						</p>
						<button
							className="btn btn-sm btn-purple text-white ms-auto"
							style={{ fontWeight: 700 }}
							onClick={() => {
								inputRef.current?.click();
							}}
						>
							Upload An Image
						</button>
					</div>
					{/* Input allows for file upload */}
					<input {...getInputProps()} />

					<div
						id="card-container"
						className="d-flex flex-column flex-grow-1 mx-auto w-100"
						style={{
							marginBottom: '70px',
						}}
					>
						<div
							className="shrink-content scrollbar"
							style={{ overflowX: 'hidden' }}
						>
							<div
								className="row g-4 w-100 mx-auto px-3  row-cols-lg-2 row-cols-xl-3 mb-4 scrollbar"
								style={{
									overflowY: 'auto',
									overflowX: 'hidden',
								}}
							>
								{filteredAssets.map(
									asset => (
										<ImageAssetCard
											asset={asset}
											key={asset.id}
										/>
									),
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			{assets.length === 0 && <NoImagesTab />}
		</>
	);
};

export default SelectImagesTab;
