// Currently only works with one file
export const throwIfNotExtension =
	(validExtensions: string[], message: string) =>
	(file: File) => {
		const ext = file.name.split('.').pop() || '';
		if (![...validExtensions].includes(ext)) {
			throw new Error(message);
		}
		return file;
	};
