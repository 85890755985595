import HighlightedBox from '../highlighted-box';

export interface SliderSplitCardProps {
	title: string;
	count: number;
	color: string;
}

const SliderSplitCard = ({
	title,
	count,
	color,
}: SliderSplitCardProps) => (
	<HighlightedBox color={color}>
		<div className="w-100 text-center">
			<h4 className="mx-auto mb-0">{title}</h4>
			<p className="mx-auto mb-0">
				{Math.floor(count)} recipients
			</p>
		</div>
	</HighlightedBox>
);

export default SliderSplitCard;
