/* eslint-disable react/no-unknown-property */
import { useHistory } from 'react-router';
import MainNavbar from 'components/navigation/main-navigation/main-navbar';
import MainNavbarItem from 'components/navigation/main-navigation/main-navbar-item';
import {
	ReactElement,
	createContext,
	MutableRefObject,
	useRef,
	useState,
} from 'react';
import {
	SettingsProvider,
	useSettings,
} from '../../../routes/settings-page/settings-provider';

import { signoutFirebase } from '../../../firebaseConfig/auth-functions';
import nimblLogo from '../../../pictures/nimblIcon400x400.png';
import promptModal from '../../../providers/alert-provider/prompt-modal';
import { useAuth } from '../../../providers/auth/auth-provider';
import SettingsNav from '../../../routes/settings-page/settings-nav';
import { toastToSuccess } from '../../trigger-toasts/toast-to-success';

export const MainContainerContext = createContext<
	MutableRefObject<HTMLDivElement> | undefined
>(undefined);

interface LeftNavProps {
	children: ReactElement | ReactElement[];
}

const LogoImg = () => {
	const history = useHistory();
	return (
		<>
			<img
				id="home-button"
				style={{ width: 45, cursor: 'pointer' }}
				src={nimblLogo}
				onClick={() => {
					history.push('/home');
				}}
			/>
		</>
	);
};

// eslint-disable-next-line arrow-body-style
const LeftNav = ({ children }: LeftNavProps) => {
	const [canClick, setCanClick] = useState(true);
	const history = useHistory();
	const mainContainerEl =
		useRef() as MutableRefObject<HTMLDivElement>;

	const { user } = useAuth();

	const { permissionLevel } = useSettings();

	const showSettings = () =>
		history.location.pathname.includes('settings');

	const handleLogout = () => {
		setCanClick(false);
		Promise.resolve()
			.then(
				promptModal(
					'Are you sure you want to logout?',
					'Not Logged Out',
				),
			)
			.then(() => {
				toastToSuccess(
					'You have successfully logged out!',
				);
				signoutFirebase().then(() =>
					history.push('/signin'),
				);
			})
			.catch(() => {
				setCanClick(true);
			});
	};

	return (
		<>
			<main
				id="kt_body"
				className="h-100 w-100 aside-fixed aside-extended-enabled"
			>
				<div className="d-flex flex-column flex-root h-100">
					<div className="page d-flex flex-row flex-column-fluid h-100">
						<div
							id="kt_aside"
							className="aside aside-extended ?php Theme::printHtmlClasses('aside', false);?&gt; bg-black"
							data-kt-drawer="true"
							data-kt-drawer-name="aside"
							data-kt-drawer-activate="{default: true, lg: false}"
							data-kt-drawer-overlay="true"
							data-kt-drawer-width="{default:'225px', '350px': '300px'}"
							data-kt-drawer-direction="start"
							data-kt-drawer-toggle="#kt_aside_toggle"
						>
							<div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
								<div
									className="aside-logo d-flex flex-column align-items-center flex-column-auto py-5 py-lg-10"
									id="kt_aside_logo"
								>
									<LogoImg />
								</div>
								<div
									className="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-0 pb-5"
									id="kt_aside_nav"
								>
									<div
										className="hover-scroll-y"
										data-kt-scroll="true"
										data-kt-scroll-activate="true"
										data-kt-scroll-height="auto"
										data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
										data-kt-scroll-offset="40px"
									>
										<ul className="nav flex-column">
											<MainNavbarItem
												clickHandler={() => {
													history.push(
														'/create-experience/create-name',
													);
												}}
												tooltip="Experience Creator"
												isActive={history.location.pathname.includes(
													'/create-experience',
												)}
												iconName="fa-solid fa-rocket-launch"
												id="create-experience-navigator"
											/>
											<MainNavbarItem
												clickHandler={() => {
													history.push(
														'/experience-analytics',
													);
												}}
												isActive={history.location.pathname.includes(
													'/experience-analytics',
												)}
												tooltip="Analytics"
												iconName="fa-solid fa-chart-mixed"
												id="experience-analytics-navigator"
											/>
											<MainNavbarItem
												clickHandler={() => {
													history.push(
														'/settings/user',
													);
												}}
												isActive={history.location.pathname.includes(
													'settings',
												)}
												tooltip="Settings"
												iconName="fa-solid fa-cogs"
												id="settings-navigator"
											/>
											<MainNavbarItem
												clickHandler={() => {
													history.push(
														'/knowledge-base',
													);
												}}
												isActive={history.location.pathname.includes(
													'/knowledge-base',
												)}
												tooltip="Knowledge Base"
												iconName="fa-solid fa-lightbulb"
												id="knowledge-base-navigator"
											/>
										</ul>
									</div>
								</div>
								<div
									className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-5"
									id="kt_aside_footer"
								>
									<div className="mb-2">
										<ul className="nav flex-column">
											<MainNavbarItem
												clickHandler={() =>
													canClick &&
													handleLogout()
												}
												tooltip="Sign Out"
												iconName="fa-solid fa-sign-out-alt"
												id="logout-button"
											/>
										</ul>
									</div>
								</div>
							</div>
							{showSettings() && (
								<SettingsNav
									permLevel={
										permissionLevel
									}
									settingsPath="users"
								/>
							)}
						</div>
						<div
							id="left-nav-main-container"
							ref={mainContainerEl}
							className={`d-flex bg-dark ${
								showSettings()
									? 'secondaryAside'
									: ''
							}`}
						>
							<MainContainerContext.Provider
								value={mainContainerEl}
							>
								{children}
							</MainContainerContext.Provider>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

LeftNav.defaultProps = {
	children: [],
};
export default LeftNav;
