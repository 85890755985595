import ConfigureFormMappingForm from './form';

const ConfigureFormMappingTab = () => (
	<div className="col h-100 d-flex flex-column text-white">
		<div
			className="d-flex flex-column flex-grow-1 mx-auto w-100"
			style={{
				marginBottom: '70px',
			}}
		>
			<div className="shrink-content px-3 scrollbar">
				<h5 className="pt-3">Field Mapping</h5>
				<p className="mb-3">
					{
						// Cannot just render inside because a single quote can be escaped
						"Select the form fields that you'd like to include on your landing page using the audience list you uploaded"
					}
				</p>
				<ConfigureFormMappingForm />
			</div>
		</div>
	</div>
);
export default ConfigureFormMappingTab;
