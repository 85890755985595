import {
	MouseEventHandler,
	useEffect,
	useState,
} from 'react';
import { useHistory } from 'react-router';
import { TemplateCardProps } from '../../../components/template-selector/template-card';
import { TemplateRow } from '../../../components/template-selector/template-row';
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import {
	EmailTemplate,
	HybridExperience,
	useCreateExperienceEmailMutation,
	useGetEmailTemplatesQuery,
} from '../../../gql/queries/generated/graphql';
import promptModal from '../../../providers/alert-provider/prompt-modal';

import { useEmailStep } from '../../../providers/email-step/email-step.provider';
import PrevAndNextContainer from '../components/prev-and-next-container';
import { useCurrentExperience } from '../current-experience.provider';
import { useEmailConfigurationStep } from './components/configure-email/providers/email-configuration-step.provider';
import { assignNextButton } from './router/assign-button';

const TemplateCard = ({
	title,
	image,
	description,
	onClick,
	isSelected,
}: {
	title: string;
	image: string;
	description: string;
	onClick: MouseEventHandler<HTMLElement>;
	isSelected: boolean;
}) => (
	<>
		<div
			className={`col-4 mx-auto template-card`}
			onClick={onClick}
			style={{ cursor: 'pointer' }}
		>
			<div
				className={`card bg-dark rounded-3 d-flex p-2 px-3 ${
					isSelected
						? 'border border-3 border-purple rounded-3 '
						: 'border-0'
				}`}
			>
				<div className="card-header p-0 bg-dark text-white border-0">
					<p className="my-0 mb-2 fw-bold">
						{title}
					</p>
				</div>
				<div className="card-body bg-dark border-0 p-0">
					<img
						src={image}
						className="img-fluid rounded rounded-3"
					/>
				</div>
				<div className="card-footer border-0 bg-dark text-white pb-3">
					<p className="fw-light">
						<small>{description}</small>
					</p>
				</div>
			</div>
		</div>
	</>
);

const SelectEmailTemplate = () => {
	const [createExperienceEmailMutation] =
		useCreateExperienceEmailMutation();
	const {
		data: currentExperienceData,
		refetch: refetchCurrentExperience,
	} = useCurrentExperience();
	const currentExperience =
		currentExperienceData?.GetHybridExperienceById;
	const currentExperienceId = currentExperience?.id;

	const { data } = useGetEmailTemplatesQuery();

	const templates = data?.GetEmailTemplates
		? data.GetEmailTemplates
		: [];

	const templatesCorrected: EmailTemplate[] =
		templates.map(
			template => template as EmailTemplate,
		);

	const {
		emailStep,
		emailStepValidities,
		setValidity,
		setEmailStep,
	} = useEmailStep();

	const [selectedTemplateId, setSelectedTemplateId] =
		useState<string | null>(
			currentExperienceId || null,
		);

	const history = useHistory();
	const emails = currentExperience?.emails;
	const templateId =
		emails && emails.length > 0
			? emails[0]?.emailTemplate?.id || null
			: null;

	useEffect(() => {
		// we want to set the default template to whatever is stored on the
		// experience if it is stored on the experience and the experience exists
		if (currentExperienceData) {
			setSelectedTemplateId(templateId);
		}
	}, [currentExperienceData]);

	useEffect(() => {
		if (!selectedTemplateId) {
			setValidity('select-template', false);
		} else {
			setValidity('select-template', true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTemplateId]);

	const nextButtonState = assignNextButton(
		emailStep,
		emailStepValidities,
	);

	const onPrev = () =>
		history.push(
			`/create-experience/${currentExperienceId}/add-audience`,
		);

	const warnDeleteData = () =>
		Promise.resolve().then(
			promptModal(
				'Changing your template will delete data for the experience that you have filled out. Click "confirm" to continue.',
				'Email Template Not Changed',
			),
		);

	const emailsExist = emails && emails.length >= 1;
	const isChangingTemplate =
		emailsExist &&
		selectedTemplateId !== emails[0]?.emailTemplate?.id;
	const isNewTemplate = !emailsExist;

	const onNext = () =>
		Promise.resolve()
			.then(() => {
				if (!currentExperienceId) {
					throw new Error(
						'Current Experience Does Not Exist',
					);
				}
				if (!selectedTemplateId) {
					throw new Error(
						'Must Select a template first	',
					);
				}
				return currentExperienceId;
			})
			.then(() => {
				if (isChangingTemplate) {
					// If the user is changing their template, then warn them about deleting data
					return warnDeleteData().then(() =>
						Promise.resolve({}),
					);
				}
				// else continue
				return Promise.resolve({});
			})
			.then(() => {
				// if a user is  not changing their template, then skip this step. We don't need to run a query
				if (!isChangingTemplate && !isNewTemplate) {
					return Promise.resolve({});
				}

				return createExperienceEmailMutation({
					variables: {
						hybridExperienceId:
							currentExperienceId as string, // will not be null because error thrown if null
						templateId:
							selectedTemplateId as string, // will not be null because error thrown if null
					},
				});
			})
			.then(() => refetchCurrentExperience())
			.then(() => setEmailStep('configure-email'))
			.catch(rejection => {
				// eslint-disable-next-line no-console
				toastToFailure(
					// 'Error processing your template selection, please reload the page and try again',
					rejection.message,
				);
			});

	const emailTemplateCards: TemplateCardProps[] =
		templatesCorrected.map(
			(template, templateIndex) => ({
				id: template.id,
				image: template.previewImage || '',
				title: template.name || '',
				description:
					template?.shortDescription || '',
				isSelected:
					selectedTemplateId === template.id,
			}),
		);

	const onCardClick = (id: TemplateCardProps['id']) => {
		setSelectedTemplateId(id.toString());
	};

	return (
		<>
			<div className="col-12 col-xxxl-10 mx-auto d-flex flex-column flex-grow-1 justify-content-around">
				<section className="py-4 text-center container">
					<div className="row py-5">
						<div className="col-lg-6 col-md-8 mx-auto">
							<h1
								className="fw-normal text-white"
								style={{ fontSize: '3rem' }}
							>
								Select An Email Template
							</h1>
							<p className="fw-light text-gray fs-4">
								Choose an email from our
								template library below. You
								can customize your email
								header, body copy, and more
								on the next page
							</p>
						</div>
					</div>
				</section>
				<div className="shrink-content scrollbar">
					<div className="container my-0 py-0 shadow bg-dark-alt rounded rounded-5 border border-purple">
						<TemplateRow
							onClick={onCardClick}
							cards={emailTemplateCards}
							title="Select an email template"
						/>
					</div>
				</div>
			</div>
			<PrevAndNextContainer
				experience={
					currentExperience as HybridExperience
				}
				nextOnClick={onNext}
				prevOnClick={onPrev}
				nextButtonLabel={'Customize/Configure'}
				prevButtonLabel={'Edit Audience'}
				nextButtonState={`next-${nextButtonState}`}
			/>
		</>
	);
};

export default SelectEmailTemplate;
