import { useState } from 'react';
import {
	CallBackObject,
	FieldMapping,
} from '../../../../../../components/field-mapping/field-mapping';
import { toastToFailure } from '../../../../../../components/trigger-toasts/toast-to-failure';
import { toastToSuccess } from '../../../../../../components/trigger-toasts/toast-to-success';
import {
	AudienceListHeader,
	GetEmailTemplatesDocument,
	GetExperienceDocument,
	MappedField,
	RenderExperienceEmailVersionDocument,
	useUpdateHybridExperienceEmailMappingMutation,
	VersionType,
} from '../../../../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../../../../current-experience.provider';
import { useEmailConfigurationStep } from '../providers/email-configuration-step.provider';

const ConfigureEmailMappingForm = () => {
	// Mutation to update the email mappings
	const [updateEmailMapping] =
		useUpdateHybridExperienceEmailMappingMutation({
			refetchQueries: [
				RenderExperienceEmailVersionDocument,
				GetExperienceDocument,
				GetEmailTemplatesDocument,
			],
		});

	// Required for color changing and passed into the field mapping component for version unique keys
	const { selectedVersion } = useEmailConfigurationStep();
	const highlightColor =
		selectedVersion === VersionType.Versiona
			? 'purple'
			: 'turquoise';

	const { data: currentExperienceQuery } =
		useCurrentExperience();

	const currentExperience =
		currentExperienceQuery?.GetHybridExperienceById;

	const experienceId = currentExperience?.id;

	const emails = currentExperience?.emails;
	// Our selectedEmail is the email on the version that we have selected
	const selectedEmail =
		emails &&
		emails.find(
			email => email.version === selectedVersion,
		);

	const emailTemplate = selectedEmail?.emailTemplate;
	const emailFields = emailTemplate?.mappableFields;

	// Previously mapped email fields for the version
	const previousMappedFields =
		selectedEmail?.mappedFields || [];

	// If there are list headers present in the HX, we need to add them as selector options
	const listHeaderOptions =
		(currentExperienceQuery?.GetHybridExperienceById
			?.audienceList?.audienceListHeaders &&
			currentExperienceQuery?.GetHybridExperienceById?.audienceList?.audienceListHeaders.map(
				header => ({
					sampleValue:
						(header && header.sampleValue) ||
						undefined,
					value:
						(header && header.headerValue) ||
						'',
					label:
						(header && header.headerValue) ||
						'',
				}),
			)) ||
		null;

	const [mappedFields, setMappedFields] = useState<
		MappedField[] | []
	>();

	const onMappingChange = (rows: CallBackObject[]) => {
		const newMapping: MappedField[] = [];
		rows.forEach(row => {
			const matchingEmailField = emailFields?.find(
				field =>
					field.variableName === row.uniqueKey,
			);
			if (matchingEmailField) {
				const audienceListHeader: AudienceListHeader =
					{
						headerValue:
							row.selectedOption?.value || '',
						sampleValue:
							row.selectedOption?.sampleValue,
					};

				const isMapped = !!(
					row.selectedOption &&
					row.selectedOption.value !== ''
				);
				const { staticValue } = row;
				const type = matchingEmailField?.type;
				const variableName =
					matchingEmailField?.variableName;
				const newObj = {
					isMapped,
					staticValue,
					type,
					variableName,
					audienceListHeader,
				};
				if (
					audienceListHeader.headerValue.length >
					1
				) {
					newObj.audienceListHeader =
						audienceListHeader;
				}
				newMapping.push(newObj);
			}
		});
		if (newMapping.length > 1) {
			setMappedFields(newMapping);
		}
	};

	return (
		<>
			<form
				className="text-white p-3"
				onSubmit={e => {
					e.preventDefault();

					if (mappedFields) {
						Promise.resolve()
							.then(() => {
								mappedFields.forEach(
									field => {
										const matchingEmailField =
											emailFields?.find(
												emailField =>
													emailField.variableName ===
													field.variableName,
											);
										const isRequired =
											matchingEmailField?.isRequired;
										// Must have either static value or audience list header if field is required
										if (
											isRequired &&
											!field.staticValue &&
											!field
												.audienceListHeader
												?.headerValue
										) {
											throw new Error(
												'Please fill in all required fields',
											);
										}
									},
								);
							})
							.then(() =>
								updateEmailMapping({
									variables: {
										hybridExperienceId:
											experienceId ||
											'',
										mappedFields:
											mappedFields ||
											[],
										version:
											selectedVersion,
									},
								}).catch(err => {
									console.error(err);
									throw new Error(
										'Mapping failed. Please try again.',
									);
								}),
							)
							.then(() => {
								toastToSuccess(
									'Successfully Mapped',
								);
							})
							.catch(err => {
								toastToFailure(err.message);
							});
					} else {
						toastToFailure(
							'Please fill in all required fields',
						);
					}
				}}
			>
				<div className="container w-100">
					<h3>Field Mapping</h3>
					<p className="mb-2">
						Dynamically map salutation
						information within the email using
						the audience list you uploaded.
					</p>
					{listHeaderOptions && emailFields && (
						<FieldMapping
							fieldLabel="Field"
							staticLabel="Static Value"
							mapLabel="Dynamic Mapping"
							highlightColor={highlightColor}
							version={selectedVersion}
							selectOptions={
								listHeaderOptions
							}
							fieldMappingRows={emailFields?.map(
								field => ({
									uniqueKey:
										field.variableName,
									isRequired:
										field.isRequired,
									selectedOption:
										previousMappedFields &&
										previousMappedFields &&
										previousMappedFields
											.filter(
												mappedField =>
													mappedField.variableName ===
													field.variableName,
											)
											.map(
												mappedField => ({
													sampleValue:
														listHeaderOptions.find(
															listHeader =>
																listHeader.value ===
																mappedField
																	.audienceListHeader
																	?.headerValue,
														)
															?.sampleValue ||
														'',
													value:
														mappedField
															.audienceListHeader
															?.headerValue ||
														'',
													label:
														mappedField
															.audienceListHeader
															?.headerValue ||
														'No Selection',
												}),
											)[0],
									staticValue:
										(previousMappedFields &&
											previousMappedFields.find(
												mappedField =>
													mappedField.variableName ===
													field.variableName,
											)
												?.staticValue) ||
										'',
									rowLabel:
										field.displayName,
								}),
							)}
							callback={callbackParam =>
								onMappingChange(
									callbackParam,
								)
							}
						/>
					)}
					<button
						// onClick={onUpdateClick}
						data-cy="email-mapping-update"
						className={`btn btn-md my-3 text-white ${
							selectedVersion !== 'VERSIONA'
								? 'btn-turquoise'
								: 'btn-purple'
						}`}
						id={
							'update-email-customization-submit'
						}
					>
						Update
					</button>
				</div>
			</form>
		</>
	);
};

export default ConfigureEmailMappingForm;
