import { useAuth } from '../../../providers/auth/auth-provider';

import ExperienceDraftsPanel from './experience-drafts-panel';

import ProfilePanel from '../../general-ui/profile-panel';

const DashboardLayout = () => {
	const { user } = useAuth();

	return (
		<>
			<div
				className="h-100 d-flex w-100 flex-column  py-4"
				style={{ overflowX: 'hidden' }}
			>
				<div className="w-100 h-100 mx-auto px-2 d-flex flex-grow-1 flex-column">
					<ProfilePanel user={user} />
					<ExperienceDraftsPanel
						entitlementId={
							user?.entitlement.id || ''
						}
					/>
				</div>
			</div>
		</>
	);
};

export default DashboardLayout;
