import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useState,
} from 'react';
import { VersionType } from '../../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../../current-experience.provider';

export type ConfigurationStep =
	| 'select'
	| 'customization'
	| 'loading';

export type BrowserStep =
	| 'images'
	| 'customize'
	| 'mapping'
	| 'loading';

export type ConfigurationVersionState =
	| VersionType.Versiona
	| VersionType.Versionb;

interface ConfigurationStepProviderProps {
	selectedVersion: ConfigurationVersionState;
	setSelectedVersion: Dispatch<
		SetStateAction<ConfigurationVersionState>
	>;
	configurationStep: ConfigurationStep;
	setConfigurationStep: Dispatch<
		SetStateAction<ConfigurationStep>
	>;
	browserStep: BrowserStep;
	setBrowserStep: Dispatch<SetStateAction<BrowserStep>>;
	renderHtml: string;
	setVersionOnHtml: SetStateAction<Dispatch<VersionType>>;
	showSettings: boolean;
	setShowSettings: (arg0: boolean) => void;
	pageUpdating: boolean;
	setPageUpdating: Dispatch<SetStateAction<boolean>>;
}

const ConfigurationStepContext =
	createContext<ConfigurationStepProviderProps>({
		configurationStep: 'select',
		setConfigurationStep: () => {},
		selectedVersion: VersionType.Versiona,
		setSelectedVersion: () => {},
		renderHtml: '',
		browserStep: 'images',
		setBrowserStep: () => {},
		setVersionOnHtml: () => {},
		showSettings: false,
		setShowSettings: () => {},
		pageUpdating: false,
		setPageUpdating: () => {},
	});

export const ConfigurationStepProvider = ({
	children,
}: {
	children: JSX.Element | JSX.Element[];
}) => {
	const { data: currentExperienceData } =
		useCurrentExperience();

	const currentExperience =
		currentExperienceData?.GetHybridExperienceById ||
		null;

	const formTemplateSelected =
		currentExperience &&
		currentExperience?.pages.length > 0;

	const [configurationStep, setConfigurationStep] =
		useState<ConfigurationStep>(
			formTemplateSelected
				? 'customization'
				: 'select',
		);

	const [browserStep, setBrowserStep] =
		useState<BrowserStep>('images');

	const [showSettings, setShowSettings] =
		useState<boolean>(false);

	const [pageUpdating, setPageUpdating] =
		useState<boolean>(false);

	const [selectedVersion, setSelectedVersion] =
		useState<ConfigurationVersionState>(
			VersionType.Versiona,
		);

	// ! Get Current Experience Here

	return (
		<ConfigurationStepContext.Provider
			value={{
				configurationStep,
				setConfigurationStep,
				selectedVersion,
				setSelectedVersion,
				browserStep,
				setBrowserStep,
				renderHtml: '',
				setVersionOnHtml: () => {},
				setShowSettings,
				showSettings,
				pageUpdating,
				setPageUpdating,
			}}
		>
			{children}
		</ConfigurationStepContext.Provider>
	);
};

export const useConfigurationStep = () => {
	const context = useContext(ConfigurationStepContext);
	if (context === undefined) {
		throw new Error(
			'You must be inside of a FormConfigurationProvider to invoke useEmailConfigurationStep',
		);
	}

	return context;
};
