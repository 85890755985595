import LoadingScreen from '../../loading-screen/loading-screen';
import { useConfigurationStep } from './configuration-form-step/configuration-form-step-provider';
import FormCustomization from './form-customization';
import SelectFormTemplate from './select-form-template';

const ConfigureFormRouter = () => {
	const { configurationStep } = useConfigurationStep();

	return (
		<>
			{configurationStep === 'select' && (
				<SelectFormTemplate />
			)}

			{configurationStep === 'customization' && (
				<FormCustomization />
			)}
			{configurationStep === 'loading' && (
				<LoadingScreen />
			)}
		</>
	);
};

export default ConfigureFormRouter;
