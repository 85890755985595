import { useHistory } from 'react-router';
import { useConfigurationStep } from '../review-step/review-step-provider';
import PageRender from './page-render';

const FormReview = () => {
	const history = useHistory();
	const { currentExperience, displayOnly } =
		useConfigurationStep();
	return (
		<>
			<div className="d-flex p-3">
				<h3
					className="text-white"
					style={{
						fontWeight: 900,
					}}
				>
					Form Overview
				</h3>
				{!displayOnly && (
					<button
						onClick={() =>
							history.push(
								`/create-experience/${
									currentExperience?.id ||
									''
								}/configure-form`,
							)
						}
						className="ms-auto btn btn-light"
					>
						Edit Form
					</button>
				)}
			</div>
			<PageRender />
		</>
	);
};

export default FormReview;
