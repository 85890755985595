/* eslint-disable react/jsx-no-target-blank */
import { useHistory } from 'react-router';
import Typewriter from 'typewriter-effect';

import WebDev from './splash-assets/webdevlady.svg';
import LetterLady from './splash-assets/letterlady.svg';
import PlaneMan from './splash-assets/planeman.svg';
import NimblFooter from '../footer/nimbl-footer';
import ExternalHeader from '../header/external-header';

import NimblLogoDark from '../../assets/Nimbl-logos/Nimbl_Primary-Horizontal-Black.png';

const SplashPage = () => {
	const history = useHistory();
	return (
		<>
			<body className="bg-dark bg-noise purpose-ui">
				<div className="main-content">
					<section className="bg-gradient-primary mt-0">
						<ExternalHeader />
						<div className="slice slice-md">
							<div className="container py-6 py-lg-0 d-flex align-items-center position-relative zindex-100">
								<div className="col">
									<div className="row">
										<div className="col-xl-5 col-lg-6 align-self-center">
											<div className="text-center text-lg-left pb-5">
												<h2 className="h1 text-white mb-4">
													Supercharge
													your
													<Typewriter
														options={{
															strings:
																[
																	'marketing',
																	'gifting',
																	'business',
																],
															autoStart:
																true,
															loop: true,
														}}
													/>
												</h2>
												<p className="lead lh-180 text-white">
													We have
													optimized
													the
													Hybrid
													Experience
													creation
													process
													to save
													your
													team
													time
													while
													helping
													you
													create
													more
													engaging
													experiences.
												</p>
												<div className="mt-5">
													<div
														onClick={() =>
															history.push(
																'/contact-us/',
															)
														}
														className="btn btn-black hover-translate-y-n3 mr-lg-4 mb-4"
													>
														<h5 className="mb-0 text-white">
															Get
															Started
														</h5>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-6 ml-lg-auto align-self-end">
											<div
												className="position-relative"
												style={{
													zIndex: 10,
												}}
											>
												<img
													alt="Marketing Strategy"
													src={
														WebDev
													}
													className="img-fluid img-center"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="shape-container h-auto"
								data-shape-position="bottom"
								style={{
									transform:
										'translateY(8px)',
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 1600 220"
									preserveAspectRatio="none"
									className="ie-shape-wave-3"
								>
									<path d="M918.34,99.41C388.23,343.6,47.11,117.12,0,87.54V220H1600V87.54C1378.72-76.71,1077.32,27.41,918.34,99.41Z"></path>
								</svg>
							</div>
						</div>
					</section>

					<section className="slice slice-lg">
						<div className="container">
							<div className="text-center">
								<h1>
									It{`'`}s time to rethink
									your direct mail
									strategy
								</h1>
								<div className="fluid-paragraph mt-4">
									<p className="lead text-muted lh-180">
										Nimbl will allow
										your marketing team
										to quickly create
										fully branded hybrid
										experiences
										leveraging the full
										power of your brand
										to help you generate
										better pipeline more
										quickly.
									</p>
									<div
										onClick={() =>
											history.push(
												'/contact-us/',
											)
										}
										className="btn btn-dark btn-icon rounded-pill hover-scale-110 mt-5 w-auto"
									>
										<span className="btn-inner--text">
											Let{`'`}s get
											started
										</span>
										<span className="btn-inner--icon">
											<i className="fas fa-angle-right text-white"></i>
										</span>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="slice slice-lg">
						<div className="container">
							<div className="row row-grid align-items-center justify-content-around">
								<div className="col-lg-5 order-lg-2">
									<div className="pr-md-4">
										<h5 className="h3">
											Change the way
											you do direct
											mail. Forever.
										</h5>
										<p className="text-muted lead my-4">
											With Nimbl you
											get access to
											proven landing
											pages, email
											templates, and
											gifts that will
											help you
											generate more
											leads and sales.
										</p>
										<div
											onClick={() =>
												history.push(
													'/contact-us/',
												)
											}
											className="btn btn-primary w-auto rounded-pill btn-icon mt-4"
										>
											<span className="btn-inner--icon">
												<i className="fa-solid fa-paper-plane"></i>
											</span>
											<span className="btn-inner--text">
												Sign Up
											</span>
										</div>
									</div>
								</div>
								<div className="col-lg-6 order-lg-1">
									<div
										className="position-relative"
										style={{
											zIndex: 10,
										}}
									>
										<img
											alt="Mail"
											src={LetterLady}
											className="img-center img-fluid"
										/>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="slice slice-lg bg-gradient-primary">
						<div className="container">
							<div className="mb-5 text-center">
								<h3 className="text-white mt-4">
									Powerful Features
								</h3>
								<div className="fluid-paragraph mt-3">
									<p className="lead lh-180 text-white">
										Create compelling
										experiences in no
										time using intuitive
										app.
									</p>
								</div>
							</div>
							<div className="row row-grid align-items-center">
								<div className="col-lg-4">
									<div className="d-flex align-items-start mb-5">
										<div className="pr-4 py-3">
											<div className="icon icon-shape bg-white text-primary box-shadow-3 rounded-circle">
												<i className="text-primary fs-1 p-4 fas fa-expand"></i>
											</div>
										</div>
										<div className="icon-text">
											<h5 className="h5 text-white">
												Responsive
												Design
											</h5>
											<p className="mb-0 text-white">
												Our pages
												and emails
												are
												guaranteed
												to look good
												on any
												device
											</p>
										</div>
									</div>
									<div className="d-flex align-items-start mb-5">
										<div className="pr-4 py-3">
											<div className="icon icon-shape bg-white text-primary box-shadow-3 rounded-circle">
												<i className="text-primary fs-1 p-4 fas fa-box-open"></i>
											</div>
										</div>
										<div className="icon-text">
											<h5 className="text-white">
												Packed with
												Capability
											</h5>
											<p className="mb-0 text-white">
												From top of
												funnel lead
												generation
												to customer
												retention
											</p>
										</div>
									</div>
									<div className="d-flex align-items-start">
										<div className="pr-4 py-3">
											<div className="icon icon-shape bg-white text-primary box-shadow-3 rounded-circle">
												<i className="text-primary fs-1 p-4 fas fa-smile"></i>
											</div>
										</div>
										<div className="icon-text">
											<h5 className="text-white">
												Friendly
												Support
											</h5>
											<p className="mb-0 text-white">
												Get access
												to our world
												class team
												of direct
												mail experts
												who can
												support your
												marketing
											</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<div
										className="position-relative"
										style={{
											zIndex: 10,
										}}
									>
										<img
											alt="Delivery"
											src={PlaneMan}
											className="img-center img-fluid"
										/>
									</div>
								</div>
								<div className="col-lg-4">
									<div className="d-flex align-items-start mb-5">
										<div className="pr-4 py-3">
											<div className="icon icon-shape bg-white text-primary box-shadow-3 rounded-circle">
												<i className="text-primary fs-1 p-4 fa-solid fa-rectangles-mixed"></i>
											</div>
										</div>
										<div className="icon-text">
											<h5 className="text-white">
												Fully
												Branded
											</h5>
											<p className="mb-0 text-white">
												Nimbl is
												fully white
												labeled
												allowing
												your brand
												to shine
												through
											</p>
										</div>
									</div>
									<div className="d-flex align-items-start mb-5">
										<div className="pr-4 py-3">
											<div className="icon icon-shape bg-white text-primary box-shadow-3 rounded-circle">
												<i className="text-primary fs-1 p-4 fa fa-rocket"></i>
											</div>
										</div>
										<div className="icon-text">
											<h5 className="text-white">
												Built to
												Scale
											</h5>
											<p className="mb-0 text-white">
												Nimbl is
												built to
												scale with
												your
												marketing
											</p>
										</div>
									</div>
									<div className="d-flex align-items-start">
										<div className="pr-4 py-3">
											<div className="icon icon-shape bg-white text-primary box-shadow-3 rounded-circle">
												<i className="text-primary fs-1 p-4 fas fa-palette"></i>
											</div>
										</div>
										<div className="icon-text">
											<h5 className="text-white">
												Customizable
											</h5>
											<p className="mb-0 text-white">
												Our team
												will work
												with you to
												ensure Nimbl
												meets all of
												your direct
												mail needs
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				<NimblFooter
					footerLogo={NimblLogoDark}
					textColor="black"
					footerType="white"
					backgroundColor="white"
				/>
			</body>
		</>
	);
};

export default SplashPage;
