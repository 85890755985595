import messagebox from './messagebox';

const promptModal =
	(message: string, errorMessage: string | 'Denied') =>
	() =>
		messagebox
			.openTwoButtonAlert({
				buttons: [
					{
						name: 'Cancel',
						handler: () => 'no',
						className:
							'cancel-button btn btn-md btn-outline btn-outline-danger text-white px-5  fs-6 text-decoration-none col me-2 ',
					},
					{
						name: 'Confirm',
						handler: () => 'yes',
						className:
							'confirm-button btn btn-md btn-outline btn-outline-green text-white px-5  fs-6 text-decoration-none col ms-2 ',
					},
				],
				title: message,
				content: <></>,
				container: '#left-nav-main-container',
			})
			.then(answer => {
				if (answer === 'no') {
					throw new Error(errorMessage);
				}
			});

export default promptModal;
