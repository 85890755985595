import { useState } from 'react';
import {
	TemplateCard,
	TemplateCardProps,
} from './template-card';

interface TemplateRowProps {
	title?: string;
	cards?: TemplateCardProps[];
	onClick: (id: TemplateCardProps['id']) => void;
}

export const TemplateRow = ({
	title,
	cards,
	onClick,
}: TemplateRowProps) => {
	const cardProps =
		cards &&
		cards.map(card => ({
			...card,
			onClick,
		}));

	return (
		<div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-stretch py-5 mx-auto w-100 justify-content-center h-100">
			{cardProps &&
				cardProps.map((card, index) => (
					<TemplateCard key={index} {...card} />
				))}
		</div>
	);
};
