import { range } from 'lodash';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import LaunchDateCard from './LaunchDateCard';
import ReminderCard from './ReminderCard';
import TestEvaluationCard from './TestEvaluationCard';
import { CalendarPanel } from './ScheduleCard';
import { useCurrentExperience } from '../../current-experience.provider';
import {
	UpdateHybridExperienceSettingsMutationVariables,
	useUpdateHybridExperienceSettingsMutation,
} from '../../../../gql/queries/generated/graphql';
import { toastToNotify } from '../../../../components/trigger-toasts/toast-to-notify';
import { toastToFailure } from '../../../../components/trigger-toasts/toast-to-failure';
import {
	applyTimeToDay,
	getOffsetDate,
} from '../../../../util/date-calculations/getOffsetDate';
import { toastToSuccess } from '../../../../components/trigger-toasts/toast-to-success';
import { useConfigurationStep } from '../review-step/review-step-provider';

const SchedulePanel = () => {
	// Hardcoded for now
	const testEvaluationLengthDays = 3;
	const reminderOffset = 3;

	const {
		data: currentExperienceData,
		refetch: refetchExperience,
	} = useCurrentExperience();

	const { showVersionB } = useConfigurationStep();

	const [updateExperience] =
		useUpdateHybridExperienceSettingsMutation();

	const currentExperience =
		currentExperienceData?.GetHybridExperienceById;

	const availableTimes = range(24 * 2).map(timeDiff =>
		dayjs()
			.hour(0)
			.minute(0)
			.second(0)
			.minute(timeDiff * 30),
	);

	const [experienceLaunchDate, setExperienceLaunchDate] =
		useState<Dayjs>(
			dayjs(
				currentExperience?.launchDate ||
					new Date().setHours(0, 0, 0, 0), // Midnight
			),
		);

	const [reminderToggleActive, setReminderToggleActive] =
		useState<boolean>(true);

	const [winningReminderDate, setWinningReminderDate] =
		useState<Dayjs>(
			getOffsetDate(
				dayjs(experienceLaunchDate),
				testEvaluationLengthDays + reminderOffset,
			).date,
		);

	const [testReminderDate, setTestReminderDate] =
		useState<Dayjs>(
			getOffsetDate(
				dayjs(experienceLaunchDate),
				reminderOffset,
			).date,
		);
	const [hasReminder, setHasReminder] = useState<boolean>(
		!!currentExperience?.hasReminder,
	);

	useEffect(() => {
		setWinningReminderDate(
			getOffsetDate(
				dayjs(experienceLaunchDate),
				testEvaluationLengthDays + reminderOffset,
			).date,
		);

		setTestReminderDate(
			getOffsetDate(
				dayjs(experienceLaunchDate),
				reminderOffset,
			).date,
		);
	}, [experienceLaunchDate]);

	const [updatingSchedule, setUpdatingSchedule] =
		useState(false);

	const onUpdate = async () => {
		if (!updatingSchedule) {
			await Promise.resolve()
				.then(() => setUpdatingSchedule(true))
				.then(() =>
					toastToNotify(`Updating schedule...`),
				)
				.then(async () =>
					updateExperience({
						variables: {
							hybridExperienceId:
								currentExperience?.id || '',
							launchDate: experienceLaunchDate
								.toDate()
								.toISOString(),
							emailReminderOffset:
								reminderOffset,
							emailReminderDate:
								winningReminderDate
									.toDate()
									.toISOString(),
							testEvaluationDate:
								testReminderDate
									.toDate()
									.toISOString(),
							testEmailReminderDate:
								testReminderDate
									.toDate()
									.toISOString(),
						},
					}),
				)
				.then(async () => refetchExperience())
				.then(() => {
					toastToSuccess('Updated successfully');
				})
				.catch(error => {
					toastToFailure(
						'Failed to update experience launch settings',
					);
					console.error(error);
				})
				.then(() => setUpdatingSchedule(false));
		}
	};

	return (
		<div className="rounded h-100 w-100 d-flex flex-column bg-black text-white p-2">
			<div className="d-flex justify-content-between pb-3">
				<div className="mb-0 mt-auto">
					<h4 className="fw-bold text-white">
						Schedule
					</h4>
				</div>
				<button
					className="btn btn-sm btn-turquoise text-white"
					onClick={() => {
						onUpdate();
					}}
				>
					Update
				</button>
			</div>
			<div className="d-flex">
				<LaunchDateCard
					date={experienceLaunchDate}
					availableTimes={availableTimes}
					selectedTime={experienceLaunchDate}
					setSelectedTime={selectedTime => {
						const selectedDayTime =
							applyTimeToDay(
								experienceLaunchDate,
								selectedTime,
							);
						setExperienceLaunchDate(
							selectedDayTime,
						);
					}}
				/>
				<ReminderCard
					reminderToggleActive={
						reminderToggleActive
					}
					toggleReminder={() => {
						const toggleValue = !hasReminder;
						const updateVars: UpdateHybridExperienceSettingsMutationVariables =
							{
								hybridExperienceId:
									currentExperience?.id ||
									'',
								hasReminder: toggleValue,
							};
						if (toggleValue) {
							updateVars.emailReminderOffset =
								reminderOffset;
							updateVars.emailReminderDate =
								winningReminderDate
									.toDate()
									.toISOString();
						}
						if (showVersionB) {
							updateVars.testEvaluationDate =
								testReminderDate
									.toDate()
									.toISOString();
							updateVars.testEmailReminderDate =
								testReminderDate
									.toDate()
									.toISOString();
						}
						Promise.resolve()
							.then(() =>
								toastToNotify(
									'Updating reminder...',
								),
							)
							.then(() =>
								setHasReminder(toggleValue),
							)
							.then(() =>
								setReminderToggleActive(
									false,
								),
							)
							.then(() =>
								updateExperience({
									variables: updateVars,
								}),
							)
							.then(() => refetchExperience())
							.then(() =>
								toastToSuccess(
									'Reminder updated',
								),
							)
							.catch(error => {
								console.error(error);
								toastToFailure(
									'Failed to update, please try again',
								);
							})
							.finally(() =>
								setReminderToggleActive(
									true,
								),
							);
					}}
					launchDate={experienceLaunchDate}
					winningReminderDate={
						winningReminderDate
					}
					testReminderDate={testReminderDate}
					testEvaluationLengthDays={3}
					hasReminder={hasReminder}
				/>
				<TestEvaluationCard />
			</div>
			<div className="bg-secondary d-flex flex-column flex-grow-1">
				<CalendarPanel
					testReminderDate={
						(hasReminder && testReminderDate) ||
						undefined
					}
					winningReminderDate={
						(hasReminder &&
							winningReminderDate) ||
						undefined
					}
					dateSelected={experienceLaunchDate}
					setSelectedDate={selectedDate => {
						const selectedDayTime =
							applyTimeToDay(
								selectedDate,
								experienceLaunchDate,
							);
						setExperienceLaunchDate(
							selectedDayTime,
						);
					}}
				/>
			</div>
		</div>
	);
};

export default SchedulePanel;
