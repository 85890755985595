import { toast } from 'react-hot-toast';

/** Send a toast to notify the user */
export const toastToNotify = (message: string) =>
	toast(message, {
		duration: 4000,
		position: 'top-center',
		className:
			'bg-gray-dark text-white border border-2 border-gray',
		// Emoji
		icon: '🔔',
		// Aria
		ariaProps: {
			role: 'status',
			'aria-live': 'polite',
		},
	});
