import { useContext } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import { MainContainerContext } from '../../../components/layout/left-nav-layout/left-nav-layout';
import ListPreviewTable from './list-preview-table';

interface IAddAudienceModalProps {
	open: boolean;
	fileName: string;
	setOpen: (arg0: boolean) => void;
	setUniqueIdField: (arg0: string) => void;
	setEmailAddressField: (arg0: string) => void;
	emailAddressField: string;
	csvHeaders: string[];
	onContinue: () => void;
	uploading: boolean;
	tableRows: {
		headerValue: string;
		sampleValue: string;
	}[];
}

const AddAudienceModal = ({
	open,
	setOpen,
	setUniqueIdField,
	setEmailAddressField,
	emailAddressField,
	csvHeaders,
	onContinue,
	uploading,
	tableRows,
	fileName,
}: IAddAudienceModalProps) => {
	const parentContainer = useContext(
		MainContainerContext,
	);

	return (
		<Modal
			isOpen={open}
			onRequestClose={() => setOpen(false)}
			className="customModal"
			overlayClassName="customOverlay"
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			parentSelector={() => parentContainer!.current}
			shouldCloseOnOverlayClick={false}
		>
			<div className="modal-content border-0 bg-dark text-white">
				<div className="modal-header border-0">
					<h4 className="modal-title fw-light">
						<i className="fad fa-lg fa-file-spreadsheet text-purple me-2"></i>
						{fileName}
					</h4>
					<button
						type="button"
						className="btn-close btn-close-white"
						aria-label="Close"
						onClick={() => setOpen(false)}
					></button>
				</div>
				<div className="modal-body">
					<h5 className="mb-2">
						Recipient email address field:
					</h5>
					<Select
						id={'email-field-select'}
						className="flex-grow-1 mb-2"
						defaultValue={{
							value: '',
							label: 'No Selection',
						}}
						value={{
							value: emailAddressField,
							label:
								emailAddressField ||
								'No Selection',
						}}
						options={
							csvHeaders &&
							csvHeaders.map(
								(header, hIndex) => ({
									label: header,
									value: header,
								}),
							)
						}
						styles={{
							container: prev => ({
								...prev,
								color: 'black',
							}),
						}}
						onChange={input => {
							setEmailAddressField(
								input?.value || '',
							);
						}}
					/>
					<h5>Review Your Column Headers</h5>
					<ListPreviewTable
						TableData={tableRows}
					/>
				</div>
				<div className="modal-footer justify-content-between border-0">
					<button
						type="button"
						className="btn btn-secondary"
						onClick={() => setOpen(false)}
					>
						Close
					</button>
					<button
						id="add-audience-continue-button"
						type="button"
						className="btn gradient-button border-0 text-white fw-bold px-3"
						onClick={onContinue}
					>
						{uploading
							? 'Loading...'
							: 'Continue'}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default AddAudienceModal;
