import ProductCarousel from '../review-carousel';
import { useConfigurationStep } from '../review-step/review-step-provider';

const RenderReview = () => {
	const { currentExperience: experience } =
		useConfigurationStep();
	const podFields = experience?.printOnDemandFields;
	return (
		<div className="text-white text-center pt-5">
			{podFields && experience && (
				<ProductCarousel
					experienceId={experience.id || ''}
				/>
			)}
		</div>
	);
};

export default RenderReview;
