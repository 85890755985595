import { FC } from 'react';
import { Toaster } from 'react-hot-toast';
import './App.scss';
// eslint-disable-next-line
import { dom } from '@fortawesome/fontawesome-svg-core';

import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './gql/apollo-client';
import { AuthProvider } from './providers/auth/auth-provider';

import AppRouter from './routes/app-router/app-router';
import './components/navigation/main-navigation/main-navigation.scss';
import './routes/loading-screen/loading-screen.scss';
import './components/animations/loadingTable.scss';
import './components/animations/lock/lock-style.scss';
import './routes/create-experience-page/1-add-audience/add-audience.scss';
import './routes/create-experience-page/navbar/create-experience-navbar.scss';
import './providers/alert-provider/alert.scss';
import './routes/create-experience-page/2-add-email/components/navbar/email-customization-navbar.scss';
import './components/layout/left-nav-layout/left-nav-layout-style.scss';
import './routes/create-experience-page/2-add-email/components/configure-email/email-customization.scss';
import 'react-input-range/lib/css/index.css';
import './routes/create-experience-page/4-select-gifts/select-gifts.scss';
import './routes/create-experience-page/6-review/styling/time-picker.scss';
import './components/purpose-files/css/purpose.scss';

// This is for the postCSS conversion in the cypress unit testing, may wind up getting rid of this.
dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

// eslint-disable-next-line arrow-body-style
const App: FC = () => {
	return (
		<>
			<Toaster />
			<ApolloProvider client={apolloClient}>
				<AuthProvider>
					<div id="app">
						<AppRouter />
					</div>
				</AuthProvider>
			</ApolloProvider>
		</>
	);
};

export default App;
