import { useEffect, useState } from 'react';
import { HermesColor } from '../../../colors.enum';
import {
	Gift,
	useGetGiftsByEntitlementIdQuery,
	VersionType,
} from '../../../gql/queries/generated/graphql';
import { useConfigurationStep } from './select-gift-step/select-gift-step-provider';
import FilterColumn from './filter-column';
import ProductSelector from './product-selector';
import { useCurrentExperience } from '../current-experience.provider';
import { filterCorrectGifts } from './filter-correct-gifts';
import { GiftFilters } from './gift-types';

const GiftSelector = () => {
	const { selectedVersion } = useConfigurationStep();

	const assignHighlightColor = (): HermesColor => {
		if (selectedVersion === VersionType.Versionb) {
			return 'turquoise';
		}
		return 'purple';
	};

	const { data: giftData } =
		useGetGiftsByEntitlementIdQuery();

	const { data: experienceData } = useCurrentExperience();

	const experience =
		experienceData?.GetHybridExperienceById;
	const pages = experience?.pages || [];
	const pageType = pages[0]?.pageType;

	const gifts = (giftData?.GetGiftsByEntitlementId ||
		[]) as Gift[];

	const [allAvailableGifts, setAllAvailableGifts] =
		useState<Gift[]>([]);

	const [filters, setFilters] = useState<GiftFilters>({
		// default filters pass everything through
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		priceFilter: _gift => true,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		searchFilter: _gift => true,
	});

	const [
		filteredAvailableGifts,
		setFilteredAvailableGifts,
	] = useState<Gift[]>([]);

	useEffect(() => {
		if (gifts.length > 0) {
			setFilteredAvailableGifts(
				allAvailableGifts.filter(gift =>
					[
						filters.priceFilter,
						filters.searchFilter,
					].every(filter => {
						if (!filter) {
							return true;
						}
						return filter(gift);
					}),
				),
			);
		}
	}, [filters, allAvailableGifts]);

	// On available gift change, set available gift
	useEffect(() => {
		const correctGifts = filterCorrectGifts(
			gifts,
			pageType,
		);
		setAllAvailableGifts(correctGifts);
		// all filter params go here
	}, [giftData, pageType]);

	// Pull in any selected gifts from the configuration step

	return (
		<div className="shrink-content text-white">
			<div
				className={`border 
        border-${assignHighlightColor()} 
        border-5 w-100 flex-grow-1 h-100`}
				style={{
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px',
					overflowX: 'hidden',
				}}
			>
				<div className="row h-100 w-100 mx-auto g-0">
					<div
						className="col-4 col-xxxl-3 scrollbar"
						style={{
							overflow: 'auto',
							maxHeight: '100%',
						}}
					>
						<FilterColumn
							setFilters={setFilters}
							filters={filters}
						/>
					</div>
					<div
						className="col scrollbar"
						style={{
							maxHeight: '100%',
							overflow: 'auto',
							overflowX: 'hidden',
						}}
					>
						<ProductSelector
							availableGifts={
								filteredAvailableGifts
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GiftSelector;
