import { HybridExperience } from '../../gql/queries/generated/graphql';
import { TAddEmailRouteLocation } from '../../routes/create-experience-page/2-add-email/router/location.type';

export const maxEmailStep = (
	experience: HybridExperience | null,
): TAddEmailRouteLocation => {
	if (!experience) {
		return 'loading';
	}
	if (
		!experience?.emails ||
		experience.emails.length < 1
	) {
		return 'select-template';
	}

	return 'configure-email';
};
