import dayjs, { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction, useState } from 'react';
import Calendar from 'react-calendar';
import { useConfigurationStep } from '../review-step/review-step-provider';

export const CalendarPanel = ({
	setSelectedDate,
	dateSelected,
	testReminderDate,
	winningReminderDate,
}: {
	setSelectedDate: (date: Dayjs) => void;
	dateSelected: Dayjs | undefined;
	testReminderDate: Dayjs | undefined;
	winningReminderDate: Dayjs | undefined;
}) => {
	const { displayOnly, showVersionB } =
		useConfigurationStep();
	const [calValue, setCalValue] = useState<Date>(
		(dateSelected && dateSelected.toDate()) ||
			new Date(),
	);

	return (
		<>
			<Calendar
				className="w-100 h-100 rounded rounded-3 text-dark"
				onChange={(selectedDate: Date) => {
					const newDate = dayjs(selectedDate);
					if (!displayOnly) {
						// don't do anything if display only
						setCalValue(selectedDate);
						setSelectedDate(newDate);
					}
				}}
				tileClassName={({
					activeStartDate,
					date,
					view,
				}) => {
					if (
						date.toDateString() ===
							testReminderDate
								?.toDate()
								.toDateString() &&
						showVersionB
					) {
						return 'react-calendar-highlight-test-reminder';
					}
					if (
						date.toDateString() ===
						winningReminderDate
							?.toDate()
							.toDateString()
					) {
						return 'react-calendar-highlight-winning-launch';
					}

					return null;
				}}
				value={calValue}
				calendarType={'US'}
				minDetail={'year'}
				minDate={new Date()}
				/* set max date 2 months in advance */
				maxDate={
					new Date(
						new Date().setMonth(
							new Date().getMonth() + 2,
						),
					)
				}
				/* disable saturday and sunday */
				tileDisabled={({
					date: selectedDate,
					view,
				}) =>
					view === 'month' &&
					(selectedDate.getDay() === 0 ||
						selectedDate.getDay() === 6)
				}
			/>
		</>
	);
};
