import { ExperienceStep } from '../../../gql/queries/generated/graphql';
import { CreateExperienceSubroute1 } from '../create-experience-types';

// Todo: Test this function
export const assignIcon = ({
	maxStep,
	index,
	isValid,
	selectedStep,
	icons,
}: {
	maxStep: number;
	index: number;
	isValid: boolean;
	selectedStep: number;
	icons: {
		valid: JSX.Element;
		unreachable: JSX.Element;
		current: JSX.Element;
		invalid: JSX.Element;
	};
}): JSX.Element => {
	if (selectedStep > maxStep + 1) {
		throw new Error(
			'In the navigator component, you are trying to select a step that is unreachable.',
		);
	}

	if (index > maxStep) {
		return icons.unreachable;
	}
	if (index === selectedStep) {
		return icons.current;
	}

	if (isValid) {
		return icons.valid;
	}
	return icons.invalid;
};

// todo: write unit tests
export const assignBorderColor = ({
	isReachable,
	isActive,
	reachableHighlight,
	reachableInactiveHighlight,
	unreachableHighlight,
}: {
	isReachable: boolean;
	isActive: boolean;
	reachableHighlight: string;
	reachableInactiveHighlight: string;
	unreachableHighlight: string;
}) => {
	if (!isReachable) {
		return unreachableHighlight;
	}
	if (!isActive) {
		return reachableInactiveHighlight;
	}
	return reachableHighlight;
};

export const getExperienceStep = (
	enumStep: ExperienceStep | undefined,
): number =>
	enumStep
		? {
				[ExperienceStep.Createname]: -1,
				[ExperienceStep.Audience]: 0,
				[ExperienceStep.Email]: 1,
				[ExperienceStep.Page]: 2,
				[ExperienceStep.Gift]: 3,
				[ExperienceStep.Print]: 4,
				[ExperienceStep.Review]: 5,
		  }[enumStep]
		: 0;
export const subrouteToExperienceStep = (
	subroute: CreateExperienceSubroute1,
): ExperienceStep => {
	switch (subroute) {
		case 'create-name':
			return ExperienceStep.Createname;
		case 'add-audience':
			return ExperienceStep.Audience;
		case 'add-email':
			return ExperienceStep.Email;
		case 'configure-form':
			return ExperienceStep.Page;
		case 'choose-gift':
			return ExperienceStep.Gift;
		case 'configure-print':
			return ExperienceStep.Print;
		case 'review':
			return ExperienceStep.Review;
		default:
			throw new Error(
				`The subroute ${subroute} is not a valid subroute for the experience step.`,
			);
	}
};

export type GetRouteNameInput = -1 | 0 | 1 | 2 | 3 | 4 | 5;
export const getRouteName = (
	step: GetRouteNameInput,
): CreateExperienceSubroute1 => {
	const options = {
		0: 'add-audience',
		1: 'add-email',
		2: 'configure-form',
		3: 'choose-gift',
		4: 'configure-print',
		5: 'review',
	} as Record<number, CreateExperienceSubroute1>;
	return step in options ? options[step] : 'create-name';
};

export const selectStep = ({
	routeOrder,
	currentStep,
}: {
	routeOrder: {
		position: number;
		name: CreateExperienceSubroute1;
	}[];
	currentStep: CreateExperienceSubroute1;
}): number =>
	(routeOrder.find(route => route.name === currentStep)
		?.position || 0) - 1;
