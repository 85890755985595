export interface TemplateCardProps {
	title: string;
	description: string;
	image: string;
	id: string | number;
	isSelected?: boolean;
}

interface TemplateCardClickProps {
	onClick: (id: TemplateCardProps['id']) => void;
}

interface AllTemplateCardProps
	extends TemplateCardProps,
		TemplateCardClickProps {}

export const TemplateCard = ({
	title,
	description,
	image,
	id,
	isSelected,
	onClick,
}: AllTemplateCardProps) => (
	<>
		<div
			onClick={() => {
				onClick(id);
			}}
			className=" h-100 d-flex flex-column my-8"
			data-cy="template-card"
			style={{
				cursor: 'pointer',
			}}
		>
			<div
				className="card h-100 overflow-hidden text-white bg-dark rounded-5 rounded"
				style={{
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundImage: `url('${image}')`,
				}}
			>
				<div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-5 ratio ratio-16x9"></div>
			</div>
			<div
				className={`bg-${
					isSelected ? 'purple' : 'black'
				} p-4 text-white text-shadow-1 my-1 rounded-5 rounded`}
			>
				<h2 className="mb-0 fw-bold py-1">
					{title}
				</h2>
				<h4 className="mb-0 fw-light">
					{description}
				</h4>
			</div>
		</div>
	</>
);
