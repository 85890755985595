/* eslint-disable no-shadow */

// This is useful for dumping colors into icons
enum Colors {
	'off-white' = '#f5f5f8',
	white = '#ffffff',
	black = '#000000',
	gray = '#c4c4c4',
	dark = '#1e1f23',
	primary = '#005eff',
	green = '#0acf83',
	red = '#fd4169',
	turquoise = '#22bbee',
	'dark-turquoise' = '#2e5e6e',
	'dark-turquoise-light' = '#22bbee',
	'gray-light' = '#a0a0a0',
	'gray-dark' = '#696969',
	'turquoise-light' = '#85bbee',
	'indigo-light' = '#4f1f7f',
	pink = '#ca4ffd',
	orange = '#ef8961',
	indigo = '#2b1e6f',
	purple = '#8a69ff',
}

export type HermesColor =
	| 'off-white'
	| 'gray-light'
	| 'indigo-light'
	| 'turquoise-light'
	| 'white'
	| 'black'
	| 'gray'
	| 'dark'
	| 'primary'
	| 'green'
	| 'red'
	| 'turquoise'
	| 'pink'
	| 'orange'
	| 'indigo'
	| 'purple'
	| 'dark-turquoise'
	| 'dark-turquoise-light';

export default Colors;
