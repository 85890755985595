import { useRef, useState, SetStateAction } from 'react';
import { useDropzone } from 'react-dropzone';
import {
	Asset,
	AssetType,
	GetAssetsByEntitlementAndAssetTypesDocument,
	useCreateAssetMutationMutation,
} from '../../../gql/queries/generated/graphql';
import { useAuth } from '../../../providers/auth/auth-provider';
import getBase64 from '../../../util/thennables/get-base-64';
import { grabFirstFileThrowIfMoreFiles } from '../../../util/thennables/grab-first-file-throw-if-more';
import { throwIfNotExtension } from '../../../util/thennables/throw-if-not-extension';
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import ImageAssetCard from './image-asset-card';
import NoImagesTab from './no-images-tab';

const SelectImagesTab = ({
	assets,
}: {
	assets: Asset[];
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const [disabled, setDisabled] = useState(false);

	const [createAsset] = useCreateAssetMutationMutation({
		refetchQueries: [
			GetAssetsByEntitlementAndAssetTypesDocument,
		],
	});

	const { user } = useAuth();

	const handleFiles = (files: File[]) => {
		let file: File;

		return Promise.resolve()
			.then(() => setDisabled(true))
			.then(() => files)
			.then(
				grabFirstFileThrowIfMoreFiles(
					'Please only upload one file at a time.',
				),
			)
			.then(firstFile => {
				file = firstFile;
				return firstFile;
			})
			.then(
				throwIfNotExtension(
					['jpg', 'png', 'jpeg'],
					'Invalid File Type',
				),
			)
			.then(() => files[0])
			.then(getBase64) // 12345
			.then(output => {
				const entitlementId = user?.entitlement.id;
				if (!entitlementId) {
					throw new Error(
						'Entitlement Not Found',
					);
				}
				return createAsset({
					variables: {
						content: output as string,
						entitlementId,
						filename: file.name,
						assetType:
							AssetType.Pageheaderimage,
					},
				});
			})
			.catch(err => {
				toastToFailure('Not Created');
			})
			.finally(() => {
				const form = inputRef.current?.form;

				// resetting the form allows for repeated uploads
				if (form) {
					form.reset();
				}

				setDisabled(false);
			});
	};

	const { getInputProps, isDragActive } = useDropzone({
		onDrop: handleFiles,
		disabled,
	});

	return (
		<>
			{assets.length > 0 && (
				<div
					className={`col h-100 d-flex flex-column`}
					style={{
						opacity: isDragActive ? 0.7 : 1,
						width: 'auto',
						height: 'auto',
					}}
				>
					<div
						id="upload-image-bar"
						className="d-flex m-3"
					>
						<input
							{...getInputProps()}
							ref={inputRef}
						/>
						<p
							className=" text-white"
							style={{
								fontWeight: 700,
							}}
						>
							Select an Image
						</p>
						<button
							className="btn btn-sm btn-purple text-white ms-auto"
							style={{ fontWeight: 700 }}
							onClick={() => {
								inputRef.current?.click();
							}}
						>
							Upload An Image
						</button>
					</div>
					{/* Input allows for file upload */}
					<input {...getInputProps()} />

					<div
						id="card-container"
						className="d-flex flex-column flex-grow-1 mx-auto w-100"
						style={{
							marginBottom: '70px',
						}}
					>
						<div
							className="shrink-content scrollbar"
							style={{ overflowX: 'hidden' }}
						>
							<div
								className="row g-4 w-100 mx-auto px-3  row-cols-lg-2 row-cols-xl-3 mb-4"
								style={{
									overflowY: 'auto',
									overflowX: 'hidden',
								}}
							>
								{assets.map(asset => (
									<ImageAssetCard
										asset={asset}
										key={asset.id}
									/>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
			{assets.length === 0 && <NoImagesTab />}
		</>
	);
};

export default SelectImagesTab;
