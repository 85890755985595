import { useState } from 'react';
import { useHistory } from 'react-router';
import validator from 'validator';
import NimblLogo from '../../assets/Nimbl-logos/Nimbl_Primary-Horizontal-White.png';
import AbstractBackground from './assets/Whangaehu.svg';
import { SigninForm } from '../../components/forms/signin-form/signin-form';

export default function SigninPage() {
	const [showLoader, setShowLoader] =
		useState<boolean>(false);

	const [signinEmail, setSigninEmail] =
		useState<string>('');

	const history = useHistory();

	return (
		<div className="bg-black d-flex">
			<section className="row mx-auto h-100 w-100">
				<div
					className="d-flex h-100 flex-column col-12 col-md-7 col-lg-6 col-xl-4 bg-black px-0"
					style={{
						minHeight: '100vh',
					}}
				>
					<div className="p-2">
						<img
							onClick={() =>
								history.push('/')
							}
							style={{
								height: 50,
								cursor: 'pointer',
							}}
							alt="nimbl logo"
							src={NimblLogo}
						/>
					</div>
					<div className="my-auto">
						<div className="mb-4 text-center purpose-ui">
							<h1 className="text-white">
								Login
							</h1>
							<h5 className="fw-light text-light mb-0">
								Sign in to your account to
								continue.
							</h5>
						</div>
						<span className="clearfix"></span>
						<SigninForm
							signInEmail={signinEmail}
							setSigninEmail={setSigninEmail}
						/>
						<div className="my-3 text-center purpose-ui">
							<h6 className="fw-light text-light">
								Not registered?{' '}
								<span
									style={{
										cursor: 'pointer',
									}}
									onClick={() =>
										history.push(
											'/contact-us',
										)
									}
									className="text-info font-weight-bold"
								>
									Sign up
								</span>
							</h6>
						</div>
					</div>
				</div>
				<div
					className="col d-none d-md-flex"
					style={{
						minHeight: '100vh',
						backgroundImage: `url(${AbstractBackground})`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}
				></div>
			</section>
		</div>
	);
}
