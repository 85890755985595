import { createContext, useContext, useState } from 'react';
import {
	useGetPowerBiEmbedTokenQuery,
	User,
} from '../../gql/queries/generated/graphql';
import { useAuth } from '../../providers/auth/auth-provider';

interface powerBiQueryResultType {
	reportsDetail: [
		{
			reportId: string;
			reportName: string;
			embedUrl: string;
		},
	];
	embedToken: {
		token: string;
		tokenId: string;
		expiration: string;
	};
}
interface SettingsProviderProps {
	user: User | undefined | null;
	embedUrl: string | undefined;
	embedToken: string | undefined;
	tenant: string | undefined;
	reportId: string | undefined;
}

const AnalyticsContext =
	createContext<SettingsProviderProps>({
		user: undefined,
		embedUrl: undefined,
		embedToken: undefined,
		tenant: undefined,
		reportId: undefined,
	});

export const AnalyticsContextProvider = ({
	children,
}: {
	children: JSX.Element | JSX.Element[];
}) => {
	// TODO: Env variable-ize
	const powerBiTenantId =
		'cb8bd4b8-074f-4349-a712-a8827eeed957';
	const powerBiReportId =
		'e344d717-7714-47cf-a7e2-eb09105d649f';

	const tenant = powerBiTenantId;
	let embedUrl;
	let embedToken;
	const reportId = powerBiReportId;

	const { user } = useAuth();

	const { data } = useGetPowerBiEmbedTokenQuery({
		variables: {
			entitlementId: user?.entitlement.id || '',
		},
	});

	let powerBiQueryResult;
	if (data?.GetPowerBiEmbedToken) {
		powerBiQueryResult = JSON.parse(
			JSON.stringify(data?.GetPowerBiEmbedToken),
		) as any as powerBiQueryResultType | undefined;
	}

	if (
		powerBiQueryResult &&
		powerBiQueryResult.reportsDetail
	) {
		embedUrl =
			powerBiQueryResult?.reportsDetail[0].embedUrl;
	}

	if (
		powerBiQueryResult &&
		powerBiQueryResult.embedToken
	) {
		embedToken = powerBiQueryResult.embedToken.token;
	}

	return (
		<AnalyticsContext.Provider
			value={{
				user,
				embedUrl,
				embedToken,
				tenant,
				reportId,
			}}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};

export const useAnalyticsContext = () => {
	const context = useContext(AnalyticsContext);
	if (context === undefined) {
		throw new Error(
			'You must be inside of an analytics context provider to invoke useAnalyticsContext',
		);
	}

	return context;
};
