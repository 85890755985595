import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
	from,
} from '@apollo/client';

import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';
// eslint-disable-next-line
import { auth } from '../firebaseConfig/config';
import { toastToNotify } from '../components/trigger-toasts/toast-to-notify';

// This is part of creating an httplink to set the context on outgoing and incoming requests, for more info please see
// https://www.apollographql.com/docs/react/api/link/introduction/
const httpLink = createHttpLink({
	uri: process.env.REACT_APP_API,
});

const isTesting =
	process.env.REACT_APP_MODE === 'testing' || false;

const errorLink = onError(({ graphQLErrors }) => {
	if (graphQLErrors)
		graphQLErrors.forEach(
			({ message, locations, path }) => {
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
				);

				if (message === 'invalid token provided') {
					// same as Firebase sign out method.
					auth.signOut().then(() => {
						toastToNotify(
							'Your user session has expired, please login again.',
						);
					});
				}
			},
		);
});

// Create an auth link that sets the context for incoming requests
const authLink = setContext(async (_, prevContext) => {
	const token = await auth.currentUser?.getIdToken();
	const headers = isTesting
		? {
				email: 'hermes_test_user@test.com',
				Authorization: token,
		  }
		: {
				Authorization: token,
		  };

	return {
		...prevContext,
		headers: {
			...prevContext.headers,
			...headers,
		},
	};
});

// Use this anywhere on the frontend where you need the apollo client so that way you are referencing the same object
export const apolloClient = new ApolloClient({
	link: from([errorLink, authLink.concat(httpLink)]),
	cache: new InMemoryCache(),
});
