import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

const Tooltip = ({
	children,
}: {
	children: JSX.Element | JSX.Element[];
}) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	});

	return (
		<>
			<ReactTooltip />
			{children}
		</>
	);
};

export const TOOLTIP_ATTRIBUTES = {
	'data-place': 'right',
	'data-delay-show': 500,
	'data-offset': JSON.stringify({
		right: 25,
	}),
	'data-background-color': '#8a69ff',
};

export default Tooltip;
