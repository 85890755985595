import { ICheckbox } from './checkbox-container';

export const priceCheckboxData: ICheckbox<{
	minValue: number;
	maxValue: number;
}>[] = [
	{
		checked: false,
		label: '$0 - $25',
		value: {
			minValue: 0,
			maxValue: 25,
		},
	},
	{
		checked: false,
		label: '$25 - $50',
		value: {
			minValue: 25,
			maxValue: 50,
		},
	},
	{
		checked: false,
		label: '$50 - $75',
		value: {
			minValue: 50,
			maxValue: 75,
		},
	},
	{
		checked: false,
		label: '$75 - $100',
		value: {
			minValue: 75,
			maxValue: 100,
		},
	},
	{
		checked: false,
		label: 'Over $100',
		value: {
			minValue: 100,
			maxValue: 99999999,
		},
	},
];
