import {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useParams } from 'react-router';
import { toastToFailure } from '../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../components/trigger-toasts/toast-to-notify';
import {
	Entitlement,
	GetOrgGiftsAndProductsDocument,
	Gift,
	Product,
	useGetOrgGiftsAndProductsQuery,
	User,
	useRefreshProductListMutation,
	UserPermissionLevel,
} from '../../gql/queries/generated/graphql';
import { useAuth } from '../../providers/auth/auth-provider';

export type RouteParams = {
	settingsPath: string;
};

// If you want to provide information to consumers, it must have its type defined in props
interface SettingsProviderProps {
	permissionLevel: UserPermissionLevel;
	settingsPath: string;
	editingUser: User | undefined;
	setEditingUser: React.Dispatch<
		React.SetStateAction<User | undefined>
	>;
	editingGift: Gift | undefined;
	setEditingGift: React.Dispatch<
		React.SetStateAction<Gift | undefined>
	>;
	editingEntitlement: Entitlement | undefined;
	setEditingEntitlement: React.Dispatch<
		React.SetStateAction<Entitlement | undefined>
	>;
	openUserModal: boolean;
	setOpenUserModal: React.Dispatch<
		React.SetStateAction<boolean>
	>;
	openGiftModal: boolean;
	setOpenGiftModal: React.Dispatch<
		React.SetStateAction<boolean>
	>;
	openEntitlementModal: boolean;
	setOpenEntitlementModal: React.Dispatch<
		React.SetStateAction<boolean>
	>;
	permissionLevelToString: (
		permissionLevel: UserPermissionLevel,
	) => 'System Admin' | 'User Admin' | 'Standard';
	refresh: () => void;
	refreshingProducts: boolean;
	giftList: Gift[] | undefined;
	productList: Product[] | undefined;
}

const SettingsContext =
	createContext<SettingsProviderProps>({
		permissionLevel: UserPermissionLevel.Standard,
		settingsPath: '',
		openUserModal: false,
		setOpenUserModal: () => {},
		openGiftModal: false,
		setOpenGiftModal: () => {},
		openEntitlementModal: false,
		setOpenEntitlementModal: () => {},
		editingUser: undefined,
		setEditingUser: () => {},
		editingGift: undefined,
		setEditingGift: () => {},
		editingEntitlement: undefined,
		setEditingEntitlement: () => {},
		permissionLevelToString: () => 'Standard',
		refresh: () => {},
		refreshingProducts: false,
		giftList: undefined,
		productList: undefined,
	});

export const SettingsProvider = ({
	children,
}: {
	children: JSX.Element | JSX.Element[];
}) => {
	const { user } = useAuth();
	const permissionLevel =
		user?.userPermissionLevel ||
		UserPermissionLevel.Standard;

	const { settingsPath } = useParams<RouteParams>();

	const [openUserModal, setOpenUserModal] =
		useState<boolean>(false);

	const [openGiftModal, setOpenGiftModal] =
		useState<boolean>(false);

	const [openEntitlementModal, setOpenEntitlementModal] =
		useState<boolean>(false);

	const [editingUser, setEditingUser] = useState<User>();
	const [editingGift, setEditingGift] = useState<Gift>();
	const [editingEntitlement, setEditingEntitlement] =
		useState<Entitlement>();

	const permissionLevelToString = (
		// eslint-disable-next-line no-shadow
		permissionLevel: UserPermissionLevel,
	) => {
		if (
			permissionLevel ===
			UserPermissionLevel.Systemadmin
		) {
			return 'System Admin';
		}
		if (
			permissionLevel ===
			UserPermissionLevel.Useradmin
		) {
			return 'User Admin';
		}
		return 'Standard';
	};

	// The master product and gift lists for this component
	const [productList, setProductList] =
		useState<Product[]>();
	const [giftList, setGiftList] = useState<Gift[]>();

	// Toggle that is flipped on refresh click
	const [refreshingProducts, setRefreshingProducts] =
		useState<boolean>(false);

	const [refreshProductList] =
		useRefreshProductListMutation();

	const { data: giftProductQuery } =
		useGetOrgGiftsAndProductsQuery();
	const gifts = giftProductQuery?.GetGiftsByOrgId as
		| Gift[]
		| undefined;
	const products =
		giftProductQuery?.GetProductsByOrgId as
			| Product[]
			| undefined;

	useEffect(() => {
		if (products) {
			setProductList(products as Product[]);
		}
		if (gifts) {
			setGiftList(gifts as Gift[]);
		}
	}, [products, gifts]);

	const refresh = () => {
		setRefreshingProducts(true);
		refreshProductList({
			refetchQueries: [
				GetOrgGiftsAndProductsDocument,
			],
		})
			.then(() => {
				toastToNotify('Refreshed');
				setRefreshingProducts(false);
			})
			.catch(error => {
				console.error(error);
				toastToFailure(
					'Unable to refresh products',
				);
				setRefreshingProducts(false);
			});
	};

	return (
		<SettingsContext.Provider
			value={{
				settingsPath,
				permissionLevel,
				openUserModal,
				setOpenUserModal,
				openGiftModal,
				setOpenGiftModal,
				openEntitlementModal,
				setOpenEntitlementModal,
				editingUser,
				setEditingUser,
				editingGift,
				setEditingGift,
				editingEntitlement,
				setEditingEntitlement,
				permissionLevelToString,
				refresh,
				refreshingProducts,
				giftList,
				productList,
			}}
		>
			{children}
		</SettingsContext.Provider>
	);
};

export const useSettings = () => {
	const context = useContext(SettingsContext);
	if (context === undefined) {
		throw new Error(
			'You must be inside of a settings provider to invoke useSettings',
		);
	}

	return context;
};
