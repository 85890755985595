import { HermesColor } from '../../../colors.enum';
import {
	Asset,
	AssetType,
	useGetAssetsByEntitlementAndAssetTypesQuery,
	VersionType,
} from '../../../gql/queries/generated/graphql';
import { usePrintConfigurationStep } from './configure-print-configuration-step-provider';
import ConfigurePrintForm from './configure-print-form';

const ConfigureFormBrowser = () => {
	const { data } =
		useGetAssetsByEntitlementAndAssetTypesQuery({
			variables: {
				assetTypes: [AssetType.Pageheaderimage],
			},
		});
	const { selectedVersion } = usePrintConfigurationStep();

	// Todo! Delete this, refactor components

	const assets =
		(data?.GetAssetsByEntitlementAndAssetTypes ||
			[]) as Asset[];

	const assignHighlightColor = (): HermesColor => {
		if (selectedVersion === VersionType.Versionb) {
			return 'turquoise';
		}
		return 'purple';
	};

	return (
		<>
			<div
				className={`border 
			border-${assignHighlightColor()} 
			border-5 w-100 flex-grow-1 col h-100 d-flex flex-column text-white rounded rounded-3`}
			>
				<div className="d-flex flex-column flex-grow-1 mx-auto w-100 ">
					<div className="shrink-content px-3 scrollbar">
						<ConfigurePrintForm />
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfigureFormBrowser;
