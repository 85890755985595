import {
	MouseEventHandler,
	useEffect,
	useState,
} from 'react';
import { useHistory } from 'react-router';
import { TemplateRow } from '../../../components/template-selector/template-row';
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import {
	PageTemplate,
	useCreateHybridExperiencePageMutation,
	HybridExperience,
	useGetAllPageTemplatesQuery,
	useResetToExperienceStepMutation,
	ResetActionType,
	GetExperienceDocument,
	PageType,
	VersionType,
} from '../../../gql/queries/generated/graphql';
import promptModal from '../../../providers/alert-provider/prompt-modal';
import { delay } from '../../../util/thennables/delay';

import PrevAndNextContainer from '../components/prev-and-next-container';
import { useCurrentExperience } from '../current-experience.provider';
import { useConfigurationStep } from './configuration-form-step/configuration-form-step-provider';

const TemplateCard = ({
	title,
	image,
	description,
	onClick,
	isSelected,
	id,
}: {
	id: string;
	title: string;
	image: string;
	description: string;
	onClick: MouseEventHandler<HTMLElement>;
	isSelected: boolean;
}) => (
	<>
		<div
			className={`col mx-auto pt-4 template-card`}
			id={id}
			onClick={onClick}
			style={{ cursor: 'pointer' }}
		>
			<div
				className={`card rounded-3 d-flex ${
					isSelected
						? 'border border-3 border-purple rounded-3 '
						: 'border-0'
				}`}
			>
				<div className="card-header p-0 bg-dark text-white border-0 rounded-0">
					<p className="my-0 mb-2 fw-bold">
						{title}
					</p>
				</div>
				<div
					className="card-body bg-dark border-0"
					style={{
						height: '0',
						backgroundRepeat: 'no-repeat',
						paddingTop: '90%',
						backgroundSize: '70%',
						backgroundPosition: 'center',
						backgroundImage: `url('${image}')`,
					}}
				></div>
				<div className="card-footer border-0 bg-dark text-white pb-3 rounded-0">
					<p className="fw-light">
						{description}
					</p>
				</div>
			</div>
		</div>
	</>
);

const SelectFormTemplate = () => {
	const {
		data: currentExperienceData,
		refetch: refetchCurrentExperience,
	} = useCurrentExperience();

	const { data: templateData } =
		useGetAllPageTemplatesQuery();

	const allTemplates =
		(templateData?.GetAllPageTemplates as PageTemplate[]) ||
		[];

	const currentExperience =
		currentExperienceData?.GetHybridExperienceById;
	const currentExperienceId = currentExperience?.id;
	const { setConfigurationStep, setSelectedVersion } =
		useConfigurationStep();

	const [selectedTemplateId, setSelectedTemplateId] =
		useState<string | null>(
			currentExperienceId || null,
		);

	const [createPageMutation] =
		useCreateHybridExperiencePageMutation();
	const [resetToExperienceStepMutation] =
		useResetToExperienceStepMutation({
			refetchQueries: [GetExperienceDocument],
		});

	const history = useHistory();
	const pages = currentExperience?.pages;
	const templateId =
		pages && pages.length > 0
			? pages[0]?.pageTemplate?.id || null
			: null;

	useEffect(() => {
		// we want to set the default template to whatever is stored on the
		// experience if it is stored on the experience and the experience exists
		if (currentExperienceData) {
			setSelectedTemplateId(templateId);
		}
	}, [currentExperienceData]);

	const onPrev = () =>
		history.push(
			`/create-experience/${currentExperienceId}/add-email`,
		);

	const warnDeleteData = () =>
		Promise.resolve().then(
			promptModal(
				'Changing your template will delete data for the experience that you have filled out. Click "confirm" to continue.',
				'Form Template Not Changed',
			),
		);

	const pagesExist = pages && pages.length >= 1;
	const isChangingTemplate =
		pagesExist &&
		selectedTemplateId !== pages[0]?.pageTemplate?.id;

	const isNewTemplate = !pagesExist;

	const nextButtonState =
		selectedTemplateId !== null
			? 'next-enabled'
			: 'next-disabled';

	const onNext = () =>
		Promise.resolve()
			.then(() => {
				if (!currentExperienceId) {
					throw new Error(
						'Current Experience Does Not Exist',
					);
				}
				if (!selectedTemplateId) {
					throw new Error(
						'Must Select a template first	',
					);
				}
				return currentExperienceId;
			})
			.then(() => {
				if (isChangingTemplate) {
					// If the user is changing their template, then warn them about deleting data
					return warnDeleteData()
						.then(() =>
							setSelectedVersion(
								VersionType.Versiona,
							),
						)
						.then(() =>
							resetToExperienceStepMutation({
								variables: {
									hybridExperienceId:
										currentExperienceId ||
										'',
									resetActionType:
										ResetActionType.Page,
								},
							}),
						);
				}
				// else continue
				return Promise.resolve({});
			})
			.then(() => {
				// if a user is  not changing their template, then skip this step. We don't need to run a query
				if (!isChangingTemplate && !isNewTemplate) {
					return Promise.resolve({});
				}

				//
				return createPageMutation({
					variables: {
						hybridExperienceId:
							currentExperienceId || '',
						templateId:
							selectedTemplateId || '',
					},
					refetchQueries: [GetExperienceDocument],
				});
			})
			// This will ensure that the form fetched in the next step has the loaded hybrid experience
			.then(delay(1000))
			.then(() =>
				setConfigurationStep('customization'),
			)
			.catch(rejection => {
				// eslint-disable-next-line no-console
				toastToFailure(
					// 'Error processing your template selection, please reload the page and try again',
					rejection.message,
				);
			});

	const landingPageCards = allTemplates.map(template => ({
		id: template.id,
		title: template?.displayName || '',
		description: template?.description || '',
		image: template?.previewImage || '',
		isSelected: template.id === selectedTemplateId,
	}));

	return (
		<>
			<div className="col-12 col-xxxl-10 mx-auto d-flex flex-column flex-grow-1">
				<section className="py-4 text-center container">
					<div className="row pt-xxxl-5">
						<div className="col-lg-6 col-md-8 mx-auto">
							<h2 className="fw-light text-white">
								Select A Page Template
							</h2>
							<p className="fw-light text-gray">
								Select a fully responsive,
								landing page from our
								template library below. You
								can customize your page
								header, body copy, form
								fields, and more on the next
								page
							</p>
						</div>
					</div>
				</section>
				<div className="shrink-content scrollbar">
					<div className="container my-0 py-0 shadow bg-dark-alt rounded rounded-5 border border-purple">
						<TemplateRow
							onClick={id => {
								setSelectedTemplateId(
									id.toString(),
								);
							}}
							cards={landingPageCards}
							title="Select a page template"
						/>
					</div>
				</div>
			</div>
			<PrevAndNextContainer
				experience={
					currentExperience as HybridExperience
				}
				nextOnClick={onNext}
				prevOnClick={onPrev}
				nextButtonLabel={'Customize/Configure'}
				prevButtonLabel="Email Configuration"
				nextButtonState={nextButtonState}
			/>
		</>
	);
};

export default SelectFormTemplate;
