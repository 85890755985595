import { MouseEventHandler } from 'react';

const buttonWidth = 150;

const DisabledNextButton = ({
	onClick,
	text,
	label,
}: {
	onClick: MouseEventHandler;
	text: string;
	label: string;
}) => (
	<div className="d-flex flex-column align-items-end">
		<h5 className="text-white mb-0 fw-light mb-1">
			{/* Customize/Preview */}
			{label}
		</h5>
		<button
			className="btn btn-light-dark btn-sm border border-dark fw-bold text-white fs-5 p-1"
			style={{
				fontWeight: 700,
				minWidth: buttonWidth,
			}}
			disabled
			onClick={onClick}
			id="next-button"
		>
			{text}
		</button>
	</div>
);

const EnabledNextButton = ({
	onClick,
	text,
	label,
}: {
	onClick: MouseEventHandler;
	text: string;
	label: string;
}) => (
	<div className="d-flex flex-column align-items-end">
		<h5 className="text-white mb-0 fw-light mb-1">
			{label}
		</h5>
		<button
			className="btn gradient-button fw-bold btn-sm  bg-gray  text-white fs-5 p-1"
			style={{
				fontWeight: 700,
				minWidth: buttonWidth,
			}}
			onClick={onClick}
			id="next-button"
		>
			{text}
		</button>
	</div>
);

const PreviousButton = ({
	onClick,
	text,
	label,
}: {
	onClick: MouseEventHandler;
	text: string;
	label: string;
}) => (
	<div className="d-flex flex-column">
		<h5 className="text-white mb-0 fw-light mb-1">
			{label}
		</h5>
		<button
			className="btn gradient-button btn-sm bg-gray fw-bold text-white fs-5 p-1"
			style={{
				fontWeight: 700,
				minWidth: buttonWidth,
			}}
			onClick={onClick}
			id="previous-button"
		>
			{text}
		</button>
	</div>
);

export type TEmailNavigatorButtonState =
	| 'next-enabled'
	| 'next-disabled'
	| 'previous';

const AddEmailButton = ({
	type,
	onClick,
	text,
	label,
}: {
	type: TEmailNavigatorButtonState;
	onClick: MouseEventHandler;
	text: string;
	label: string;
}) => (
	<>
		{type === 'next-disabled' && (
			<DisabledNextButton
				onClick={onClick}
				text={text}
				label={label}
			/>
		)}
		{type === 'next-enabled' && (
			<EnabledNextButton
				onClick={onClick}
				text={text}
				label={label}
			/>
		)}
		{type === 'previous' && (
			<PreviousButton
				onClick={onClick}
				text={text}
				label={label}
			/>
		)}
	</>
);

export default AddEmailButton;
