import { useHistory } from 'react-router';
import { HybridExperience } from '../../gql/queries/generated/graphql';
import LoadingScreen from '../loading-screen/loading-screen';
import CreateExperienceNameSubroute from './0-create-name/create-experience-start-page';
import AddAudienceSubroute from './1-add-audience/add-audience-subroute';
import AddEmailSubroute from './2-add-email/add-email-subroute';
import ConfigureFormSubroute from './3-configure-form/configure-form-subroute';
import ConfigureGiftSubroute from './4-select-gifts/configure-gift-subroute';
import ConfigurePrintSubroute from './5-configure-print/configure-print-subroute';
import ReviewSubroute from './6-review/review-subroute';
import { chooseRouteOrRedirect } from './create-experience-helpers';
import { CreateExperienceSubroute1 } from './create-experience-types';
import { useCurrentExperience } from './current-experience.provider';

const CreateExperienceRouter = ({
	step, // from router
}: {
	step: CreateExperienceSubroute1;
}) => {
	const history = useHistory();
	const { data, loading } = useCurrentExperience();
	const hybridExperience =
		(data?.GetHybridExperienceById as HybridExperience) ||
		undefined;

	const route = chooseRouteOrRedirect({
		history,
		hybridExperience,
		loading,
		subroute: step,
	});

	return (
		<>
			{route === 'loading' && <LoadingScreen />}
			{route === 'create-name' && (
				<CreateExperienceNameSubroute
					experience={hybridExperience}
				/>
			)}
			{route === 'add-audience' && (
				<AddAudienceSubroute
					experience={hybridExperience}
					currentStep={step}
				/>
			)}
			{route === 'add-email' && (
				<AddEmailSubroute
					experience={hybridExperience}
					currentStep={step}
				/>
			)}
			{route === 'configure-form' && (
				<ConfigureFormSubroute
					experience={hybridExperience}
					currentStep={step}
				/>
			)}
			{route === 'choose-gift' && (
				<ConfigureGiftSubroute
					experience={hybridExperience}
					currentStep={step}
				/>
			)}
			{route === 'configure-print' && (
				<ConfigurePrintSubroute
					experience={hybridExperience}
					currentStep={step}
				/>
			)}
			{route === 'review' && (
				<ReviewSubroute
					experience={hybridExperience}
					currentStep={step}
				/>
			)}
		</>
	);
};

export default CreateExperienceRouter;
