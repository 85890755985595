import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dayjs } from 'dayjs';
import Colors from '../../../../colors.enum';
import HighlightedBox from '../highlighted-box';
import { useConfigurationStep } from '../review-step/review-step-provider';

export default function ReminderCard({
	launchDate,
	testEvaluationLengthDays,
	winningReminderDate,
	testReminderDate,
	hasReminder,
	toggleReminder,
	reminderToggleActive,
}: {
	launchDate: Dayjs | undefined;
	winningReminderDate: Dayjs | undefined;
	testReminderDate: Dayjs | undefined;
	testEvaluationLengthDays: number;
	hasReminder: boolean | undefined;
	toggleReminder: () => void;
	reminderToggleActive: boolean;
}) {
	const winningReminderDateText = (() => {
		if (!launchDate) {
			return 'Please select a launch date';
		}
		if (!testEvaluationLengthDays) {
			return 'Please select a test evaluation length';
		}

		if (!winningReminderDate) {
			return '';
		}

		return winningReminderDate.format('MMMM DD, YYYY');
	})();

	const testReminderDateText = (() => {
		if (!launchDate) {
			return 'Please Fill Out a Launch Date First';
		}

		if (!testReminderDate) {
			return '';
		}
		return testReminderDate.format('MMM DD, YYYY');
	})();

	const showReminder = (() => {
		if (hasReminder === null) {
			return false;
		}
		if (hasReminder === undefined) {
			return false;
		}
		if (hasReminder === false) {
			return false;
		}
		return true;
	})();

	const { showVersionB } = useConfigurationStep();

	return (
		<HighlightedBox
			color={Colors.pink}
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
			}}
		>
			<div className="w-100 d-flex text-white">
				<FontAwesomeIcon
					icon={faBell}
					color={'white'}
					size="2x"
				/>
				<div className="d-flex">
					<h5 className="ms-2 my-auto">
						Reminder
					</h5>
				</div>
				<button
					className="ms-auto btn-dark btn-sm border border-2 border-gray "
					disabled={!reminderToggleActive}
					onClick={() => {
						toggleReminder();
					}}
				>
					{showReminder ? 'Remove' : 'Add'}
				</button>
			</div>
			<>
				{showReminder && showVersionB && (
					<>
						<p>
							For Test Subjects:{' '}
							{testReminderDateText}
						</p>
					</>
				)}
				{showReminder &&
					(showVersionB ? (
						<p>
							For Winners:{' '}
							{winningReminderDateText}
						</p>
					) : (
						<p>
							Reminder Date:{' '}
							{winningReminderDateText}
						</p>
					))}
			</>
		</HighlightedBox>
	);
}
