const ListPreviewTable = ({
	TableData,
}: {
	TableData:
		| { headerValue: string; sampleValue: string }[]
		| undefined;
}) => (
	<div className="table-responsive">
		<table
			data-cy="list-preview-table"
			className="table table-dark table-striped  shadow fw-light my-3 shrink-content"
		>
			<thead
				style={{
					display: 'table',
					width: '50vw',
					overflowX: 'hidden',
					tableLayout: 'fixed',
				}}
			>
				<tr>
					<th className="fw-light" scope="col">
						Header
					</th>
					<th className="fw-light" scope="col">
						Sample Value
					</th>
				</tr>
			</thead>
			<tbody
				className="scrollbar"
				style={{
					display: 'block',
					height: '20vh',
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				{TableData &&
					TableData.map(x => (
						<tr
							style={{
								display: 'table',
								width: '50vw',
								overflowX: 'hidden',
								tableLayout: 'fixed',
								height: '3vh',
							}}
							key={x.headerValue}
							className="border-top-0"
						>
							<th
								className="fw-light"
								scope="row"
							>
								{x.headerValue}
							</th>
							<td>{x.sampleValue}</td>
						</tr>
					))}
			</tbody>
		</table>
	</div>
);

export default ListPreviewTable;
