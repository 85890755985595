import ReactSelect from 'react-select';
import Identicon from 'react-identicons';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import ReactTimeAgo from 'react-time-ago';
import { User } from '../../../../gql/queries/generated/graphql';

TimeAgo.addDefaultLocale(en);

const UserRow = ({
	user,
	handleDelete,
	setOpenModal,
	setEditingUser,
}: {
	user: User;
	handleDelete: (userToDelete: User) => void;
	setOpenModal: (modal: boolean) => void;
	setEditingUser: (arg0: any) => void;
}) => (
	<>
		<tr data-cy="user-row">
			<td
				className="d-flex align-items-center border-0"
				onClick={() => {
					setEditingUser(user);
					setOpenModal(true);
				}}
			>
				<div className=" overflow-hidden me-3">
					<div
						className="me-3 d-flex bg-white rounded-circle shadow-sm"
						style={{
							width: 40,
							height: 40,
						}}
					>
						<Identicon
							size={20}
							bg={'#fff'}
							className="mx-auto my-auto"
							string={user?.emailAddress}
						/>
					</div>
				</div>
				<div className="d-flex flex-column">
					<div
						data-cy="user-name-element"
						className="text-gray-800  mb-1"
					>
						{user.firstName} {user.lastName}
					</div>
					<span>{user.emailAddress}</span>
				</div>
			</td>
			<td
				className="border-0"
				onClick={() => {
					setEditingUser(user);
					setOpenModal(true);
				}}
			>
				{user.userPermissionLevel}
			</td>
			<td
				className="border-0"
				onClick={() => {
					setEditingUser(user);
					setOpenModal(true);
				}}
			>
				{user.entitlement.name}
			</td>
			<td
				className="border-0"
				onClick={() => {
					setEditingUser(user);
					setOpenModal(true);
				}}
			>
				{user.lastLoginDateTime ? (
					<ReactTimeAgo
						date={
							new Date(user.lastLoginDateTime)
						}
						locale="en-US"
					/>
				) : (
					'Never'
				)}
			</td>
			<td
				className="text-center border-0"
				data-cy="row-actions"
			>
				<button
					id="edit-user-btn"
					data-cy="edit-user-btn"
					className="btn btn-icon btn-outline btn-outline-turquoise m-1 text-hover-white"
					onClick={() => {
						setEditingUser(user);
						setOpenModal(true);
					}}
				>
					<i className="fa fa-edit color-turquoise" />
				</button>
				<button
					id="delete-user-btn"
					data-cy="delete-user-btn"
					className="btn btn-icon btn-outline btn-outline-red m-1 text-hover-white"
					onClick={() => {
						handleDelete(user);
					}}
				>
					<i className="fa fa-trash color-red" />
				</button>
			</td>
		</tr>
	</>
);

export default UserRow;
