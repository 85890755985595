/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { toastToFailure } from 'components/trigger-toasts/toast-to-failure';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { readString } from 'react-papaparse';
import { useDropzone } from 'react-dropzone';
import getBase64 from '../../../util/thennables/get-base-64';
import {
	HybridExperience,
	useUploadAudienceListMutation,
	useResetToExperienceStepMutation,
	ExperienceStep,
	ResetActionType,
} from '../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../current-experience.provider';
import { CreateExperienceSubroute1 } from '../create-experience-types';
import CreateExperienceLayout from '../create-experience-layout';
import AddAudienceModal from './add-audience-modal';
import PrevAndNextContainer from '../components/prev-and-next-container';
import promptModal from '../../../providers/alert-provider/prompt-modal';
import { toastToNotify } from '../../../components/trigger-toasts/toast-to-notify';
import { delay } from '../../../util/thennables/delay';

const AddAudienceSubroute = ({
	experience,
	currentStep,
}: {
	experience: HybridExperience;
	currentStep: CreateExperienceSubroute1;
}) => {
	const { refetch: refetchExp } = useCurrentExperience();
	const audienceConfigured =
		experience?.audienceConfigured;

	const history = useHistory();
	// The id of the experience pulled from the URL
	const experienceId = experience.id;

	const [isOpen, setIsOpen] = useState(false);
	const [fileText, setFileText] = useState<string>('');
	const [fileAsFile, setFileAsFile] = useState<File>();
	const [sampleData, setSampleData] = useState<
		Record<string, string>
	>({});
	const [csvHeaders, setCsvHeaders] = useState<string[]>(
		[],
	);
	const [uniqueIdField, setUniqueIdField] =
		useState<string>('');

	const [emailAddressField, setEmailAddressField] =
		useState<string>('');

	const [tableRows, setTableRows] = useState<
		{ headerValue: string; sampleValue: string }[]
	>([]);

	const [fileName, setfileName] = useState<string>('');

	const [audienceListCount, setAudienceListCount] =
		useState<number>(0);

	const [uploading, setUploading] =
		useState<boolean>(false);

	useEffect(() => {
		if (
			experience.audienceList &&
			experience.audienceList.emailAddressField
		) {
			setEmailAddressField(
				experience.audienceList.emailAddressField
					.headerValue,
			);
		}
	}, [experience]);

	useEffect(() => {
		// If sampleData exists, then send it to the modal preview table through tableRows state
		if (typeof sampleData === 'object') {
			setTableRows(
				Object.entries(sampleData).map(
					([key, value]) => ({
						headerValue: key,
						sampleValue: value,
					}),
				),
			);
		}
	}, [sampleData]);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		open,
		acceptedFiles,
	} = useDropzone({
		disabled: isOpen,
		noClick: true,
		// noDrag: true,
	});

	const beforeOpenMenu = () =>
		Promise.resolve()
			.then(() => {
				open();
			})
			.catch(err => {
				toastToNotify(err.message);
			});

	const onFileSelected = () => {
		if (acceptedFiles.length > 1) {
			toastToFailure(
				'You may only upload one audience list file.',
			);
			return;
		}

		// Check file type by extension
		const ext = acceptedFiles[0].name.split('.').pop();

		if (ext !== 'csv') {
			toastToFailure(
				'Your audience list must be in CSV format',
			);
			return;
		}

		if (acceptedFiles[0].size > 50000000) {
			toastToFailure(
				'Your audience list must less than 50mb, please reduce your list size and try again',
			);
			return;
		}

		const reader = new FileReader();

		Promise.resolve()
			.then(() => promptModal('no', 'yes'))
			.then(() => {
				// eslint-disable-next-line func-names
				reader.onload = function (e) {
					// The results of reading the csv file
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const text = e.target!.result;

					// Use papaparse to read the csv
					const results = readString(
						text!.toString(),
						{
							header: true,
							skipEmptyLines: true,
						},
					);
					setFileText(text!.toString());

					// check headers
					function hasDuplicates(
						array: string[],
					) {
						return (
							new Set(array).size !==
							array.length
						);
					}

					if (
						hasDuplicates(
							results.meta.fields || [],
						)
					) {
						toastToFailure(
							'Your audience list may not contain duplicate headers. Please check your headers and try again.',
						);
						return;
					}

					// Set various states to the results of the csv output
					setCsvHeaders(results.meta.fields!);
					setAudienceListCount(
						results.data.length,
					);
					setSampleData(
						results.data[0] as Record<
							string,
							string
						>,
					);

					// Pop the modal
					setIsOpen(true);
					setfileName(acceptedFiles[0].name);
				};

				// Run the file reader
				reader.readAsText(acceptedFiles[0]);
				setFileAsFile(acceptedFiles[0]);
			});
	};

	useEffect(() => {
		if (acceptedFiles.length > 0) {
			onFileSelected();
		}
	}, [acceptedFiles]);

	const [UploadAudienceListMutation] =
		useUploadAudienceListMutation();
	const [resetToExperienceMutation] =
		useResetToExperienceStepMutation({
			variables: {
				resetActionType: ResetActionType.Audience,
				hybridExperienceId: experienceId,
			},
		});

	// Run when someone hits the continue confirmation button inside the list upload modal, sends the list to backend
	const onContinue = async () => {
		const fileRows = readString(fileText, {
			header: true,
			skipEmptyLines: true,
		});

		if (emailAddressField) {
			setUploading(true);
			const emailToString: string =
				emailAddressField?.toString();

			const hasEmailDuplicates = () => {
				// Set array of email addresses
				const emailAddressArray = fileRows.data.map(
					(row: any) => row[emailToString],
				);
				// Create set of emailAddresses
				const emailAddressSet = new Set(
					emailAddressArray,
				);
				if (
					emailAddressArray.length !==
					emailAddressSet.size
				) {
					return (
						emailAddressArray.length -
						emailAddressSet.size
					);
				}
				return false;
			};

			const resetHybridExperience = audienceConfigured
				? await promptModal(
						'Modifying your audience list will reset your experience',
						'Not Deleted',
				  )()
						.then(() => true)
						.catch(() => false)
				: false;

			Promise.resolve()
				.then(() => {
					if (resetHybridExperience) {
						return resetToExperienceMutation();
					}
					// eslint-disable-next-line no-useless-return
					return;
				})
				.then(() => {
					const duplicateNumber =
						hasEmailDuplicates();
					if (duplicateNumber) {
						throw new Error(
							`Your audience list contains ${duplicateNumber} duplicate value${
								duplicateNumber > 1
									? 's'
									: ''
							} in the ${emailToString} field. Please remove the duplicate values in  your list and try again.`,
						);
					}
				})
				.then(() => {
					if (fileAsFile) {
						return getBase64(fileAsFile);
					}
					return undefined;
				})
				.then(output =>
					UploadAudienceListMutation({
						variables: {
							hybridExperienceId:
								experienceId || '',
							audienceCount:
								audienceListCount,
							filename: fileName || '',
							content:
								(output as string) || '',
							audienceListHeaders: tableRows,
							emailAddressField: {
								headerValue:
									emailAddressField,
								sampleValue:
									sampleData![
										emailToString
									],
							},
						},
					}).catch(() => {
						throw new Error(
							'Audience list upload failed, please try again.',
						);
					}),
				)
				.then(() => refetchExp())
				.then(() => {
					history.push(
						`/create-experience/${experienceId}/add-email`,
					);
					setUploading(false);
				})
				.catch(err => {
					toastToFailure(err.message);
				})
				.finally(() => {
					setUploading(false);
				});
		} else {
			toastToFailure(
				'You must select an email address field',
			);
		}
	};

	return (
		<CreateExperienceLayout
			experience={experience}
			currentStep={currentStep}
		>
			<div className="d-flex flex-column flex-grow-1">
				{isDragActive ? (
					<p
						className={`text-white text-center fw-bold fs-6 mt-4 mb-${
							experience.audienceConfigured
								? '0'
								: '4'
						}`}
					>
						Drop your audience list
					</p>
				) : (
					<p
						className={`text-white text-center fw-bold fs-6 mt-4 mb-${
							experience.audienceConfigured
								? '0'
								: '4'
						}`}
					>
						Drag and drop a .csv list of
						recipients or click to upload
					</p>
				)}
				{experience.audienceConfigured && (
					<>
						<p className="text-center text-white mb-0">
							Current List:{' '}
							<span className="text-green">
								{
									experience.audienceList
										?.list?.filename
								}
							</span>
						</p>
						<p className="text-center text-white mb-0">
							Member Count:{' '}
							<span className="text-green">
								{experience.audienceCount}
							</span>{' '}
							Email Field:{' '}
							<span className="text-green">
								{
									experience.audienceList
										?.emailAddressField
										?.headerValue
								}
							</span>
						</p>
					</>
				)}
				<div
					style={
						isOpen
							? { pointerEvents: 'none' }
							: {}
					}
					{...getRootProps({
						onDropCapture: e => {
							e.preventDefault();
						},
					})}
					className="h-100 my-4 mx-5"
					id="file-upload-wrapper"
					onClick={() => {
						beforeOpenMenu();
					}}
				>
					<input {...getInputProps()} />
				</div>
				<PrevAndNextContainer
					experience={experience}
					nextOnClick={() =>
						history.push(
							`/create-experience/${experienceId}/add-email`,
						)
					}
					prevOnClick={() =>
						history.push(
							`/create-experience/${
								experience?.id || ''
							}/create-name`,
						)
					}
					nextButtonLabel="Select Email"
					prevButtonLabel="Edit Name"
					nextButtonState={`next-${
						experience.audienceConfigured &&
						experience.audienceCount > 0
							? 'enabled'
							: 'disabled'
					}`}
				/>
				<AddAudienceModal
					open={isOpen}
					fileName={fileName}
					csvHeaders={csvHeaders}
					onContinue={onContinue}
					uploading={uploading}
					setOpen={setIsOpen}
					setUniqueIdField={setUniqueIdField}
					emailAddressField={emailAddressField}
					setEmailAddressField={
						setEmailAddressField
					}
					tableRows={tableRows}
				/>
			</div>
		</CreateExperienceLayout>
	);
};

export default AddAudienceSubroute;
