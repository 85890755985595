import dayjs, { Dayjs } from 'dayjs';

export const isBusinessDay = (date: Dayjs): boolean => {
	const weekday = date.get('day') as
		| 0
		| 1
		| 2
		| 3
		| 4
		| 5
		| 6;

	return {
		0: false,
		1: true,
		2: true,
		3: true,
		4: true,
		5: true,
		6: false,
	}[weekday];
};

// Gets the offset date, factoring in weekends
export const getOffsetDate = (
	date: Dayjs,
	offset: number,
) => {
	let offsetDate = date;
	let offsetCount = offset;
	let accumulatedDays = 0;

	while (offsetCount > 0) {
		const nextDay = offsetDate.add(1, 'day');
		if (isBusinessDay(nextDay)) {
			offsetCount -= 1;
		}
		offsetDate = nextDay;
		accumulatedDays += 1;
	}

	return {
		date: offsetDate,
		offsetCount: accumulatedDays,
	};
};

export const applyTimeToDay = (
	date: Dayjs,
	time: Dayjs,
) => {
	const hours = time.get('hours');
	const minutes = time.get('minutes');
	return dayjs(date)
		.hour(0)
		.minute(0)
		.hour(hours)
		.minute(minutes);
};
