import { useState } from 'react';
import { useHistory } from 'react-router';
import TextInput from '../../../components/inputs/text-input';
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import {
	HybridExperience,
	SendType,
	useCreateExperienceMutation,
	useUpdateHybridExperienceMutationMutation,
} from '../../../gql/queries/generated/graphql';
import { useAuth } from '../../../providers/auth/auth-provider';
import { useCurrentExperience } from '../current-experience.provider';

const OptionsDropdown = ({
	selectOptions,
	onChange,
	selected,
}: {
	selectOptions: string[];
	onChange: (selectedValue: string) => void;
	selected?: string;
}) => (
	<select
		onChange={e => {
			onChange(e.target.value);
		}}
		className="form-select my-3 ms-3 w-auto bg-white text-dark"
		// value={selected}
		defaultValue={selected}
	>
		<option
			className="text-dark"
			disabled
			selected={!selected}
			hidden={!!selected}
		>
			Select Experience Send Type
		</option>
		{selectOptions.map((optionValue, index) => (
			<option
				key={index}
				className="text-dark"
				selected={selected === optionValue}
			>
				{optionValue}
			</option>
		))}
	</select>
);

const CreateExperienceNameSubroute = ({
	experience,
}: {
	experience?: HybridExperience;
}) => {
	const { user } = useAuth();
	const history = useHistory();
	const { refetch } = useCurrentExperience();

	const [createExperience] =
		useCreateExperienceMutation();

	const [updateHybridExperienceMutation] =
		useUpdateHybridExperienceMutationMutation();

	const [experienceName, setExperienceName] =
		useState<string>(experience?.name || '');

	const [sendType, setSendType] = useState<
		SendType | undefined
	>(experience?.sendType || undefined);

	const experienceNameValid = experienceName.length > 0;
	const handleUpdateHybridExperience = () => {
		updateHybridExperienceMutation({
			variables: {
				hybridExperience: {
					id: experience?.id || '',
					name: experienceName,
					sendType,
				},
			},
		})
			.then(() => {
				history.push(
					`/create-experience/${
						experience?.id || ''
					}/add-audience`,
				);
			})
			.then(() => {
				refetch();
			});
	};
	const handleCreateHybridExperience = () => {
		if (
			user &&
			user.id &&
			user?.entitlement.id &&
			experienceNameValid
		) {
			Promise.resolve()
				.then(() => {
					if (
						!user?.entitlement?.id ||
						!experienceNameValid
					) {
						throw new Error(
							'Unable to create experience, there was an issue fetching your user data. Please reload the page and try again.',
						);
					}
				})
				.then(() => {
					if (!sendType) {
						throw new Error(
							'Please select a Send type',
						);
					}
				})
				.then(() =>
					createExperience({
						variables: {
							name: experienceName,
							sendType,
						},
					}),
				)
				.then(result => {
					const id =
						result.data?.CreateHybridExperience
							?.id;
					history.push(
						`/create-experience/${id}/add-audience`,
					);
				})
				.catch(err => {
					toastToFailure(err.message);
				});
		}
	};

	const selectOptions: string[] = [
		// put other at the end
		...Object.keys(SendType).filter(t => t !== 'Other'),
		'Other',
	];

	return (
		<div className="bg-indigo bg-gradient d-flex flex-column w-100">
			<div className="row my-auto w-100 mx-auto px-5">
				<div className="col-10 col-xxl-6 me-auto me-auto px-5">
					<div className="row px-5">
						<h1
							className="text-white  display-4"
							style={{ fontWeight: 900 }}
						>
							{experience
								? 'Reconfigure Experience'
								: 'Create Experience'}
						</h1>
						<p className="text-gray-800 fs-2">
							{experience
								? `Change the experience name or reconfigure the experience type here.`
								: `Engage your audience by sharing some
					company news, promoting a new product or
					service offering, or announcing an event.
					`}
						</p>
					</div>
					<div className="row px-5">
						<form
							onSubmit={e => {
								e.preventDefault();
								if (!experience) {
									handleCreateHybridExperience();
								}
								if (experience) {
									handleUpdateHybridExperience();
								}
							}}
						>
							<div className="d-flex">
								<TextInput
									id="create-experience-name-input"
									placeholder="Experience Name"
									className="form-control py-1 px-2 my-3 text-black fs-5 border-1 border-black bg-white"
									value={experienceName}
									onChange={e => {
										setExperienceName(
											e.target.value,
										);
									}}
								/>
								<OptionsDropdown
									selectOptions={
										selectOptions
									}
									selected={(() => {
										const keys =
											Object.keys(
												SendType,
											);
										const values =
											Object.values(
												SendType,
											);
										const index =
											values.findIndex(
												key =>
													key ===
													sendType,
											);

										return (
											keys[index] ||
											undefined
										);
									})()}
									onChange={selected => {
										setSendType(
											SendType[
												selected as 'Awareness'
											] || undefined,
										);
									}}
								/>
							</div>
							<input
								id="create-experience-name-submit-button"
								type="submit"
								disabled={
									!experienceNameValid
								}
								value={
									experience
										? 'Next'
										: 'Start'
								}
								className="btn  font-weight-bold gradient-button fs-4 text-white border-0 shadow-sm"
								style={{
									fontWeight: 800,
									opacity:
										experienceNameValid
											? '100%'
											: '20%',
								}}
							/>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateExperienceNameSubroute;
