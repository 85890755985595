import { useState } from 'react';
import Tooltip, {
	TOOLTIP_ATTRIBUTES,
} from '../../../components/tooltip';
import { toastToNotify } from '../../../components/trigger-toasts/toast-to-notify';
import {
	Gift,
	PageType,
} from '../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../current-experience.provider';
import LearnMoreModal from './learn-more-modal';
import { useConfigurationStep } from './select-gift-step/select-gift-step-provider';

const ProductCard = ({ gift }: { gift: Gift }) => {
	const {
		addSelectedGift,
		selectedGiftsAtVersion,
		selectedVersion,
		isLoading,
	} = useConfigurationStep();
	const { data: currentExperienceData } =
		useCurrentExperience();

	const pages =
		currentExperienceData?.GetHybridExperienceById
			?.pages;

	// the version type of the selected page doesn't matter
	const selectedPage = pages && pages[0];
	const pageType = selectedPage?.pageType;

	const [openModal, setOpenModal] =
		useState<boolean>(false);

	const addToExperience = () => {
		const foundGift =
			selectedGiftsAtVersion &&
			selectedGiftsAtVersion.find(
				selectedGift =>
					selectedGift.gift.id === gift.id,
			);

		const maxGiftNumbers = {
			[PageType.Multiitem]: 2,
			[PageType.Singleitem]: 1,
			[PageType.Singleitemsize]: 1,
			default: 0,
		};

		const maxGiftNumber =
			maxGiftNumbers[
				pageType && pageType in maxGiftNumbers
					? pageType
					: 'default'
			];

		const atMaximumGiftNumber =
			selectedGiftsAtVersion &&
			selectedGiftsAtVersion.length >= maxGiftNumber;

		if (!foundGift && !atMaximumGiftNumber) {
			addSelectedGift({
				versionType: selectedVersion,
				gift,
			});
		}

		if (!foundGift && atMaximumGiftNumber) {
			toastToNotify(
				`You can only select ${maxGiftNumber} gift${
					maxGiftNumber === 1 ? '' : 's'
				} for this experience and version`,
			);
		}

		if (foundGift) {
			toastToNotify(
				'This gift has already been selected',
			);
		}
	};

	const shortenWord = (word: string, length: number) => {
		if (word.length > length) {
			return `${word.substring(0, length - 3)}...`;
		}
		return word;
	};

	return (
		<div
			className="col p-2 h-100"
			style={{ maxHeight: '100%' }}
		>
			<div className="card bg-white">
				<div className="card-body  pb-0 pt-3 border-0 text-dark">
					<div className="card-px pt-5 pb-5 d-flex justify-content-between">
						<Tooltip>
							<h2
								className="text-purple d-flex"
								data-tip={gift.name}
								style={{ cursor: 'help' }}
								{...TOOLTIP_ATTRIBUTES}
							>
								{shortenWord(gift.name, 20)}
							</h2>
							<h2 className="text-purple fw-normal d-flex">
								${gift.price}
							</h2>
						</Tooltip>
					</div>
				</div>
				<div className="card-px p-5 ratio ratio-4x3 p-0">
					<img
						className="img-fluid"
						src={gift.previewImage?.url || ''}
					/>
				</div>

				<div className="d-flex justify-content-between card-footer bg-white border-0">
					<button
						style={{ borderRadius: '50px' }}
						type="button"
						className=" d-flex justify-content-center w-60 my-1 btn btn-purple fw-bold text-white"
						onClick={() => addToExperience()}
						disabled={isLoading}
					>
						Add To Experience
					</button>
					<button
						style={{
							borderRadius: '50px',
						}}
						type="button"
						className=" d-flex w-60 my-1 btn btn-outline btn-outline-purple fw-bold "
						onClick={() => setOpenModal(true)}
					>
						Learn More
					</button>
				</div>
			</div>
			<LearnMoreModal
				addToExperience={addToExperience}
				gift={gift}
				open={openModal}
				setOpen={setOpenModal}
			/>
		</div>
	);
};
export default ProductCard;
