/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import Editor from './Editor';
import { toastToFailure } from '../../../../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../../../../components/trigger-toasts/toast-to-notify';
import {
	Email,
	GetExperienceDocument,
	HybridExperience,
	LegalTerms,
	RenderExperienceEmailVersionDocument,
	useGetEntitlementByIdQuery,
	useUpdateHybridExperienceEmailCustomizationMutation,
} from '../../../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../../../current-experience.provider';
import { useEmailConfigurationStep } from './providers/email-configuration-step.provider';
import { toastToSuccess } from '../../../../../components/trigger-toasts/toast-to-success';

const FooterTermsSelect = ({
	footerTerms,
	selectedFooter,
	setFooterTerms,
}: {
	footerTerms: LegalTerms[];
	setFooterTerms: React.Dispatch<
		React.SetStateAction<string | undefined>
	>;
	selectedFooter: string;
}) => {
	useEffect(() => {
		const defaultTerm = footerTerms.find(
			term => term.isDefault === true,
		);
		if (defaultTerm && defaultTerm.text) {
			setFooterTerms(defaultTerm.text);
		}
	}, [footerTerms]);

	return (
		<>
			{footerTerms.map((x, y) => (
				<option
					selected={
						selectedFooter === x?.text ||
						!!x.isDefault
					}
					key={y.toString()}
					value={x.text || ''}
				>
					{x?.displayName}
				</option>
			))}
		</>
	);
};

const EmailCustomizationTab = () => {
	// House current experience in local state
	const [currentExperience, setCurrentExperience] =
		useState<HybridExperience>();

	// The version selected to be configured in the tab bar
	const { selectedVersion: version } =
		useEmailConfigurationStep();

	// Pull out the current entitlement, for footer terms
	const { data: currentEntitlement } =
		useGetEntitlementByIdQuery();

	// Pull out the current experience
	const { data: hybridExperience, refetch: refetchExp } =
		useCurrentExperience();

	// The current email as determined by the version selected
	const [currentEmail, setCurrentEmail] =
		useState<Email>();

	// If data updates, reset the current experience
	useEffect(() => {
		if (hybridExperience) {
			// Update experience
			setCurrentExperience(
				hybridExperience.GetHybridExperienceById as HybridExperience,
			);
		}
	}, [hybridExperience]);

	// The elements to configure
	const [subjectLine, setSubjectLine] =
		useState<string>();

	const [buttonText, setButtonText] = useState<string>();

	const [currentHtml, setCurrentHtml] =
		useState<string>();

	const [footerTerms, setFooterTerms] =
		useState<string>();

	// When the selected version changes, current email/editor according to the version
	useEffect(() => {
		if (currentExperience && version) {
			setCurrentEmail(
				currentExperience!.emails!.filter(
					email => email?.version === version,
				)[0] as Email,
			);
		}
	}, [version, currentExperience]);

	// If current email changes, reset the states based on the version
	useEffect(() => {
		if (currentEmail) {
			currentEmail.bodyHtml
				? setCurrentHtml(
						currentEmail.bodyHtml as unknown as string,
				  )
				: null;
			currentEmail.subjectLine
				? setSubjectLine(
						currentEmail.subjectLine as string,
				  )
				: null;
			currentEmail.buttonText
				? setButtonText(
						currentEmail.buttonText as string,
				  )
				: null;
			currentEmail.footerLegalTerms
				? setFooterTerms(
						currentEmail.footerLegalTerms as string,
				  )
				: null;
		}
	}, [currentEmail]);

	// Use ref to easily pull out the state of the editor html and send to the backend
	const editorEl = useRef() as MutableRefObject<Editor>;
	const experienceId = currentExperience?.id;

	// Mutation to update email customization
	const [updateHybridExperienceEmailCustomization] =
		useUpdateHybridExperienceEmailCustomizationMutation();

	// Update the experience and then refetch it
	const onUpdateClick = () => {
		Promise.resolve()
			.then(() => {
				if (!experienceId) {
					throw new Error(
						'Experience ID is undefined',
					);
				}
				if (
					!currentEmail ||
					!subjectLine ||
					!buttonText ||
					!footerTerms
				) {
					throw new Error(
						'Please fill out all required fields',
					);
				}
			})
			.then(() =>
				updateHybridExperienceEmailCustomization({
					variables: {
						hybridExperienceId: experienceId!,
						version,
						subjectLine: subjectLine || '',
						buttonText: buttonText || '',
						bodyHtml:
							editorEl.current.state
								.editorHtml,
						footerLegalTerms: footerTerms,
					},
					refetchQueries: [
						RenderExperienceEmailVersionDocument,
						GetExperienceDocument,
					],
				}),
			)
			.then(() => {
				refetchExp();
				toastToSuccess('Updated successfully');
			})
			.catch(error => {
				console.error(error);
				toastToFailure(
					error.message,
					// 'Failed to update, please try again',
				);
			});
	};

	return (
		<div className={`col h-100 d-flex flex-column`}>
			<form
				className="d-flex flex-column flex-grow-1 mx-auto w-100"
				style={{
					marginBottom: '70px',
				}}
				onSubmit={e => {
					e.preventDefault();
					onUpdateClick();
				}}
			>
				<div className="shrink-content scrollbar">
					<div className="row text-white mt-3 mb-5 mx-3">
						<h5 className="py-2">Body Copy*</h5>
						<Editor
							currentHtml={currentHtml}
							ref={editorEl}
							placeholder={
								'Write something...'
							}
						/>
						<div className="col my-4">
							<label className="form-label">
								<h5>Subject Line*</h5>
							</label>
							<input
								type="text"
								onChange={e =>
									setSubjectLine(
										e.currentTarget
											.value,
									)
								}
								value={subjectLine || ''}
								className="form-control bg-white text-black"
								id="subject-line-input"
								placeholder="Edit Me"
							/>
						</div>
						<div className="col my-4">
							<label className="form-label">
								<h5>Button Text*</h5>
							</label>
							<input
								type="text"
								onChange={e =>
									setButtonText(
										e.currentTarget
											.value,
									)
								}
								value={buttonText || ''}
								className="form-control  bg-white text-black"
								placeholder="Edit Me"
								id="button-text-input"
							/>
						</div>
						<div className="my-3">
							<label className="form-label">
								<h5>Footer Terms*</h5>
							</label>
							<select
								id="footer-terms-select"
								className="form-select form-select-md mb-3 w-50  bg-white text-black"
								onChange={e =>
									setFooterTerms(
										e.currentTarget
											.value,
									)
								}
								aria-label=".form-select-lg"
								value={footerTerms}
							>
								{!footerTerms && (
									<option
										value={undefined}
									>
										Select Footer Terms
									</option>
								)}
								{currentEntitlement &&
									currentEntitlement.GetEntitlementById &&
									currentEntitlement
										.GetEntitlementById
										.emailFooterTerms &&
									currentEmail && (
										<FooterTermsSelect
											setFooterTerms={
												setFooterTerms
											}
											selectedFooter={
												currentEmail.footerLegalTerms ||
												''
											}
											footerTerms={
												currentEntitlement
													.GetEntitlementById
													.emailFooterTerms as LegalTerms[]
											}
										/>
									)}
							</select>
							<button
								// onClick={onUpdateClick}
								className={`btn btn-md my-3 text-white ${
									version !== 'VERSIONA'
										? 'btn-turquoise'
										: 'btn-purple'
								}`}
								id={
									'update-email-customization-submit'
								}
							>
								Update
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default EmailCustomizationTab;
