import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useState,
} from 'react';
import { VersionType } from '../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../current-experience.provider';

export type BrowserStep =
	| 'images'
	| 'customize'
	| 'mapping'
	| 'loading';

export type ConfigurationVersionState =
	| VersionType.Versiona
	| VersionType.Versionb;

interface ConfigurationStepProviderProps {
	selectedVersion: ConfigurationVersionState;
	setSelectedVersion: Dispatch<
		SetStateAction<ConfigurationVersionState>
	>;
	renderHtml: string;
	setVersionOnHtml: SetStateAction<Dispatch<VersionType>>;
	pageUpdating: boolean;
	setPageUpdating: Dispatch<SetStateAction<boolean>>;
}

const PrintConfigurationStepContext =
	createContext<ConfigurationStepProviderProps>({
		selectedVersion: VersionType.Versiona,
		setSelectedVersion: () => {},
		renderHtml: '',
		setVersionOnHtml: () => {},
		pageUpdating: false,
		setPageUpdating: () => {},
	});

export const PrintConfigurationStepProvider = ({
	children,
}: {
	children: JSX.Element | JSX.Element[];
}) => {
	// todo
	// Get all Print data

	const { data: currentExperienceData } =
		useCurrentExperience();

	const currentExperience =
		currentExperienceData?.GetHybridExperienceById ||
		null;

	const [pageUpdating, setPageUpdating] =
		useState<boolean>(false);

	const [selectedVersion, setSelectedVersion] =
		useState<ConfigurationVersionState>(
			VersionType.Versiona,
		);

	// ! Get Current Experience Here

	return (
		<PrintConfigurationStepContext.Provider
			value={{
				selectedVersion,
				setSelectedVersion,
				renderHtml: '',
				setVersionOnHtml: () => {},
				pageUpdating,
				setPageUpdating,
			}}
		>
			{children}
		</PrintConfigurationStepContext.Provider>
	);
};

export const usePrintConfigurationStep = () => {
	const context = useContext(
		PrintConfigurationStepContext,
	);
	if (context === undefined) {
		throw new Error(
			'You must be inside of a PrintConfigurationProvider to invoke usePrintConfigurationStep',
		);
	}

	return context;
};
