import { useEffect, useState } from 'react';
import { HybridExperience } from '../../../gql/queries/generated/graphql';
import { isTruthey } from '../../../util/is-truthey';
import LoadingScreen from '../../loading-screen/loading-screen';
import { TEmailNavigatorButtonState } from '../2-add-email/add-email-buttons';
import PrevAndNextContainer from '../components/prev-and-next-container';
import { useCurrentExperience } from '../current-experience.provider';
import ReviewBrowser from './review-browser';
import { ConfigurationStepProvider } from './review-step/review-step-provider';
import ReviewTabBar from './review-tab-bar';
import messagebox from '../../../providers/alert-provider/messagebox';
import { getExperienceSteps } from '../validations/review.validations';

const defaultProps = {
	showNextAndBackButtons: false,
	onPrev: () => {},
	onNext: () => {},
};

const ReviewComponent = ({
	experience,
	experienceId,
	showNextAndBackButtons,
	onNext,
	onPrev,
}: {
	experience?: HybridExperience;
	experienceId?: string;
} & typeof defaultProps) => {
	if (![experience, experienceId].some(isTruthey)) {
		throw new Error(
			'You must provide either an experience or an experienceId',
		);
	}
	const { data: experienceQueryData } =
		useCurrentExperience();
	const currentExperience =
		experienceQueryData?.GetHybridExperienceById ||
		experience;

	const [nextButtonState, setNextButtonState] =
		useState<TEmailNavigatorButtonState>(
			'next-disabled',
		);
	useEffect(() => {
		if (currentExperience?.readyToLaunch) {
			setNextButtonState('next-enabled');
		}
	}, [currentExperience?.readyToLaunch]);

	const tasks = getExperienceSteps(currentExperience);

	return (
		<>
			{currentExperience && (
				<ConfigurationStepProvider
					currentExperience={
						currentExperience as HybridExperience
					}
					displayOnly={!!experienceId}
				>
					<div className="bg-dark w-100 h-100 d-flex flex-column p-3">
						<div className="row d-flex flex-grow-1">
							<div className="col d-flex flex-column flex-grow-1 my-3">
								<ReviewTabBar
									showTodo={
										!tasks.every(
											task =>
												task.complete,
										)
									}
									onTodo={() => {
										messagebox.openTodoList(
											{
												tasks,
												title: 'Review: ',
												subtitle:
													'Please complete all of the configuration steps below to launch your experience!',
												headerA: '',
												headerB: '',
											},
										);
									}}
								/>
								<ReviewBrowser />
							</div>
						</div>
						{showNextAndBackButtons && (
							<PrevAndNextContainer
								experience={experience}
								nextButtonText="Schedule Launch"
								nextButtonLabel="Ready to Launch?"
								nextButtonState={
									nextButtonState
								}
								nextOnClick={async () => {
									if (onNext) {
										setNextButtonState(
											'next-disabled',
										); // disable button
										await Promise.resolve()
											.then(() =>
												onNext(),
											)
											.finally(() =>
												setNextButtonState(
													'next-enabled',
												),
											); // re-enable button
									}
								}}
								prevButtonText="Previous"
								prevButtonLabel="Print"
								prevOnClick={() => {
									if (onPrev) {
										onPrev();
									}
								}}
							/>
						)}
					</div>
				</ConfigurationStepProvider>
			)}
			{!currentExperience && experienceId && (
				<LoadingScreen />
			)}
		</>
	);
};

ReviewComponent.defaultProps = defaultProps;

export default ReviewComponent;
