import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { service, factories, models } from 'powerbi-client';
import { MutableRefObject, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import Colors from '../../colors.enum';
import ProfilePanel from '../../components/general-ui/profile-panel';
import LeftNav from '../../components/layout/left-nav-layout/left-nav-layout';
import { HybridExperience } from '../../gql/queries/generated/graphql';
import { useAuth } from '../../providers/auth/auth-provider';
import ReviewComponent from '../create-experience-page/6-review/review-component';
import PrevAndNextContainer from '../create-experience-page/components/prev-and-next-container';
import {
	AnalyticsContextProvider,
	useAnalyticsContext,
} from './analytics-provider';

import UnderConstruction from '../../components/general-ui/under-construction';

const powerbi = new service.Service(
	factories.hpmFactory,
	factories.wpmpFactory,
	factories.routerFactory,
);

const PowerBIEmbedded = () => {
	const { user, embedToken, embedUrl, tenant, reportId } =
		useAnalyticsContext();

	const embedContainer =
		useRef() as MutableRefObject<HTMLDivElement>;

	// Set up the configuration object that determines what to embed and how to embed it.
	useEffect(() => {
		if (embedUrl && embedToken) {
			const embedConfiguration = {
				accessToken: embedToken,
				embedUrl,
				id: reportId,
				permissions: models.Permissions.All,
				tokenType: models.TokenType.Embed,
				viewMode: models.ViewMode.View,
				type: 'report',
				settings: {
					filterPaneEnabled: true,
					navContentPaneEnabled: true,
				},
			};

			powerbi.reset(embedContainer.current);
			const embeddedReport = powerbi.embed(
				embedContainer.current,
				embedConfiguration,
			);
		}
	}, [embedUrl, embedToken]);

	return (
		<>
			<div
				ref={embedContainer}
				className="container-fluid text-white h-100 d-flex flex-column"
			>
				<UnderConstruction feature="Analytics Page" />
			</div>
		</>
	);
};

const AnalyticsLayout = ({
	showExperience,
}: {
	showExperience?: string;
}) => {
	const { user } = useAuth();
	const history = useHistory();
	return (
		<div className="h-100">
			<LeftNav>
				<div
					className="d-flex flex-column h-100 w-100 px-4 pb-4"
					style={{
						backgroundColor: '#171717',
						overflowX: 'hidden',
					}}
				>
					<header className="d-flex justify-content-between w-100 mx-auto py-3">
						<h2
							className="text-white"
							style={{ fontWeight: 900 }}
						>
							Analytics
						</h2>
						{user && (
							<ProfilePanel user={user} />
						)}
					</header>
					{!showExperience && <PowerBIEmbedded />}
					{showExperience && (
						<>
							<ReviewComponent
								experienceId={
									showExperience
								}
							/>
							<div>
								<button
									className="btn btn-gray"
									onClick={() => {
										history.push(
											'/experience-analytics',
										);
									}}
								>
									<FontAwesomeIcon
										icon={faArrowLeft}
										color={
											Colors[
												'gray-dark'
											]
										}
										size="2x"
										style={{
											cursor: 'pointer',
										}}
									/>
								</button>
							</div>
						</>
					)}
				</div>
			</LeftNav>
		</div>
	);
};

const ExperienceAnalyticsPage = () => {
	const params = useParams<{ experienceId: string }>();

	return (
		<AnalyticsContextProvider>
			<AnalyticsLayout
				showExperience={params.experienceId}
			/>
		</AnalyticsContextProvider>
	);
};

export default ExperienceAnalyticsPage;
