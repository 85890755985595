import { Redirect, Route, RouteProps } from 'react-router';
import { invertAuthStateIfInverted } from 'routes/app-router/assign-auth-state';
import { TAuthState } from 'types/auth-state.type';

export type ProtectedRouteProps = {
	redirectTo?: string;
	authState: TAuthState;
} & RouteProps;

const defaultProps = {
	loadingComponent: null as null | JSX.Element,
	// Inverted would be if you want a route to only show that page if unauthenticated. And reject the page if authenticated.
	inverted: false as boolean,
};

/**
 * 😁Uses the react-router Route component to protect a route
 * */
function ProtectedRoute({
	redirectTo = '/login',
	authState,
	inverted,
	loadingComponent = null,
	...routeProps
}: ProtectedRouteProps & typeof defaultProps): JSX.Element {
	return (
		<>
			{invertAuthStateIfInverted(
				authState,
				inverted,
			) === 'authenticated' && (
				<Route {...routeProps} />
			)}
			{invertAuthStateIfInverted(
				authState,
				inverted,
			) === 'unauthenticated' && (
				<Redirect to={{ pathname: redirectTo }} />
			)}
			{authState === 'loading' &&
				!!loadingComponent &&
				loadingComponent}

			{authState === 'loading' &&
				!loadingComponent && (
					<Route {...routeProps} />
				)}
		</>
	);
}

ProtectedRoute.defaultProps = defaultProps;

export default ProtectedRoute;
