import Modal from 'react-modal';
import { useContext } from 'react';
import { MainContainerContext } from '../../../../components/layout/left-nav-layout/left-nav-layout';
import { Gift } from '../../../../gql/queries/generated/graphql';

interface ILearnMoreModalProps {
	open: boolean;
	setOpen: (arg0: boolean) => void;
	gift: Gift;
}

const LearnMoreModal = ({
	open,
	setOpen,
	gift,
}: ILearnMoreModalProps) => {
	const parentContainer = useContext(
		MainContainerContext,
	);

	return (
		<Modal
			onRequestClose={() => setOpen(false)}
			className="customModal"
			overlayClassName="customOverlay"
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			parentSelector={() => parentContainer!.current}
			shouldCloseOnOverlayClick={false}
			isOpen={open}
		>
			<div className="modal-content w-50 mx-auto  border-0 bg-dark text-white">
				<div className="container px-4">
					<div className="row align-items-center">
						<button
							type="button"
							style={{
								position: 'absolute',
								top: 10,
								right: 10,
							}}
							onClick={() => setOpen(false)}
							className="btn-close btn-close-white"
							aria-label="Close"
						></button>
						<div className="col-lg-6 text-center text-lg-start py-4">
							<h2 className="fw-bold lh-1 mb-3">
								{gift.name}
							</h2>
							<p className="col-lg-10">
								{gift.description}
							</p>
						</div>
						<div className="col">
							<img
								src={
									gift.previewImage
										?.url || ''
								}
								className="img-fluid"
							></img>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default LearnMoreModal;
