import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';

import {
	HybridExperience,
	VersionType,
} from '../../../../gql/queries/generated/graphql';

// Defined set of options for the nav menu's active item
// eslint-disable-next-line no-shadow
export enum NavMenuStep {
	AUDIENCE,
	EMAIL,
	FORM,
	GIFT,
	PRINT,
	REVIEW,
}

export const IconStepMap = [
	'fas fa-users',
	'fad fa-inbox-out',
	'fad fa-id-card',
	'fad fa-gifts',
	'fas fa-sticky-note',
];

export type ConfigurationVersionState =
	| VersionType.Versiona
	| VersionType.Versionb;

// If you want to provide information to consumers, it must have its type defined in props
interface ConfigurationStepProviderProps {
	selectedVersion: ConfigurationVersionState;
	setSelectedVersion: Dispatch<
		SetStateAction<ConfigurationVersionState>
	>;
	currentNavStep: NavMenuStep;
	setCurrentNavStep: Dispatch<
		SetStateAction<NavMenuStep>
	>;
	activeButton: HTMLButtonElement | undefined;
	setActiveButton: Dispatch<
		SetStateAction<HTMLButtonElement | undefined>
	>;
	currentExperience: HybridExperience | undefined;
	showVersionB: boolean;
	setShowVersionB: Dispatch<SetStateAction<boolean>>;
	color: string;
	accentColor: string;
	displayOnly: boolean;
}

const ConfigurationStepContext =
	createContext<ConfigurationStepProviderProps>({
		selectedVersion: VersionType.Versiona,
		setSelectedVersion: () => {},
		currentNavStep: NavMenuStep.AUDIENCE,
		setCurrentNavStep: () => {},
		activeButton: undefined,
		setActiveButton: () => {},
		currentExperience: undefined,
		showVersionB: false,
		setShowVersionB: () => {},
		color: 'purple',
		accentColor: 'indigo',
		displayOnly: false,
	});

export const ConfigurationStepProvider = ({
	children,
	currentExperience,
	displayOnly,
}: {
	children: JSX.Element | JSX.Element[];
	currentExperience: HybridExperience;
	displayOnly?: boolean;
}) => {
	const [selectedVersion, setSelectedVersion] =
		useState<ConfigurationVersionState>(
			VersionType.Versiona,
		);

	// The current nav menu bar navigational state, begin on audience
	const [currentNavStep, setCurrentNavStep] =
		useState<NavMenuStep>(NavMenuStep.AUDIENCE);

	const [activeButton, setActiveButton] =
		useState<HTMLButtonElement>();

	const [showVersionB, setShowVersionB] =
		useState<boolean>(false);

	// Determines if a version tab should be shown
	const shouldShowVersion = () => {
		const uniqueGiftVersions =
			(currentExperience.selectedGifts && [
				...new Set(
					currentExperience.selectedGifts.map(
						gift => gift.versionType,
					),
				),
			]) ||
			[];

		if (
			currentExperience.emails.length > 1 ||
			currentExperience.pages.length > 1 ||
			uniqueGiftVersions.length > 1
		) {
			setShowVersionB(true);
		}
	};

	useEffect(() => {
		shouldShowVersion();
	}, []);

	const color = {
		[VersionType.Winner]: 'purple',
		[VersionType.Versiona]: 'purple',
		[VersionType.Versionb]: 'turquoise',
	}[selectedVersion as VersionType];

	const accentColor = {
		[VersionType.Winner]: 'purple',
		[VersionType.Versiona]: 'indigo',
		[VersionType.Versionb]: 'dark-turquoise',
	}[selectedVersion as VersionType];

	return (
		<ConfigurationStepContext.Provider
			value={{
				color,
				accentColor,
				showVersionB,
				setShowVersionB,
				currentExperience,
				selectedVersion,
				setSelectedVersion,
				currentNavStep,
				setCurrentNavStep,
				activeButton,
				setActiveButton,
				displayOnly: displayOnly || false,
			}}
		>
			{children}
		</ConfigurationStepContext.Provider>
	);
};

export const useConfigurationStep = () => {
	const context = useContext(ConfigurationStepContext);
	if (context === undefined) {
		throw new Error(
			'You must be inside of a giftConfigurationProvider to invoke useEmailConfigurationStep',
		);
	}

	return context;
};
