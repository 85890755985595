import TextInput from 'components/inputs/text-input';
import validator from 'validator';
import toast from 'react-hot-toast';
import {
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import { throwIfFalsey } from 'util/thennables/throw-if-falsey';
import { toastToFailure } from '../../trigger-toasts/toast-to-failure';
import {
	santitizeEmail,
	sendSignInLink,
} from '../../../firebaseConfig/auth-functions';
import { toastToSuccess } from '../../trigger-toasts/toast-to-success';

interface SignInProps {
	setSigninEmail: Dispatch<SetStateAction<string>>;
	signInEmail: string;
}

export const SigninForm = ({
	setSigninEmail,
	signInEmail,
}: SignInProps) => {
	const [buttonDisabled, setButtonDisabled] =
		useState<boolean>(false);

	return (
		<div className="container purpose-ui px-5">
			<form
				id="signin-form"
				className="d-flex justify-content-between flex-column py-0 px-4"
				onSubmit={e => {
					setButtonDisabled(true);
					e.preventDefault();
					Promise.resolve(
						validator.isEmail(signInEmail),
					)
						.then(
							throwIfFalsey(
								'Please enter a valid email address',
							),
						)
						.then(() =>
							setSigninEmail(
								santitizeEmail(signInEmail),
							),
						)
						.then(() =>
							// sendSignInLink should handle error throwing
							sendSignInLink(
								santitizeEmail(signInEmail),
							),
						)
						.then(() => {
							setButtonDisabled(false);
							toastToSuccess(
								'An authorization link was sent to your email address!',
							);
						})
						.catch(err => {
							toast(err.message, {
								duration: 4000,
								position: 'top-center',
								className:
									'bg-light text-dark purpose-ui',
								// Emoji
								icon: '🛎️',
								// Aria
								ariaProps: {
									role: 'status',
									'aria-live': 'polite',
								},
							});
							setButtonDisabled(false);
						});
				}}
			>
				<div className="col">
					<TextInput
						className="form-control w-100 mt-0 mb-3 fs-6"
						placeholder="hello@example.com"
						setValue={setSigninEmail}
						value={signInEmail}
						autoComplete="email"
						id="signin-form-email-input"
					/>

					<input
						id="signin-button"
						type="submit"
						disabled={buttonDisabled}
						value={
							buttonDisabled
								? 'Loading...'
								: 'Sign In'
						}
						className={`btn w-100 gradient-button fs-5 text-white border-0 shadow mb-2`}
					/>
				</div>
			</form>
		</div>
	);
};
