import { apolloClient } from 'gql/apollo-client';
import {
	GetHermesUserDocument,
	GetHermesUserQuery,
} from '../queries/generated/graphql';

export const getHermesUser = () =>
	apolloClient.query<GetHermesUserQuery>({
		query: GetHermesUserDocument,
	});
