export default function getBase64(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (!reader.result) {
				throw new Error('Could not be converted');
			}
			resolve(
				reader.result.toString().split(',').pop(),
			);
		};
		reader.onerror = error => reject(error);
	});
}
