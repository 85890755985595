import validator from 'validator';
import { signInWithCustomToken } from '@firebase/auth';
import { apolloClient } from '../gql/apollo-client';
import { SendMagicLinkDocument } from '../gql/queries/generated/graphql';
import { auth } from './config';

interface ISendMagicLinkVariables {
	email: string;
}

export const santitizeEmail = (email: string) =>
	validator.normalizeEmail(email, {
		all_lowercase: true,
	}) || '';

export const sendSignInLink = (email: string) =>
	apolloClient
		.query<boolean, ISendMagicLinkVariables>({
			query: SendMagicLinkDocument,
			variables: {
				email: santitizeEmail(email),
			},
		})
		.then(() => {
			window.localStorage.setItem(
				'emailForSignin',
				santitizeEmail(email),
			);
		})
		.catch(error => {
			const errorMessage = error.message;
			// eslint-disable-next-line no-console
			console.error(
				'sendSignInLinkErrorMessage',
				errorMessage,
			);
			throw new Error(error);
		});

// signs in user via firebase
export const signin = () =>
	// look for apiKey in query params and only
	Promise.resolve()
		.then(() => auth.currentUser)
		.then(currentUser => {
			if (currentUser) {
				throw new Error('Already Logged In');
			}
		})
		.then(
			() =>
				new URLSearchParams(window.location.search),
		)
		.then(queryParams => {
			const token = queryParams.get('token');
			if (!token) {
				throw new Error('No Token  Provided');
			}
			return token;
		})
		.then(token => signInWithCustomToken(auth, token))
		.then(result => {
			window.localStorage.removeItem(
				'emailForSignin',
			);
			return result;
		});

// signs out user via firebase
export const signoutFirebase = () => auth.signOut();
