import { Gift } from '../../../gql/queries/generated/graphql';
import ProductCard from './product-card';

const ProductSelector = ({
	availableGifts,
}: {
	availableGifts: Gift[];
}) => (
	// The total gifts to be shown is determine by a user's entitlement

	<div className="p-3 h-100 w-100">
		<div className="row row-cols-1 row-cols-lg-2 row-cols-xxl-3 row-cols-xxxl-4 mb-3 text-center">
			{availableGifts?.map((gift, giftIndex) => (
				<ProductCard gift={gift} key={giftIndex} />
			))}
		</div>
	</div>
);
export default ProductSelector;
