import { EmailStepValidities } from '../../../../providers/email-step/email-step.provider';
import { TAddEmailRouteLocation } from './location.type';

export type TPreviousButtonState = 'disabled' | 'enabled';

export const assignNextButton = (
	location: TAddEmailRouteLocation,
	validities: EmailStepValidities,
): TPreviousButtonState =>
	validities[location] ? 'enabled' : 'disabled';
