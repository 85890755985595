import {
	FormField,
	Page,
	VersionType,
} from '../../../gql/queries/generated/graphql';
import { isTruthey } from '../../../util/is-truthey';
import { IStepLeft } from './types.validation';

const isCustomizationConfigured = ({
	bodyHtml,
	buttonTerms,
	buttonText,
	footerTerms,
}: Page): boolean =>
	[bodyHtml, buttonTerms, buttonText, footerTerms].every(
		isTruthey,
	);

const isHeaderConfigured = ({
	headerImage,
}: Page): boolean => !!headerImage;

export const areAllFormFieldsFilled = (
	formFields: FormField[],
) =>
	formFields.every(formField => {
		if (formField.isVisible === false) {
			return true;
		}
		return !!formField.placeholder;
	});

export const areAllRequiredFormFieldsVisible = (
	formFields: FormField[],
) =>
	!formFields.some(formField => {
		if (formField.isRequired) {
			return !formField.isVisible;
		}
		return false;
	});

export const areFormFieldsConfigured = (
	formFields: FormField[],
): boolean =>
	formFields &&
	formFields.length > 0 &&
	areAllRequiredFormFieldsVisible(formFields) &&
	areAllFormFieldsFilled(formFields);

export const getPageSteps = (
	pages: Page[],
): IStepLeft[] => {
	const steps: IStepLeft[] = [];

	pages.forEach(page => {
		steps.push({
			message: 'Customization Configured',
			complete: isCustomizationConfigured(page),
			version: page.version || VersionType.Versiona,
		});

		steps.push({
			message: 'Image Configured',
			complete: isHeaderConfigured(page),
			version: page.version || VersionType.Versiona,
		});

		steps.push({
			message: 'Mapped Fields Configured',
			complete: areFormFieldsConfigured(
				page.formFields,
			),
			version: page.version || VersionType.Versiona,
		});
	});

	return steps;
};
