import DashboardLayout from 'components/layout/dashboard-layout/dashboard-layout';
import LeftNav from 'components/layout/left-nav-layout/left-nav-layout';

/**  Also known as the dashboard */
export default function HomePage() {
	return (
		<div id="home-wrapper">
			<LeftNav>
				<DashboardLayout />
			</LeftNav>
		</div>
	);
}
