import { useEffect, useRef, MutableRefObject } from 'react';

interface CommentProps {
	comment: string;
}

// Allows us to write a comment in react
const ReactComment = ({ comment }: CommentProps) => {
	const el = useRef() as MutableRefObject<HTMLDivElement>;
	useEffect(() => {
		el.current.outerHTML = `<!-- ${comment} -->`;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <div ref={el} />;
};

export default ReactComment;
