import {
	faCheckCircle,
	faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../colors.enum';

const TodoButton = ({
	showTodo,
	onTodo,
}: {
	showTodo: boolean;
	onTodo: () => void;
}) => (
	<>
		<div
			style={{
				cursor: showTodo ? 'pointer' : 'default',
			}}
			className={`${
				showTodo ? 'todo-alert' : 'todo-complete'
			} h-100 d-flex align-items-center justify-content-center bg-black px-2 rounded`}
			onClick={() => {
				if (showTodo) {
					onTodo();
				}
			}}
		>
			{showTodo && (
				<>
					<div className="m-2">To Do</div>
					<FontAwesomeIcon
						icon={faExclamationCircle}
						color={Colors.red}
						focusable="true"
					/>
				</>
			)}
			{!showTodo && (
				<>
					<div
						style={{
							margin: 10,
						}}
					>
						Ready For Next Step
					</div>
					<FontAwesomeIcon
						icon={faCheckCircle}
						color={Colors.green}
						focusable="true"
					/>
				</>
			)}
		</div>
	</>
);

export default TodoButton;
