import { useState } from 'react';
import { HermesColor } from '../../../colors.enum';
import {
	Asset,
	AssetType,
	useGetAssetsByEntitlementAndAssetTypesQuery,
	VersionType,
} from '../../../gql/queries/generated/graphql';
import { useConfigurationStep } from './configuration-form-step/configuration-form-step-provider';
import FormCustomizationBrowser from './form-customization/form-customization';
import ConfigureFormMappingTab from './form-mapping/configure-form-mapping-tab';
import BrowserBottomNavbar from './navbar/browser-bottom-navbar';
import SelectImagesTab from './select-images-tab';

const ConfigureFormBrowser = () => {
	const { data } =
		useGetAssetsByEntitlementAndAssetTypesQuery({
			variables: {
				assetTypes: [AssetType.Pageheaderimage],
			},
		});

	// Todo! Delete this, refactor components
	const [activeMenuItem, setActiveMenuItem] =
		useState<HTMLButtonElement>();

	const assets =
		(data?.GetAssetsByEntitlementAndAssetTypes ||
			[]) as Asset[];

	const { selectedVersion, showSettings, browserStep } =
		useConfigurationStep();

	const assignHighlightColor = (): HermesColor => {
		if (showSettings) {
			return 'gray';
		}
		if (selectedVersion === VersionType.Versionb) {
			return 'turquoise';
		}
		return 'purple';
	};

	return (
		<>
			<div
				className={`border 
			border-${assignHighlightColor()} 
			border-5 w-100  d-flex flex-grow-1`}
				style={{
					borderBottomLeftRadius: '10px',
					borderBottomRightRadius: '10px',
					overflowX: 'hidden',
				}}
			>
				{
					<div
						className="w-100 h-100"
						style={{
							position: 'relative',
							overflow: 'hidden',
						}}
					>
						<div
							id="overlay"
							className="w-100 h-100 d-flex flex-column justify-content-end  rounded "
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								// Without this property you couldn't click on anything inside of the pointerEvent
								pointerEvents: 'none',
							}}
						>
							<BrowserBottomNavbar
								setActive={
									setActiveMenuItem
								}
								showSettings={showSettings}
							/>
						</div>
						{showSettings && (
							<div>
								Settings Browser Tab Goes
								here
							</div>
						)}
						{!showSettings && (
							<>
								{browserStep ===
									'loading' && (
									// todo: add a loading wheel
									<div className="text-white">
										Loading
									</div>
								)}

								{browserStep ===
									'images' && (
									<SelectImagesTab
										assets={assets}
									/>
								)}

								{browserStep ===
									'customize' && (
									<FormCustomizationBrowser />
								)}

								{browserStep ===
									'mapping' && (
									<ConfigureFormMappingTab />
								)}
							</>
						)}
					</div>
				}
			</div>
		</>
	);
};

export default ConfigureFormBrowser;
