import { useState } from 'react';
import { useHistory } from 'react-router';
import {
	HybridExperience,
	Page,
} from '../../../gql/queries/generated/graphql';
import messagebox from '../../../providers/alert-provider/messagebox';
import PrevAndNextContainer from '../components/prev-and-next-container';
import { useCurrentExperience } from '../current-experience.provider';
import { getPageSteps } from '../validations/page.validations';
import { useConfigurationStep } from './configuration-form-step/configuration-form-step-provider';
import ConfigureFormBrowser from './configure-form-browser';
import ConfigureFormTabBar from './form-browser-tab-bar';
import PageRender from './page-render';

const FormCustomization = () => {
	const { data: currentExperienceData } =
		useCurrentExperience();
	const history = useHistory();
	const experience =
		currentExperienceData?.GetHybridExperienceById;
	const pageConfigured = experience?.pageConfigured;
	const { setConfigurationStep } = useConfigurationStep();
	const pages = experience?.pages || [];
	const pageSteps = getPageSteps((pages as Page[]) || []);
	const showTodo = !pageSteps.every(
		page => page.complete,
	);

	return (
		<>
			<div className="d-flex flex-column flex-grow-1">
				<div className="row w-100 h-100 mx-auto px-0 d-flex flex-grow-1 mt-2">
					<div className="col-6 col-xxxl-5 p-2 d-flex flex-column">
						<ConfigureFormTabBar
							showVersions={pages.length > 1}
							showSettings={true}
							showTodo={showTodo}
							onTodo={() => {
								messagebox.openTodoList({
									tasks: pageSteps,
									title: 'Page Customization: ',
									subtitle:
										'Please complete all of the configuration steps below to continue.',
									headerA: 'A',
									headerB: 'B',
								});
							}}
						/>
						<ConfigureFormBrowser />
					</div>

					<div
						className="col-6 col-xxxl-7 p-2"
						style={{
							flex: '1',
							flexDirection: 'column',
							display: 'flex',
						}}
					>
						<PageRender />
					</div>
				</div>
			</div>

			<PrevAndNextContainer
				experience={experience as HybridExperience}
				nextButtonLabel="Choose Gift"
				nextOnClick={() => {
					history.push(
						`/create-experience/${
							experience?.id || ''
						}/choose-gift`,
					);
				}}
				prevOnClick={() => {
					setConfigurationStep('select');
				}}
				nextButtonState={
					pageConfigured
						? 'next-enabled'
						: 'next-disabled'
				}
				prevButtonLabel={'Select Template'}
			/>
		</>
	);
};

export default FormCustomization;
