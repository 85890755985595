const UserAggregateCard = ({
	title,
	numberDisplay,
	color,
}: {
	title: string;
	numberDisplay: number;
	color: string;
}) => (
	<div
		className="col d-flex  flex-column text-center"
		data-cy="aggregateCard"
	>
		<div
			className={`w-100  py-5 border border-1 border-${color} rounded rounded-3 h-100 d-flex`}
		>
			<div className="my-auto mx-auto">
				<h3 className="text-white">{title}</h3>
				<h1
					className={`text-${color}`}
					data-cy="userCount"
				>
					{numberDisplay}
				</h1>
			</div>
		</div>
	</div>
);

export default UserAggregateCard;
