import { useEmailStep } from '../../../../providers/email-step/email-step.provider';
import LoadingScreen from '../../../loading-screen/loading-screen';
import ConfigureEmailTemplate from '../components/configure-email/configure-email-layout';
import SelectEmailTemplate from '../select-email-template';

const AddEmailRouter = () => {
	const { emailStep } = useEmailStep();

	return (
		<div className="h-100 d-flex flex-column">
			{emailStep === 'select-template' && (
				<SelectEmailTemplate />
			)}
			{emailStep === 'configure-email' && (
				<ConfigureEmailTemplate />
			)}
			{emailStep === 'loading' && <LoadingScreen />}
		</div>
	);
};

export default AddEmailRouter;
