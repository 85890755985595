import LeftNav from '../../components/layout/left-nav-layout/left-nav-layout';
import UnderConstruction from '../../components/general-ui/under-construction';
import ProfilePanel from '../../components/general-ui/profile-panel';
import { useAuth } from '../../providers/auth/auth-provider';
// eslint-disable-next-line arrow-body-style
const KnowledgeBasePage = () => {
	const { user } = useAuth();
	return (
		<div className="page h-100">
			<LeftNav>
				<div
					className="d-flex flex-column h-100 w-100 px-4 pb-4"
					style={{
						backgroundColor: '#171717',
						overflowX: 'hidden',
					}}
				>
					<header className="d-flex justify-content-between w-100 mx-auto py-3">
						<h2
							className="text-white"
							style={{ fontWeight: 900 }}
						>
							Knowledge Base
						</h2>
						{user && (
							<ProfilePanel user={user} />
						)}
					</header>
					<UnderConstruction feature="Knowledge Base" />
				</div>
			</LeftNav>
		</div>
	);
};

export default KnowledgeBasePage;
