import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { intersectionBy } from 'lodash';
import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import Colors from '../../../colors.enum';
import {
	CallBackObject,
	FieldMapping,
	FieldMappingRowProps,
	IDynamicMappingOption,
} from '../../../components/field-mapping/field-mapping';
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import { toastToSuccess } from '../../../components/trigger-toasts/toast-to-success';
import {
	AudienceListHeader,
	FormField,
	FormFieldType,
	GetPodProofDocument,
	PrintOnDemandField,
	PrintOnDemandFieldInput,
	useUpdateHybridExperiencePrintOnDemandFieldsMutation,
	VersionType,
} from '../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../current-experience.provider';

const ConfigurePrintForm = () => {
	const {
		data: experienceData,
		refetch: refetchCurrentExperience,
	} = useCurrentExperience();

	const [updatePODFields] =
		useUpdateHybridExperiencePrintOnDemandFieldsMutation(
			{
				refetchQueries: [GetPodProofDocument],
			},
		);

	const experience =
		experienceData?.GetHybridExperienceById;

	const pages = experience?.pages;

	const audienceList = experience?.audienceList;

	const audienceListHeaders =
		audienceList?.audienceListHeaders;

	const podFields = experience?.printOnDemandFields;

	const areFormsVersioned = !!(pages && pages.length > 1);

	const versionAPage =
		pages &&
		pages.find(
			page => page.version === VersionType.Versiona,
		);

	const versionBPage =
		pages &&
		pages.find(
			page => page.version === VersionType.Versionb,
		);

	const getFieldsInBothPages = () => {
		const fieldsInVersionA = versionAPage?.formFields;
		const fieldsInVersionB = versionBPage?.formFields;
		const fieldsInBoth: FormField[] = [];
		// The field must exist in both versions of the pages
		// And it must be required in both versions
		if (fieldsInVersionA && fieldsInVersionB) {
			fieldsInVersionA.forEach(aField => {
				if (aField.isRequired) {
					const exists = fieldsInVersionB.find(
						bField =>
							bField.type === aField.type,
					);
					if (exists && exists.isRequired) {
						fieldsInBoth.push(aField);
					}
				}
			});
		}
		return fieldsInBoth;
	};

	let formFields = areFormsVersioned
		? getFieldsInBothPages()
		: versionAPage?.formFields;

	formFields = formFields?.filter(
		field => field.isRequired,
	);

	const [loading, setLoading] = useState<boolean>(false);

	const formFieldSelectOptions: IDynamicMappingOption[] =
		formFields?.map(formField => ({
			label: `Form: ${formField.type}`,
			value: formField.type,
			type: 'FORM',
			sampleValue: undefined,
		})) || [];

	const audienceListSelectOptions: IDynamicMappingOption[] =
		(audienceListHeaders &&
			audienceListHeaders.map(audienceListHeader => ({
				label: `List: ${audienceListHeader?.headerValue}`,
				type: 'LIST',
				value:
					audienceListHeader?.headerValue || '',
				sampleValue:
					audienceListHeader?.sampleValue || '',
			}))) ||
		[];

	// All options is a list of all form fields and all audience list headers, used when creating select options for each mapping row
	const allOptions = [
		...formFieldSelectOptions,
		...audienceListSelectOptions,
	];

	// These are the fields that get mapped and then sent to the backend, 1 mapped print field per row
	const [mappedPrintFields, setMappedPrintFields] =
		useState<PrintOnDemandFieldInput[]>();

	const handleSubmit = () => {
		if (mappedPrintFields) {
			Promise.resolve()
				.then(() => {
					setLoading(true);
				})
				.then(() =>
					updatePODFields({
						variables: {
							hybridExperienceId:
								(experience &&
									experience.id) ||
								'',
							printOnDemandFields:
								mappedPrintFields,
						},
					}),
				)
				.then(() => refetchCurrentExperience())
				.then(() => {
					toastToSuccess(
						'Successfully updated POD fields',
					);
				})
				.catch(err => {
					toastToFailure(
						err.message ||
							'Something went wrong',
					);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	// The label for selected option depends on if it is a form field or audience list header, so we need to check this and generate an appropriate mapping option
	const generateSelectedOption: (
		podField: PrintOnDemandField,
	) => IDynamicMappingOption = (
		podField: PrintOnDemandField,
	) => {
		const selectedOption: IDynamicMappingOption = {
			value: '',
			label: 'No Selection',
			sampleValue: undefined,
		}; // no sample for POD fields
		if (podField.audienceListHeader) {
			selectedOption.label = `List: ${podField.audienceListHeader.headerValue}`;
			selectedOption.value =
				podField.audienceListHeader?.headerValue ||
				'';
		}
		if (podField.formField) {
			selectedOption.label = `Form: ${podField.formField.type}`;
			selectedOption.value = podField.formField.type;
		}
		return selectedOption;
	};

	// This converts pod fields into the type that the row mapping component expects
	const fieldMappingRows = podFields?.map(podField => ({
		uniqueKey: podField.name,
		isRequired: podField.isRequired,
		rowLabel: podField.name,
		staticValue: podField.staticValue || '',
		selectedOption: generateSelectedOption(
			podField as PrintOnDemandField,
		),
	}));

	// When the static value or selected option changes, we need to update the mapped print fields
	const onMappingChange = (rows: CallBackObject[]) => {
		const rowsToInput: PrintOnDemandFieldInput[] =
			rows.map(row => {
				// if a form field is selected we need to set a form field type in the update
				let type;
				if (
					row.selectedOption &&
					row.selectedOption.label.startsWith(
						'Form',
					)
				) {
					type = 'FORM';
				}
				// if an audience list header is picked we need to set the header value
				if (
					row.selectedOption &&
					row.selectedOption.label.startsWith(
						'List',
					)
				) {
					type = 'LIST';
				}
				const audienceListHeader:
					| AudienceListHeader
					| undefined =
					type === 'LIST' && audienceListHeaders
						? audienceListHeaders.find(
								header =>
									header &&
									row.selectedOption
										?.value ===
										header.headerValue,
						  ) || undefined
						: undefined;

				const formFieldType:
					| FormFieldType
					| undefined =
					type === 'FORM' && formFields
						? formFields.find(
								formField =>
									formField.type ===
									row.selectedOption
										?.value,
						  )?.type || undefined
						: undefined;

				return {
					audienceListHeader:
						audienceListHeader?.headerValue,
					type: formFieldType,
					name: row.rowLabel || '',
					staticValue:
						row.staticValue || undefined,
				};
			});
		setMappedPrintFields(rowsToInput);
	};

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}
			className="p-3 w scrollbar"
		>
			<h5>Customize Printed Material</h5>
			<p className="fw-light mb-2">
				Leverage the dynamic data from your audience
				list to create a personalized print
				experience
			</p>
			{podFields && fieldMappingRows && (
				<FieldMapping
					fieldLabel="Field"
					staticLabel="Static Value"
					mapLabel="Mapping"
					highlightColor={'white'}
					version={VersionType.Versiona}
					selectOptions={allOptions}
					fieldMappingRows={fieldMappingRows}
					callback={onMappingChange}
				/>
			)}
			<button
				className="btn btn-md btn-purple my-3 text-white"
				disabled={loading}
			>
				{loading ? (
					<FontAwesomeIcon
						icon={faSpinner}
						spin={true}
					/>
				) : (
					'Update'
				)}
			</button>
		</form>
	);
};

export default ConfigurePrintForm;
