import { HybridExperience } from '../../../gql/queries/generated/graphql';
import CreateExperienceLayout from '../create-experience-layout';
import { CreateExperienceSubroute1 } from '../create-experience-types';
import { PrintConfigurationStepProvider } from './configure-print-configuration-step-provider';
import PrintCustomization from './print-customization';

const ConfigurePrintSubroute = ({
	experience,
	currentStep,
}: {
	experience: HybridExperience;
	currentStep: CreateExperienceSubroute1;
}) => (
	<CreateExperienceLayout
		currentStep={currentStep}
		experience={experience}
	>
		<PrintConfigurationStepProvider>
			<PrintCustomization />
		</PrintConfigurationStepProvider>
	</CreateExperienceLayout>
);

export default ConfigurePrintSubroute;
