import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import Colors from '../../../../colors.enum';

import HighlightedBox from '../highlighted-box';
import { useConfigurationStep } from '../review-step/review-step-provider';

const TestEvaluationCard = () => {
	const date = dayjs(Date.now()).format('MMM DD, YYYY');
	const testEvaluationLengthDays = 3;

	const { showVersionB } = useConfigurationStep();

	return (
		<HighlightedBox
			color={Colors.orange}
			style={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
			}}
		>
			<div className="w-100 d-flex text-white">
				<FontAwesomeIcon
					icon={faChartPie}
					color={'white'}
					size="2x"
				/>
				<div className="d-flex">
					<h5 className="ms-2 my-auto">
						Test Evaluation
					</h5>
				</div>
			</div>
			<p className="text-white">
				Length:{' '}
				{showVersionB
					? `${testEvaluationLengthDays} Days`
					: `Experience not versioned`}
			</p>
		</HighlightedBox>
	);
};

export default TestEvaluationCard;
