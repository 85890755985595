import { HybridExperience } from '../../../gql/queries/generated/graphql';
import CreateExperienceLayout from '../create-experience-layout';
import { CreateExperienceSubroute1 } from '../create-experience-types';
import { ConfigurationStepProvider } from './configuration-form-step/configuration-form-step-provider';
import ConfigureFormRouter from './configure-form-routes';

const ConfigureFormSubroute = ({
	experience,
	currentStep,
}: {
	experience: HybridExperience;
	currentStep: CreateExperienceSubroute1;
}) => (
	<CreateExperienceLayout
		currentStep={currentStep}
		experience={experience}
	>
		<ConfigurationStepProvider>
			<ConfigureFormRouter />
		</ConfigurationStepProvider>
	</CreateExperienceLayout>
);

export default ConfigureFormSubroute;
