import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useQuery } from '@apollo/client';
import {
	HybridExperience,
	RenderExperienceEmailVersionDocument,
	VersionType,
} from '../../../../../../gql/queries/generated/graphql';

export type EmailConfigurationStep =
	| 'select-image'
	| 'email-customization'
	| 'field-mapping'
	| 'loading';

export type EmailVersionState =
	| VersionType.Versiona
	| VersionType.Versionb;

interface EmailConfigurationStepProviderProps {
	selectedVersion: EmailVersionState;
	setSelectedVersion: Dispatch<
		SetStateAction<EmailVersionState>
	>;
	emailConfigurationStep: EmailConfigurationStep;
	setEmailConfigurationStep: Dispatch<
		SetStateAction<EmailConfigurationStep>
	>;
	emailHtml: string;
	setEmailVersionOnHtml: SetStateAction<
		Dispatch<VersionType>
	>;
	showSettings: boolean;
	setShowSettings: (arg0: boolean) => void;
}

const EmailConfigurationStepContext =
	createContext<EmailConfigurationStepProviderProps>({
		emailConfigurationStep: 'select-image',
		setEmailConfigurationStep: () => {},
		selectedVersion: VersionType.Versiona,
		setSelectedVersion: () => {},
		emailHtml: '',
		setEmailVersionOnHtml: () => {},
		showSettings: false,
		setShowSettings: () => {},
	});

export const EmailConfigurationStepProvider = ({
	children,
	experience,
}: {
	children: JSX.Element | JSX.Element[];
	experience: HybridExperience | undefined;
}) => {
	const [
		emailConfigurationStep,
		setEmailConfigurationStep,
	] = useState<EmailConfigurationStep>('select-image');

	const [showSettings, setShowSettings] =
		useState<boolean>(false);

	const [selectedVersion, setSelectedVersion] =
		useState<EmailVersionState>(VersionType.Versiona);

	const [emailVersion, setEmailVersion] =
		useState<VersionType>(VersionType.Versiona);

	const experienceId = experience?.id;

	const variables = {
		hybridExperienceId: experienceId || '',
		emailVersion: selectedVersion,
	};

	// using useQuery instead of useRenderExperienceEmailVersionQuery so we can set fetch policy
	const { data, refetch } = useQuery(
		RenderExperienceEmailVersionDocument,
		{ fetchPolicy: 'no-cache', variables },
	);

	const emailHtml = data?.RenderHybridExperienceEmail;

	useEffect(() => {
		if (!emailHtml || emailHtml.length === 0) {
			refetch();
		}
	}, [experience]);

	return (
		<EmailConfigurationStepContext.Provider
			value={{
				emailConfigurationStep,
				setEmailConfigurationStep,
				selectedVersion,
				setSelectedVersion,
				emailHtml: emailHtml || '',
				setEmailVersionOnHtml: setEmailVersion,
				setShowSettings,
				showSettings,
			}}
		>
			{children}
		</EmailConfigurationStepContext.Provider>
	);
};

export const useEmailConfigurationStep = () => {
	const context = useContext(
		EmailConfigurationStepContext,
	);
	if (!context) {
		throw new Error(
			'You must be inside of a EmailConfigurationProvider to invoke useEmailConfigurationStep',
		);
	}

	return context;
};
