import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface StringMap<T> {
	[x: string]: T;
}
/*
 * Simple editor component that takes placeholder text as a prop
 */
class Editor extends React.Component<
	Record<string, unknown>,
	{
		editorHtml: string;
		theme: string;
		placeholder: string;
		modules: StringMap<string>;
	}
> {
	constructor(props: {
		placeholder: string;
		currentHtml: string;
		onChange: React.Dispatch<
			React.SetStateAction<undefined>
		>;
	}) {
		super(props);
		this.state = {
			placeholder: props.placeholder,
			editorHtml: props.currentHtml || '',
			theme: 'snow',
			modules: Editor.modules,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	static modules: StringMap<string> = {
		modules: '',
	};

	static formats: StringMap<string> = {
		formats: '',
	};

	handleChange(html: any) {
		this.setState({ editorHtml: html });
	}

	handleThemeChange(newTheme: any) {
		// eslint-disable-next-line no-param-reassign
		if (newTheme === 'core') newTheme = null;
		this.setState({ theme: newTheme });
	}

	componentDidUpdate(prevProps: {
		placeholder: string;
		currentHtml: string;
		onChange: React.Dispatch<
			React.SetStateAction<undefined>
		>;
	}) {
		if (
			this.props.currentHtml !== prevProps.currentHtml
		) {
			// Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
			this.setState({
				editorHtml: this.props
					.currentHtml as string,
			});
		}
	}

	render() {
		return (
			<div>
				<ReactQuill
					theme={this.state.theme}
					onChange={this.handleChange}
					value={this.state.editorHtml}
					modules={Editor.modules}
					bounds={'.app'}
					placeholder={this.state.placeholder}
				/>
			</div>
		);
	}
}

export default Editor;
