import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { titleCase } from 'voca';
import {
	useGetDraftExperiencesQuery,
	HybridExperienceStatus,
	useDeleteHybridExperienceMutation,
	GetDraftExperiencesDocument,
	useScheduleHybridExperienceMutation,
} from '../../../gql/queries/generated/graphql';
import { toastToNotify } from '../../trigger-toasts/toast-to-notify';
import promptModal from '../../../providers/alert-provider/prompt-modal';
import { toastToSuccess } from '../../trigger-toasts/toast-to-success';
import Colors from '../../../colors.enum';
import { toastToFailure } from '../../trigger-toasts/toast-to-failure';

interface IExperienceDraftsProps {
	entitlementId: string;
}

const ExperienceDraftsPanel = ({
	entitlementId,
}: IExperienceDraftsProps) => {
	const { data, refetch } = useGetDraftExperiencesQuery({
		variables: {
			statuses: [
				HybridExperienceStatus.Created,
				HybridExperienceStatus.Ready,
			],
		},
		fetchPolicy: 'network-only',
	});
	const hybridExperiences =
		data?.GetHybridExperiencesByEntitlementStatus;

	const [deleteHybridExperienceMutation] =
		useDeleteHybridExperienceMutation();

	const [scheduleHybridExperience] =
		useScheduleHybridExperienceMutation();

	const [disabled, setDisabled] = useState(false);
	const history = useHistory();

	const deleteClick = (
		experienceId: string,
		experienceName: string,
	) => {
		if (experienceId && !disabled) {
			setDisabled(true);
			Promise.resolve()
				.then(
					promptModal(
						`Delete ${experienceName}`,
						'Not Deleted',
					),
				)
				.then(() =>
					deleteHybridExperienceMutation({
						variables: {
							hybridExperienceId:
								experienceId,
						},
					}),
				)
				.then(() =>
					toastToSuccess(
						`Deleting your experience...`,
					),
				)
				.then(() => refetch())
				.catch(() => {
					toastToNotify('Experience Not Deleted');
				})
				.finally(() => {
					setDisabled(false);
				});
		}
	};

	return (
		<>
			<div
				id="dashboard-experience-drafts-panel"
				className="text-white mx-3 px-4 card scrollbar bg-dark border border-purple border-2"
				style={{
					borderRadius: '10px',
					overflowX: 'auto',
				}}
			>
				<div
					className="d-flex justify-content-center align-items-center p-3 mt-4"
					style={{ overflow: 'hidden' }}
				>
					<h1 className="py-2 fs-3 text-center">
						Experience Drafts
						<i className="ms-3 fa fa-edit fa-1x"></i>
					</h1>
				</div>
				<div className="table-responsive">
					<table className="table align-middle table-row-bordered table-row-dashed gy-5 scrollbar">
						<thead className="fs-6">
							<tr
								style={{
									position: 'sticky',
									top: 0,
									zIndex: 1,
								}}
							>
								<th>
									<p className="mb-0 text-white">
										Status
									</p>
								</th>
								<th>
									<p className="mb-0 text-white">
										Experience Name
									</p>
								</th>
								<th>
									<p className="mb-0 text-white">
										Created By
									</p>
								</th>
								<th>
									<p className="mb-0 text-white">
										Audience
									</p>
								</th>
								<th>
									<p className="mb-0 text-white">
										Created
									</p>
								</th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody
							className="h-100 scrollbar text-gray-800"
							style={{
								borderTop: 'none',
							}}
						>
							{hybridExperiences ? (
								hybridExperiences.map(
									experience => {
										const status =
											experience?.status;
										const audienceCount =
											experience?.audienceCount;
										const createdDateTime =
											experience?.createdDateTime;

										const name =
											experience?.name ||
											'';

										const firstName =
											experience
												?.createdBy
												.firstName;
										const lastName =
											experience
												?.createdBy
												.lastName;

										const createdBy = `${firstName} ${lastName}`;
										const parsedDate =
											dayjs(
												createdDateTime,
											).format(
												'MM/DD/YY',
											);

										const launchClickHandler =
											() => {
												Promise.resolve()
													.then(
														() => {
															setDisabled(
																true,
															);
														},
													)
													.then(
														() =>
															scheduleHybridExperience(
																{
																	variables:
																		{
																			hybridExperienceId:
																				experience?.id ||
																				'',
																		},
																	refetchQueries:
																		[
																			GetDraftExperiencesDocument,
																		],
																},
															),
													)

													.then(
														() => {
															toastToSuccess(
																'Your experience has been launched',
															);
														},
													)
													.then(
														() =>
															refetch(),
													)
													.catch(
														err => {
															toastToFailure(
																'Something went wrong.',
															);
														},
													)
													.finally(
														() => {
															setDisabled(
																false,
															);
														},
													);
											};

										const experienceId =
											experience?.id;

										const editClickHandler =
											() => {
												// default to the last step, the router will re-reccomend an earlier route
												history.push(
													`/create-experience/${
														experienceId ||
														''
													}/review`,
												);
											};

										return (
											<tr
												key={
													experience?.id
												}
												className="align-middle text-gray-800 fs-6"
											>
												<td
													className={`text-${
														status ===
														'CREATED'
															? 'white'
															: 'green'
													}`}
												>
													{titleCase(
														status,
													)}
												</td>
												<td className="border-none">
													{name}
												</td>
												<td className="border-none">
													{
														createdBy
													}
												</td>
												<td className="border-none">
													{
														audienceCount
													}
												</td>
												<td className="border-none">
													{
														parsedDate
													}
												</td>
												<td className="text-center border-none">
													{status ===
														HybridExperienceStatus.Ready && (
														<button
															className="gradient-button btn text-white fw-bold btn-lg mx-auto border-0"
															disabled={
																disabled
															}
															onClick={
																launchClickHandler
															}
														>
															<p className=" fw-light fs-6 mb-0">
																<i className="d-sm-none d-xl-inline fa fa-rocket-launch color-white fa-md me-2" />
																Launch
															</p>
														</button>
													)}
												</td>
												<td className="border-none">
													<button
														style={{
															float: 'right',
														}}
														className="button btn btn-outline btn-outline-turquoise"
														onClick={
															editClickHandler
														}
													>
														Edit
													</button>
												</td>
												<td className="border-none">
													<button
														style={{
															float: 'left',
														}}
														className="button btn btn-outline  btn-outline-red trash-btn"
														onClick={() =>
															deleteClick(
																experienceId ||
																	'',
																experience?.name ||
																	'',
															)
														}
													>
														<i
															className="fa fa-lg fa-trash py-1 px-2 color-white"
															style={{
																cursor: 'pointer',
															}}
														/>
													</button>
												</td>
											</tr>
										);
									},
								)
							) : (
								<>
									<tr className="text-gray fs-6 border-0">
										<td
											colSpan={8}
											className="customTd border-0"
										>
											<div className="text-center m-4">
												<h5 className="pb-3">
													Loading...
												</h5>
												<span></span>
											</div>
										</td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default ExperienceDraftsPanel;
