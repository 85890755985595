import { createContext, useContext, useState } from 'react';
import { HybridExperience } from '../../gql/queries/generated/graphql';
import { TAddEmailRouteLocation } from '../../routes/create-experience-page/2-add-email/router/location.type';
import { maxEmailStep } from './max-email-step';

export type EmailStepValidities = Record<
	TAddEmailRouteLocation,
	boolean
>;

const EmailStepContext = createContext<{
	emailStep: TAddEmailRouteLocation;
	setEmailStep: (arg0: TAddEmailRouteLocation) => void;
	emailStepValidities: Record<
		TAddEmailRouteLocation,
		boolean
	>;
	setValidity: (
		arg0: TAddEmailRouteLocation,
		arg1: boolean,
	) => void;
}>({
	emailStep: 'select-template',

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setEmailStep: (_newStep: TAddEmailRouteLocation) => {},
	emailStepValidities: {
		'configure-email': true,
		'select-template': true,
	} as EmailStepValidities,
	setValidity: (
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		key: TAddEmailRouteLocation,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		value: boolean,
	) => {},
});

export const EmailStepProvider = ({
	children,
	experience,
}: {
	children: JSX.Element | JSX.Element[] | undefined;
	experience: HybridExperience;
}) => {
	const maxStep = maxEmailStep(experience);

	const [emailStep, setEmailStep] =
		useState<TAddEmailRouteLocation>(maxStep);

	const [emailStepValidities, setEmailStepValidities] =
		useState<EmailStepValidities>({
			'configure-email': false,
			'select-template': false,
			loading: true,
		});

	const setValidity = (
		key: TAddEmailRouteLocation,
		value: boolean,
	) => {
		setEmailStepValidities({
			...emailStepValidities,
			[key]: value,
		});
	};

	return (
		<EmailStepContext.Provider
			value={{
				emailStep,
				setEmailStep,
				emailStepValidities,
				setValidity,
			}}
		>
			{children}
		</EmailStepContext.Provider>
	);
};

export const useEmailStep = () => {
	const context = useContext(EmailStepContext);
	if (!context) {
		throw new Error(
			'useEmailStep Hook cannot be used outside of a EmailStepProvider',
		);
	}
	return context;
};
