import { FormEvent, useState } from 'react';
import { useHistory } from 'react-router';
import { useSendContactAlertMutation } from '../../gql/queries/generated/graphql';
import NimblFooter from '../footer/nimbl-footer';
import ExternalHeader from '../header/external-header';
import { toastToFailure } from '../trigger-toasts/toast-to-failure';
import PuzzlePiece from './assets/PuzzleMan.svg';

import NimblLogoWhite from '../../assets/Nimbl-logos/Nimbl_Primary-Horizontal-White.png';

const ContactUs = () => {
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [message, setMessage] = useState('');
	const [sendContactAlert] =
		useSendContactAlertMutation();
	const [disabled, setDisabled] = useState(false);

	const history = useHistory();

	const onFormSubmit = (
		event: FormEvent<HTMLFormElement>,
	) => {
		event.preventDefault();
		Promise.resolve()
			.then(() => setDisabled(true))
			.then(() =>
				sendContactAlert({
					variables: { email, name, message },
				}),
			)
			.then(() => history.push('/thank-you'))
			.catch(err => {
				console.error(err);
				toastToFailure(
					'Failed to send message, please try again later.',
				);
			})
			.finally(() => setDisabled(false));
	};

	return (
		<>
			<body className="purpose-ui">
				<div className="bg-primary">
					<ExternalHeader />
				</div>
				<div className="main-content d-flex bg-primary flex-column h-100">
					<section
						className="slice my-auto pt-4 bg-transparent"
						data-offset-top="#header-main"
					>
						<div className="container position-relative zindex-100">
							<div className="row mx-auto w-100">
								<div className="col-lg-5">
									<h1 className="text-white">
										Ready to get started
										with Nimbl?
									</h1>
									<h4 className="fw-light text-white mt-3">
										Fill out the form
										below and we{`'`}ll
										be in touch to
										discuss if Nimbl is
										right for your
										organization.
									</h4>
									<form
										className="mt-4"
										onSubmit={
											onFormSubmit
										}
									>
										<div className="form-group">
											<label className="sr-only">
												Your name
											</label>
											<input
												className="form-control form-control-md"
												placeholder="Your name"
												value={name}
												onChange={e =>
													setName(
														e
															.target
															.value,
													)
												}
												type="text"
												required
											/>
										</div>
										<div className="form-group">
											<label className="sr-only">
												Email
												address
											</label>
											<input
												required
												className="form-control form-control-md"
												placeholder="Email address"
												type="email"
												value={
													email
												}
												onChange={e =>
													setEmail(
														e
															.target
															.value,
													)
												}
											/>
										</div>
										<div className="form-group">
											<label className="sr-only">
												Type your
												message
											</label>
											<textarea
												required
												className="form-control form-control-md textarea-autosize"
												rows={4}
												placeholder="Type your message"
												value={
													message
												}
												onChange={e =>
													setMessage(
														e
															.target
															.value,
													)
												}
											></textarea>
										</div>
										<button
											disabled={
												disabled
											}
											type="submit"
											className="btn btn-light rounded-pill mt-3"
										>
											Send message
										</button>
									</form>
								</div>
								<div className="col-lg-7">
									<img
										alt="Puzzle Piece"
										src={PuzzlePiece}
										className="img-fluid img-center"
									/>
								</div>
							</div>
						</div>
					</section>
				</div>
				<NimblFooter
					footerLogo={NimblLogoWhite}
					footerType="dark"
					backgroundColor="black"
					textColor="white"
				/>
			</body>
		</>
	);
};

export default ContactUs;
