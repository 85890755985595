import { InputHTMLAttributes } from 'react';

const defaultProps = {
	setValue: null as null | ((arg0: string) => void),
	type: 'text' as 'text' | 'email' | 'password',
};

const TextInput = (
	// Lets you get props from a regular html input element
	// for example you can pass in placeholder, name, className etc..
	// without having to explicitly specify it
	props: InputHTMLAttributes<HTMLInputElement> &
		typeof defaultProps,
) => {
	const { setValue, ...restOfProps } = props;

	return (
		<>
			<input
				onChange={e =>
					// only set the value if setValue is defined
					setValue && setValue(e.target.value)
				}
				{
					// This is called props spreading
					// It basically says, any prop that you dump in gets added here
					...restOfProps
				}
			/>
		</>
	);
};

TextInput.defaultProps = defaultProps;

export default TextInput;
