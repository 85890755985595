const defaultProps = {
	title: 'ModalTitle',
	children: (<></>) as JSX.Element | JSX.Element[],
};

const Modal = (props: typeof defaultProps) => {
	const { title } = {
		...defaultProps,
		...props,
	};
	return (
		<div className="alertOverlay">
			<div className="alertModal">
				<div className="modal-content rounded-4 shadow bg-dark">
					<div className="modal-body px-5 py-4 text-center">
						<h5 className="mb-0">{title}</h5>
					</div>
					{props.children}
				</div>
			</div>
		</div>
	);
};

Modal.defaultProps = defaultProps;

export default Modal;
