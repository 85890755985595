import { version } from 'os';
import {
	createContext,
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import { toastToFailure } from '../../../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../../../components/trigger-toasts/toast-to-success';
import {
	VersionType,
	SelectedGift,
	useUpdateHybridExperienceGiftsMutation,
	PageType,
	Gift,
	GiftInput,
} from '../../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../../current-experience.provider';

export type ConfigurationVersionState =
	| VersionType.Versiona
	| VersionType.Versionb;

interface ConfigurationStepProviderProps {
	selectedVersion: ConfigurationVersionState;
	setSelectedVersion: Dispatch<
		SetStateAction<ConfigurationVersionState>
	>;
	allSelectedGifts: SelectedGift[];
	selectedGiftsAtVersion: SelectedGift[];
	removeSelectedGift: (
		selectedGift: SelectedGift,
	) => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	showB: boolean;
	setShowB: Dispatch<SetStateAction<boolean>>;
	addSelectedGift: (selectedGift: SelectedGift) => void;
	isLoading: boolean;
	refetchExperience: () => void;
}

const ConfigurationStepContext =
	createContext<ConfigurationStepProviderProps>({
		allSelectedGifts: [],
		selectedGiftsAtVersion: [],
		removeSelectedGift: gift => {},
		selectedVersion: VersionType.Versiona,
		setSelectedVersion: () => {},
		setIsLoading: () => {},
		showB: false,
		isLoading: false,
		setShowB: () => {},
		addSelectedGift: () => {},
		refetchExperience: () => {},
	});

export const ConfigurationStepProvider = ({
	children,
}: {
	children: JSX.Element | JSX.Element[];
}) => {
	const [updateHybridExperienceGifts] =
		useUpdateHybridExperienceGiftsMutation({});

	const [selectedVersion, setSelectedVersion] =
		useState<ConfigurationVersionState>(
			VersionType.Versiona,
		);

	const {
		data: currentExperienceData,
		refetch: refetchExperience,
	} = useCurrentExperience();
	// If the current experience already has gifts, figure out if there are gifts for >1 version
	const currentExperience =
		currentExperienceData?.GetHybridExperienceById;

	const currentExperienceSelectedGifts =
		currentExperience?.selectedGifts;

	const experienceASelectedGifts =
		currentExperience?.selectedGifts.filter(
			gift =>
				gift.versionType === VersionType.Versiona,
		) || [];

	const experienceBSelectedGifts =
		currentExperience?.selectedGifts.filter(
			gift =>
				gift.versionType === VersionType.Versionb,
		) || [];

	const [showB, setShowB] = useState<boolean>(
		experienceBSelectedGifts.length > 0,
	);

	const [isLoading, setIsLoading] =
		useState<boolean>(false);

	const removeSelectedGift = (
		selectedGift: SelectedGift,
	) => {
		const filtered =
			currentExperienceSelectedGifts?.filter(
				gift =>
					gift.gift.id !== selectedGift.gift.id ||
					gift.versionType !== selectedVersion,
			);

		const giftInput = filtered?.map(
			gift =>
				({
					gift: gift.gift.id,
					versionType: gift.versionType,
				} as GiftInput),
		);

		Promise.resolve()
			.then(() => setIsLoading(true))
			.then(() =>
				toastToNotify('Updating your gifts...'),
			)
			.then(() =>
				updateHybridExperienceGifts({
					variables: {
						hybridExperience:
							currentExperience?.id || '',
						gifts: giftInput || [],
					},
				}),
			)
			.then(() => refetchExperience())
			.then(() => toastToNotify('Gifts updated'))
			.catch(err => {
				toastToFailure(err.message);
			})
			.finally(() => setIsLoading(false));
	};

	const addSelectedGift = (
		selectedGift: SelectedGift,
	) => {
		let giftInput =
			currentExperience?.selectedGifts?.map(
				gift =>
					({
						gift: gift.gift.id,
						versionType: gift.versionType,
					} as GiftInput),
			);

		giftInput = giftInput
			? [
					...giftInput,
					{
						gift: selectedGift.gift.id,
						versionType:
							selectedGift.versionType,
					},
			  ]
			: [
					{
						gift: selectedGift.gift.id,
						versionType:
							selectedGift.versionType,
					},
			  ];

		Promise.resolve()
			.then(() => setIsLoading(true))
			.then(() =>
				toastToNotify('Updating your gifts...'),
			)
			.then(() =>
				updateHybridExperienceGifts({
					variables: {
						hybridExperience:
							currentExperience?.id || '',
						gifts: giftInput || [],
					},
				}),
			)
			.then(() => refetchExperience())
			.then(() => toastToSuccess('Gifts updated'))
			.catch(err => {
				toastToFailure(err.message);
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<ConfigurationStepContext.Provider
			value={{
				refetchExperience,
				selectedVersion,
				setSelectedVersion,
				allSelectedGifts:
					(currentExperienceSelectedGifts ||
						[]) as SelectedGift[],
				selectedGiftsAtVersion:
					selectedVersion === VersionType.Versiona
						? (experienceASelectedGifts as SelectedGift[]) ||
						  []
						: (experienceBSelectedGifts as SelectedGift[]) ||
						  [],
				setIsLoading,
				removeSelectedGift,
				showB,
				setShowB,
				isLoading,
				addSelectedGift,
			}}
		>
			{children}
		</ConfigurationStepContext.Provider>
	);
};

export const useConfigurationStep = () => {
	const context = useContext(ConfigurationStepContext);
	if (context === undefined) {
		throw new Error(
			'You must be inside of a giftConfigurationProvider to invoke useEmailConfigurationStep',
		);
	}

	return context;
};
