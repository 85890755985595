import Identicon from 'react-identicons';
import { GetHermesUserQuery } from '../../gql/queries/generated/graphql';

/**  This displays the users data on the dashboard */
const ProfilePanel = ({
	user,
}: {
	user: GetHermesUserQuery['LoggedInUser'] | null;
}) => (
	<div className="d-flex justify-content-lg-end justify-content-between text-light mb-3">
		<div className="d-flex align-items-center d-lg-none">
			<div
				className="btn btn-icon btn-active-color-primary ms-n2 me-1"
				id="kt_aside_toggle"
				data-kt-toggle="true"
				data-kt-toggle-state="active"
				data-kt-toggle-target="#kt_aside"
				data-kt-toggle-name="toggle-name"
			>
				<span className="svg-icon svg-icon-1">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
					>
						<path
							d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
							fill="black"
						></path>
						<path
							opacity="0.3"
							d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
							fill="black"
						></path>
					</svg>
				</span>
			</div>
		</div>
		<div className="d-flex">
			<div
				className="me-3 d-flex bg-white rounded-circle shadow-sm"
				style={{
					width: 40,
					height: 40,
				}}
			>
				<Identicon
					size={20}
					bg={'#fff'}
					className="mx-auto my-auto"
					string={user?.emailAddress}
				/>
			</div>
			<p className="text-white fs-6 my-auto">
				{user?.emailAddress}
			</p>
		</div>
	</div>
);

export default ProfilePanel;
