import { Email } from '../../../gql/queries/generated/graphql';
import { isTruthey } from '../../../util/is-truthey';
import { IStepLeft } from './types.validation';

const isCustomizationConfigured = ({
	bodyHtml,
	subjectLine,
	buttonText,
	footerLegalTerms,
}: Email): boolean =>
	[
		bodyHtml,
		subjectLine,
		buttonText,
		footerLegalTerms,
	].every(isTruthey);

const isHeaderConfigured = ({
	headerImage,
}: Email): boolean => !!headerImage;

const areMappedFieldsConfigured = ({
	mappedFields,
}: Email): boolean =>
	mappedFields.length > 0 &&
	mappedFields.every(field =>
		field.isRequired
			? !!field.staticValue || field.isMapped
			: true,
	);

export const getEmailSteps = (
	emails: Email[],
): IStepLeft[] => {
	const steps: IStepLeft[] = [];

	emails.forEach(email => {
		steps.push({
			message: 'Customization Configured',
			complete: isCustomizationConfigured(email),
			version: email.version,
		});

		steps.push({
			message: 'Image Configured',
			complete: isHeaderConfigured(email),
			version: email.version,
		});

		steps.push({
			message: 'Mapped Fields Configured',
			complete: areMappedFieldsConfigured(email),
			version: email.version,
		});
	});

	return steps;
};
