import { FetchResult } from '@apollo/client';
import { add } from 'lodash';
import {
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import Modal from 'react-modal';
import { toastToFailure } from '../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../components/trigger-toasts/toast-to-success';
import {
	Product,
	GetOrgGiftsAndProductsDocument,
	Gift,
	useGetOrgQuery,
	useUpdateProductVanityNameMutation,
	useUpdateGiftActiveStateMutation,
	useDeleteAssetMutation,
	useCreateAssetMutationMutation,
	AssetType,
	useAddGiftDetailImageMutation,
	CreateAssetMutationMutationResult,
	useAddGiftPreviewImageMutation,
	useUpdateGiftMutation,
	VariantType,
	useCreateGiftMutation,
	useDeleteGiftMutation,
	Asset,
} from '../../gql/queries/generated/graphql';
import promptModal from '../../providers/alert-provider/prompt-modal';
import getBase64 from '../../util/thennables/get-base-64';
import { grabFirstFileThrowIfMoreFiles } from '../../util/thennables/grab-first-file-throw-if-more';
import { throwIfNotExtension } from '../../util/thennables/throw-if-not-extension';
import { useSettings } from './settings-provider';

const ImageAssetCard = ({
	assetId,
	assetUrl,
	handleFiles,
	giftId,
	type,
}: {
	handleFiles: (
		files: File[],
	) => Promise<
		CreateAssetMutationMutationResult | undefined
	>;
	assetId: string | undefined;
	assetUrl: string | undefined;
	giftId: string;
	type: string;
}) => {
	const { refresh: refreshGifts } = useSettings();

	const [deleteAssetById] = useDeleteAssetMutation({
		variables: {
			assetId: assetId || '',
		},
		refetchQueries: [GetOrgGiftsAndProductsDocument],
	});

	const [addGiftDetailImage] =
		useAddGiftDetailImageMutation();
	const [addGiftPreviewImage] =
		useAddGiftPreviewImageMutation();

	const handleDelete = () =>
		Promise.resolve()
			.then(
				promptModal(
					`Delete this asset?`,
					'Not Deleted',
				),
			)
			.then(() => deleteAssetById())
			.then(() => refreshGifts())
			.then(() => toastToSuccess('Deleted'))
			.catch(() => toastToNotify('Not Deleted'));

	const fileInput =
		useRef() as MutableRefObject<HTMLInputElement>;

	return (
		<>
			<div className={`col`}>
				<div className="card rounded-3 shadow-sm border-0">
					<div
						className="card-body"
						style={{
							height: '0',
							paddingTop: '50%',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundImage: `url('${
								assetUrl || ''
							}')`,
						}}
					></div>
					<div className="card-footer bg-black text-white py-2 px-2">
						<div className="text-white text-shadow-1">
							<ul className="d-flex list-unstyled mb-0">
								<li className="me-auto">
									<button
										className="btn btn-sm btn-purple text-white align-self-end select-button"
										onClick={e => {
											e.preventDefault();
											fileInput.current.click();
										}}
									>
										Change
									</button>
									<input
										ref={fileInput}
										onChange={e => {
											if (
												e
													.currentTarget
													.files
											) {
												handleFiles(
													[
														e
															.currentTarget
															.files[0],
													],
												).then(
													createdAssetResult => {
														const createdAsset =
															createdAssetResult
																?.data
																?.CreateAsset;
														if (
															type ===
															'preview'
														) {
															addGiftPreviewImage(
																{
																	variables:
																		{
																			assetId:
																				createdAsset?.id ||
																				'',
																			giftId,
																		},
																	refetchQueries:
																		[
																			GetOrgGiftsAndProductsDocument,
																		],
																},
															)
																.then(
																	() =>
																		refreshGifts(),
																)
																.then(
																	() =>
																		toastToSuccess(
																			'Image Uploaded',
																		),
																)
																.catch(
																	error =>
																		toastToFailure(
																			'Error Uploading',
																		),
																);
														} else {
															addGiftDetailImage(
																{
																	variables:
																		{
																			assetId:
																				createdAsset?.id ||
																				'',
																			giftId,
																		},
																	refetchQueries:
																		[
																			GetOrgGiftsAndProductsDocument,
																		],
																},
															)
																.then(
																	() =>
																		toastToSuccess(
																			'Image Uploaded',
																		),
																)
																.catch(
																	error =>
																		toastToFailure(
																			'Error Uploading',
																		),
																);
														}
													},
												);
											}
										}}
										className="d-none form-control mt-3"
										type="file"
										id="formFile"
									/>
								</li>
								<li className="ms-auto">
									<button
										className="btn btn-sm btn-red text-white align-self-end"
										onClick={e => {
											e.preventDefault();
											handleDelete();
										}}
									>
										Delete
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const ProductCheckbox = ({
	id,
	allGiftProducts,
	removeFromList,
	addToList,
}: {
	id: string;
	allGiftProducts: string[] | undefined;
	removeFromList: (productId: string) => void;
	addToList: (productId: string) => void;
}) => {
	const [checked, setChecked] = useState<boolean>();
	const onCheck = () => {
		// eslint-disable-next-line no-unused-expressions
		checked ? removeFromList(id) : addToList(id);
	};

	const [value, setValue] = useState<string>();

	const { productList } = useSettings();
	const productDetail = productList?.find(
		pl => pl.id === id,
	);

	useEffect(() => {
		setValue(productDetail?.vanityName || undefined);
	}, [productDetail]);

	useEffect(() => {
		if (allGiftProducts) {
			const exists = allGiftProducts.find(
				allGiftProduct => allGiftProduct === id,
			);
			if (exists) {
				setChecked(true);
			} else {
				setChecked(false);
			}
		}
	}, [allGiftProducts]);

	const [updateVanityName] =
		useUpdateProductVanityNameMutation();

	const onUpdate = () => {
		updateVanityName({
			variables: {
				productId: id,
				vanityName: value || '',
			},
			refetchQueries: [
				GetOrgGiftsAndProductsDocument,
			],
		})
			.then(() => {
				toastToSuccess('Updated vanity name');
			})
			.catch(() => {
				toastToFailure(
					'Unable to update product, please try again',
				);
			});
	};

	return (
		<div className="bg-white text-dark p-2">
			<div className="d-flex">
				<input
					className="form-check-input flex-shrink-0 me-2"
					type="checkbox"
					name="inputcheck"
					onChange={() => onCheck()}
					checked={checked}
				/>
				<span>{`${productDetail?.name} : ${productDetail?.pflProductId}`}</span>
			</div>
			<div className="input-group mt-1">
				<input
					type="text"
					className="form-control form-control-sm bg-white text-black"
					placeholder="Product Vanity Name"
					aria-label="Product Vanity Name"
					value={value}
					onChange={e =>
						setValue(e.currentTarget.value)
					}
				/>
				<span
					onClick={() => onUpdate()}
					className="btn btn-sm btn-green input-group-text text-white fw-bold"
				>
					Update
				</span>
			</div>
		</div>
	);
};

interface ProductCardProps {
	productImage: string;
	productName: string;
	productId: string;
	pflProductId: string;
	removeFromList: (productId: string) => void;
	addToList: (productId: string) => void;
	allGiftProducts: string[] | undefined;
}

const ProductCard = ({
	productImage,
	productName,
	productId,
	pflProductId,
	removeFromList,
	addToList,
	allGiftProducts,
}: ProductCardProps) => {
	const [checked, setChecked] = useState<boolean>(false);
	const onCheck = () => {
		// eslint-disable-next-line no-unused-expressions
		checked
			? removeFromList(productId)
			: addToList(productId);
	};

	useEffect(() => {
		if (allGiftProducts) {
			const exists = allGiftProducts.filter(
				allGiftProduct =>
					allGiftProduct === productId,
			)[0];
			if (exists) {
				setChecked(true);
			} else {
				setChecked(false);
			}
		}
	}, [allGiftProducts]);

	return (
		<div className="col text-center">
			<div className="mx-auto ratio ratio-1x1 w-50 rounded rounded-3">
				<img
					className="img-fluid"
					src={productImage}
				/>
			</div>
			<p className="my-2">{productName}</p>
			<div className="d-flex justify-content-center w-100">
				<input
					className="form-check-input flex-shrink-0 mx-2"
					type="checkbox"
					name="inputcheck"
					onChange={() => onCheck()}
					checked={checked}
				/>
				<p className="text-muted">{pflProductId}</p>
			</div>
		</div>
	);
};

const EntCheckbox = ({
	label,
	id,
	allGiftEnts,
	removeFromList,
	addToList,
}: {
	label: string;
	id: string;
	allGiftEnts: string[];
	removeFromList: (entitlementId: string) => void;
	addToList: (entitlementId: string) => void;
}) => {
	const [checked, setChecked] = useState<boolean>(false);

	useEffect(() => {
		const exists = allGiftEnts.filter(
			allGiftEnt => allGiftEnt === id,
		)[0];
		if (exists) {
			setChecked(true);
		} else {
			setChecked(false);
		}
	}, [allGiftEnts]);

	const onCheck = () => {
		// eslint-disable-next-line no-unused-expressions
		checked ? removeFromList(id) : addToList(id);
	};

	return (
		<div>
			<label className="list-group-item d-flex gap-2 form-check form-check-custom form-check-solid form-check-sm">
				<input
					className="form-check-input flex-shrink-0 "
					type="checkbox"
					name="inputcheck"
					onChange={() => onCheck()}
					checked={checked}
				/>
				<span className="text-black">{label}</span>
			</label>
		</div>
	);
};

interface GiftEditModalProps {
	parentContainer: MutableRefObject<HTMLDivElement>;
	open: boolean;
	setOpen: (arg0: boolean) => void;
}

export const GiftEditModal = ({
	parentContainer,
	open,
	setOpen,
}: GiftEditModalProps) => {
	const { productList, editingGift: gift } =
		useSettings();

	const formEl =
		useRef() as MutableRefObject<HTMLFormElement>;
	// Gift name input
	const [giftName, setGiftName] = useState<string>();
	const [giftEntitlements, setGiftEntitlements] =
		useState<string[]>([]);
	const [giftPrice, setGiftPrice] = useState<number>();
	const [giftVariantType, setGiftVariantType] =
		useState<VariantType>();

	const [giftProducts, setGiftProducts] = useState<
		string[]
	>([]);

	const [giftDescription, setGiftDescription] =
		useState<string>();

	const { data: orgQueryResult } = useGetOrgQuery();
	const org = orgQueryResult?.GetOrg;

	const [giftDetailImage, setGiftDetailImage] =
		useState<Asset>();

	// On editing gift change, set values
	useEffect(() => {
		if (gift) {
			// Set the initial name & description if exists
			setGiftName(gift.name);
			setGiftDescription(gift.description || '');
			setGiftPrice(gift.price || 0);
			setGiftVariantType(
				gift.variantType || undefined,
			);

			// Grab out all the gift entitlements and put them in an array, then set the state
			const giftEnt: string[] = [];
			gift.entitlements?.forEach(ent =>
				giftEnt.push(ent!.id),
			);
			setGiftEntitlements(giftEnt);

			// Grab out all the gift products and put them in an array, then set the state
			const giftProd: string[] = [];
			gift.products?.forEach(prod => {
				if (prod) {
					giftProd.push(prod!.id);
				}
			});
			setGiftProducts(giftProd);

			setGiftDetailImage(
				gift.detailImage || undefined,
			);
		}
	}, [gift]);

	// Add and remove entitlements from list
	const removeFromList = (entitlementId: string) => {
		const cleansedEnts = giftEntitlements.filter(
			ge => ge !== entitlementId,
		);
		setGiftEntitlements(cleansedEnts);
	};
	const addToList = (entitlementId: string) => {
		const newEnts = [
			...giftEntitlements,
			entitlementId,
		];
		setGiftEntitlements(newEnts);
	};

	// Add and remove entitlements from list
	const removeFromGift = (productId: string) => {
		const cleansedGifts = giftProducts.filter(
			gp => gp !== productId,
		);
		setGiftProducts(cleansedGifts);
	};
	const addToGift = (productId: string) => {
		const newGifts = [...giftProducts, productId];
		setGiftProducts(newGifts);
	};

	const [createAsset] = useCreateAssetMutationMutation();

	const [addGiftDetailImage] =
		useAddGiftDetailImageMutation();

	const [addGiftPreviewImage] =
		useAddGiftPreviewImageMutation();

	const handleFiles = async (files: File[]) => {
		let file: File;

		const uploadedAsset = await Promise.resolve()
			.then(() => files)
			.then(
				grabFirstFileThrowIfMoreFiles(
					'Please only upload one file at a time.',
				),
			)
			.then(firstFile => {
				file = firstFile;
				return firstFile;
			})
			.then(
				throwIfNotExtension(
					['jpg', 'png', 'jpeg'],
					'Invalid File Type',
				),
			)
			.then(() => files[0])
			.then(getBase64)
			.then(output =>
				createAsset({
					variables: {
						content: output as string,
						filename: file.name,
						assetType: AssetType.Productimage,
					},
				}),
			)
			.then(result => result)
			.catch(err => {
				toastToFailure(err.message);
			});
		return uploadedAsset as
			| CreateAssetMutationMutationResult
			| undefined;
	};

	const [updateGift] = useUpdateGiftMutation();

	const onUpdateGift = () => {
		updateGift({
			variables: {
				giftId: gift?.id || '',
				price: giftPrice,
				name: giftName,
				description: giftDescription,
				entitlements: giftEntitlements,
				variantType: giftVariantType,
				products: giftProducts,
			},
			refetchQueries: [
				GetOrgGiftsAndProductsDocument,
			],
		})
			.then(() => toastToSuccess('Changes saved'))
			.catch(err => {
				console.error(err);
				toastToFailure('Unable to save changes');
			});
	};

	return (
		<>
			<Modal
				onRequestClose={() => setOpen(false)}
				className="customModal"
				overlayClassName="customOverlay"
				parentSelector={() =>
					parentContainer.current
				}
				shouldCloseOnOverlayClick={false}
				isOpen={open}
			>
				<div className="modal-content w-100 border-0 bg-dark text-white">
					<div className="container">
						<div className="modal-body pb-4 px-4">
							<div className="row">
								<div className="d-flex justify-content-between my-3">
									<h4 className="fw-bold my-auto">
										Edit Gift
									</h4>
									<button
										onClick={() =>
											setOpen(false)
										}
										className=" ms-auto my-auto btn btn-close btn-close-white"
									></button>
								</div>
								<hr className="mb-3" />

								<form
									ref={formEl}
									noValidate
								>
									<div className="mb-3 px-3">
										<div className="row mx-auto w-100">
											<div className="col-7 ps-0">
												<label className="form-label fw-bold">
													Name
												</label>
												<input
													required={
														true
													}
													type="text"
													value={
														giftName
													}
													onChange={e =>
														setGiftName(
															e
																.currentTarget
																.value,
														)
													}
													className="form-control bg-white text-black"
													aria-describedby="emailHelp"
												/>
											</div>
											<div className="col">
												<label className="form-label fw-bold">
													Price
												</label>
												<input
													required={
														true
													}
													type="number"
													value={
														giftPrice
													}
													onChange={e =>
														setGiftPrice(
															Number(
																e
																	.currentTarget
																	.value,
															),
														)
													}
													className="form-control bg-white text-black"
												/>
											</div>
											<div className="col">
												<label className="form-label fw-bold">
													Variant
													Type
												</label>
												<select
													onChange={e =>
														Number.isInteger(
															Number(
																e
																	.currentTarget
																	.value,
															),
														)
															? setGiftVariantType(
																	Object.keys(
																		VariantType,
																	)[
																		Number(
																			e
																				.currentTarget
																				.value,
																		)
																	].toUpperCase() as VariantType,
															  )
															: setGiftVariantType(
																	undefined,
															  )
													}
													className="form-select w-auto bg-white text-black"
												>
													<option
														selected={
															!gift?.variantType
														}
														value={
															VariantType.None
														}
													>
														No
														Variant
													</option>

													{Object.keys(
														VariantType,
													).map(
														(
															type,
															index,
														) =>
															type.toUpperCase() !==
																'NONE' && (
																<option
																	selected={
																		gift?.variantType ===
																		(type.toUpperCase() as VariantType)
																	}
																	key={
																		index
																	}
																	value={
																		index
																	}
																>
																	{
																		type
																	}
																</option>
															),
													)}
												</select>
											</div>
										</div>
										<div className="my-3 row w-100 mx-auto">
											<div className="col-6 ps-0">
												<label className="form-label">
													Gift
													Products
												</label>
												<div className="mx-0 my-0 rounded rounded-3">
													{giftProducts &&
														giftProducts.map(
															(
																giftProduct,
																index,
															) => (
																<ProductCheckbox
																	allGiftProducts={
																		giftProducts
																	}
																	id={
																		giftProduct ||
																		''
																	}
																	removeFromList={
																		removeFromGift
																	}
																	addToList={
																		addToGift
																	}
																	key={
																		index
																	}
																/>
															),
														)}
												</div>
											</div>
											<div className="col-6 pe-0">
												<label className="form-label">
													Entitlement
													Groups
												</label>
												<div className="list-group mx-0 my-0 rounded rounded-3">
													{org &&
														org.entitlements &&
														org.entitlements.map(
															(
																entitlement,
																index,
															) => (
																<EntCheckbox
																	removeFromList={
																		removeFromList
																	}
																	addToList={
																		addToList
																	}
																	allGiftEnts={
																		giftEntitlements
																	}
																	key={
																		index
																	}
																	id={
																		entitlement?.id ||
																		''
																	}
																	label={
																		entitlement?.name ||
																		''
																	}
																/>
															),
														)}
												</div>
											</div>
										</div>
										<div className="my-3 row w-100 mx-auto">
											<div className="col-6 ps-0">
												<div>
													<label
														htmlFor="exampleFormControlTextarea1"
														className="form-label"
													>
														Gift
														Description
													</label>
													<textarea
														className="form-control bg-white text-black"
														id="exampleFormControlTextarea1"
														value={
															giftDescription
														}
														onChange={e =>
															setGiftDescription(
																e
																	.currentTarget
																	.value,
															)
														}
														rows={
															3
														}
													></textarea>
												</div>
											</div>
											<div className="col-6 pe-0">
												<div className="row w-100 mx-auto">
													<div className="col-6 ps-0">
														<p className="text-center">
															Preview
															Image
														</p>
														{gift &&
															gift.previewImage && (
																<ImageAssetCard
																	type="preview"
																	giftId={
																		gift.id
																	}
																	handleFiles={
																		handleFiles
																	}
																	assetUrl={
																		gift
																			?.previewImage
																			.url ||
																		''
																	}
																	assetId={
																		gift
																			?.previewImage
																			.id
																	}
																/>
															)}
														{gift &&
															!gift.previewImage && (
																<input
																	onChange={e => {
																		if (
																			e
																				.currentTarget
																				.files
																		) {
																			handleFiles(
																				[
																					e
																						.currentTarget
																						.files[0],
																				],
																			).then(
																				createdAssetResult => {
																					const createdAsset =
																						createdAssetResult
																							?.data
																							?.CreateAsset;
																					addGiftPreviewImage(
																						{
																							variables:
																								{
																									assetId:
																										createdAsset?.id ||
																										'',
																									giftId: gift.id,
																								},
																							refetchQueries:
																								[
																									GetOrgGiftsAndProductsDocument,
																								],
																						},
																					)
																						.then(
																							() =>
																								toastToSuccess(
																									'Image Uploaded',
																								),
																						)
																						.catch(
																							error =>
																								toastToFailure(
																									'Error Uploading',
																								),
																						);
																				},
																			);
																		}
																	}}
																	className="form-control mt-3 bg-white text-black"
																	type="file"
																	id="formFile"
																/>
															)}
													</div>
													<div className="col-6 d-flex flex-column ps-0">
														<p className="text-center">
															Detail
															Image
														</p>
														{gift &&
															gift.detailImage &&
															giftDetailImage && (
																<ImageAssetCard
																	type="detail"
																	giftId={
																		gift.id
																	}
																	handleFiles={
																		handleFiles
																	}
																	assetUrl={
																		gift
																			?.detailImage
																			.url ||
																		''
																	}
																	assetId={
																		giftDetailImage.id
																	}
																/>
															)}
														{gift &&
															!gift.detailImage && (
																<input
																	onChange={e => {
																		if (
																			e
																				.currentTarget
																				.files
																		) {
																			handleFiles(
																				[
																					e
																						.currentTarget
																						.files[0],
																				],
																			).then(
																				createdAssetResult => {
																					const createdAsset =
																						createdAssetResult
																							?.data
																							?.CreateAsset;
																					addGiftDetailImage(
																						{
																							variables:
																								{
																									assetId:
																										createdAsset?.id ||
																										'',
																									giftId: gift.id,
																								},
																							refetchQueries:
																								[
																									GetOrgGiftsAndProductsDocument,
																								],
																						},
																					)
																						.then(
																							() =>
																								toastToSuccess(
																									'Image Uploaded',
																								),
																						)
																						.catch(
																							error =>
																								toastToFailure(
																									'Error Uploading',
																								),
																						);
																				},
																			);
																		}
																	}}
																	className="form-control mt-3 text-black bg-white"
																	type="file"
																	id="formFile"
																/>
															)}
													</div>
												</div>
											</div>
										</div>
										<h5 className="text-center">
											Available
											Products
										</h5>
										<div className="my-3 py-3 shadow border border-purple rounded rounded-3">
											<div
												className="shrink-content"
												style={{
													height: '40vh',
												}}
											>
												<div className="w-100 mx-auto row g-4 row-cols-1 row-cols-lg-3">
													{productList &&
														productList.map(
															(
																listProduct,
																listProductIndex,
															) => (
																<ProductCard
																	allGiftProducts={
																		giftProducts
																	}
																	removeFromList={
																		removeFromGift
																	}
																	addToList={
																		addToGift
																	}
																	productId={
																		listProduct.id
																	}
																	pflProductId={
																		listProduct.pflProductId?.toString() ||
																		''
																	}
																	productName={
																		listProduct.name
																	}
																	productImage={
																		listProduct.previewImage ||
																		''
																	}
																	key={
																		listProductIndex
																	}
																/>
															),
														)}
												</div>
											</div>
										</div>
										<div className="w-100 mx-auto row">
											<button
												onClick={e => {
													e.preventDefault();
													onUpdateGift();
												}}
												className="btn btn-lg btn-green text-white my-3 w-100"
											>
												Save Changes
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

const GiftCard = ({ gift }: { gift: Gift }) => {
	const { productList } = useSettings();
	const giftProducts: Product[] = [];
	gift.products?.forEach(giftProduct => {
		const match =
			productList &&
			productList.find(
				listProduct =>
					listProduct.id === giftProduct?.id,
			);
		giftProducts.push(match as Product);
	});

	const { setOpenGiftModal, setEditingGift } =
		useSettings();

	const [active, setActive] = useState<boolean>();

	useEffect(() => {
		setActive(gift.active);
	}, [gift]);

	const [updateGiftActiveState] =
		useUpdateGiftActiveStateMutation();

	const onChange = (activeChange: boolean) => {
		updateGiftActiveState({
			variables: {
				giftId: gift.id,
				active: activeChange,
			},
			refetchQueries: [
				GetOrgGiftsAndProductsDocument,
			],
		})
			.then(() => toastToSuccess('Updated'))
			.catch(() =>
				toastToFailure('Unable to update'),
			);
	};

	const [deleteGift] = useDeleteGiftMutation();

	const onDelete = (giftId: string) =>
		Promise.resolve()
			.then(
				promptModal(
					`Delete this Gift?`,
					'Not Deleted',
				),
			)
			.then(() =>
				deleteGift({
					variables: {
						giftId,
					},
					refetchQueries: [
						GetOrgGiftsAndProductsDocument,
					],
				}),
			)
			.then(() => toastToSuccess('Deleted'))
			.catch(() => toastToNotify('Not Deleted'));

	return (
		<>
			<div className="col d-flex flex-column text-white ">
				<div className="container shadow bg-dark p-4 rounded rounded-5 text-white h-100 d-flex flex-column">
					<div className="row w-100 mx-auto">
						<div className="col px-0">
							<h5 className="fw-bold mb-0">
								{gift.name}
							</h5>
							<p className="text-muted">
								Price: ${gift.price}
							</p>
						</div>
						<div className="col-3 text-center d-flex px-0">
							<p className="me-1">
								<small>Active</small>
							</p>
							<div className="form-check form-switch mx-auto">
								<input
									className="form-check-input"
									type="checkbox"
									checked={active}
									onChange={e =>
										onChange(
											e.currentTarget
												.checked,
										)
									}
									role="switch"
									id="flexSwitchCheckDefault"
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="px-0 col-9 mx-auto">
							<div className="ratio ratio-1x1">
								<img
									className="img-fluid"
									src={
										gift &&
										gift.previewImage
											? (gift
													.previewImage
													.url as string)
											: ''
									}
								></img>
							</div>
						</div>
					</div>
					<div className="row mt-auto pt-3">
						<div className="col-4">
							<button
								onClick={() =>
									onDelete(gift.id)
								}
								className="btn btn-sm btn-outline btn-outline-red w-100"
							>
								Delete
							</button>
						</div>
						<div className="col-8">
							<button
								onClick={() => {
									setOpenGiftModal(true);
									setEditingGift(gift);
								}}
								className="btn btn-outline btn-sm btn-outline-turquoise w-100"
							>
								<i className="fas fa-edit"></i>{' '}
								Edit
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const GiftSettings = () => {
	const {
		refresh,
		refreshingProducts,
		giftList,
		setEditingGift,
		setOpenGiftModal,
	} = useSettings();

	const [createGift] = useCreateGiftMutation();

	const onAdd = () => {
		createGift().then(giftMutationResult => {
			if (
				giftMutationResult &&
				giftMutationResult.data
			) {
				setEditingGift(
					giftMutationResult.data
						.CreateGift as Gift,
				);
				setOpenGiftModal(true);
			} else {
				toastToFailure(
					'Unable to create a new gift',
				);
			}
		});
	};

	return (
		<>
			<div className="container-fluid px-0">
				<div className="div mx-auto w-100 justify-content-between d-flex w-100 mx-auto">
					<button
						onClick={() => refresh()}
						className="btn w-auto btn-info border-0 ms-4"
					>
						{refreshingProducts ? (
							<span
								className="spinner-border spinner-border-sm"
								role="status"
								aria-hidden="true"
							></span>
						) : (
							<i className="far fa-sync-alt"></i>
						)}{' '}
						Refresh Products
					</button>
					<button
						onClick={() => {
							onAdd();
						}}
						className="btn gradient-button btn-md border-0 text-white me-4 fw-bold"
					>
						<i className="fas fa-plus"></i> Add
						Gift
					</button>
				</div>
				<div className="row w-100 mt-3 row-cols-1 row-cols-lg-2 row-cols-xxl-3 row-cols-xxxl-4 g-4 mx-auto">
					{giftList &&
						giftList.map((gift, giftIndex) => (
							<GiftCard
								gift={gift as Gift}
								key={giftIndex}
							/>
						))}
				</div>
			</div>
		</>
	);
};

export default GiftSettings;
