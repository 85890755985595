import { MutableRefObject, useState, useRef } from 'react';
import TypewriterComponent from 'typewriter-effect';
import Identicon from 'react-identicons';
import Select from 'react-select';
import OrgUserFieldInputComponent, {
	OrgField,
} from './org-user-field';

import { useAuth } from '../../../providers/auth/auth-provider';
// Toast Factory
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import { toastToSuccess } from '../../../components/trigger-toasts/toast-to-success';

// Import Data Queries
import {
	GetUsersByOrgDocument,
	OrgUserFields,
	useCreateHermesUserMutation,
	useGetOrgQuery,
	User,
	UserPermissionLevel,
	useUpdateHermesUserMutation,
} from '../../../gql/queries/generated/graphql';

import { useSettings } from '../settings-provider';
import NimblIcon from '../../../pictures/nimblIcon400x400.png';

interface SelectedEntitlement {
	value: string;
	label: string;
}
interface TempUser {
	firstName: User['firstName'];
	lastName: User['lastName'];
	emailAddress: User['emailAddress'];
	entitlement: SelectedEntitlement;
	permission: string;
}

const UserCreateEdit = () => {
	const { editingUser, setOpenUserModal: setOpenModal } =
		useSettings();
	const { user } = useAuth();
	const { data: orgQueryResult } = useGetOrgQuery();
	const org = orgQueryResult?.GetOrg;

	const createOrgUserFieldsArray = (
		fields: OrgUserFields[],
	) => {
		let orgFields = org?.orgUserFields || [];
		// eslint-disable-next-line no-restricted-syntax
		for (const field of fields) {
			const index = orgFields.findIndex(
				(f: OrgField) => f.field === field.field,
			);
			if (index > -1) {
				orgFields = [
					...orgFields.slice(0, index),
					field,
					...orgFields.slice(index + 1),
				];
			}
		}
		return orgFields;
	};

	const [newOrgUserFields, setNewOrgUserFields] =
		useState<OrgField[] | undefined>(
			editingUser && editingUser.orgUserFields
				? createOrgUserFieldsArray(
						editingUser.orgUserFields,
				  )
				: org?.orgUserFields || [],
		);

	// Handle user data as state object
	const [userData, setUserData] = useState<TempUser>({
		firstName: editingUser?.firstName || '',
		lastName: editingUser?.lastName || '',
		emailAddress: editingUser?.emailAddress || '',
		entitlement: {
			value: editingUser?.entitlement.id || '',
			label: editingUser?.entitlement.name || '',
		},
		permission: editingUser?.userPermissionLevel || '',
	});

	const PermissionMap = Object.keys(UserPermissionLevel);

	const [createHermesUser] =
		useCreateHermesUserMutation();

	const [updateHermesUser] =
		useUpdateHermesUserMutation();

	const formEl =
		useRef() as MutableRefObject<HTMLFormElement>;

	const [isSubmitting, setIsSubmitting] =
		useState<boolean>(false);

	const handleCreateEditUser = () => {
		if (formEl.current.checkValidity()) {
			if (editingUser) {
				updateHermesUser({
					variables: {
						updateUserId: editingUser?.id || '',
						firstName: userData.firstName || '',
						lastName: userData.lastName || '',
						emailAddress:
							userData.emailAddress || '',
						entitlement:
							userData.entitlement.value ||
							'',
						orgUserFields:
							newOrgUserFields?.map(
								field => ({
									displayName:
										field.displayName,
									field: field.field as any, // This is an enum didn't want to set it as enum value because it might change, willing to let it error if theres an issue
									value: field.value,
								}),
							) || [],
						updateUserPermissionLevel:
							userData.permission.toUpperCase() as UserPermissionLevel,
					},
					refetchQueries: [GetUsersByOrgDocument],
				})
					.then(() => {
						toastToSuccess(
							'User updated successfully',
						);
						setOpenModal(false);
					})
					.catch(() => {
						toastToFailure(
							'Unable to update user',
						);
					});
			} else {
				createHermesUser({
					variables: {
						firstName: userData.firstName || '',
						lastName: userData.lastName || '',
						emailAddress:
							userData.emailAddress || '',
						entitlement:
							userData.entitlement.value ||
							'',
						orgUserFields:
							newOrgUserFields?.map(
								field => ({
									displayName:
										field.displayName,
									field: field.field as any, // This is an enum didn't want to set it as enum value because it might change, willing to let it error if theres an issue
									value: field.value,
								}),
							) || [],
						createUserPermissionLevel:
							userData.permission.toUpperCase() as UserPermissionLevel,
					},
					refetchQueries: [GetUsersByOrgDocument],
				})
					.then(() => {
						toastToSuccess(
							'User created successfully',
						);
						setOpenModal(false);
					})
					.catch(() => {
						toastToFailure(
							'Unable to create user, please ensure this user does not already exist',
						);
					});
			}
		} else {
			toastToFailure(
				'Please fill out all required fields',
			);
			formEl.current.classList.add('was-validated');
		}
	};
	return (
		<div
			data-cy="user-create-edit-form"
			className="modal-content p-0 bg-dark"
		>
			<div className="d-flex modal-header justify-content-between">
				<div className="col">
					<h4
						className="fw-bold my-auto"
						data-cy="userFormHeader"
					>
						{editingUser
							? 'Edit User'
							: 'Add User'}
					</h4>
				</div>
				<div className="col d-flex justify-content-center">
					{userData.emailAddress ? (
						<div
							className=" d-flex bg-white rounded-circle shadow-sm "
							style={{
								width: 50,
								height: 50,
							}}
						>
							<Identicon
								size={25}
								bg={'#fff'}
								className="mx-auto my-auto"
								string={
									userData.emailAddress
								}
							/>
						</div>
					) : (
						<img
							className="img img-fluid"
							src={NimblIcon}
							style={{
								height: '50px',
							}}
						/>
					)}
				</div>
				<div className="col d-flex">
					<button
						data-cy="userFormClose"
						onClick={() => setOpenModal(false)}
						className=" ms-auto my-auto btn btn-close btn-close-white"
					></button>
				</div>
			</div>

			<form
				ref={formEl}
				noValidate
				className="modal-body"
			>
				<div className="row pb-3"></div>
				<div className="standardFields">
					<div className="input-group mb-3">
						<div className="col pe-1">
							<label
								className="form-label required"
								htmlFor="userFormFirstName"
							>
								First Name
							</label>
							<input
								id="userFormFirstName"
								data-cy="userFormFirstName"
								required={true}
								type="text"
								value={
									userData.firstName || ''
								}
								onChange={e =>
									setUserData({
										...userData,
										firstName:
											e.target.value,
									})
								}
								className="form-control bg-white text-black"
								aria-describedby="emailHelp"
							/>
						</div>
						<div className="col ps-1">
							<label
								className="form-label required"
								htmlFor="userFormLastName"
							>
								Last Name
							</label>
							<input
								id="userFormLastName"
								data-cy="userFormLastName"
								required={true}
								type="text"
								value={
									userData.lastName || ''
								}
								onChange={e =>
									setUserData({
										...userData,
										lastName:
											e.target.value,
									})
								}
								className="form-control bg-white text-black"
								aria-describedby="emailHelp"
							/>
						</div>
					</div>
					<label
						className="form-label required"
						htmlFor="userFormEmail"
					>
						Email Address
					</label>
					<div className="input-group mb-3">
						<input
							id="userFormEmail"
							data-cy="userFormEmail"
							required={true}
							value={
								userData.emailAddress || ''
							}
							className="form-control bg-white text-black"
							onChange={e =>
								setUserData({
									...userData,
									emailAddress:
										e.target.value,
								})
							}
							type="email"
							aria-describedby="emailHelp"
						/>
					</div>
					{org && org.entitlements && (
						<div className="mb-3">
							<label className="form-label required">
								Entitlement Group
							</label>
							<Select
								classNamePrefix={
									'reactSelectEntitlement'
								}
								id="entitlementSelect"
								data-cy="userFormEntitlement"
								className=" flex-grow-1 color-black"
								onChange={selection => {
									setUserData({
										...userData,
										entitlement: {
											value:
												selection?.value ||
												'',
											label:
												selection?.label ||
												'',
										},
									});
								}}
								options={org.entitlements.map(
									entitlement => ({
										label:
											entitlement?.name ||
											'',
										value:
											entitlement?.id ||
											'',
									}),
								)}
								value={{
									label:
										userData.entitlement
											.label || '',
									value:
										userData.entitlement
											.value || '',
								}}
								aria-label="Entitlement Select"
							/>
						</div>
					)}

					<div className="my-3">
						<label className="form-label required">
							Permission Level Group
						</label>
						<Select
							classNamePrefix={
								'reactSelectPermission'
							}
							data-cy="userFormPermission"
							className="flex-grow-1 color-black"
							aria-label="Permission Select"
							value={{
								label:
									userData.permission ||
									'',
								value:
									userData.permission ||
									'',
							}}
							options={
								user?.userPermissionLevel !==
								'STANDARD'
									? PermissionMap.map(
											permission => ({
												value:
													permission ||
													'',
												label:
													permission ||
													'',
											}),
									  )
									: [
											{
												label: 'Standard',
												value: 'STANDARD',
											},
									  ]
							}
							onChange={value => {
								setUserData({
									...userData,
									permission:
										value?.value || '',
								});
							}}
						/>
					</div>

					{newOrgUserFields && (
						<>
							<div className="separator py-3"></div>
							<div className="my-3">
								Org Fields{' '}
							</div>
						</>
					)}
					{newOrgUserFields &&
						newOrgUserFields.map(
							(orgUserField, index) => (
								<OrgUserFieldInputComponent
									index={index}
									orgUserFields={
										newOrgUserFields
									}
									setOrgUserFields={
										setNewOrgUserFields
									}
									key={index}
								/>
							),
						)}
					<div className="d-flex justify-content-around modal-footer">
						{isSubmitting ? (
							<div>Loading</div>
						) : (
							<>
								<button
									data-cy="userFormCancelButton"
									type="button"
									className="btn btn-md btn-outline btn-outline-danger text-white  "
									onClick={() =>
										setOpenModal(false)
									}
								>
									Discard Changes
								</button>
								<button
									data-cy="userFormSaveButton"
									type="button"
									className="btn btn-md btn-outline btn-outline-green text-white "
									onClick={
										handleCreateEditUser
									}
								>
									Save Changes
								</button>
							</>
						)}
					</div>
				</div>
			</form>
		</div>
	);
};

export default UserCreateEdit;
