import {
	PageType,
	SelectedGift,
	VersionType,
} from '../../../gql/queries/generated/graphql';
import { isTruthey } from '../../../util/is-truthey';
import { IStepLeft } from '../validations/types.validation';

type GiftVersionA = Omit<SelectedGift, 'versionType'> & {
	versionType: VersionType.Versiona;
};

type GiftVersionB = Omit<SelectedGift, 'versionType'> & {
	versionType: VersionType.Versiona;
};

type CorrectGiftQuantity = Record<PageType, number>;

const CORRECT_GIFT_QUANTITY: CorrectGiftQuantity = {
	[PageType.Multiitem]: 2,
	[PageType.Singleitem]: 1,
	[PageType.Singleitemsize]: 1,
};

type GiftVersionAOrB = GiftVersionA | GiftVersionB;

export const isEnoughGifts = (
	selectedGifts: SelectedGift[],
	pageType: PageType,
): boolean =>
	CORRECT_GIFT_QUANTITY[pageType] ===
	selectedGifts.length;

// Currently unused but we can add it later
const getIconMessage = (
	foundQt: number,
	correctQt: number,
) =>
	`${'🎁'.repeat(foundQt)} ${'🟥'.repeat(
		correctQt - foundQt,
	)}:`;

const getMessage = (
	foundQt: number,
	correctQt: number,
): string =>
	`Gifts Selected: 
 ${foundQt}/${correctQt}`;

export const getGiftSteps = (
	selectedGifts: SelectedGift[],
	pageType: PageType,
	isVersionB: boolean,
): IStepLeft[] => {
	const versionA = selectedGifts.filter(
		gift => gift.versionType === VersionType.Versiona,
	);
	const versionB = selectedGifts.filter(
		gift => gift.versionType === VersionType.Versionb,
	);

	const versionAQuantity = versionA.length;
	const versionBQuantity = versionB.length;
	const expectedQuantity =
		CORRECT_GIFT_QUANTITY[pageType];

	const versionAStep: IStepLeft = {
		version: VersionType.Versiona,
		complete: isEnoughGifts(versionA, pageType),
		message: getMessage(
			versionAQuantity,
			expectedQuantity,
		),
	};

	const versionBStep: IStepLeft | undefined = isVersionB
		? {
				version: VersionType.Versionb,
				complete: isEnoughGifts(versionB, pageType),
				message: getMessage(
					versionBQuantity,
					expectedQuantity,
				),
		  }
		: undefined;

	return [versionAStep, versionBStep].filter(isTruthey);
};
