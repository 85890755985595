import { MouseEventHandler, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

const defaultProps = {
	isActive: false as boolean,
};

const MainNavbarItem = ({
	isActive,
	clickHandler,
	iconName,
	tooltip,
	id,
}: {
	isActive?: boolean;
	clickHandler?: MouseEventHandler;
	iconName?: string;
	tooltip?: string;
	id: string;
}) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	});
	return (
		<li className="nav-item mb-1 ">
			<ReactTooltip />
			<div
				onClick={e =>
					clickHandler && clickHandler(e)
				}
				data-tip={tooltip}
				data-place="right"
				data-delay-show={500}
				data-offset={JSON.stringify({ right: 25 })}
				data-background-color="#8a69ff"
				className={`nav-link w-40px h-40px w-lg-50px h-lg-50px btn btn-icon btn-color-gray-500 btn-active-color-primary btn-active-light fs-1 d-flex my-1 ${
					isActive && 'active'
				}`}
				id={id}
			>
				<i
					className={`text-white fs-1 my-auto mx-auto ${iconName}`}
				/>
			</div>
		</li>
	);
};

MainNavbarItem.defaultProps = defaultProps;

export default MainNavbarItem;
