import { MutableRefObject, useRef } from 'react';
import {
	useGetLandingPagePreviewUrlQuery,
	VersionType,
} from '../../../../gql/queries/generated/graphql';
import { useConfigurationStep } from '../review-step/review-step-provider';

const PageRender = () => {
	const { currentExperience, selectedVersion } =
		useConfigurationStep();

	const { data: landingPagePreview } =
		useGetLandingPagePreviewUrlQuery({
			variables: {
				hybridExperienceId:
					currentExperience?.id || '',
				landingPageVersion:
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					currentExperience!.pages.length > 1
						? selectedVersion
						: VersionType.Versiona,
			},
		});

	const iFrame =
		useRef() as MutableRefObject<HTMLIFrameElement>;

	return (
		<>
			<div className="w-100 h-100">
				<iframe
					ref={iFrame}
					className="w-100 h-100 page-frame"
					src={`${landingPagePreview?.GetLandingPagePreviewUrl}`}
				></iframe>
			</div>
		</>
	);
};

export default PageRender;
