import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import {
	RenderExperienceEmailVersionDocument,
	useRenderExperienceEmailVersionQuery,
	VersionType,
} from '../../../../gql/queries/generated/graphql';
import RenderWindow from './render-window';
import { useConfigurationStep } from '../review-step/review-step-provider';

const EmailReview = () => {
	const history = useHistory();

	const {
		currentExperience,
		selectedVersion,
		displayOnly,
	} = useConfigurationStep();

	const variables = {
		emailVersion:
			currentExperience!.emails.length > 1
				? selectedVersion
				: VersionType.Versiona,
		hybridExperienceId: currentExperience?.id || '',
	};
	// using useQuery instead of useRenderExperienceEmailVersionQuery so we can set fetch policy
	const { data } = useQuery(
		RenderExperienceEmailVersionDocument,
		{ fetchPolicy: 'no-cache', variables },
	);

	return (
		<>
			<div className="d-flex p-3">
				<h3
					className="text-white"
					style={{
						fontWeight: 900,
					}}
				>
					Email Overview
				</h3>
				{!displayOnly && (
					<button
						onClick={() =>
							history.push(
								`/create-experience/${
									currentExperience?.id ||
									''
								}/add-email`,
							)
						}
						className="ms-auto btn btn-light"
					>
						Edit Email
					</button>
				)}
			</div>
			{
				<RenderWindow
					emailHtml={
						data?.RenderHybridExperienceEmail ||
						''
					}
				/>
			}
		</>
	);
};

export default EmailReview;
