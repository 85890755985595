import { useHistory } from 'react-router';
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../../components/trigger-toasts/toast-to-success';
import {
	GetExperienceDocument,
	HybridExperience,
	useScheduleHybridExperienceMutation,
} from '../../../gql/queries/generated/graphql';
import CreateExperienceLayout from '../create-experience-layout';

import { CreateExperienceSubroute1 } from '../create-experience-types';
import ReviewComponent from './review-component';

const ReviewSubroute = ({
	experience,
	currentStep,
}: {
	experience: HybridExperience;
	currentStep: CreateExperienceSubroute1;
}) => {
	const history = useHistory();
	const [scheduleHybridExperienceMutation] =
		useScheduleHybridExperienceMutation();
	const onNext = () => {
		Promise.resolve()
			.then(() =>
				toastToNotify(
					'Launching your experience...',
				),
			)
			.then(() =>
				scheduleHybridExperienceMutation({
					variables: {
						hybridExperienceId: experience.id,
					},
					refetchQueries: [GetExperienceDocument],
				})
					.then(() => {
						toastToSuccess(
							'Experience Scheduled!',
						);
					})
					.then(() => history.push('/')),
			)
			.catch(error => {
				console.error(error);
				toastToFailure(
					'Failed to schedule experience, please try again',
				);
			});
	};

	return (
		<CreateExperienceLayout
			experience={experience}
			currentStep={currentStep}
		>
			<ReviewComponent
				experience={experience}
				showNextAndBackButtons={true}
				onPrev={() => {
					history.push(
						`/create-experience/${experience.id}/configure-print`,
					);
				}}
				onNext={onNext}
			/>
		</CreateExperienceLayout>
	);
};

export default ReviewSubroute;
