import { MutableRefObject, useEffect, useRef } from 'react';
import { useGetLandingPagePreviewUrlQuery } from '../../../gql/queries/generated/graphql';
import { useCurrentExperience } from '../current-experience.provider';
import { useConfigurationStep } from './configuration-form-step/configuration-form-step-provider';

const PageRender = () => {
	const { data: currentExperience } =
		useCurrentExperience();
	const {
		selectedVersion,
		pageUpdating,
		setPageUpdating,
	} = useConfigurationStep();

	const { data: landingPagePreview } =
		useGetLandingPagePreviewUrlQuery({
			variables: {
				hybridExperienceId:
					currentExperience
						?.GetHybridExperienceById?.id || '',
				landingPageVersion: selectedVersion,
			},
		});

	const iFrame =
		useRef() as MutableRefObject<HTMLIFrameElement>;

	useEffect(() => {
		if (pageUpdating === true) {
			// eslint-disable-next-line no-self-assign
			iFrame.current.src = iFrame.current.src;
			setPageUpdating(false);
		}
	}, [pageUpdating, setPageUpdating]);

	return (
		<>
			<div className="w-100 h-100">
				<iframe
					ref={iFrame}
					className="w-100 h-100 scrollbar page-frame"
					src={`${landingPagePreview?.GetLandingPagePreviewUrl}`}
				/>
			</div>
		</>
	);
};

export default PageRender;
