import { useEffect, useState } from 'react';
import { useConfigurationStep } from '../review-step/review-step-provider';
import Editor from './Editor';
import { useUpdateHybridExperienceSettingsMutation } from '../../../../gql/queries/generated/graphql';
import { toastToSuccess } from '../../../../components/trigger-toasts/toast-to-success';
import { toastToFailure } from '../../../../components/trigger-toasts/toast-to-failure';

const NotesCard = () => {
	const { currentExperience } = useConfigurationStep();
	const [html, setHtml] = useState<string>();

	useEffect(() => {
		if (currentExperience && currentExperience.notes) {
			setHtml(currentExperience.notes);
		}
	}, [currentExperience]);

	const [updateHybridExperienceSettings] =
		useUpdateHybridExperienceSettingsMutation();
	return (
		<>
			<div className="col-12 h-100">
				<div className="col p-2 bg-black rounded rounded-2 h-100 d-flex flex-column">
					<div className="d-flex pb-3">
						<div className="mb-0 mt-auto">
							<h4 className="fw-bold text-white">
								Notes
							</h4>
						</div>
						<div className="ms-auto">
							<button
								onClick={() =>
									updateHybridExperienceSettings(
										{
											variables: {
												hybridExperienceId:
													currentExperience?.id ||
													'',
												notes: html,
											},
										},
									)
										.then(() =>
											toastToSuccess(
												'Updated successfully',
											),
										)
										.catch(error => {
											console.error(
												error,
											);
											toastToFailure(
												'There was a problem saving your updates, please try again',
											);
										})
								}
								className="btn btn-sm btn-turquoise text-white"
							>
								Update
							</button>
						</div>
					</div>

					<Editor
						value={html || ''}
						onChange={e => setHtml(e)}
						placeholder="Write Something..."
					/>
				</div>
			</div>
		</>
	);
};

export default NotesCard;
