import {
	faChevronLeft,
	faChevronRight,
	faFilePdf,
	faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten } from 'lodash';
import { useState } from 'react';
import Colors from '../../../colors.enum';
import { toastToFailure } from '../../../components/trigger-toasts/toast-to-failure';
import { apolloClient } from '../../../gql/apollo-client';
import {
	MergePdfsDocument,
	MergePdfsQuery,
	ProductProof,
	useGetPodProofQuery,
} from '../../../gql/queries/generated/graphql';
import { openPDF } from './pdf.utility';

interface ProofSlideData {
	pageUrl: string;
	giftName: string;
	pdfUrl: string;
	productId: string;
	productOrder: number;
}

const ProductCarousel = ({
	experienceId,
}: {
	experienceId: string;
}) => {
	// Get Data
	const { data: podProofData } = useGetPodProofQuery({
		variables: { hybridExperienceId: experienceId },
	});

	const prodProofs =
		podProofData?.GetPodProof || ([] as ProductProof[]);

	const proofSlides: ProofSlideData[] = flatten(
		prodProofs.map((proof): ProofSlideData[] =>
			proof.PageURLs.map((pageUrl, index) => ({
				pageUrl,
				giftName: proof.giftName,
				pdfUrl: proof.PdfURL,
				productId: proof.productId,
				productOrder: index + 1,
			})),
		),
	);

	// Control Index State
	const [proofSlideIndex, setProofSlideIndex] =
		useState(0);

	const incrementProofSlideIndex = () => {
		if (proofSlideIndex < proofSlides.length - 1) {
			setProofSlideIndex(proofSlideIndex + 1);
		} else {
			setProofSlideIndex(0);
		}
	};

	const decrementProofSlideIndex = () => {
		if (proofSlideIndex > 0) {
			setProofSlideIndex(proofSlideIndex - 1);
		} else {
			setProofSlideIndex(proofSlides.length - 1);
		}
	};
	const defaultUrl =
		'https://pflhermes.blob.core.windows.net/images/pod_notecard_generic.jpg';
	const currentSlide = proofSlides[proofSlideIndex];
	const currentUrl = currentSlide?.pageUrl || defaultUrl;
	const currentGiftName =
		currentSlide?.giftName || defaultUrl;

	const downloadPDF = () => {
		const allPdfUrls = prodProofs.map(
			proof => proof.PdfURL,
		);

		return Promise.resolve()
			.then(() =>
				apolloClient.query<MergePdfsQuery>({
					query: MergePdfsDocument,
					variables: {
						pdfUrls: allPdfUrls,
					},
				}),
			)
			.then(result => result.data.MergePdfs)
			.then(mergedPdfUrl => openPDF(mergedPdfUrl))
			.catch(() =>
				toastToFailure('Something Went Wrong'),
			);
	};

	return (
		<div className="text-center w-50 mx-auto">
			<h5 className="d-flex mx-auto text-center">
				{currentGiftName}{' '}
				<FontAwesomeIcon
					icon={faFilePdf}
					color="white"
					size="1x"
					className="ms-2"
					style={{
						cursor: 'pointer',
					}}
					onClick={() => {
						downloadPDF();
					}}
				/>
			</h5>

			<div
				className="bg-dark rounded rounded-3"
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{/* Image */}
				<div
					className="w-100 d-flex flex-column justify-content-between align-items-center rounded rounded-3"
					style={{ padding: 0 }}
				>
					{proofSlides.length > 0 && (
						// show image
						<div className="w-100 d-flex flex-column flex-grow-1 justify-content-center  align-items-center">
							<img
								className="w-100"
								style={{
									height: 'auto',
								}}
								src={currentUrl}
							/>
						</div>
					)}
					{proofSlides.length === 0 && (
						// loading indicator
						<div className="w-100 h-100 d-flex justify-content-center align-items-center">
							<FontAwesomeIcon
								icon={faSpinner}
								size="3x"
								spin={true}
							/>
						</div>
					)}
				</div>
			</div>

			{proofSlides.length > 0 && (
				// Controls Menu
				<div
					className=" w-100 d-flex flex-column justify-content-end align-items-center mt-3"
					style={
						{
							// backgroundColor: Colors.purple,
						}
					}
				>
					<div
						className="g-dark d-flex flex-column justify-content-between"
						style={{
							width: 400,
						}}
					>
						<div className="grid">
							<div className="row">
								<div className="col d-flex justify-content-center">
									<FontAwesomeIcon
										onClick={() => {
											decrementProofSlideIndex();
										}}
										icon={faChevronLeft}
										size="1x"
										style={{
											cursor: 'pointer',
										}}
									/>
								</div>
								<h5 className="col d-flex justify-content-center">
									{proofSlideIndex + 1}/
									{proofSlides.length}
								</h5>
								<div className="col d-flex justify-content-center">
									<FontAwesomeIcon
										onClick={() => {
											incrementProofSlideIndex();
										}}
										icon={
											faChevronRight
										}
										size="1x"
										style={{
											cursor: 'pointer',
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ProductCarousel;
