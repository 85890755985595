import React, { useEffect } from 'react';

interface SearchInputProps {
	searchArray: any[]; // Initial array of objects to search
	fieldsToSearch: string[]; // Array of key fields to search in
	setSearchedObject: (arg0: any) => void; // State method to that filtered array is set to
	placeholder: string; // Placeholder for inside search field
	keyField: string | 'id'; // What field to dedupe by if searching multiple fields
}

const SearchInput = (props: SearchInputProps) => {
	const [searchInput, setSearchInput] =
		React.useState<string>('');

	const filterAll = () => {
		const tempObject = [];
		for (
			let i = 0;
			i < props.fieldsToSearch.length;
			i += 1
		) {
			const field = props.fieldsToSearch[i];
			const filteredItems = props.searchArray.filter(
				item =>
					item[field]
						.toLowerCase()
						.includes(
							searchInput.toLowerCase(),
						),
			);
			tempObject.push(...filteredItems);
		}

		const seen = new Set(); // Tracks keys for dedupe
		const filteredArray = tempObject.filter(item => {
			const duplicate = seen.has(
				item[props.keyField],
			);
			seen.add(item[props.keyField]);
			return !duplicate;
		});
		return filteredArray;
	};

	const filteredObject = filterAll();

	useEffect(() => {
		props.setSearchedObject(filteredObject);
	}, [searchInput]);

	// If the initial array
	useEffect(() => {
		setSearchInput('');
	}, [props.searchArray]);

	return (
		<div className="d-flex align-items-center position-relative my-1 px-3">
			<span className="svg-icon svg-icon-1 position-absolute ms-6">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
				>
					<rect
						opacity="0.5"
						x="17.0365"
						y="15.1223"
						width="8.15546"
						height="2"
						rx="1"
						transform="rotate(45 17.0365 15.1223)"
						fill="black"
					/>
					<path
						d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
						fill="black"
					/>
				</svg>
			</span>
			<input
				type="text"
				className="form-control w-250px ps-14 bg-dark"
				id="searchInput"
				placeholder={props.placeholder}
				onChange={e =>
					setSearchInput(e.target.value)
				}
				value={searchInput}
			/>
		</div>
	);
};

export default SearchInput;
