import {
	faCheckCircle,
	faExclamationCircle,
	faPlus,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors, { HermesColor } from '../../../colors.enum';
import TodoButton from '../../../components/todo-button';
import { VersionType } from '../../../gql/queries/generated/graphql';

import { useCurrentExperience } from '../current-experience.provider';
import { useConfigurationStep } from './select-gift-step/select-gift-step-provider';

interface TabProps {
	color: HermesColor;
	text: string;
	onClick: () => void;
	version:
		| 'settings'
		| VersionType.Versiona
		| VersionType.Versionb;
	onDeleteClick?: () => void;
}

const Tab = ({
	color,
	text,
	onClick,
	version,
	onDeleteClick,
}: TabProps) => (
	<div
		id="tab-1"
		className={`bg-${color.toString()} tab d-flex rounded-top h-100 me-1 text-center`}
		style={{
			cursor: 'pointer',
		}}
		onClick={() => onClick()}
	>
		<p className="text-center m-0 fw-bold align-self-center px-3">
			{text}
		</p>

		{version === VersionType.Versionb && (
			<div className="tab-x-button">
				<FontAwesomeIcon
					icon={faTimes}
					color={Colors.white}
					className="pointer"
					style={{
						cursor: 'pointer',
						position: 'relative',
						top: 0,
						right: 4,
					}}
					onClick={e => {
						e.stopPropagation();
						if (onDeleteClick) {
							onDeleteClick();
						}
					}}
				/>
			</div>
		)}
	</div>
);

const PlusTab = ({ onClick }: { onClick: () => void }) => (
	<div
		id="tab-1"
		className="tab plus-tab d-flex justify-content-center align-items-center px-2"
		onClick={() => onClick()}
	>
		<FontAwesomeIcon
			icon={faPlus}
			color="white"
			className="poiter"
			style={{ cursor: 'pointer' }}
		/>
	</div>
);

interface ConfigureFormTabBarProps {
	showVersions: boolean;
	showSettings: boolean;
	showTodo: boolean;
	onTodo: () => void;
	onPlus: () => void;
	onRemove: () => void;
}

const GiftTabBar = ({
	showVersions,
	showSettings,
	showTodo,
	onTodo,
	onPlus,
	onRemove,
}: ConfigureFormTabBarProps) => {
	const { setSelectedVersion, selectedVersion } =
		useConfigurationStep();

	const color = {
		[VersionType.Winner]: 'purple',
		[VersionType.Versiona]: 'indigo',
		[VersionType.Versionb]: 'dark-turquoise',
		settings: 'gray-dark',
	}[selectedVersion as VersionType | 'settings'];

	const showTab2 = showVersions;
	const showPlus = !showVersions;

	return (
		<div
			className={`bg-${color} text-white d-flex rounded-top p-0 justify-content-between`}
		>
			{/* Tabs Container */}
			<div
				className={`bg-${color} text-white d-flex rounded-top p-0`}
			>
				<Tab
					color={'purple'}
					text="Version A"
					onClick={() => {
						setSelectedVersion(
							VersionType.Versiona,
						);
					}}
					version={VersionType.Versiona}
				/>
				{showTab2 && (
					<Tab
						color={'turquoise'}
						text="Version B"
						onClick={() => {
							setSelectedVersion(
								VersionType.Versionb,
							);
						}}
						onDeleteClick={() => {
							onRemove();
						}}
						version={VersionType.Versionb}
					/>
				)}

				{showPlus && (
					<PlusTab
						onClick={() => {
							if (!showVersions) {
								onPlus();
							}
						}}
					/>
				)}
			</div>

			{/* end tabs container ^^^ */}
			<TodoButton
				showTodo={showTodo}
				onTodo={onTodo}
			/>
		</div>
	);
};

export default GiftTabBar;
