import {
	FormEventHandler,
	useEffect,
	useRef,
	useState,
} from 'react';
import { toastToFailure } from '../../components/trigger-toasts/toast-to-failure';
import { toastToSuccess } from '../../components/trigger-toasts/toast-to-success';
import {
	GetExperienceDocument,
	HybridExperience,
	useUpdateHybridExperienceMutationMutation,
} from '../../gql/queries/generated/graphql';
import { delay } from '../../util/thennables/delay';

const Namebar = ({
	experience,
}: {
	experience: HybridExperience;
}) => {
	const { name } = experience;

	const editInput = useRef<HTMLInputElement | null>(null);

	const [nameInput, setNameInput] = useState('');
	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);

	const isNameValid = nameInput.length > 0;

	const [updateExperience] =
		useUpdateHybridExperienceMutationMutation({
			variables: {
				hybridExperience: {
					id: experience.id,
					name: nameInput,
				},
			},
			refetchQueries: [GetExperienceDocument],
		});

	const focusEditInput = () => {
		setNameInput(name || '');
		setEditing(true);
		editInput?.current?.focus();
	};

	const cancel = () => setEditing(false);

	const onSubmitName: FormEventHandler = e => {
		setLoading(true);
		e.preventDefault();
		if (isNameValid) {
			Promise.resolve()
				.then(() => updateExperience())
				.then(() => setLoading(false))
				.then(() =>
					toastToSuccess(
						'Your experience name has been changed!',
					),
				)
				.then(delay(200))
				.catch(() => {
					toastToFailure(
						'Experience name Not Saved',
					);
					setLoading(false);
				})
				.finally(() => cancel());
		} else {
			toastToFailure(
				'Your experience name cannot be blank. Please enter a name.',
			);
			setLoading(false);
		}
	};

	const escPress = (e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			cancel();
		}
	};

	useEffect(() => {
		document.addEventListener(
			'keydown',
			escPress,
			false,
		);
		return () =>
			document.removeEventListener(
				'keydown',
				escPress,
				false,
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<header className="d-flex justify-content-between w-100 mx-auto pt-2 px-3 text-center">
			<div className="d-flex align-items-center mx-auto">
				{editing ? (
					<form onSubmit={onSubmitName}>
						<div className="input-group">
							<input
								id="editInput"
								type="text"
								value={nameInput}
								autoComplete="off"
								className="form-control"
								onChange={e =>
									setNameInput(
										e.target.value,
									)
								}
								style={{
									backgroundColor:
										'transparent',
									color: '#fff',
									fontSize: '1rem',
								}}
								// on focus leave
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								ref={editInput}
							/>
							<button
								className={`fas text-white btn ${
									loading
										? 'disabled fa-spinner'
										: 'fa-check'
								}`}
								style={{
									backgroundColor:
										'#8a69ff',
								}}
								onClick={onSubmitName}
							/>
						</div>
					</form>
				) : (
					<p
						className="text-white mb-0"
						id="create-experience-title"
						style={{
							fontWeight: 500,
							fontSize: '1.5rem',
							cursor: 'pointer',
						}}
						onClick={() => focusEditInput()}
					>
						{name}
						<span className="m-3">
							<i
								className="fas fa-pen"
								style={{
									fontSize: '1rem',
									color: '#8a69ff',
								}}
							/>
						</span>
					</p>
				)}
			</div>
		</header>
	);
};

export default Namebar;
