import { HTMLAttributes } from 'react';

const HighlightedBox = ({
	color,
	style = {},
	children,
}: {
	color: string;
	children: JSX.Element | JSX.Element[];
	style?: HTMLAttributes<HTMLDivElement>['style'];
}) => (
	<div
		className="col p-1 bg-black"
		style={{ height: '100%', width: '100%' }}
	>
		<div
			className={`col d-flex bg-dark rounded rounded-2  p-2`}
			style={{
				...style,
				border: `3px solid ${color}`,
			}}
		>
			{children}
		</div>
	</div>
);

export default HighlightedBox;
