import toast from 'react-hot-toast';

/** Send a toast to notify a success to the user */
export const toastToSuccess = (message: string) =>
	toast.success(message, {
		duration: 4000,
		position: 'top-center',
		className: 'bg-green text-white',
		// Emoji
		icon: '🎉',
		// Aria
		ariaProps: {
			role: 'status',
			'aria-live': 'polite',
		},
	});
