import { SelectedGift } from '../../../gql/queries/generated/graphql';
import { useConfigurationStep } from './select-gift-step/select-gift-step-provider';

const CartCard = ({ gift }: { gift: SelectedGift }) => {
	const { removeSelectedGift, isLoading } =
		useConfigurationStep();

	const onRemove = () => {
		removeSelectedGift(gift);
	};

	return (
		<div className="row bg-white rounded rounded-3 mx-1 my-2">
			<div className="col d-flex flex-column">
				<div className="ratio ratio-4x3 my-auto text-center">
					<img
						src={
							gift.gift.previewImage?.url ||
							''
						}
						className="h-100 img-fluid"
					/>
				</div>
			</div>
			<div className="d-flex flex-column justify-content-evenly col-xxl-4 pb-3">
				<h1 className="d-flex fs-1x justify-content-center text-center fw-bolder text-purple">
					{gift.gift.name}
				</h1>
				<div className="d-flex justify-content-center">
					<button
						style={{
							borderRadius: '50px',
						}}
						type="button"
						className="d-flex btn btn-outline-dark btn-outline fw-bold color-black"
						disabled={isLoading}
						onClick={() => onRemove()}
					>
						Remove
					</button>
				</div>
			</div>
		</div>
	);
};
export default CartCard;
