import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = initializeApp({
	apiKey: 'AIzaSyDvipJkegUUsAxLc8B9VZa62wqJJQxnd1U',
	authDomain: 'project-hermes-bdf99.firebaseapp.com',
	projectId: 'project-hermes-bdf99',
	storageBucket: 'project-hermes-bdf99.appspot.com',
	messagingSenderId: '298143674054',
	appId: '1:298143674054:web:2707cb1e3fbf4e75bf31c7',
});

export const auth = getAuth();

export const appDomain = 'localhost:3000';
