import { useHistory } from 'react-router';
import {
	ExperienceStep,
	HybridExperience,
} from '../../../gql/queries/generated/graphql';
import PrevAndNextContainer from '../components/prev-and-next-container';
import { useCurrentExperience } from '../current-experience.provider';
import ConfigurePrintBrowser from './configure-print-browser';
import ProductCarousel from './product-carousel';

const PrintCustomization = () => {
	const history = useHistory();
	const { data } = useCurrentExperience();
	const experience = data?.GetHybridExperienceById;
	const podFields = experience?.printOnDemandFields;
	const nextDisabled = !(
		experience?.maxStep === ExperienceStep.Review
	);

	return (
		<>
			<div className="d-flex flex-column flex-grow-1 text-white">
				<div className="row w-100 h-100 mx-auto px-0 d-flex flex-grow-1 mt-2 ">
					<div className="col-6 col-xxxl-7 p-2 d-flex flex-column">
						<ConfigurePrintBrowser />
					</div>
					<div
						className="col-6 col-xxxl-5 p-2"
						style={{
							flex: '1',
							flexDirection: 'column',
							display: 'flex',
						}}
					>
						{!podFields && (
							<img
								className="img-fluid my-auto mx-auto shadow"
								src="https://pflhermes.blob.core.windows.net/images/pod_notecard_generic.jpg"
							/>
						)}
						{podFields && (
							<ProductCarousel
								experienceId={
									experience.id || ''
								}
							/>
						)}
					</div>
				</div>
			</div>
			<PrevAndNextContainer
				experience={experience as HybridExperience}
				nextButtonLabel="Review"
				nextOnClick={() =>
					history.push(
						`/create-experience/${
							experience?.id || ''
						}/review`,
					)
				}
				prevOnClick={() => {
					history.push(
						`/create-experience/${
							experience?.id || ''
						}/choose-gift`,
					);
				}}
				nextButtonState={
					nextDisabled
						? 'next-disabled'
						: 'next-enabled'
				}
				prevButtonLabel={'Select Template'}
			/>
		</>
	);
};

export default PrintCustomization;
