const RenderWindow = ({
	emailHtml,
	subject,
}: {
	emailHtml: string;
	subject: string;
}) => {
	function createMarkup() {
		return { __html: emailHtml };
	}

	function Render() {
		return (
			<div
				className="mx-auto my-auto shadow"
				dangerouslySetInnerHTML={createMarkup()}
			/>
		);
	}
	return (
		<>
			<div
				style={{
					background: '#f6f9fc',
					border: '0px solid transparent',
				}}
				className="d-flex justify-content-center"
			>
				<h1 className=" py-3 text-dark">
					{subject}
				</h1>
			</div>
			<div
				className="shrink-content text-white border-2 d-flex flex-column scrollbar"
				style={{
					background: '#f6f9fc',
				}}
			>
				<Render />
			</div>
		</>
	);
};

export default RenderWindow;
