import { toastToFailure } from '../../../../../components/trigger-toasts/toast-to-failure';
import { toastToNotify } from '../../../../../components/trigger-toasts/toast-to-notify';
import { toastToSuccess } from '../../../../../components/trigger-toasts/toast-to-success';
import {
	Asset,
	GetAssetsByEntitlementAndAssetTypesDocument,
	GetExperienceDocument,
	RenderExperienceEmailVersionDocument,
	useDeleteAssetMutation,
	useUpdateHybridExperienceEmailHeroMutation,
	VersionType,
} from '../../../../../gql/queries/generated/graphql';
import promptModal from '../../../../../providers/alert-provider/prompt-modal';
import { useCurrentExperience } from '../../../current-experience.provider';
import { useEmailConfigurationStep } from './providers/email-configuration-step.provider';

const ImageAssetCard = ({ asset }: { asset: Asset }) => {
	const [deleteAssetById] = useDeleteAssetMutation({
		variables: {
			assetId: asset.id,
		},
		refetchQueries: [
			GetAssetsByEntitlementAndAssetTypesDocument,
			GetExperienceDocument,
		],
	});
	const { data } = useCurrentExperience();
	const currentExperience = data?.GetHybridExperienceById;
	const { selectedVersion } = useEmailConfigurationStep();

	const [updateHero] =
		useUpdateHybridExperienceEmailHeroMutation();

	const handleDelete = () =>
		Promise.resolve()
			.then(
				promptModal(
					`Delete this asset?`,
					'Not Deleted',
				),
			)
			.then(() => deleteAssetById())
			.then(() => toastToSuccess('Deleted'))
			.catch(() => toastToNotify('Not Deleted'));

	const handleSelect = () =>
		Promise.resolve().then(() =>
			updateHero({
				variables: {
					heroImageAssetId: asset.id || '',
					hybridExperienceId:
						currentExperience?.id || '',
					version: selectedVersion as
						| VersionType.Versiona
						| VersionType.Versionb,
				},
				refetchQueries: [
					RenderExperienceEmailVersionDocument,
					GetExperienceDocument,
				],
			})
				.catch(error => {
					console.error(error);
					toastToFailure(
						'Failed to update, please try again',
					);
				})
				.then(() => {
					toastToSuccess('Updated successfully');
				}),
		);
	return (
		<>
			<div className={`col`}>
				<div className="card rounded-3 shadow-sm border-0">
					<div
						className="card-body"
						style={{
							height: '0',
							paddingTop: '50%',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundImage: `url('${
								asset.url || ''
							}')`,
						}}
					></div>
					<div className="card-footer bg-black text-white py-2 px-2">
						<div className="text-white text-shadow-1">
							<ul className="d-flex list-unstyled mb-0">
								<li className="me-auto">
									<button
										className="btn btn-sm btn-purple text-white align-self-end select-button"
										onClick={() =>
											handleSelect()
										}
									>
										Select
									</button>
								</li>
								<li className="ms-auto">
									<button
										className="btn btn-sm btn-red text-white align-self-end"
										onClick={() =>
											handleDelete()
										}
									>
										Delete
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default ImageAssetCard;
