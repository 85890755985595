import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { VersionType } from '../../gql/queries/generated/graphql';

// This represents a single option in the react selector
export interface IDynamicMappingOption {
	value: string;
	label: string;
	sampleValue: string | undefined; // Audience list headers have a sample value
}

// The combination of props required for a given row in the field mapping component
export interface FieldMappingRowProps {
	selectOptions: IDynamicMappingOption[];
	selectedOption: IDynamicMappingOption | undefined;
	staticValue: string | undefined;
	rowLabel: string | undefined;
	uniqueKey: string | undefined;
	isRequired: boolean;
	onMapChange: (
		selectedOption: IDynamicMappingOption | undefined,
		staticValue: string | undefined,
		rowLabel: string | undefined,
		uniqueKey: string | undefined,
		isRequired: boolean,
	) => void;
}

const FieldMappingRow = ({
	selectOptions,
	staticValue,
	rowLabel,
	selectedOption,
	isRequired,
	uniqueKey,
	onMapChange,
}: FieldMappingRowProps) => {
	// The react select option chosen for this row
	// If the row has been mapped, we need to set the selected option or default to a no value label
	const [selectedOptionState, setSelectedOptionState] =
		useState(selectedOption);

	// The static value for this row, or empty string if not mapped
	const [staticValueState, setStaticValueState] =
		useState(staticValue);

	useEffect(() => {
		onMapChange(
			selectedOptionState,
			staticValueState,
			rowLabel,
			uniqueKey,
			isRequired,
		);
	}, [selectedOptionState, staticValueState]);

	const customStyles = {
		control: (base: any) => ({
			...base,
			height: '42px',
		}),
	};

	return (
		<>
			<div className="row w-100 mx-auto px-0 my-2 d-flex g-1">
				<div className="col px-1">
					<p className="text-white px-0 my-auto fw-bold">
						{`${rowLabel}${
							isRequired ? '*' : ''
						}`}
					</p>
				</div>
				<div className="col px-1">
					<input
						type="text"
						value={staticValueState}
						onChange={e =>
							setStaticValueState(
								e.target.value,
							)
						}
						className="form-control static-value-input bg-white text-black"
					/>
				</div>
				<div className="col text-dark px-1">
					{selectOptions && (
						<ReactSelect
							onChange={reactSelectOption =>
								reactSelectOption &&
								setSelectedOptionState(
									reactSelectOption,
								)
							}
							value={selectedOptionState}
							options={selectOptions.map(
								singleOption =>
									singleOption,
							)}
							styles={customStyles}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export interface CallBackObject {
	selectedOption: IDynamicMappingOption | undefined;
	staticValue: string | undefined;
	rowLabel: string | undefined;
	uniqueKey: string | undefined;
}

interface FieldMappingProps {
	fieldLabel: string;
	staticLabel: string;
	mapLabel: string;
	selectOptions: IDynamicMappingOption[];
	fieldMappingRows: {
		uniqueKey: string | undefined;
		selectedOption: IDynamicMappingOption | undefined;
		staticValue: string | undefined;
		rowLabel: string | undefined;
		isRequired: boolean;
	}[];
	highlightColor: string;
	callback: (CurrentState: CallBackObject[]) => void;
	version: VersionType; // This is used so that on A/B versions we can have a unique key for the same rows (key + version) and trigger a reload of the state
}

export const FieldMapping = ({
	fieldLabel,
	staticLabel,
	mapLabel,
	fieldMappingRows,
	selectOptions,
	highlightColor,
	callback,
	version,
}: FieldMappingProps) => {
	const [fieldMappingRowState, setFieldMappingRowState] =
		useState<
			{
				selectedOption:
					| IDynamicMappingOption
					| undefined;
				staticValue: string | undefined;
				rowLabel: string | undefined;
				uniqueKey: string | undefined;
				isRequired: boolean;
			}[]
		>(
			fieldMappingRows.map(field => ({
				rowLabel: field.rowLabel,
				selectedOption: field.selectedOption,
				staticValue: field.staticValue,
				uniqueKey: field.uniqueKey,
				isRequired: field.isRequired,
			})),
		);

	const defaultOption = {
		value: '',
		label: 'No Selection',
		type: undefined,
		sampleValue: undefined,
	}; // default option for the react select

	const onMapChange = (
		selectedOption: IDynamicMappingOption | undefined,
		staticValue: string | undefined,
		rowLabel: string | undefined,
		uniqueKey: string | undefined,
		isRequired: boolean,
		// eslint-disable-next-line max-params
	) => {
		if (fieldMappingRowState) {
			const filteredRows =
				fieldMappingRowState.filter(
					field => field.uniqueKey !== uniqueKey,
				);
			const merge = [
				...filteredRows,
				{
					selectedOption,
					staticValue,
					rowLabel,
					uniqueKey,
					isRequired,
				},
			];
			setFieldMappingRowState(merge);
		}
	};

	useEffect(() => {
		if (fieldMappingRowState) {
			callback(fieldMappingRowState);
		}
	}, [fieldMappingRowState]);

	return (
		<>
			<div className="row mx-auto w-100 row-cols-3">
				<div className="col px-1">
					<h5
						className={`fw-bold text-${highlightColor}`}
					>
						{fieldLabel}
					</h5>
				</div>
				<div className="col px-1">
					<h5
						className={`fw-bold text-${highlightColor}`}
					>
						{staticLabel}
					</h5>
				</div>
				<div className="col px-1">
					<h5
						className={`fw-bold text-${highlightColor}`}
					>
						{mapLabel}
					</h5>
				</div>
			</div>
			{fieldMappingRows &&
				fieldMappingRows.map(
					(
						fieldMappingRow,
						fieldMappingRowIndex,
					) => (
						<FieldMappingRow
							key={`${fieldMappingRowIndex}-${version}`}
							uniqueKey={
								fieldMappingRow.uniqueKey
							}
							selectOptions={[
								defaultOption,
								...selectOptions,
							]}
							onMapChange={onMapChange}
							staticValue={
								fieldMappingRow.staticValue
							}
							rowLabel={
								fieldMappingRow.rowLabel
							}
							selectedOption={
								fieldMappingRow.selectedOption
							}
							isRequired={
								fieldMappingRow.isRequired
							}
						/>
					),
				)}
		</>
	);
};
